import { Dialog, Transition } from "@headlessui/react";
import * as Xstate from "@xstate/react";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import EditDots from "../../../../components/EditDots/EditDots";
import NoItems from "../../../../components/NoItems";
import { ContactsSvg } from "../../../../components/svg/ContactSvg";
import { FormSection } from "../../../../support/FormSection";
import { ILinkedProfileOrgContact } from "../../../../types/types";
import AddLinkedProfile from "./AddLinkedProfile";
import PlusSvg from "../../../../components/svg/PlusSvg";
import EditLinkedProfile from "./EditLinkedProfile";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import { OutlineButton } from "../../../../components/button/OutlineButton";

const OrganizationLinkedProfileBlock: React.FC<{ contactId?: string }> = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { organizationId } = useParams();
  const [linkedProfiles, setLinkedProfiles] = useState<
    ILinkedProfileOrgContact[]
  >([]);

  const [isEditLinkedProfilesPopupOpen, setIsEditLinkedProfilesPopupOpen] =
    useState(false);
  const [isAddLinkedProfilesPopupOpen, setIsAddLinkedProfilesPopupOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLinkedProfile, setCurrentLinkedProfile] =
    useState<ILinkedProfileOrgContact>({} as ILinkedProfileOrgContact);

  const closeEditLinkedProfilesPopupModal = () => {
    setIsEditLinkedProfilesPopupOpen(false);
  };
  const openEditLinkedProfilesPopupModal = () => {
    setIsEditLinkedProfilesPopupOpen(true);
  };
  const closeAddLinkedProfilesPopupModal = () => {
    setIsAddLinkedProfilesPopupOpen(false);
  };
  const openAddLinkedProfilesPopupModal = () => {
    setIsAddLinkedProfilesPopupOpen(true);
  };
  const getLinkedProfile = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/business-contacts/linked-profiles/get-by-business-contact-id/${organizationId}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: ILinkedProfileOrgContact[] = await response.json();
      setLinkedProfiles(data.filter((item) => item?.linkedContact));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getLinkedProfile().then();
  }, [organizationId]);

  const deleteLinkedProfile = async (
    linkedProfile: ILinkedProfileOrgContact,
  ) => {
    const response = await fetch(
      "https://dev.procharted.com/api/business-contacts/linked-profiles",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify({ id: linkedProfile.id }),
      },
    );
    if (response.ok) {
      getLinkedProfile();
    } else {
      setIsLoading(false);
    }
  };

  const editLinkedProfiles = (
    <>
      <Transition appear show={isEditLinkedProfilesPopupOpen} as={Fragment}>
        <Dialog
          as={"div"}
          className={"relative z-10"}
          onClose={closeEditLinkedProfilesPopupModal}>
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}>
            <div className={"fixed inset-0 bg-black bg-opacity-25"} />
          </Transition.Child>

          <div className={"fixed inset-0 overflow-y-auto"}>
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0 scale-95"}
                enterTo={"opacity-100 scale-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100 scale-100"}
                leaveTo={"opacity-0 scale-95"}>
                <Dialog.Panel
                  className={
                    "z-index-100 w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                  }>
                  <div className={"py-8"}>
                    <EditLinkedProfile
                      linkedProfile={currentLinkedProfile}
                      closeModal={closeEditLinkedProfilesPopupModal}
                      getOrganizationContact={getLinkedProfile}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
  const addLinkedProfiles = (
    <>
      <OutlineButton
        buttonType={"button"}
        label={""}
        colorType={"neutral"}
        icon={<PlusSvg />}
        onClick={openAddLinkedProfilesPopupModal}
      />
      <Transition appear show={isAddLinkedProfilesPopupOpen} as={Fragment}>
        <Dialog
          as={"div"}
          className={"relative z-10"}
          onClose={closeAddLinkedProfilesPopupModal}>
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}>
            <div className={"fixed inset-0 bg-black bg-opacity-25"} />
          </Transition.Child>

          <div className={"fixed inset-0 overflow-y-auto"}>
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0 scale-95"}
                enterTo={"opacity-100 scale-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100 scale-100"}
                leaveTo={"opacity-0 scale-95"}>
                <Dialog.Panel
                  className={
                    "z-index-100 w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                  }>
                  <div className={"py-8"}>
                    <AddLinkedProfile
                      setIsLoading={setIsLoading}
                      id={`${organizationId}`}
                      getOrganizationContact={getLinkedProfile}
                      closeModal={closeAddLinkedProfilesPopupModal}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );

  return (
    <>
      <FormSection
        customCx={"my-0 p-0"}
        extraCx={"space-y-0 static"}
        viewFormat={"custom"}
        name={""}
        showHeading={false}>
        <div className={"flex justify-between p-6"}>
          <div className={"text-lg font-bold"}>Linked Profile</div>
          <div>{addLinkedProfiles}</div>
        </div>

        <div className={"flex flex-col gap-6 px-6 pb-6"}>
          {isLoading ? (
            <LottieLoading />
          ) : Boolean(linkedProfiles.length === 0) ? (
            <div className={"p-6 pt-0"}>
              <NoItems
                icon={<ContactsSvg />}
                message={"There are no linked profiles"}
                addButtonMessage={"Add Profile"}
                openAdd={openAddLinkedProfilesPopupModal}
              />
            </div>
          ) : (
            linkedProfiles.map((item) => (
              <div key={item.id}>
                <div className={"flex items-center justify-between"}>
                  <div className={"flex items-center text-[14px]"}>
                    <AvatarPlaceholder
                      size={"extra-small"}
                      label={
                        item?.linkedContact?.firstName &&
                        item?.linkedContact?.lastName
                          ? `${item?.linkedContact?.firstName.substring(
                              0,
                              1,
                            )}${item?.linkedContact?.lastName.substring(0, 1)}`
                          : "User"
                      }
                    />
                    <div className={"ml-2"}>
                      <Link
                        to={
                          item?.linkedContact?.customers
                            ? `/client/${item?.linkedContact?.customers[0].id}`
                            : ""
                        }
                        className={
                          "link-primary text-sm font-medium hover:underline"
                        }>
                        {`${item?.linkedContact?.firstName} ${item?.linkedContact?.lastName}`}
                      </Link>
                      <div className={"text-gray-400"}>{item?.relation}</div>
                    </div>
                  </div>
                  <div>
                    <EditDots>
                      <div className={""}>
                        <div
                          onClick={() => {
                            setCurrentLinkedProfile(item);
                            openEditLinkedProfilesPopupModal();
                          }}
                          className={
                            "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                          }>
                          Edit
                        </div>
                        <div
                          onClick={() => {
                            setIsLoading(true);
                            deleteLinkedProfile(item);
                          }}
                          className={
                            "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                          }>
                          Delete
                        </div>
                      </div>
                    </EditDots>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </FormSection>
      {editLinkedProfiles}
    </>
  );
};

export default OrganizationLinkedProfileBlock;
