import * as Xstate from "@xstate/react";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import * as devConfig from "../../../../app/configs/devConfig";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import EditDots from "../../../../components/EditDots/EditDots";
import InputFile from "../../../../components/InputFile";
import PreviewFile from "../../../../components/PreviewFile";
import ContactFileSvg from "../../../../components/svg/ContactFileSvg";
import fileIcon from "../../../../components/svg/file.svg";
import { FormSection } from "../../../../support/FormSection";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";

const OrganizationFilesBlock = () => {
  const addFileRef = useRef<HTMLInputElement>(null);
  const { organizationId } = useParams();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [isLoading, setIsLoading] = useState(false);
  const [activeFile, setActiveFile] = useState<any>(null);
  const [contactFiles, setContactFiles] = useState<any[]>([]);
  const [error, setError] = useState("");

  const getFiles = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/contact-documents/get-by-busnessContactId/${organizationId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setContactFiles(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const addFile = async (formData: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/contact-documents/add-document`,
      {
        headers: {
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: formData,
      },
    );
    if (response.ok) {
      getFiles();
    } else {
      setIsLoading(false);
      setError("Something went wrong");
    }
  };
  const deleteFile = async (id: string) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/contact-documents?docId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
      },
    );
    if (response.ok) {
      getFiles();
    } else {
      setIsLoading(false);
    }
  };

  const handleButtonClick = async (id: string) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/contact-documents/preview-document/${id}`,
      {
        headers: {
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setActiveFile(data);
    } else {
    }
  };
  const handleFileChange = (file: File) => {
    const formData = new FormData();
    formData.append("document", file);
    formData.append("businessContactId", `${organizationId}`);
    formData.append("assignedUserRelayId", `${userInfoByEmail?.id}`);
    formData.append("notes", "");
    setIsLoading(true);
    addFile(formData)
      .then()
      .catch(() => {
        setIsLoading(false);
        alert("Something went wrong");
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getFiles().then();
  }, [organizationId]);

  return (
    <>
      <FormSection
        customCx={"my-0 p-0"}
        extraCx={"space-y-0 static"}
        viewFormat={"custom"}
        name={""}
        showHeading={false}>
        <div className={"flex items-center justify-between p-6"}>
          <div>
            <div className={"text-lg font-bold"}>Files</div>
            {error && <div className={"text-[14px] text-red-500"}>{error}</div>}
          </div>
          <InputFile
            setFile={handleFileChange}
            setError={setError}
            inputRef={addFileRef}
          />
        </div>
        <div style={{ marginTop: 0 }}>
          {contactFiles.length === 0 ? (
            isLoading ? (
              <LottieLoading />
            ) : (
              <div className={"p-6 pt-0"}>
                <div
                  className={
                    "flex h-[215px] flex-col items-center justify-center gap-3 rounded-[8px] border-[1px] border-[#E8E9EA]"
                  }>
                  <div
                    className={
                      "flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#7C66F014]"
                    }>
                    <ContactFileSvg />
                  </div>
                  <div className={"text-[16px] font-[500] text-[#1F2937] "}>
                    There are no files
                  </div>
                  <div>
                    <button
                      onClick={() => addFileRef?.current?.click()}
                      className={
                        "cursor-pointer rounded-[8px] bg-[#7C66F0] p-[15px] text-[14px] font-[500] text-white"
                      }>
                      Add File
                    </button>
                  </div>
                </div>
              </div>
            )
          ) : (
            <>
              {contactFiles?.map((file, index) => (
                <div key={file.id} className={"px-6  hover:bg-gray-100"}>
                  <div
                    onClick={() => handleButtonClick(file.id)}
                    className={
                      "flex cursor-pointer items-center justify-between py-4 "
                    }>
                    <div className={"flex items-center space-x-3 text-[14px]"}>
                      <div className={"h-[32px] w-[32px]"}>
                        <img src={fileIcon} alt={"file"} />
                      </div>
                      <div>
                        <div
                          className={
                            "... overflow-hidden text-ellipsis  whitespace-nowrap  text-[14px] font-[500] xl:max-w-[180px] 2xl:max-w-[220px] 3xl:max-w-[270px] 4xl:max-w-[320px]"
                          }>
                          {file.originalFileName}
                        </div>
                        <div className={"text-[12px] text-gray-400"}>
                          {moment(file.createdDate).format("MMM DD, YYYY")}
                        </div>
                      </div>
                    </div>
                    <div>
                      <EditDots>
                        <div className={""}>
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                              setIsLoading(true);
                              deleteFile(file.id);
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Delete
                          </div>
                        </div>
                      </EditDots>
                    </div>
                  </div>
                  {Boolean(index !== contactFiles.length - 1) && (
                    <div className={"h-[1px] bg-[#E8E9EA]"}></div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </FormSection>
      {activeFile && (
        <PreviewFile
          close={() => setActiveFile(null)}
          file={activeFile.documentUrl}
        />
      )}
    </>
  );
};

export default OrganizationFilesBlock;
