import * as Xstate from "@xstate/react";
import { DatePicker } from "baseui/datepicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { DATE_FORMATS } from "../../../../app/commonOps/CommonDateOps";
import { LABELS } from "../../../../app/constants/TextConstants";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import { IconButton } from "../../../../components/button/IconButton";
import ConfirmPopup from "../../../../components/ConfirmPopup";
import EditDots from "../../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import OrganizationIconSvg from "../../../../components/svg/organizationIconSvg";
import { WarningExclaimSvg } from "../../../../components/svg/WarningExclaimSvg";
import {
  getInitialsFromContactName,
  inactiveStatusId,
  validatePhoneFormat,
} from "../../../../constants/constants";
import { useDeleteContactMutation } from "../../../../services/ContactService";
import { CustomPage } from "../../../../support/CustomLink";
import { FormSection } from "../../../../support/FormSection";
import {
  IContactType,
  IOrganizationContact,
  IOrganizationType,
} from "../../../../types/types";
import DeleteMessageContactPopup from "../../DeleteMessageContactPopup";
import OrganizationFilesBlock from "./OrganizationFilesBlock";
import OrganizationLinkedProfileBlock from "./OrganizationLinkedProfileBlock";
import OrganizationNotesBlock from "./OrganizationNotesBlock";
import OrganizationWorksBlock from "./OrganizationWorksBlock";

interface IFormData {
  companyName: string;
  taxId: string;
  incorporationDate: null | Date;
  yearEnd: string;
  organizationTypeId: string;
  email: string;
  phoneNumber: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  additionalInfo: string;
  contactTypeId: string;
}

interface IOrganizationDetailsProps {
  setActivePage: (activePage: "profile" | "work" | "notes") => void;
  activePage: "profile" | "work" | "notes";
}

const OrganizationDetails: React.FC<IOrganizationDetailsProps> = ({
  setActivePage,
  activePage,
}) => {
  const navigate = useNavigate();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { organizationId } = useParams();
  const [organization, setOrganization] = useState<IOrganizationContact>(
    {} as IOrganizationContact,
  );
  const [confirmArchived, setConfirmArchived] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteContact, setIsDeleteContact] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [organizationTypes, setOrganizationTypes] = useState<
    IOrganizationType[]
  >([]);
  const [contactTypes, setContactTypes] = useState<IContactType[]>([]);
  const [deleteContactFetch, { isSuccess, data: newContact }] =
    useDeleteContactMutation();
  const getOrganizations = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/business-contacts/get-by-id/${organizationId}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setIsActive(
        Boolean(
          data?.contactStatusEnumId &&
            data?.contactStatusEnumId !== inactiveStatusId,
        ),
      );
      setOrganization(data);
      setValue("companyName", data?.companyName ? data.companyName : "");
      setValue(
        "taxId",
        data?.socialIdentificationNumber ? data.socialIdentificationNumber : "",
      );
      setValue(
        "incorporationDate",
        data?.incorporationDate ? new Date(data.incorporationDate) : null,
      );
      setValue("yearEnd", data?.yearEnd ? data.yearEnd : "");
      setValue(
        "organizationTypeId",
        data?.organizationTypeId ? data.organizationTypeId : "",
      );
      setValue("email", data?.email ? data.email : "");
      setValue("phoneNumber", data?.businessNumber ? data.businessNumber : "");
      setValue("street", data?.address?.line1 ? data.address?.line1 : "");
      setValue("city", data?.address?.city ? data.address?.city : "");
      setValue("state", data?.address?.region ? data.address?.region : "");
      setValue(
        "zipCode",
        data?.address?.postalCode ? data.address?.postalCode : "",
      );
      setValue(
        "additionalInfo",
        data?.additionalInfo ? data.additionalInfo : "",
      );
      setValue(
        "contactTypeId",
        data?.contactTypeEnumId ? data.contactTypeEnumId : "",
      );
    }
    setIsLoading(false);
  };
  const getOrganizationTypes = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/contact-types/organization-types",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setOrganizationTypes(data);
    }
  };
  const getContactTypes = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/contact-types/all",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setContactTypes(data);
    }
  };

  const editOrganizationContact = async (data: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/business-contacts/update-business-contact",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      await getOrganizations();
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getOrganizations();
    getOrganizationTypes();
    getContactTypes();
  }, [organizationId]);
  const deactivateContact = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/business-contacts/deactivate-business-contact",
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({ id: organization.id }),
      },
    );
    if (response.ok) {
      await getOrganizations();
    } else {
      setIsLoading(false);
    }
  };
  const activateContact = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/business-contacts/activate-business-contact",
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({ id: organization.id }),
      },
    );
    if (response.ok) {
      await getOrganizations();
    } else {
      setIsLoading(false);
    }
  };
  const deleteContact = async () => {
    deleteContactFetch({
      id: organization?.businessCustomers[0].id,
      name: organization?.companyName,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);
  const isActivation = () => {
    return isActive ? deactivateContact() : activateContact();
  };

  async function closeModalWithConfirm() {
    setIsLoading(true);
    setConfirmArchived(false);
    await isActivation();
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues: {
      companyName: "",
      taxId: "",
      incorporationDate: null,
      yearEnd: "",
      organizationTypeId: "",
      email: "",
      phoneNumber: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      additionalInfo: "",
      contactTypeId: "",
    },
  });

  const onSubmit = (data: any) => {
    setIsEdit(false);
    editOrganizationContact({
      id: organization.id,
      contactStatusEnumId: organization?.contactStatusEnumId,
      companyName: data?.companyName,
      address: {
        ...organization?.address,
        city: data.city,
        region: data.state,
        line1: data.street,
        postalCode: data.zipCode,
      },
      email: data.email,
      incorporationDate: data.incorporationDate,
      socialIdentificationNumber: data.taxId,
      yearEnd: data.yearEnd,
      additionalInfo: data.additionalInfo,
      contactTypeEnumId: data.contactTypeId,
      organizationTypeId: data.organizationTypeId
        ? data.organizationTypeId
        : null,
      businessNumber: data.phoneNumber,
    });
  };

  if (isLoading) {
    return <LottieLoading />;
  }

  const editOrganizationContactInfoPopup = (
    <>
      {isEdit ? (
        <div className={"flex gap-4"}>
          <button
            onClick={() => setIsEdit(false)}
            className={
              " hover:bg-purple hover:border-purple h-[40px] rounded-[7px] border-[1px] border-black px-[20px] text-[14px] font-bold uppercase hover:text-white"
            }>
            Cancel
          </button>
          <button
            type={"submit"}
            className={
              " hover:bg-purple hover:border-purple h-[40px] rounded-[7px] border-[1px] border-black px-[20px] text-[14px] font-bold uppercase hover:text-white"
            }>
            Save
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsEdit(true)}
          className={
            " hover:bg-purple hover:border-purple h-[40px] rounded-[7px] border-[1px] border-black px-[20px] text-[14px] font-bold uppercase hover:text-white"
          }>
          Edit
        </button>
      )}
    </>
  );

  return (
    <>
      <div className={"flex justify-center"}>
        <div className={"w-full max-w-[1920px] "}>
          <div className={"... flex items-center gap-4"}>
            <div
              className={
                "flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#581C87]"
              }>
              <OrganizationIconSvg />
            </div>
            <div className={"flex items-end"}>
              <h1 className={"text-2xl"}>{organization?.companyName || "-"}</h1>
              {!isActive && (
                <div
                  className={"tooltip tooltip-bottom tooltip-warning"}
                  data-tip={LABELS.warnings.inactiveTooltip}>
                  <IconButton
                    size={"extra-small"}
                    colorType={"secondary"}
                    border={false}
                    extraCx={"ml-1 mt-1 rounded-full"}
                    IconSvg={WarningExclaimSvg}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={"flex items-center justify-between"}>
            <div className={"tabs my-8"}>
              <CustomPage
                onClick={() => setActivePage("profile")}
                isActive={activePage === "profile"}>
                {LABELS.features.profile}
              </CustomPage>
              <CustomPage
                onClick={() => setActivePage("work")}
                isActive={activePage === "work"}>
                {LABELS.features.work}
              </CustomPage>
              <CustomPage
                onClick={() => setActivePage("notes")}
                isActive={activePage === "notes"}>
                {LABELS.features.notes}
              </CustomPage>
            </div>
            <div>
              <EditDots>
                <div
                  onClick={() => setConfirmArchived(true)}
                  className={
                    " cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                  }>
                  {isActive ? "Archive" : "Activate"}
                </div>
                <div
                  onClick={() => setIsDeleteContact(true)}
                  className={
                    " cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                  }>
                  Delete
                </div>
              </EditDots>
            </div>
          </div>
          <div className={"flex gap-6"}>
            <FormSection
              customCx={"my-0 p-0"}
              extraCx={"space-y-0 h-fit text-[16px] w-1/3"}
              viewFormat={"custom"}
              name={""}
              showHeading={false}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"flex justify-between p-6"}>
                  <div className={"text-lg font-bold"}>Organization Info</div>
                  <div>{editOrganizationContactInfoPopup}</div>
                </div>
                {isEdit ? (
                  <div className={"flex flex-col gap-6 px-6 pb-6"}>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>FULL NAME</div>
                      <input
                        {...register("companyName", {
                          required: "This field is required",
                        })}
                        type={"text"}
                        className={"input input-bordered w-full"}
                      />
                      {errors?.companyName && (
                        <div className={"pt-2 text-sm font-bold text-error"}>
                          {errors?.companyName?.message}
                        </div>
                      )}
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>TAX ID</div>
                      <input
                        {...register("taxId")}
                        type={"text"}
                        className={"input input-bordered w-full"}
                      />
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>
                        INCORPORATION DATE
                      </div>
                      <div>
                        <Controller
                          control={control}
                          name={"incorporationDate"}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              overrides={{
                                MonthYearSelectPopover: {
                                  props: {
                                    overrides: {
                                      Body: {
                                        style: { zIndex: 10000 },
                                      },
                                    },
                                  },
                                },
                                Input: {
                                  props: {
                                    overrides: {
                                      Input: {
                                        style: () => ({
                                          backgroundColor: "#FFFFFF",
                                        }),
                                      },
                                      Root: {
                                        style: () => ({
                                          borderTopWidth: "1px",
                                          borderRightWidth: "1px",
                                          borderBottomWidth: "1px",
                                          borderLeftWidth: "1px",
                                          borderTopColor: "#D4D6D9",
                                          borderRightColor: "#D4D6D9",
                                          borderBottomColor: "#D4D6D9",
                                          borderLeftColor: "#D4D6D9",
                                        }),
                                      },
                                    },
                                  },
                                },
                                Popover: {
                                  props: {
                                    overrides: {
                                      Body: {
                                        style: {
                                          zIndex: 9999,
                                        },
                                      },
                                    },
                                  },
                                },
                              }}
                              value={value}
                              formatString={DATE_FORMATS.dateOnly}
                              onChange={({ date }) => onChange(date)}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>YEAR END</div>
                      <input
                        {...register("yearEnd")}
                        type={"text"}
                        className={"input input-bordered w-full"}
                      />
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>
                        ORGANIZATION TYPE
                      </div>
                      <select
                        {...register("organizationTypeId")}
                        className={"select select-bordered w-full"}>
                        {organizationTypes.map((item, index) => (
                          <option
                            value={item.id}
                            key={item.id}
                            selected={index === 0}>
                            {item.organizationType}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>EMAIL</div>
                      <input
                        {...register("email", {
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                            message: "Invalid email address format",
                          },
                        })}
                        type={"text"}
                        className={"input input-bordered w-full"}
                      />
                      {errors.email && (
                        <div className={"pt-2 text-sm font-bold text-error"}>
                          {errors.email.message}
                        </div>
                      )}
                    </div>

                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>PHONE NUMBER</div>
                      <input
                        {...register("phoneNumber", {
                          validate: {
                            phoneFormat: (value) =>
                              value
                                ? validatePhoneFormat(value) ||
                                  "Invalid phone number format"
                                : true,
                          },
                        })}
                        type={"text"}
                        className={"input input-bordered w-full"}
                      />
                      {errors.phoneNumber && (
                        <span className={"pt-2 text-sm font-bold text-error"}>
                          {errors.phoneNumber.message}
                        </span>
                      )}
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>CONTACT TYPE</div>
                      <select
                        {...register("contactTypeId")}
                        className={"select select-bordered w-[calc(50%-8px)]"}>
                        {contactTypes.map((item) => (
                          <option value={item?.id} key={item.id}>
                            {item?.contactType}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>ADDRESS</div>
                      <div className={"mb-4"}>
                        <input
                          {...register("street")}
                          placeholder={"Street Address"}
                          type={"text"}
                          className={"input input-bordered w-full"}
                        />
                      </div>
                      <div className={"mb-4 flex gap-4"}>
                        <div className={"w-1/2"}>
                          <input
                            {...register("city")}
                            placeholder={"City"}
                            type={"text"}
                            className={"input input-bordered w-full"}
                          />
                        </div>
                        <div className={"w-1/2"}>
                          <input
                            {...register("state")}
                            placeholder={"State / Province"}
                            type={"text"}
                            className={"input input-bordered w-full"}
                          />
                        </div>
                      </div>
                      <div className={"w-[calc(50%-8px)]"}>
                        <input
                          {...register("zipCode")}
                          placeholder={"Zip / Postal Code"}
                          type={"text"}
                          className={"input input-bordered w-full"}
                        />
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>
                        ADDITIONAL INFO
                      </div>
                      <textarea
                        {...register("additionalInfo")}
                        className={"input input-bordered min-h-[100px] w-full"}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={"flex flex-col gap-6 px-6 pb-6"}>
                    <div>
                      <div className={"mb-2 text-gray-400"}>FULL NAME</div>
                      <div className={"flex items-center"}>
                        <AvatarPlaceholder
                          size={"extra-small"}
                          type={"Organization"}
                          label={
                            organization?.companyName
                              ? getInitialsFromContactName(
                                  organization?.companyName,
                                )
                              : "N/A"
                          }
                        />
                        <div
                          className={"pl-2 text-[14px] font-[500] normal-case"}>
                          <div className={"mt-0"}>
                            {`${organization?.companyName}`}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>TAX ID</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {organization?.socialIdentificationNumber || "-"}
                      </div>
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>
                        INCORPORATION DATE
                      </div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {organization?.incorporationDate
                          ? moment(organization?.incorporationDate)
                              .add(1, "days")
                              .format("MMM DD, YYYY")
                          : "-"}
                      </div>
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>YEAR END</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {organization?.yearEnd || "-"}
                      </div>
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>
                        ORGANIZATION TYPE
                      </div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {organization?.organizationType?.organizationType ||
                          "-"}
                      </div>
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>EMAIL</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        <a
                          className={
                            "link-primary text-sm font-[500] hover:underline"
                          }
                          href={"mailto:abc@test.test"}>
                          {organization?.email || "-"}
                        </a>
                      </div>
                    </div>
                    <div className={"w-[calc(50%-8px)]"}>
                      <div className={"mb-2 text-gray-400"}>PHONE NUMBER</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {organization?.businessNumber || "-"}
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>CONTACT TYPE</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {organization?.contactType?.contactType}
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>ADDRESS</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {organization?.address?.line1
                          ? organization?.address?.line1 + ", "
                          : "-"}
                        {organization?.address?.city
                          ? organization?.address?.city + ", "
                          : "-"}
                        {organization?.address?.region
                          ? organization?.address?.region + ", "
                          : "-"}
                        {organization?.address?.postalCode
                          ? organization?.address?.postalCode
                          : "-"}
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>
                        ADDITIONAL INFO
                      </div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {organization?.additionalInfo || "-"}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </FormSection>
            <div className={"flex h-fit w-1/3 flex-col gap-6"}>
              <OrganizationWorksBlock
                organizationContact={organization}
                isActive={isActive}
              />
              <OrganizationLinkedProfileBlock />
            </div>
            <div className={"flex h-fit w-1/3 flex-col gap-6"}>
              <OrganizationNotesBlock />
              <OrganizationFilesBlock />
            </div>
          </div>
        </div>
      </div>
      {isDeleteContact && (
        <DeleteMessageContactPopup
          isOpen={isDeleteContact}
          closePopup={() => setIsDeleteContact(false)}
          confirmDeleteContact={() => {
            setIsDeleteContact(false);
            deleteContact().then();
          }}
        />
      )}
      {confirmArchived && (
        <ConfirmPopup
          confirm={closeModalWithConfirm}
          close={() => setConfirmArchived(false)}
          title={
            isActive ? "Archive organization?" : "Reactivate organization?"
          }
          message={
            isActive ? (
              <>
                <div>
                  You are about to archive this organization. Their profile will
                  be
                </div>
                <div> moved to the "Archived" section. You can always</div>
                <div>reactivate them later</div>
              </>
            ) : (
              <>
                <div>
                  You are about to reactivate this organization. Their profile
                  will be
                </div>
                <div> moved to the "Active" section. You can always</div>
                <div>archive them later</div>
              </>
            )
          }
        />
      )}
    </>
  );
};

export default OrganizationDetails;
