import React, { useState } from "react";

import * as devConfig from "../../../app/configs/devConfig";
import {
  WORK_TASK_CHANGE_STATUS,
  WORK_TASK_DELETE,
} from "../../../app/constants/ApiEndpointConstants";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import EditDots from "../../../components/EditDots/EditDots";
import { IWorkTask, IWorkTemplateTask } from "../../../types/types";

interface IWorkTaskProps {
  task: IWorkTask | IWorkTemplateTask;
  isTemplate?: boolean;
  getWorkApiCall: () => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsUpdateTask: (isUpdateTask: boolean) => void;
  setCurrentTask: (task: IWorkTask | IWorkTemplateTask) => void;
}
const WorkTask: React.FC<IWorkTaskProps> = ({
  task,
  getWorkApiCall,
  setIsLoading,
  isTemplate,
  setCurrentTask,
  setIsUpdateTask,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const changeWorkTaskStatus = async (
    taskId: string | null,
    isDone: boolean,
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/${WORK_TASK_CHANGE_STATUS(
        taskId,
        isDone,
        isTemplate,
      )}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
      },
    );
    if (response.ok) {
      await getWorkApiCall();
    } else {
      setIsLoading(false);
    }
  };
  const deleteTask = async () => {
    const response = await fetch(
      isTemplate
        ? `${devConfig.getServerBaseUrlForEnv()}/api/template-task`
        : `${devConfig.getServerBaseUrlForEnv()}/${WORK_TASK_DELETE}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(task),
      },
    );
    if (response.ok) {
      await getWorkApiCall();
    } else {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <div
        data-id={`${task.id}`}
        data-isDone={`${task.isDone}`}
        className={
          "ui-state-default task w-100 flex items-center rounded-[7px] border-[1px] border-[#AFAFAF] bg-white  px-[20px]  py-[15px] text-[14px]"
        }>
        <img src={"/img/drag.png"} className={""} alt={""} />
        <span
          className={"relative ml-5 inline-block"}
          onClick={() => {
            changeWorkTaskStatus(task.id, !task.isDone);
          }}>
          <input
            onChange={() => {
              changeWorkTaskStatus(task.id, !task.isDone);
            }}
            className={"styled-checkbox"}
            id={"styled-checkbox-1"}
            type={"checkbox"}
            checked={Boolean(task.isDone)}
          />
          <label></label>
        </span>

        <div
          className={`text ml-4 ${
            task.isDone ? "line-through" : ""
          } w-full cursor-pointer pr-4`}
          onClick={() => setIsCollapsed(!isCollapsed)}>
          <div className={"mb-0 text-[14px] font-bold"}>
            {task?.name}
            <button type={"button"} className={"redit-btn"}>
              <img src={"/img/edit.png"} alt={""} />
            </button>
          </div>
          <div
            className={`mb-0  w-full text-[14px] text-[#6A7584] ${
              !isCollapsed
                ? "...  max-w-[370px] overflow-hidden text-ellipsis whitespace-nowrap break-words sm:max-w-[100px] 2xl:max-w-[300px]"
                : " max-w-[370px] whitespace-pre-line break-words sm:max-w-[100px] 2xl:max-w-[300px]"
            }`}>
            {task.description}
          </div>
        </div>
        <div
          className={
            "text-blackDark ml-auto flex w-1/4 items-center whitespace-nowrap text-[14px]"
          }>
          {task.isDone ? (
            <div className={"flex items-center"}>
              <div
                className={
                  "bg-green mr-2 h-[10px] w-[10px] rounded-[50%]"
                }></div>
              <div className={"text-blackDark text-[14px]"}>Complete</div>
            </div>
          ) : (
            <div className={"flex items-center"}>
              <div
                className={
                  "bg-yellow mr-2 h-[10px] w-[10px] rounded-[50%]"
                }></div>
              <div className={"text-blackDark text-[14px]"}>In progress</div>
            </div>
          )}
          <div className={"ml-8"}>
            <EditDots>
              <div className={""}>
                <div
                  onClick={() => {
                    setCurrentTask(task);
                    setIsUpdateTask(true);
                  }}
                  className={
                    "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                  }>
                  Edit
                </div>
                <div
                  onClick={() => {
                    setIsLoading(true);
                    deleteTask();
                  }}
                  className={
                    "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                  }>
                  Delete
                </div>
              </div>
            </EditDots>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkTask;
