import React from "react";
import { match } from "ts-pattern";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "../svg/SvgIconProps";

type Props = {
  buttonType?: "button" | "submit";
  colorType:
    | "primary"
    | "secondary"
    | "accent"
    | "ghost"
    | "link"
    | "neutral"
    | "warning"
    | "error";
  size?: "small" | "medium" | "large" | "extra-small";
  disabled?: boolean;
  wide?: boolean;
  outline?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  IconSvg: React.FunctionComponent<SvgIconProps>;
  border?: boolean;
  extraCx?: string;
};

export const IconButton: React.FC<Props> = (props) => {
  const {
    buttonType = "button",
    colorType = "primary",
    disabled = false,
    size = "medium",
    wide = false,
    outline = true,
    onClick,
    IconSvg,
    border = true,
    extraCx = "",
    onMouseEnter,
    onMouseLeave,
  } = props;

  const btnActiveClass: string = match(colorType)
    .with("primary", () => "btn-primary")
    .with("secondary", () => "btn-secondary")
    .with("accent", () => "btn-accent")
    .with("ghost", () => "btn-ghost")
    .with("link", () => "btn-link")
    .with("warning", () => "btn-warning")
    .with("error", () => "btn-error")
    .with("neutral", () => "")
    .exhaustive();

  const btnDisabledClass = "text-neutral border-neutral";

  const btnCx = disabled ? btnDisabledClass : btnActiveClass;

  const btnSizeCx: string = match(size)
    .with("large", () => "btn-lg")
    .with("small", () => "btn-sm")
    .with("extra-small", () => "btn-xs")
    .with("medium", () => "")
    .exhaustive();

  const btnWideCx = wide ? "btn-wide" : "";

  const svgIconSizeCx = match(size)
    .with("extra-small", () => TW_SIZES.hw.xs)
    .with("small", () => TW_SIZES.hw.sm)
    .with("medium", () => TW_SIZES.hw.md)
    .with("large", () => TW_SIZES.hw.lg)
    .exhaustive();

  const svgIconCx = `${svgIconSizeCx}`;
  const borderCx = border ? "border" : "border-0";
  const outlineCx = outline ? "btn-outline" : "";

  return (
    <button
      type={buttonType}
      className={`btn ${outlineCx} ${btnCx} ${btnSizeCx} ${btnWideCx} ${borderCx} ${extraCx}`}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {IconSvg && <IconSvg sizeCx={svgIconCx} />}
    </button>
  );
};
