import { Dialog, Transition } from "@headlessui/react";
import * as Xstate from "@xstate/react";
import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { DATES } from "../../app/commonOps/CommonDateOps";
import * as devConfig from "../../app/configs/devConfig";
import { WORK_GET_BY_ID } from "../../app/constants/ApiEndpointConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import {
  deleteTime,
  getAllTimes,
  getTimesByWork,
} from "../../services/AppService";
import { timerSlice, updateStatusTimer } from "../../services/TimerService";
import { useSaveTimerMutation } from "../../services/UserService";
import {
  getWorkByContact,
  getWorkByOrganizationContact,
} from "../../services/WorkReducer";
import { IContactProfile, IWork } from "../../types/types";

interface IAddTimerProps {
  isOpen: boolean;
  closeModal: () => void;
}

const AddTimer: React.FC<IAddTimerProps> = ({ isOpen, closeModal }) => {
  const [uploadTimer] = useSaveTimerMutation();
  const dispatch = useAppDispatch();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { timer } = useAppSelector((state) => state.timerReducer);
  const { clearTimer, setStatusTimer } = timerSlice.actions;
  const [work, setWork] = useState<IWork>();
  const [contactId, setContactId] = useState({
    id: "",
    isBusinessContact: false,
  });
  const getContact = async (customerId: string) => {
    const response = await fetch(
      `https://dev.procharted.com/api/contacts/get/${customerId}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IContactProfile = await response.json();
      setContactId({
        id: data.contactId,
        isBusinessContact: false,
      });
    }
  };
  const getWorkApiCall = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/${WORK_GET_BY_ID(timer.workId)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const workState: IWork = await response.json();
      workState?.businessContactId
        ? setContactId({
            id: workState.businessContactId,
            isBusinessContact: true,
          })
        : await getContact(workState.customerId);
      setWork(workState);
    }
  };
  useEffect(() => {
    if (timer.workId) {
      getWorkApiCall().then(() => {});
    }
  }, []);
  useEffect(() => {
    if (contactId.isBusinessContact) {
      if (contactId.id) {
        dispatch(
          getWorkByOrganizationContact({
            id: contactId.id,
            orgId: `${userInfoByEmail?.org?.id}`,
          }),
        );
      }
    } else {
      if (contactId.id) {
        dispatch(
          getWorkByContact({
            contactId: contactId.id,
            orgId: `${userInfoByEmail?.org?.id}`,
          }),
        );
      }
    }
  }, [contactId]);

  const { register, control, handleSubmit, setFocus } = useForm({
    defaultValues: {
      date: DATES.today(),
      notes: "",
    },
  });
  const onSubmit = async (data: any) => {
    await uploadTimer({
      duration: timer.duration,
      workId: timer.workId,
      notes: data.notes,
      timerStatus: "ended",
      assignedUserRelayId: userInfoByEmail!.id,
      contactId: timer.contactId,
      businessContactId: timer.businessContactId,
      date: data.date,
      id: timer.id,
    });
    dispatch(clearTimer());
    dispatch(getAllTimes(`${userInfoByEmail?.org?.id}`));
    dispatch(
      getTimesByWork({
        jobId: `${timer.workId}`,
        orgId: `${userInfoByEmail?.org?.id}`,
      }),
    );
    closeModal();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as={"div"} className={"relative z-10"} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}>
          <div className={"fixed inset-0 bg-black bg-opacity-25"} />
        </Transition.Child>

        <div className={"fixed inset-0 overflow-y-auto"}>
          <div
            className={
              "flex min-h-full items-center justify-center p-4 text-center"
            }>
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 scale-95"}
              enterTo={"opacity-100 scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 scale-100"}
              leaveTo={"opacity-0 scale-95"}>
              <Dialog.Panel
                className={
                  "z-index-100 w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                }>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className={"w-full text-center"}>Confirm Time</div>

                    <div className={"mb-4"}>
                      <div className={"label-text mb-2"}>Contact</div>
                      <div
                        className={
                          "input input-bordered flex w-full items-center disabled:border-[#D4D6D9] disabled:bg-transparent"
                        }>
                        {work?.customerId
                          ? work?.customer.name
                          : work?.businessContact.companyName}
                      </div>
                    </div>
                    <div className={"mb-4"}>
                      <div className={"label-text mb-2"}>Work</div>
                      <div
                        className={
                          "input input-bordered flex w-full items-center disabled:border-[#D4D6D9] disabled:bg-transparent"
                        }>
                        {timer.work.name}
                      </div>
                    </div>
                    <div className={"mb-4 flex justify-between space-x-[20px]"}>
                      <div className={"w-full"}>
                        <div className={"label-text mb-2"}>Date</div>
                        <div>
                          {/*<Controller
                            control={control}
                            name={"date"}
                            render={({ field: { onChange, value } }) => (
                              <DatePicker
                                overrides={{
                                  Input: {
                                    props: {
                                      overrides: {
                                        Input: {
                                          style: () => ({
                                            backgroundColor: "#FFFFFF",
                                            color: "black",
                                          }),
                                        },
                                        Root: {
                                          style: () => ({
                                            borderTopWidth: "1px",
                                            borderRightWidth: "1px",
                                            borderBottomWidth: "1px",
                                            borderLeftWidth: "1px",
                                            borderTopColor: "#D4D6D9",
                                            borderRightColor: "#D4D6D9",
                                            borderBottomColor: "#D4D6D9",
                                            borderLeftColor: "#D4D6D9",
                                          }),
                                        },
                                      },
                                    },
                                  },
                                  Popover: {
                                    props: {
                                      overrides: {
                                        Body: {
                                          style: {
                                            zIndex: 9999,
                                          },
                                        },
                                      },
                                    },
                                  },
                                }}
                                disabled={true}
                                value={value}
                                formatString={DATE_FORMATS.dateOnly}
                                onChange={({ date }) => onChange(date)}
                              />
                            )}
                          />*/}
                          <div
                            className={
                              "input input-bordered flex items-center"
                            }>
                            {moment(DATES.today()).format("YYYY-MM-DD")}
                          </div>
                        </div>
                      </div>
                      <div className={"w-full"}>
                        <div className={"label-text mb-2"}>Duration</div>
                        <div>
                          <div
                            className={
                              "input input-bordered flex w-full items-center disabled:border-[#D4D6D9] disabled:bg-transparent"
                            }>
                            {timer.duration
                              ? `${moment
                                  .duration(timer.duration, "seconds")
                                  .hours()}h ${moment
                                  .duration(timer.duration, "seconds")
                                  .minutes()}m`
                              : "0h 0m"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"mb-4"}>
                      <div className={"label-text mb-2"}>Notes</div>
                      <div>
                        <textarea
                          autoFocus={true}
                          {...register("notes")}
                          className={
                            "input input-bordered min-h-[100px] w-full py-[8px]"
                          }
                        />
                      </div>
                    </div>
                    <div className={"flex justify-between"}>
                      <div>
                        <button
                          onClick={() => {
                            dispatch(
                              timer.workId
                                ? updateStatusTimer({
                                    workId: timer.workId,
                                    status: "ended",
                                  })
                                : clearTimer(),
                            );
                            if (timer.id) {
                              dispatch(deleteTime(timer));
                            }
                            closeModal();
                          }}
                          className={
                            "inline-block h-[40px] rounded-[7px] border-solid border-[#F15252]  bg-[#F15252] px-[20px] text-[14px] uppercase leading-[40px] text-white"
                          }>
                          Delete
                        </button>
                      </div>
                      <div className={"flex space-x-[10px]"}>
                        <div>
                          <button
                            type={"button"}
                            onClick={() => {
                              dispatch(
                                setStatusTimer({ timerStatus: "started" }),
                              );
                              closeModal();
                            }}
                            className={
                              "bg-purple border-purple inline-block h-[40px] rounded-[7px]  border-solid px-[20px] text-[14px] uppercase leading-[40px] text-white"
                            }>
                            Cancel
                          </button>
                        </div>
                        <div>
                          <button
                            type={"submit"}
                            className={
                              "bg-purple border-purple inline-block h-[40px] rounded-[7px]  border-solid px-[20px] text-[14px] uppercase leading-[40px] text-white"
                            }>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddTimer;
