import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomLink } from "../../support/CustomLink";
import { GetPageTitle } from "../../support/ScrollToTop";
import { ActiveCustomersListView } from "./ActiveCustomersListView";
import { AddCustomerView } from "./AddCustomerView";
import { DeactivatedCustomersListView } from "./DeactivatedCustomersListView";

export const CustomersView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Contacts");
  });

  return (
    <PageLayout title={LABELS.features.contacts}>
      <div className={"tabs my-4"}>
        <CustomLink to={ROUTE_NAMES.customers.active.index}>
          {LABELS.features.activeContacts}
        </CustomLink>
        <CustomLink to={ROUTE_NAMES.customers.deactivated.index}>
          {LABELS.features.deactivatedContacts}
        </CustomLink>
      </div>

      <Routes>
        <Route
          index
          element={
            <Navigate
              to={`./${ROUTE_NAMES.customers.active.index}`}
              replace={true}
            />
          }
        />
        <Route path={ROUTE_NAMES.customers.active.index} element={<Outlet />}>
          <Route index element={<ActiveCustomersListView />} />
          <Route
            path={ROUTE_NAMES.customers.active.add}
            element={<AddCustomerView />}
          />
        </Route>
        <Route
          path={ROUTE_NAMES.customers.deactivated.index}
          element={<Outlet />}>
          <Route index element={<DeactivatedCustomersListView />} />
        </Route>
        <Route path={"*"} element={<>Not found</>} />
      </Routes>

      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};
