import React from "react";
import { match } from "ts-pattern";
interface IButtonProps {
  id?: string;
  buttonType?: "button" | "submit";
  label: string;
  disabled?: boolean;
  onClick?: () => void;
  extraClasses?: string;
  size?: "custom" | "normal" | "small";
  customSize?: string;
  icon?: JSX.Element;
  colorType?:
    | "primary"
    | "secondary"
    | "accent"
    | "ghost"
    | "link"
    | "neutral"
    | "warning";
}
const Button: React.FC<IButtonProps> = ({
  id,
  colorType = "secondary",
  buttonType,
  disabled,
  onClick,
  label,
  extraClasses,
  size = "normal",
  customSize,
  icon,
}) => {
  const btnActiveClass: string = match(colorType)
    .with("primary", () => "btn-primary")
    .with("secondary", () => "bg-purple border-none")
    .with("accent", () => "btn-accent")
    .with("ghost", () => "btn-ghost")
    .with("link", () => "btn-link")
    .with("warning", () => "btn-warning")
    .with("neutral", () => "")
    .exhaustive();

  const buttonSize = match(size)
    .with("small", () => "px-[14px] py-[8px]")
    .with("normal", () => "px-4 py-[14px]")
    .with("custom", () => customSize)
    .exhaustive();

  return (
    <button
      id={id}
      type={buttonType}
      className={`flex cursor-pointer items-center justify-center gap-2 rounded-[8px] ${btnActiveClass} btn w-fit text-[14px] font-medium ${buttonSize} ${
        extraClasses ?? "uppercase"
      } `}
      disabled={disabled}
      onClick={onClick}>
      {icon && <div className={"h-fit"}>{icon}</div>}
      {label && <div className={"h-fit"}>{label}</div>}
    </button>
  );
};

export default Button;
