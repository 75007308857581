import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import * as devConfig from "../../app/configs/devConfig";
import { WORK_GET_BY_ID } from "../../app/constants/ApiEndpointConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import ConfirmPopup from "../../components/ConfirmPopup";
import EditDots from "../../components/EditDots/EditDots";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomPage } from "../../support/CustomLink";
import { GetPageTitle } from "../../support/ScrollToTop";
import { IWorkTask } from "../../types/types";
import { WorkModel } from "../work-charts/models/work.model";
import WorkNotes from "./Notes/WorkNotes";
import { Work } from "./Work";
import WorkTime from "./WorkTime";

export const WorkView: React.FC<unknown> = () => {
  const { jobId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [workState, setWorkState] = useState<WorkModel | any>();
  const [workTasks, setWorkTasks] = useState<IWorkTask[]>([]);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDeleteWork, setErrorDeleteWork] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [activePage, setActivePage] = useState<"details" | "time" | "notes">(
    "details",
  );

  useEffectOnce(() => {
    document.title = GetPageTitle("Work");
  });

  const getWorkApiCall = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/${WORK_GET_BY_ID(jobId)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const model: WorkModel = await response.json();
      model ? setWorkState(model) : navigate(-1);
    } else {
      navigate(-1);
    }
  };
  const getWorkTasks = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/work-tasks/get-by-jobId/${jobId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IWorkTask[] = await response.json();
      setWorkTasks(
        data.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else return -1;
        }),
      );
      setCompletedTasks(data?.filter((x) => x.isDone)?.length ?? 0);
      setTotalTasks(data?.length ?? 0);

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const deleteWork = async (id: string) => {
    const response = await fetch("https://dev.procharted.com/api/work", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
    if (response.ok) {
      setConfirmDelete(false);
      navigate(-1);
    } else {
      setIsLoading(false);
      setConfirmDelete(false);
      setErrorDeleteWork("Something went wrong");
    }
  };
  const getData = async () => {
    await getWorkApiCall();
    await getWorkTasks();
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (jobId) {
      getData().then();
    }
  }, [jobId, location.pathname]);

  const viewPage = () => {
    switch (activePage) {
      case "details":
        return (
          <Work
            workState={workState}
            workTasks={workTasks}
            completedTasks={completedTasks}
            totalTasks={totalTasks}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            getWorkApiCall={getWorkApiCall}
            getWorkTasks={getWorkTasks}
            getData={getData}
          />
        );
      case "time":
        return <WorkTime />;
      case "notes":
        return <WorkNotes />;
      default:
        return <>Not found</>;
    }
  };

  if (!workState) {
    return <></>;
  }

  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <div className={"flex justify-center"}>
      <div
        className={
          "w-fit pl-24 pr-32 xl:min-w-[1300px] 3xl:min-w-[1500px] 4xl:min-w-[1700px]"
        }>
        <PageLayout
          title={
            <span>
              {workState?.name} |{" "}
              <span className={"text-gray-500"}>
                {workState?.customer?.name ||
                  workState?.businessContact?.companyName}
              </span>
            </span>
          }>
          <div className={"flex justify-between"}>
            <div className={"mt-6 flex"}>
              <CustomPage
                onClick={() => setActivePage("details")}
                isActive={activePage === "details"}>
                Details
              </CustomPage>
              <CustomPage
                onClick={() => setActivePage("time")}
                isActive={activePage === "time"}>
                Time
              </CustomPage>
              <CustomPage
                onClick={() => setActivePage("notes")}
                isActive={activePage === "notes"}>
                Notes
              </CustomPage>
            </div>
            <div className={"flex flex-col items-end"}>
              <EditDots>
                <div className={""}>
                  <div
                    onClick={() => {
                      setConfirmDelete(true);
                    }}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Delete
                  </div>
                </div>
              </EditDots>
              {errorDeleteWork && (
                <div className={"mt-4 text-[14px] text-red-600"}>
                  {errorDeleteWork}
                </div>
              )}
              {confirmDelete && (
                <ConfirmPopup
                  confirm={() => {
                    setIsLoading(true);
                    deleteWork(workState.id).then();
                  }}
                  close={() => setConfirmDelete(false)}
                  title={"Delete work?"}
                  message={
                    <>
                      <div>
                        You are about to delete this work item. All progress,
                      </div>
                      <div>
                        {" "}
                        including <span className={"font-bold"}>
                          Files
                        </span> and <span className={"font-bold"}>Notes</span>,
                        will be deleted.
                      </div>
                      <div> Do you wish to proceed?</div>
                    </>
                  }
                />
              )}
            </div>
          </div>
          {viewPage()}
        </PageLayout>
      </div>
    </div>
  );
};
