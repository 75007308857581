import * as Xstate from "@xstate/react";
import moment from "moment";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import * as devConfig from "../../app/configs/devConfig";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import EditDots from "../../components/EditDots/EditDots";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import NoItems from "../../components/NoItems";
import PreviewFile from "../../components/PreviewFile";
import ContactFileSvg from "../../components/svg/ContactFileSvg";
import fileIcon from "../../components/svg/file.svg";
import { FormSection } from "../../support/FormSection";
import InputFile from "../../components/InputFile";

interface IFile {
  assignedUserRelayId: string;
  createdDate: Date;
  documentName: string;
  documentSize: number;
  id: string;
  notes: string;
  originalFileName: string;
  userId: string;
  workId: string;
  documentURI: string;
}

const WorkFiles: React.FC<unknown> = () => {
  const { jobId } = useParams();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const addFileRef = useRef<HTMLInputElement>(null);
  const [newFile, setNewFile] = useState<File>();
  const [workFiles, setWorkFiles] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeFile, setActiveFile] = useState<any>(null);
  const [error, setError] = useState("");

  const getFiles = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/documents/get-by-workId/${jobId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setWorkFiles(data);
    } else {
    }
    setIsLoading(false);
  };
  const addFile = async (formData: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/documents/add-document`,
      {
        headers: {
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: formData,
      },
    );
    if (response.ok) {
      getFiles().then();
    } else {
      setError("Something went wrong");
    }
  };
  const deleteFile = async (id: string) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/documents?docId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
      },
    );
    if (response.ok) {
      getFiles().then();
    } else {
      setIsLoading(false);
      setError("Something went wrong");
    }
  };

  useEffect(() => {
    getFiles().then();
  }, []);

  const handleFileChange = (file: File) => {
    const formData = new FormData();
    formData.append("document", file);
    formData.append("workId", `${jobId}`);
    formData.append("assignedUserRelayId", `${userInfoByEmail?.id}`);
    formData.append("notes", "");
    setIsLoading(true);
    addFile(formData)
      .then()
      .catch(() => {
        setIsLoading(false);
        setError("Something went wrong");
      });
  };
  const handleButtonClick = async (id: string) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/documents/preview-document/${id}`,
      {
        headers: {
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setActiveFile(data);
    } else {
    }
  };

  return (
    <>
      <FormSection
        name={""}
        extraCx={"h-[343px] overflow-y-auto static w-1/3"}
        cardBodyExtraCx={"space-y-0"}>
        <div
          className={`flex items-center justify-between pb-4 ${
            workFiles.length !== 0 && "border-b-[1px] border-[#eaeaea]"
          }`}>
          <div>
            <div className={"text-lg font-bold"}>Files</div>
            {error && <div className={"text-[14px] text-red-500"}>{error}</div>}
          </div>
          <InputFile
            setFile={handleFileChange}
            setError={setError}
            inputRef={addFileRef}
          />
        </div>
        {isLoading ? (
          <LottieLoading />
        ) : (
          <div className={""}>
            {workFiles.length === 0 ? (
              <NoItems
                height={"200px"}
                icon={<ContactFileSvg />}
                message={"There are no files"}
                addButtonMessage={"Add File"}
                openAdd={() => addFileRef?.current?.click()}
              />
            ) : (
              <>
                {workFiles?.map((file) => (
                  <div
                    key={file.id}
                    onClick={() => handleButtonClick(file.id)}
                    className={
                      "flex cursor-pointer items-center justify-between border-b-[1px]  border-[#E8E9EA] py-[18px] hover:bg-gray-100"
                    }>
                    <div className={"flex items-center space-x-3 text-[14px]"}>
                      <div className={"h-[32px] w-[32px]"}>
                        <img src={fileIcon} alt={"file"} />
                      </div>
                      <div>
                        <div
                          className={
                            "... overflow-hidden text-ellipsis  whitespace-nowrap  text-[14px] font-[500] xl:max-w-[180px] 2xl:max-w-[220px] 3xl:max-w-[270px] 4xl:max-w-[320px]"
                          }>
                          {file.originalFileName}
                        </div>
                        <div className={"text-[12px] text-gray-400"}>
                          {moment(file.createdDate).format("MMM DD, YYYY")}
                        </div>
                      </div>
                    </div>
                    <div>
                      <EditDots>
                        <div className={""}>
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                              setIsLoading(true);
                              deleteFile(file.id);
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Delete
                          </div>
                        </div>
                      </EditDots>
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className={"px-6 pb-6 hover:bg-gray-100"}>
                    <div className={"text-[14px]"}>Loading...</div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </FormSection>
      {activeFile && (
        <PreviewFile
          close={() => setActiveFile(null)}
          file={activeFile.documentUrl}
        />
      )}
    </>
  );
};

export default WorkFiles;
