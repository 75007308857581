import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

import Button from "../../components/button/Button";
import { OutlineButton } from "../../components/button/OutlineButton";

interface IDeleteMessageContactPopupProps {
  isOpen: boolean;
  closePopup: () => void;
  confirmDeleteContact: () => void;
}
const DeleteMessageContactPopup: React.FC<IDeleteMessageContactPopupProps> = ({
  closePopup,
  confirmDeleteContact,
  isOpen,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as={"div"} className={"relative z-10"} onClose={closePopup}>
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}>
          <div className={"fixed inset-0 bg-black bg-opacity-25"} />
        </Transition.Child>

        <div className={"fixed inset-0 overflow-y-auto"}>
          <div
            className={
              "flex min-h-full items-center justify-center p-4 text-center"
            }>
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 scale-95"}
              enterTo={"opacity-100 scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 scale-100"}
              leaveTo={"opacity-0 scale-95"}>
              <Dialog.Panel
                className={
                  "w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                }>
                <Dialog.Title
                  as={"h3"}
                  className={
                    "text-lg font-medium capitalize leading-6 text-gray-900"
                  }>
                  Delete contact
                </Dialog.Title>
                <div className={"mt-2"}>
                  <p className={"text-gray-500"}>
                    You are about to permanently delete this contact. All work
                    items associated with this contact will be deleted along
                    with all notes and files. This can't be undone. Are you sure
                    you want to continue?
                  </p>
                </div>

                <div className={"my-8 flex justify-start space-x-4"}>
                  <Button
                    buttonType={"button"}
                    label={"Yes"}
                    onClick={confirmDeleteContact}
                    extraClasses={"normal-case"}
                  />
                  <OutlineButton
                    colorType={"neutral"}
                    label={"No"}
                    onClick={closePopup}
                    extraClasses={"normal-case"}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteMessageContactPopup;
