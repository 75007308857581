import * as Xstate from "@xstate/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { getIndividualContacts } from "../../../api/api";
import { LABELS } from "../../../app/constants/TextConstants";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import SearchContact from "../../../components/searchContact";
import { validatePhoneFormat } from "../../../constants/constants";
import { useAddOrgContactMutation } from "../../../services/ContactService";
import {
  IContact,
  IContactType,
  ICustomers,
  IEmail,
  IPhoneNumber,
  IStatusTypeContact,
} from "../../../types/types";
interface IAddOrganizationProps {
  setIsLoading?: (isLoading: boolean) => void;
  closeModal: () => void;
  closeAddCustomerOrganizationPopupModal: () => void;
  isSearch?: boolean;
  setContactsForSort?: (newContact: {
    id: string;
    name: string;
    isBusinessContact: boolean;
    customerId?: string;
  }) => void;
}
const AddOrganizationView: React.FC<IAddOrganizationProps> = ({
  closeModal,
  closeAddCustomerOrganizationPopupModal,
  setContactsForSort,
  isSearch,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail, currentLocation } = userInfoState.context;
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [contactType, setContactType] = useState("Client");
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [contactTypes, setContactTypes] = useState([{ id: "" }]);
  const [organizationAdd, setOrganizationAdd] = useState({
    location: { id: "" },
    contactStatusType: { id: "" },
    contactTypes: [{ id: "" }],
  });
  const [addOrgContact, { data: newOrgContact, isSuccess }] =
    useAddOrgContactMutation();

  useEffect(() => {
    getContacts();
    getContactTypes();
    getOrganizationAdd();
  }, []);
  const getContacts = async () => {
    const data = await getIndividualContacts(true, userInfoByEmail!.org!.id);
    setContacts(data);
  };
  const getOrganizationAdd = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/business-contacts/add",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setOrganizationAdd(data);
    }
  };
  const getContactTypes = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/contact-types/all",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setContactTypes(data);
    }
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: "",
      businessNumber: "",
      contactId: "",
      role: "",
    },
  });

  const updateContactTypeClient = () => {
    setContactType("Client");
  };
  const updateContactTypeProspect = () => {
    setContactType("Prospect");
  };
  const updateContactTypeOther = () => {
    setContactType("Other");
  };
  const onSubmit = async (data: any) => {
    await addOrgContact({
      companyName: data.companyName,
      businessNumber: data.businessNumber,
      role: data.role,
      contactId: data.contactId ? data.contactId : null,
      contactTypeEnumId: organizationAdd?.contactTypes?.find(
        (t: any) => t.contactType === contactType,
      )?.id,
      locationId: currentLocation?.id,
      contactStatusEnumId: organizationAdd.contactStatusType.id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (isSearch) {
        setContactsForSort &&
          setContactsForSort({
            name: `${newOrgContact?.companyName}`,
            id: `${newOrgContact?.id}`,
            customerId: `${newOrgContact?.businessCustomers[0]?.id}`,
            isBusinessContact: true,
          });
        closeModal();
        closeAddCustomerOrganizationPopupModal();
      } else {
        navigate(`/organization/${newOrgContact.id}`);
        closeModal();
        closeAddCustomerOrganizationPopupModal();
      }
    }
  }, [isSuccess]);

  const companyNameRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Company Name*</span>
        </label>
        <input
          type={"text"}
          {...register("companyName", {
            required: LABELS.required,
          })}
          className={"input input-bordered"}
          placeholder={"Company name"}
        />
        {errors?.companyName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.companyName?.message}
          </span>
        )}
      </div>
    </>
  );
  const businessNumberRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Business Number</span>
        </label>
        <input
          type={"text"}
          {...register("businessNumber", {
            validate: {
              phoneFormat: (value) =>
                value
                  ? validatePhoneFormat(value) || "Invalid phone number format"
                  : true,
            },
          })}
          className={"input input-bordered"}
          placeholder={"Business number"}
        />
        {errors.businessNumber && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors.businessNumber.message}
          </span>
        )}
      </div>
    </>
  );

  const contactTypeRows = (
    <div className={"mt-5 flex gap-4"}>
      <div className={"form-control w-fit "}>
        <label className={"label"}>
          <span className={"label-text"}>Contact type</span>
        </label>
        <Button
          buttonType={"button"}
          colorType={contactType === "Client" ? "secondary" : "ghost"}
          label={"Client"}
          onClick={updateContactTypeClient}
        />
      </div>
      <div className={"form-control w-fit "}>
        <label className={"label"}>
          <span className={"label-text"}>&nbsp;</span>
        </label>
        <Button
          buttonType={"button"}
          colorType={contactType === "Prospect" ? "secondary" : "ghost"}
          label={"Prospect"}
          onClick={updateContactTypeProspect}
        />
      </div>
      <div className={"form-control w-fit "}>
        <label className={"label"}>
          <span className={"label-text"}>&nbsp;</span>
        </label>
        <Button
          buttonType={"button"}
          colorType={contactType === "Other" ? "secondary" : "ghost"}
          label={"Other"}
          onClick={updateContactTypeOther}
        />
      </div>
    </div>
  );

  const contactPersonRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Contact Person</span>
        </label>
        <SearchContact
          isIdLikeContactId={true}
          isLinkedProfile={true}
          saveContact={(contact) => {
            setValue("contactId", contact.id);
            setContacts((prev) => [
              ...prev,
              {
                id: contact.id,
                firstName: contact.name.split(" ")[0],
                lastName:
                  contact.name.split(" ")[contact.name.split(" ").length - 1],
                contactType: {} as IContactType,
                address: "",
                contactStatusEnumId: "",
                contactTypeEnumId: "",
                createdAt: "",
                customers: [] as ICustomers[],
                email: {} as IEmail,
                customerType: "",
                middleName: "",
                openWorkCount: 0,
                phoneNumber: "",
                phoneNumbers: [] as IPhoneNumber[],
                socialIdentificationNumber: "",
                statusType: {} as IStatusTypeContact,
              },
            ]);
          }}
          contacts={contacts.map((item) => ({
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            isBusinessContact: false,
          }))}
        />
        {error && (
          <span className={"pt-2 text-sm font-bold text-red-600"}>
            Try another contact
          </span>
        )}
      </div>
    </>
  );
  const roleRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Role</span>
        </label>
        <input
          type={"text"}
          {...register("role")}
          className={"input input-bordered"}
          placeholder={"Role"}
        />
      </div>
    </>
  );

  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Add New Organization</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {companyNameRow}
        {businessNumberRow}
        {contactTypeRows}
        {contactPersonRow}
        {roleRow}

        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <Button onClick={handleSubmit(onSubmit)} label={"Create"} />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={closeAddCustomerOrganizationPopupModal}
          />
        </div>
      </form>
    </>
  );
};

export default AddOrganizationView;
