import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { IContact, IOrganizationContact } from "../types/types";

export const contactsAPI = createApi({
  reducerPath: "contactsAPI",
  tagTypes: ["Contacts", "OrgContacts"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://dev.procharted.com",
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getAllContacts: build.query<IContact[], string>({
      query: (orgId = "") => ({
        url: "api/contacts/search",
        params: {
          orgId: orgId,
        },
      }),
      providesTags: () => ["Contacts"],
    }),
    addContact: build.mutation({
      query: (body) => ({
        url: "api/contacts/add-individual",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Contacts"],
    }),
    deleteContact: build.mutation({
      query: (body) => ({
        url: "api/customers/delete",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Contacts", "OrgContacts"],
    }),
    getAllOrgContacts: build.query<IOrganizationContact[], string>({
      query: (orgId = "") => ({
        url: "api/business-contacts/get-all",
        params: {
          orgId: orgId,
        },
      }),
      providesTags: () => ["OrgContacts"],
    }),
    addOrgContact: build.mutation({
      query: (body) => ({
        url: "api/business-contacts/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["OrgContacts"],
    }),
  }),
});
export const {
  useGetAllContactsQuery,
  useAddContactMutation,
  useGetAllOrgContactsQuery,
  useAddOrgContactMutation,
  useDeleteContactMutation,
} = contactsAPI;
