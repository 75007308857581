import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getWorkTypes } from "../../../api/api";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import Button from "../../../components/button/Button";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { CustomLink } from "../../../support/CustomLink";
import { FormSection } from "../../../support/FormSection";
import { IWorkType } from "../../../types/types";
import AddWorkType from "./AddWorkType";
import DeleteErrorPopup from "./DeleteErrorPopup";
import HideWorkTypePopup from "./HideWorkTypePopup";

const WorkTypes = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [isAddWorkType, setIsAddWorkType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isHideWorkType, setIsHideWorkType] = useState(false);
  const [isDeleteWorkType, setIsDeleteWorkType] = useState(false);

  const [workTypes, setWorkTypes] = useState<IWorkType[]>([]);
  const [workTypeId, setWorkTypeId] = useState("");
  const getWorkTypesFetch = () => {
    getWorkTypes(`${userInfoByEmail?.org?.id}`).then((res) => {
      setWorkTypes(res);
      setIsLoading(false);
    });
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getWorkTypesFetch();
  });

  const deleteWorkType = async (id: string) => {
    const response = await fetch("https://dev.procharted.com/api/services", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
    if (response.ok) {
      getWorkTypes(`${userInfoByEmail?.org?.id}`).then((res) => {
        setWorkTypes(res);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
      setWorkTypeId(id);
    }
  };
  const hideWorkType = async (id: string) => {
    const response = await fetch(
      "https://dev.procharted.com/api/services/hide-worktype",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({ id }),
      },
    );
    if (response.ok) {
      getWorkTypesFetch();
    } else {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <LottieLoading />;
  }
  return (
    <>
      <FormSection name={""} extraCx={"overflow-visible"} showHeading={false}>
        <div className={"mb-4 flex items-start justify-between"}>
          <div>
            <div className={"tabs mb-4"}>
              <CustomLink to={"./"}>Active</CustomLink>
              <CustomLink to={"../inactive"}>Inactive</CustomLink>
            </div>
            <div className={"text-lg font-bold"}>Work Types Name</div>
          </div>
          <Button
            buttonType={"button"}
            onClick={() => setIsAddWorkType(true)}
            label={"Add work type"}
            extraClasses={"normal-case"}
          />
        </div>
        <div className={"text-[14px]"}>
          {workTypes.map((item) => (
            <div key={item.id} className={"mb-8"}>
              <div
                className={
                  "mb-4 flex w-full  justify-between border-b-[1px] border-gray-300 pb-2"
                }>
                <Link
                  to={`../${item.id}`}
                  className={"link-primary text-sm font-[500] hover:underline"}>
                  {item.name}
                </Link>
                <EditDots>
                  <div className={""}>
                    <Link to={`../${item.id}`}>
                      <div
                        className={
                          "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                        }>
                        Edit
                      </div>
                    </Link>
                    <div
                      onClick={() => {
                        setIsLoading(true);
                        hideWorkType(item.id).then();
                      }}
                      className={
                        "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                      }>
                      Hide
                    </div>
                    <div
                      onClick={() => {
                        setWorkTypeId(item.id);
                        setIsDeleteWorkType(true);
                      }}
                      className={
                        "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                      }>
                      Delete
                    </div>
                  </div>
                </EditDots>
              </div>
            </div>
          ))}
        </div>
      </FormSection>
      {isHideWorkType && (
        <HideWorkTypePopup
          isOpen={isHideWorkType}
          closePopup={() => setIsHideWorkType(false)}
          confirmHideWorkType={() => {
            setIsLoading(true);
            setIsHideWorkType(false);
            hideWorkType(workTypeId).then();
          }}
        />
      )}
      {isDeleteWorkType && (
        <DeleteErrorPopup
          isOpen={isDeleteWorkType}
          closePopup={() => setIsDeleteWorkType(false)}
          confirmDeleteWorkType={() => {
            setIsLoading(true);
            setIsDeleteWorkType(false);
            deleteWorkType(workTypeId).then();
          }}
        />
      )}
      {isAddWorkType && (
        <AddWorkType
          setActive={setIsAddWorkType}
          getWorkTypesFetch={getWorkTypesFetch}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default WorkTypes;
