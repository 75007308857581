import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";

import * as devConfig from "../../../app/configs/devConfig";
import { WORK_MARK_AS_COMPLETED } from "../../../app/constants/ApiEndpointConstants";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import NoItems from "../../../components/NoItems";
import ContactWorkSvg from "../../../components/svg/ContactWorkSvg";
import { useAppDispatch } from "../../../redux/redux";
import { reorderWorkTasks } from "../../../services/AppService";
import { IWork, IWorkTask, IWorkTemplateTask } from "../../../types/types";
import AddTask from "./AddTask";
import WorkTask from "./WorkTask";
import EditTask from "./EditTask";
import { LABELS } from "../../../app/constants/TextConstants";
import Button from "../../../components/button/Button";
interface IWorkTasksProps {
  work: IWork;
  getWorkApiCall: () => void;
  setIsLoading: (isLoading: boolean) => void;
  workTasksState: IWorkTask[];
}
const reorder = (list: IWorkTask[], startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const WorkTasks: React.FC<IWorkTasksProps> = ({
  work,
  getWorkApiCall,
  setIsLoading,
  workTasksState,
}) => {
  const dispatch = useAppDispatch();

  const [workTasks, setWorkTasks] = useState<IWorkTask[]>(workTasksState);
  const [currentTask, setCurrentTask] = useState<
    IWorkTask | IWorkTemplateTask
  >();
  const [isAddTask, setIsAddTask] = useState(false);
  const [isUpdateTask, setIsUpdateTask] = useState(false);

  useEffect(() => {
    setWorkTasks(workTasksState);
  }, [workTasksState]);

  const markAsCompleted = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/${WORK_MARK_AS_COMPLETED(
        work.id,
      )}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: "{}",
      },
    );
    if (response.ok) {
      getWorkApiCall();
    } else {
      setIsLoading(false);
    }
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      workTasks,
      result.source.index,
      result.destination.index,
    );
    dispatch(reorderWorkTasks(newItems.map((item) => item.id)));
    setWorkTasks(newItems);
  };

  return (
    <>
      <div
        className={
          "text-14 mt-6 h-fit w-2/3 rounded-[10px] border-[1px] border-solid border-[#E8E9EA] bg-white py-6 px-8"
        }>
        <div className={"items-center justify-between md:flex"}>
          <p className={"mb-0 font-bold"}>Subtasks</p>
          <div className={"btns sm-mt-3"}>
            <Button
              id={"select-all-task"}
              buttonType={"button"}
              label={"mark all as completed"}
              onClick={() => {
                setIsLoading(true);
                markAsCompleted();
              }}
            />
          </div>
        </div>
        {workTasks.length === 0 ? (
          <div className={"mt-8"}>
            <NoItems
              icon={<ContactWorkSvg />}
              message={"There are no subtasks"}
              addButtonMessage={"Add Subtask"}
              openAdd={() => setIsAddTask(true)}
            />
          </div>
        ) : (
          workTasks.length !== 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={"droppable"}>
                {(provided: DroppableProvided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={
                      "tasks-list mt-8 flex flex-col gap-[20px] rounded-[7px] border-[1px] border-[#AFAFAF] bg-gray-100 py-[10px] px-[15px] first:mt-0"
                    }>
                    {workTasks?.map((task, index) => (
                      <Draggable
                        key={task.id}
                        draggableId={task.id}
                        index={index}>
                        {(provided: DraggableProvided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}>
                            <WorkTask
                              setIsLoading={setIsLoading}
                              task={task}
                              setCurrentTask={setCurrentTask}
                              setIsUpdateTask={setIsUpdateTask}
                              getWorkApiCall={getWorkApiCall}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )
        )}
        <button
          className={
            "open-popup add-task border-purple text-purple mt-8 block w-full rounded-[7px] border-[1px] border-solid px-[20px] py-[15px] text-[14px]"
          }
          onClick={() => setIsAddTask(true)}>
          <img src={"/img/plus.png"} alt={""} className={"mr-[17px]"} /> Add
          Subtask
        </button>
      </div>
      {isUpdateTask && (
        <EditTask
          setIsLoading={setIsLoading}
          task={currentTask}
          setIsUpdateTask={setIsUpdateTask}
          getWorkApiCall={getWorkApiCall}
        />
      )}
      {isAddTask && (
        <AddTask
          setIsLoading={setIsLoading}
          getWorkApiCall={getWorkApiCall}
          work={work}
          setIsAddTask={setIsAddTask}
        />
      )}
    </>
  );
};

export default WorkTasks;
