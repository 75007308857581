import React from "react";
import { Route, Routes } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { GetPageTitle } from "../../support/ScrollToTop";
import { Dashboard } from "./Dashboard";
import { WorksByStatus } from "./WorksByStatus";

export const DashboardView = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Dashboard");
  });

  return (
    <>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route
          path={"/works/:status/:workTypeId"}
          element={<WorksByStatus />}
        />
      </Routes>
    </>
  );
};
