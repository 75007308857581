import React from "react";
import { LABELS } from "../../../app/constants/TextConstants";
import { SubmitHandler, useForm } from "react-hook-form";
import * as devConfig from "../../../app/configs/devConfig";
import { WORK_TASK_ADD } from "../../../app/constants/ApiEndpointConstants";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import { IWork } from "../../../types/types";
interface IFormDataTask {
  name: string;
  description: string;
}
interface IAddTaskProps {
  getWorkApiCall: () => void;
  work: IWork;
  setIsAddTask: (isAddTask: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
}
const AddTask: React.FC<IAddTaskProps> = ({
  getWorkApiCall,
  work,
  setIsAddTask,
  setIsLoading,
}) => {
  const { nameRule } = ValidationConstants.customerRules;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormDataTask>({
    defaultValues: {
      name: "",
      description: "",
    },
  });
  const addWorkTask = async (data: IFormDataTask) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/${WORK_TASK_ADD}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          jobIdBase64: work.id,
          name: data.name,
          description: data.description,
        }),
      },
    );
    if (response.ok) {
      setIsAddTask(false);
      await getWorkApiCall();
    } else {
      setIsLoading(false);
    }
  };
  const onSubmit: SubmitHandler<IFormDataTask> = async (data) => {
    setIsLoading(true);
    addWorkTask(data);
  };
  return (
    <div className={"fixed left-0 bottom-1/4 w-screen bg-transparent"}>
      <div className={"wrapper flex h-full items-end justify-center"}>
        <div className={"holder max-w-572 w-100 w-full"}>
          <div
            className={
              "text-18 mt-6 rounded-[15px] border-[1px] border-solid border-[#E8E9EA] bg-white p-10 shadow-[0px_4px_30px_rgba(34,34,34,0.15)]"
            }>
            <form
              className={"popup-form c-form "}
              onSubmit={handleSubmit(onSubmit)}>
              <div className={"mb-8 flex items-center justify-between"}>
                <p className={"mb-0"}>New Subtask</p>
                <button
                  className={"close-popup"}
                  onClick={() => setIsAddTask(false)}>
                  <img src={"/img/closse.png"} alt={""} />
                </button>
              </div>
              <div className={"mb-4"}>
                <input
                  type={"text"}
                  placeholder={"Subtask name"}
                  className={"input"}
                  {...register("name", {
                    required: LABELS.required,
                    ...nameRule.valueLength,
                  })}
                />
                {errors?.name?.message && (
                  <span className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.name?.message}
                  </span>
                )}
              </div>
              <div className={"mb-4"}>
                <textarea
                  placeholder={"Description"}
                  className={"input"}
                  {...register("description", {
                    maxLength: {
                      value: 500,
                      message: "Must not be more than 500 characters",
                    },
                  })}></textarea>
                {errors?.description?.message && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.description?.message}
                  </div>
                )}
              </div>
              <div className={"mb-8 flex items-center justify-end"}>
                <div>
                  <button
                    type={"button"}
                    className={
                      "cancel-btn hover:bg-purple hover:border-purple close-popup mr-2 inline-block h-[40px] rounded-[7px] border-0  border-solid border-[#1F2937] px-[20px] text-[14px] uppercase leading-[40px] hover:text-white"
                    }
                    onClick={() => setIsAddTask(false)}>
                    cancel
                  </button>
                  <button
                    type={"submit"}
                    className={
                      "bg-purple border-purple inline-block h-[40px] rounded-[7px] border-solid  px-[20px] text-[14px] uppercase leading-[40px] text-white"
                    }>
                    save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
