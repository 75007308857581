import { Dialog, Transition } from "@headlessui/react";
import * as Xstate from "@xstate/react";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getUsers } from "../../../api/api";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import { BrandButton } from "../../../components/button/BrandButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { CustomLink } from "../../../support/CustomLink";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { IUser } from "../../../types/types";
import { EditUserView } from "./EditUserView";
import Button from "../../../components/button/Button";

const UsersActiveList = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const navigate = useNavigate();
  useEffectOnce(() => {
    document.title = GetPageTitle("Firm users");
  });
  const [users, setUsers] = useState<IUser[]>([]);
  const getUsersFetch = () => {
    getUsers(userInfoByEmail!.org!.id).then((res) => {
      setUsers(res);
      setIsLoading(false);
    });
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getUsersFetch();
  });
  const [error, setError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentUser, setCurrentUser] = useState<IUser>(
    null as unknown as IUser,
  );
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const openDeletePopup = (user: IUser) => {
    setCurrentUser(user);
    setIsOpenDelete(true);
  };
  const closeDeletePopup = () => {
    setIsOpenDelete(false);
  };
  const confirmDeleteUser = (user: IUser) => {
    setIsLoading(true);
    setIsOpenDelete(false);
    deleteUser(user);
  };

  const archiveUser = async (user: IUser) => {
    const response = await fetch(
      "https://dev.procharted.com/api/users/archive-user",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(user),
      },
    );
    if (response.ok) {
      getUsersFetch();
      setError(false);
    } else {
      setError(true);
      setIsLoading(false);
    }
  };
  const deleteUser = async (user: IUser) => {
    const response = await fetch("https://dev.procharted.com/api/users", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "DELETE",
      body: JSON.stringify(user),
    });
    if (response.ok) {
      getUsersFetch();
      setError(false);
    } else {
      setError(true);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LottieLoading />;
  }

  const modalDeleteUser = (
    <Transition appear show={isOpenDelete} as={Fragment}>
      <Dialog as={"div"} className={"relative z-10"} onClose={closeDeletePopup}>
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}>
          <div className={"fixed inset-0 bg-black bg-opacity-25"} />
        </Transition.Child>

        <div className={"fixed inset-0 overflow-y-auto"}>
          <div
            className={
              "flex min-h-full items-center justify-center p-4 text-center"
            }>
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 scale-95"}
              enterTo={"opacity-100 scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 scale-100"}
              leaveTo={"opacity-0 scale-95"}>
              <Dialog.Panel
                className={
                  "w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                }>
                <Dialog.Title
                  as={"h3"}
                  className={
                    "text-lg font-medium capitalize leading-6 text-gray-900"
                  }>
                  {`${currentUser?.userProfile.firstName} ${currentUser?.userProfile.lastName}`}
                </Dialog.Title>
                <div className={"mt-2"}>
                  <p className={"text-gray-500"}>
                    Are you sure you want to delete this user?
                  </p>
                </div>

                <div className={"my-8 flex justify-start space-x-4"}>
                  <Button
                    buttonType={"button"}
                    label={"Confirm"}
                    onClick={() => confirmDeleteUser(currentUser)}
                    extraClasses={"normal-case"}
                  />
                  <OutlineButton
                    colorType={"neutral"}
                    label={"Cancel"}
                    onClick={closeDeletePopup}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
  const userRows = users?.map((user) => {
    return (
      <tr key={user?.id}>
        <td>
          <div className={"flex items-center"}>
            <AvatarPlaceholder
              size={"extra-small"}
              label={
                user?.userProfile?.firstName && user?.userProfile?.lastName
                  ? `${user?.userProfile?.firstName.substring(
                      0,
                      1,
                    )}${user?.userProfile?.lastName.substring(0, 1)}`
                  : "User"
              }
            />
            <div className={"flex flex-col pl-2"}>
              <div className={"text-[14px] capitalize"}>
                {`${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className={"text-[14px]"}>{user?.userProfile?.email}</div>
        </td>
        <td>
          <div className={"flex items-center"}>
            <div className={"badge badge-outline text-[14px] capitalize"}>
              {user?.userRoleEnum?.userRole}
            </div>
          </div>
        </td>
        {/*<td>*/}
        {/*  <div className={"flex items-center text-[14px]"}>Invited</div>*/}
        {/*</td>*/}
      </tr>
    );
  });

  return (
    <>
      <FormSection
        customCx={"my-0 p-0"}
        extraCx={"space-y-0 h-fit text-[16px]"}
        viewFormat={"custom"}
        name={""}
        showHeading={false}>
        <div className={"p-6"}>
          <div className={"mb-4"}>
            <div className={"tabs mb-4"}>
              <CustomLink to={"./"}>Active</CustomLink>
              <CustomLink to={"../archived"}>Archived</CustomLink>
            </div>
            <div className={"text-lg font-bold "}>Users</div>
          </div>
          <div>
            <button
              onClick={() => navigate("./add")}
              className={
                "bg-purple mb-6 flex items-center justify-center rounded-[7px] px-[20px] py-[8px] text-[14px] text-white hover:bg-purple-800"
              }>
              Add User
            </button>
          </div>
          {error && (
            <div className={"text-[14px] text-red-600"}>
              Something went wrong. Try again or contact support
            </div>
          )}
          <div className={"flex"}>
            <table
              className={
                "table-compact table w-full overflow-x-auto  md:table-normal"
              }>
              <thead>
                <tr>
                  <th>
                    <div className={"text-[14px]"}>Name</div>
                  </th>
                  <th>
                    <div className={"text-[14px]"}>Email</div>
                  </th>
                  <th>
                    <div className={"text-[14px]"}>Role</div>
                  </th>
                </tr>
              </thead>
              <tbody>{userRows}</tbody>
            </table>
            <div className={"mt-[55px]"}>
              {users.map((user) => (
                <div className={"flex items-center py-[25px]"} key={user.id}>
                  <EditDots>
                    <div className={""}>
                      <div
                        onClick={() => {
                          setCurrentUser(user);
                          setIsEdit(true);
                        }}
                        className={
                          "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                        }>
                        Edit
                      </div>
                      <div
                        onClick={() => archiveUser(user).then()}
                        className={
                          "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                        }>
                        Archive
                      </div>
                      <div
                        onClick={() => openDeletePopup(user)}
                        className={
                          "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                        }>
                        Delete
                      </div>
                    </div>
                  </EditDots>
                </div>
              ))}
            </div>
          </div>
        </div>
      </FormSection>
      {modalDeleteUser}
      <EditUserView
        isEdit={isEdit}
        closeEdit={() => setIsEdit(false)}
        setIsLoading={setIsLoading}
        user={currentUser}
        getUsersFetch={getUsersFetch}
      />
    </>
  );
};

export default UsersActiveList;
