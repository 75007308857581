import React, { Fragment, useState } from "react";
import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import { Link } from "react-router-dom";
import { FormSection } from "../../../../support/FormSection";
import { Dialog, Transition } from "@headlessui/react";
import { UpdateLinkedProfile } from "./UpdateLinkedProfile";
import AddLinkedProfile from "./AddLinkedProfile";
import { ILinkedProfile } from "../../../../types/types";
import EditDots from "../../../../components/EditDots/EditDots";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import PlusSvg from "../../../../components/svg/PlusSvg";
import { IndividualSvg } from "../../../../components/svg/IndividualSvg";
import { ContactsSvg } from "../../../../components/svg/ContactSvg";
import ContactWorkSvg from "../../../../components/svg/ContactWorkSvg";
import NoItems from "../../../../components/NoItems";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import Button from "../../../../components/button/Button";
import { OutlineButton } from "../../../../components/button/OutlineButton";

interface IContactLinkedProfileBlockProps {
  linkedProfiles: ILinkedProfile[];
  getContact: () => void;
  setIsLoading: (isLoading: boolean) => void;
  contactId?: string;
  isLoading: boolean;
}

const ContactLinkedProfileBlock: React.FC<IContactLinkedProfileBlockProps> = ({
  linkedProfiles,
  getContact,
  contactId,
  setIsLoading,
  isLoading,
}) => {
  const [isEditLinkedProfilesPopupOpen, setIsEditLinkedProfilesPopupOpen] =
    useState(false);
  const [isAddLinkedProfilesPopupOpen, setIsAddLinkedProfilesPopupOpen] =
    useState(false);
  const [currentLinkedProfile, setCurrentLinkedProfile] =
    useState<ILinkedProfile>({} as ILinkedProfile);

  const closeEditLinkedProfilesPopupModal = () => {
    setIsEditLinkedProfilesPopupOpen(false);
  };
  const openEditLinkedProfilesPopupModal = () => {
    setIsEditLinkedProfilesPopupOpen(true);
  };
  const closeAddLinkedProfilesPopupModal = () => {
    setIsAddLinkedProfilesPopupOpen(false);
  };
  const openAddLinkedProfilesPopupModal = () => {
    setIsAddLinkedProfilesPopupOpen(true);
  };

  const deleteLinkedProfile = async (linkedProfile: ILinkedProfile) => {
    const response = await fetch(
      "https://dev.procharted.com/api/contacts/linked-profiles",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(linkedProfile),
      },
    );
    if (response.ok) {
      getContact();
    } else {
      setIsLoading(false);
    }
  };

  const editLinkedProfiles = (
    <>
      <Transition appear show={isEditLinkedProfilesPopupOpen} as={Fragment}>
        <Dialog
          as={"div"}
          className={"relative z-10"}
          onClose={closeEditLinkedProfilesPopupModal}>
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}>
            <div className={"fixed inset-0 bg-black bg-opacity-25"} />
          </Transition.Child>

          <div className={"fixed inset-0 overflow-y-auto"}>
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0 scale-95"}
                enterTo={"opacity-100 scale-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100 scale-100"}
                leaveTo={"opacity-0 scale-95"}>
                <Dialog.Panel
                  className={
                    "z-index-100 w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                  }>
                  <div className={"py-8"}>
                    <UpdateLinkedProfile
                      linkedProfile={currentLinkedProfile}
                      closeModalPopup={closeEditLinkedProfilesPopupModal}
                      getContact={getContact}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
  const addLinkedProfiles = (
    <>
      <OutlineButton
        buttonType={"button"}
        label={""}
        colorType={"neutral"}
        icon={<PlusSvg />}
        onClick={openAddLinkedProfilesPopupModal}
      />
      <Transition appear show={isAddLinkedProfilesPopupOpen} as={Fragment}>
        <Dialog
          as={"div"}
          className={"relative z-10"}
          onClose={closeAddLinkedProfilesPopupModal}>
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}>
            <div className={"fixed inset-0 bg-black bg-opacity-25"} />
          </Transition.Child>

          <div className={"fixed inset-0 overflow-y-auto"}>
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0 scale-95"}
                enterTo={"opacity-100 scale-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100 scale-100"}
                leaveTo={"opacity-0 scale-95"}>
                <Dialog.Panel
                  className={
                    "z-index-100 w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                  }>
                  <div className={"py-8"}>
                    <AddLinkedProfile
                      id={contactId}
                      closeModalPopup={closeAddLinkedProfilesPopupModal}
                      getContact={getContact}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );

  return (
    <>
      <FormSection
        customCx={"my-0 p-0"}
        extraCx={"space-y-0 static"}
        viewFormat={"custom"}
        name={""}
        showHeading={false}>
        <div className={"flex items-center justify-between p-6"}>
          <div className={"text-lg font-bold"}>Linked Profile</div>
          <div>{addLinkedProfiles}</div>
        </div>
        <div style={{ marginTop: 0 }}>
          {isLoading ? (
            <LottieLoading />
          ) : linkedProfiles.length === 0 ? (
            <div className={"p-6 pt-0"}>
              <NoItems
                icon={<ContactsSvg />}
                message={"There are no linked profiles"}
                addButtonMessage={"Add Profile"}
                openAdd={openAddLinkedProfilesPopupModal}
              />
            </div>
          ) : (
            <div className={"flex flex-col gap-6 px-6 pb-6"}>
              {linkedProfiles.map((item) => (
                <div key={item.id}>
                  <div className={"flex items-center justify-between"}>
                    <div className={"flex items-center text-[14px]"}>
                      <AvatarPlaceholder
                        size={"extra-small"}
                        label={
                          item?.linkedProfile?.firstName &&
                          item?.linkedProfile?.lastName
                            ? `${item?.linkedProfile?.firstName.substring(
                                0,
                                1,
                              )}${item?.linkedProfile?.lastName.substring(
                                0,
                                1,
                              )}`
                            : "User"
                        }
                      />
                      <div className={"ml-2"}>
                        <Link
                          to={
                            item.linkedProfile?.customers
                              ? `/client/${item.linkedProfile?.customers[0]?.id}`
                              : ""
                          }
                          className={
                            "link-primary text-sm font-medium hover:underline"
                          }>
                          {`${item?.linkedProfile?.firstName} ${item?.linkedProfile?.lastName}`}
                        </Link>
                        <div className={"text-gray-400"}>{item?.relation}</div>
                      </div>
                    </div>
                    <div>
                      <EditDots>
                        <div className={""}>
                          <div
                            onClick={() => {
                              setCurrentLinkedProfile(item);
                              openEditLinkedProfilesPopupModal();
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Edit
                          </div>
                          <div
                            onClick={() => {
                              setIsLoading(true);
                              deleteLinkedProfile(item);
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Delete
                          </div>
                        </div>
                      </EditDots>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </FormSection>
      {editLinkedProfiles}
    </>
  );
};

export default ContactLinkedProfileBlock;
