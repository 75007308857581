import * as Xstate from "@xstate/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import * as devConfig from "../../../../app/configs/devConfig";
import { CONTACT_UPDATE_INDIVIDUAL_POST } from "../../../../app/constants/ApiEndpointConstants";
import { LABELS, ROUTE_NAMES } from "../../../../app/constants/TextConstants";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import { IconButton } from "../../../../components/button/IconButton";
import ConfirmPopup from "../../../../components/ConfirmPopup";
import EditDots from "../../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import IndividualContactSvg from "../../../../components/svg/IndividualContactSvg";
import { WarningExclaimSvg } from "../../../../components/svg/WarningExclaimSvg";
import {
  inactiveStatusId,
  validatePhoneFormat,
} from "../../../../constants/constants";
import { useDeleteContactMutation } from "../../../../services/ContactService";
import { CustomLink, CustomPage } from "../../../../support/CustomLink";
import { FormSection } from "../../../../support/FormSection";
import { GetPageTitle } from "../../../../support/ScrollToTop";
import {
  IContactProfile,
  IContactType,
  ILinkedProfile,
} from "../../../../types/types";
import DeleteMessageContactPopup from "../../DeleteMessageContactPopup";
import ContactFilesBlock from "./ContactFilesBlock";
import ContactLinkedProfileBlock from "./ContactLinkedProfileBlock";
import ContactNoteBlock from "./ContactNoteBlock";
import ContactWorksBlock from "./ContactWorksBlock";

interface ICustomerProfileViewProps {
  setActivePage: (activePage: "profile" | "notes" | "work") => void;
  activePage: "profile" | "notes" | "work";
}
export const CustomerProfileView: React.FC<ICustomerProfileViewProps> = ({
  setActivePage,
  activePage,
}) => {
  const navigate = useNavigate();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { customerId } = useParams<{ customerId: string }>();
  const [linkedProfiles, setLinkedProfiles] = useState<ILinkedProfile[]>([]);
  const [contactNotes, setContactNotes] = useState<any[]>([]);
  const [contactFiles, setContactFiles] = useState<any[]>([]);
  const [contact, setContact] = useState<IContactProfile>(
    {} as IContactProfile,
  );
  const [contactTypes, setContactTypes] = useState<IContactType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [isLoadingLinked, setIsLoadingLinked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteContact, setIsDeleteContact] = useState(false);
  const [deleteContactFetch, { isSuccess, data: newContact }] =
    useDeleteContactMutation();

  const getFiles = async (id: string) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/contact-documents/get-by-contactId/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setContactFiles(data);
    } else {
    }
    setIsLoading(false);
  };

  const getContactTypes = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/contact-types/all",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setContactTypes(data);
    }
  };

  const getContactNotes = async (id: string) => {
    const response = await fetch(
      `https://dev.procharted.com/api/contact-notes/${id}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: any[] = await response.json();
      const sortedData = data.sort(
        (a, b) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
      );

      const pinnedNotes = sortedData.filter((note) => note.isPinned);
      const unpinnedNotes = sortedData.filter((note) => !note.isPinned);

      setContactNotes([...pinnedNotes, ...unpinnedNotes]);
    }
  };
  const getLinkedProfile = async (id: string) => {
    const response = await fetch(
      `https://dev.procharted.com/api/contacts/linked-profiles/get-by-contact-id/${id}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setLinkedProfiles(data);
    }
  };

  const getContact = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/contacts/get/${customerId}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IContactProfile = await response.json();
      setContact(data);
      setIsActive(
        Boolean(
          data?.contactStatusTypeEnumId &&
            data?.contactStatusTypeEnumId !== inactiveStatusId,
        ),
      );

      setValue("firstName", data?.firstName ? data.firstName : "");
      setValue("lastName", data?.lastName ? data.lastName : "");
      setValue(
        "taxId",
        data?.socialIdentificationNumber ? data.socialIdentificationNumber : "",
      );
      setValue("email", data?.email ? data.email : "");
      setValue("phoneNumber", data?.phoneNumber ? data.phoneNumber : "");
      setValue("street", data?.address?.line1 ? data.address.line1 : "");
      setValue("city", data?.address?.city ? data.address.city : "");
      setValue("state", data?.address?.region ? data.address.region : "");
      setValue(
        "zipCode",
        data?.address?.postalCode ? data.address.postalCode : "",
      );
      setValue(
        "additionalInfo",
        data?.additionalInfo ? data.additionalInfo : "",
      );
      setValue(
        "contactTypeId",
        data?.contactTypeEnumId ? data.contactTypeEnumId : "",
      );
      setIsLoading(false);
      return data;
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    document.title = GetPageTitle("Contact profile");
    setIsLoading(true);
    setIsLoadingFiles(true);
    setIsLoadingLinked(true);
    setIsLoadingNotes(true);
    getContactTypes().then();
    getContact().then((res) => {
      getLinkedProfile(`${res?.contactId}`).then(() =>
        setIsLoadingLinked(false),
      );
      getContactNotes(`${res?.contactId}`).then(() => setIsLoadingNotes(false));
      getFiles(`${res?.contactId}`).then(() => setIsLoadingFiles(false));
    });
  }, [customerId]);

  const activateContact = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/contacts/activate-contact",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({ id: contact?.contactId }),
      },
    );
    if (response.ok) {
      await getContact();
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  };
  const deactivateContact = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/contacts/de-activate-contact",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({ id: contact?.contactId }),
      },
    );
    if (response.ok) {
      await getContact();
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const deleteContact = async () => {
    deleteContactFetch({
      id: contact?.customerId,
      name: `${contact.firstName} ${contact.lastName}`,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);
  const isActivation = () => {
    return isActive ? deactivateContact() : activateContact();
  };

  const [confirmArchived, setConfirmArchived] = useState(false);

  async function closeModalWithConfirm() {
    setIsLoading(true);
    setConfirmArchived(false);
    await isActivation();
  }
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      taxId: "",
      email: "",
      phoneNumber: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      additionalInfo: "",
      contactTypeId: "",
    },
  });

  const updateContact = async (data: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/${CONTACT_UPDATE_INDIVIDUAL_POST}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({
          ...data,
        }),
      },
    );
    if (response.ok) {
      getContact().then();
    }
    setIsEdit(false);
  };
  const onSubmit = (data: any) => {
    updateContact({
      ...contact,
      socialIdentificationNumber: `${data.taxId}`,
      address: {
        ...contact.address,
        line1: `${data.street}`,
        postalCode: `${data.zipCode}`,
        city: `${data.city}`,
        region: `${data.state}`,
      },
      contactTypeEnumId: `${data.contactTypeId}`,
      firstName: `${data.firstName}`,
      lastName: `${data.lastName}`,
      phoneNumber: `${data.phoneNumber}`,
      email: `${data.email}`,
      contactId: `${contact.contactId}`,
      additionalInfo: `${data.additionalInfo}`,
      locationId: `${contact.locationId}`,
    });
  };

  if (isLoading) {
    return <LottieLoading />;
  }
  const editContactInfoPopup = (
    <>
      {isEdit ? (
        <div className={"flex gap-4"}>
          <button
            onClick={() => setIsEdit(false)}
            className={
              " hover:bg-purple hover:border-purple h-[40px] rounded-[7px] border-[1px] border-black px-[20px] text-[14px] font-bold uppercase hover:text-white"
            }>
            Cancel
          </button>
          <button
            type={"submit"}
            className={
              " hover:bg-purple hover:border-purple h-[40px] rounded-[7px] border-[1px] border-black px-[20px] text-[14px] font-bold uppercase hover:text-white"
            }>
            Save
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsEdit(true)}
          className={
            " hover:bg-purple hover:border-purple h-[40px] rounded-[7px] border-[1px] border-black px-[20px] text-[14px] font-bold uppercase hover:text-white"
          }>
          Edit
        </button>
      )}
    </>
  );

  return (
    <>
      <div className={"flex justify-center"}>
        <div className={"w-full max-w-[1920px]"}>
          <div className={"... flex w-full items-center gap-4"}>
            <div
              className={
                "flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#002466]"
              }>
              <IndividualContactSvg />
            </div>
            <div className={"flex items-end"}>
              <h1
                className={
                  "text-2xl"
                }>{`${contact?.firstName} ${contact?.middleName} ${contact?.lastName}`}</h1>
              {!isActive && (
                <div
                  className={"tooltip tooltip-bottom tooltip-warning"}
                  data-tip={LABELS.warnings.inactiveTooltip}>
                  <IconButton
                    size={"extra-small"}
                    colorType={"secondary"}
                    border={false}
                    extraCx={"ml-1 mt-1 rounded-full"}
                    IconSvg={WarningExclaimSvg}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={"flex items-center justify-between"}>
            <div className={"tabs my-8"}>
              <CustomPage
                onClick={() => setActivePage("profile")}
                isActive={activePage === "profile"}>
                {LABELS.features.profile}
              </CustomPage>
              <CustomPage
                onClick={() => setActivePage("work")}
                isActive={activePage === "work"}>
                {LABELS.features.work}
              </CustomPage>
              <CustomPage
                onClick={() => setActivePage("notes")}
                isActive={activePage === "notes"}>
                {LABELS.features.notes}
              </CustomPage>
            </div>
            <div>
              <EditDots>
                <div
                  onClick={() => setConfirmArchived(true)}
                  className={
                    " cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                  }>
                  {isActive ? "Archive" : "Activate"}
                </div>
                <div
                  onClick={() => setIsDeleteContact(true)}
                  className={
                    "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                  }>
                  Delete
                </div>
              </EditDots>
            </div>
          </div>
          <div className={"flex gap-6"}>
            <FormSection
              customCx={"my-0 p-0"}
              extraCx={"space-y-0 h-fit text-[16px] w-1/3"}
              viewFormat={"custom"}
              name={""}
              showHeading={false}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"flex items-center justify-between p-6"}>
                  <div className={"text-lg font-bold"}>Contact Info</div>
                  <div>{editContactInfoPopup}</div>
                </div>
                {isEdit ? (
                  <div className={"flex flex-col gap-6 px-6 pb-6 "}>
                    <div>
                      <div className={"mb-2 text-gray-400"}>FULL NAME</div>
                      <div className={"flex gap-4"}>
                        <div>
                          <input
                            placeholder={"First name"}
                            {...register("firstName", {
                              required: "This field is required",
                            })}
                            type={"text"}
                            className={"input input-bordered w-full"}
                          />
                          {errors?.firstName && (
                            <div
                              className={"pt-2 text-sm font-bold text-error"}>
                              {errors?.firstName?.message}
                            </div>
                          )}
                        </div>
                        <div>
                          <input
                            placeholder={"Last name"}
                            {...register("lastName", {
                              required: "This field is required",
                            })}
                            type={"text"}
                            className={"input input-bordered w-full"}
                          />
                          {errors?.lastName && (
                            <div
                              className={"pt-2 text-sm font-bold text-error"}>
                              {errors?.lastName?.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>TAX ID</div>
                      <input
                        {...register("taxId")}
                        type={"text"}
                        className={"input input-bordered w-[calc(50%-8px)]"}
                      />
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>EMAIL</div>
                      <input
                        {...register("email", {
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                            message: "Invalid email address format",
                          },
                        })}
                        type={"text"}
                        className={"input input-bordered w-[calc(50%-8px)]"}
                      />
                      {errors?.email && (
                        <div className={"pt-2 text-sm font-bold text-error"}>
                          {errors?.email?.message}
                        </div>
                      )}
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>PHONE NUMBER</div>
                      <input
                        {...register("phoneNumber", {
                          validate: {
                            phoneFormat: (value) =>
                              value
                                ? validatePhoneFormat(value) ||
                                  "Invalid phone number format"
                                : true,
                          },
                        })}
                        type={"text"}
                        className={"input input-bordered w-[calc(50%-8px)]"}
                      />
                      {errors.phoneNumber && (
                        <div className={"pt-2 text-sm font-bold text-error"}>
                          {errors.phoneNumber.message}
                        </div>
                      )}
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>CONTACT TYPE</div>
                      <select
                        {...register("contactTypeId")}
                        className={"select select-bordered w-[calc(50%-8px)]"}>
                        {contactTypes.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.contactType}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>ADDRESS</div>
                      <div className={"mb-4"}>
                        <input
                          {...register("street")}
                          placeholder={"Street Address"}
                          type={"text"}
                          className={"input input-bordered w-full"}
                        />
                      </div>
                      <div className={"mb-4 flex gap-4"}>
                        <div className={"w-1/2"}>
                          <input
                            {...register("city")}
                            placeholder={"City"}
                            type={"text"}
                            className={"input input-bordered w-full"}
                          />
                        </div>
                        <div className={"w-1/2"}>
                          <input
                            {...register("state")}
                            placeholder={"State / Province"}
                            type={"text"}
                            className={"input input-bordered w-full"}
                          />
                        </div>
                      </div>
                      <div className={"w-[calc(50%-8px)]"}>
                        <input
                          {...register("zipCode")}
                          placeholder={"Zip / Postal Code"}
                          type={"text"}
                          className={"input input-bordered w-full"}
                        />
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"mb-2 text-gray-400"}>
                        ADDITIONAL INFO
                      </div>
                      <textarea
                        {...register("additionalInfo")}
                        className={"input input-bordered min-h-[100px] w-full"}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={"flex flex-col gap-6 px-6 pb-6"}>
                    <div>
                      <div className={"mb-2 text-gray-400"}>FULL NAME</div>
                      <div className={"flex items-center"}>
                        <AvatarPlaceholder
                          size={"extra-small"}
                          label={
                            contact?.firstName && contact?.lastName
                              ? `${contact?.firstName.substring(
                                  0,
                                  1,
                                )}${contact?.lastName.substring(0, 1)}`
                              : "User"
                          }
                        />
                        <div className={"pl-2 font-[500] normal-case"}>
                          <div className={"mt-0 text-[14px]"}>
                            {`${contact?.firstName} ${contact?.middleName} ${contact?.lastName}`}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={""}>
                      <div className={"text-gray-400"}>TAX ID</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {contact?.socialIdentificationNumber || "-"}
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"text-gray-400"}>EMAIL</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {contact?.email ? (
                          <a
                            className={
                              "link-primary text-sm font-[500] hover:underline"
                            }
                            href={`mailto:${contact?.email}`}>
                            {contact?.email || "-"}
                          </a>
                        ) : (
                          <div className={"mt-0  text-[14px] font-[500]"}>
                            {contact?.email || "-"}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"text-gray-400"}>PHONE NUMBER</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {contact?.phoneNumber || "-"}
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"text-gray-400"}>CONTACT TYPE</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {contact?.contactType}
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"text-gray-400"}>ADDRESS</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {contact?.address?.line1
                          ? contact?.address?.line1 + ", "
                          : "-"}
                        {contact?.address?.city
                          ? contact?.address?.city + ", "
                          : "-"}
                        {contact?.address?.region
                          ? contact?.address?.region + ", "
                          : "-"}
                        {contact?.address?.postalCode
                          ? contact?.address?.postalCode
                          : "-"}
                      </div>
                    </div>
                    <div className={""}>
                      <div className={"text-gray-400"}>ADDITIONAL INFO</div>
                      <div className={"mt-0  text-[14px] font-[500]"}>
                        {contact?.additionalInfo || "-"}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </FormSection>
            <div className={"col flex h-fit w-1/3 flex-col gap-6"}>
              <ContactWorksBlock contact={contact} isActive={isActive} />
              <ContactLinkedProfileBlock
                isLoading={isLoadingLinked}
                setIsLoading={setIsLoadingLinked}
                linkedProfiles={linkedProfiles}
                getContact={() => {
                  getLinkedProfile(`${contact?.contactId}`).then(() =>
                    setIsLoadingLinked(false),
                  );
                }}
                contactId={contact?.contactId}
              />
            </div>

            <div className={"flex h-fit w-1/3 flex-col gap-[24px]"}>
              <ContactNoteBlock
                isLoading={isLoadingNotes}
                getContact={() => {
                  getContactNotes(`${contact?.contactId}`).then(() =>
                    setIsLoadingNotes(false),
                  );
                }}
                contactNotes={contactNotes}
                setIsLoading={setIsLoadingNotes}
                contactId={contact?.contactId}
              />
              <ContactFilesBlock
                isLoading={isLoadingFiles}
                contactId={contact?.contactId}
                getContact={() => {
                  getFiles(`${contact?.contactId}`).then(() =>
                    setIsLoadingFiles(false),
                  );
                }}
                setIsLoading={setIsLoadingFiles}
                contactFiles={contactFiles}
              />
            </div>
          </div>
        </div>
      </div>
      {isDeleteContact && (
        <DeleteMessageContactPopup
          isOpen={isDeleteContact}
          closePopup={() => setIsDeleteContact(false)}
          confirmDeleteContact={() => {
            setIsDeleteContact(false);
            deleteContact().then();
          }}
        />
      )}
      {confirmArchived && (
        <ConfirmPopup
          confirm={closeModalWithConfirm}
          close={() => setConfirmArchived(false)}
          title={isActive ? "Archive client?" : "Reactivate client?"}
          message={
            isActive ? (
              <>
                <div>
                  You are about to archive this client. Their profile will be
                </div>
                <div> moved to the "Archived" section. You can always</div>
                <div>reactivate them later</div>
              </>
            ) : (
              <>
                <div>
                  You are about to reactivate this client. Their profile will be
                </div>
                <div> moved to the "Active" section. You can always</div>
                <div>archive them later</div>
              </>
            )
          }
        />
      )}
    </>
  );
};
