import moment from "moment";
import React, { useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { FormSection } from "../../../../support/FormSection";
import { ITaskTemplate, IWorkTemplateTasks } from "../../../../types/types";
import AddTask from "./AddTask";
import Task from "./Task";
import { useAppDispatch } from "../../../../redux/redux";
import { reorderSubTaskTemplates } from "../../../../services/AppService";

const reorder = (
  list: IWorkTemplateTasks[],
  startIndex: any,
  endIndex: any,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export const TaskTemplateDetails: React.FC<unknown> = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [isAddTask, setIsAddTask] = useState(false);
  const [taskTemplate, setTaskTemplate] = useState<ITaskTemplate>(
    {} as ITaskTemplate,
  );
  const getTaskTemplate = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/work-template/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: ITaskTemplate = await response.json();
      setTaskTemplate(data);
    }
  };
  useEffectOnce(() => {
    getTaskTemplate();
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      taskTemplate.workTemplateTasks,
      result.source.index,
      result.destination.index,
    );
    dispatch(reorderSubTaskTemplates(newItems.map((item) => item.id)));
    setTaskTemplate({ ...taskTemplate, workTemplateTasks: newItems });
  };

  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>
        {taskTemplate.templateName}
      </div>

      <FormSection name={"Subtasks"} extraCx={"overflow-visible"}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"droppable"}>
            {(provided: DroppableProvided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {taskTemplate?.workTemplateTasks?.map((task: any, index) => (
                  <Draggable key={task.id} draggableId={task.id} index={index}>
                    {(provided: DraggableProvided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}>
                        <Task task={task} getTaskTemplate={getTaskTemplate} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div>
          <button
            className={
              "bg-purple  mr-8 flex items-center justify-center rounded-[7px] px-[20px] py-[8px] text-[14px] text-white"
            }
            onClick={() => setIsAddTask(true)}>
            <div>Add Subtask</div>
          </button>
        </div>
      </FormSection>
      {isAddTask && (
        <AddTask
          setActive={setIsAddTask}
          getTaskTemplate={getTaskTemplate}
          taskTemplate={taskTemplate}
        />
      )}
    </>
  );
};
