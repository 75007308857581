import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import * as devConfig from "../../../app/configs/devConfig";
import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { BrandButton } from "../../../components/button/BrandButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { AddStackSvg } from "../../../components/svg/AddStackSvg";
import { AddUserForm } from "../../../generated/operation-result-types";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";

interface IUserRoleEnum {
  createdAt: string;
  id: string;
  relayId: string;
  userRole: string;
}

export const AddUserView: React.FC<unknown> = () => {
  const [userRoleEnum, setUserRoleEnum] = useState<IUserRoleEnum[]>([
    {
      id: "",
      createdAt: "",
      relayId: "",
      userRole: "",
    },
  ]);
  const getUserRoleEnum = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-role-enum/get-all`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setUserRoleEnum(data);
    }
  };
  useEffectOnce(() => {
    document.title = GetPageTitle("Add user");
    getUserRoleEnum();
  });

  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddUserForm>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      userRole: 0,
    },
  });

  const onSubmit: SubmitHandler<AddUserForm> = async (data) => {
    const addUser = async () => {
      const response = await fetch(
        `${devConfig.getServerBaseUrlForEnv()}/api/user-invitations/add-user-invitation`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: getAuthHeader().Authorization,
          },
          method: "POST",
          body: JSON.stringify({
            orgId: userInfoByEmail?.org?.id,
            userRoleEnumId: userRoleEnum[data.userRole].id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
          }),
        },
      );
      if (response.ok) {
        navigate("../");
      }
    };
    addUser();
  };

  const roles = userRoleEnum?.map((item, index) => {
    return (
      <option key={item.id} value={index}>
        {item.userRole}
      </option>
    );
  });
  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Add user</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"User detail"} weightTitle={"bold"}>
          <div className={"form-control mb-8 flex-row"}>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>First name</span>
              </label>
              <input
                type={"text"}
                {...register("firstName", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.firstName?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.firstName?.message}
                </div>
              )}
            </div>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>Last name</span>
              </label>
              <input
                type={"text"}
                {...register("lastName", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.lastName?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.lastName?.message}
                </div>
              )}
            </div>
          </div>
          <div className={"form-control mb-8"}>
            <label className={"label"}>
              <span className={"label-text"}>Email</span>
            </label>
            <input
              type={"text"}
              {...register("email", {
                required: LABELS.required,
                ...ValidationConstants.orgRules.nameRule.valueLength,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  message: "Invalid email format",
                },
              })}
              className={"input input-bordered"}
            />
            {errors.email?.message && (
              <div className={"pt-2 text-sm font-bold text-error"}>
                {errors.email?.message}
              </div>
            )}
          </div>
          <div className={"form-control mb-8 w-1/2"}>
            <label className={"label"}>
              <span className={"label-text"}>Role</span>
            </label>
            <select
              {...register("userRole", {
                required: true,
              })}
              className={"select select-bordered w-full"}>
              {roles}
            </select>
            {errors.userRole?.message && (
              <div className={"pt-2 text-sm font-bold text-error"}>
                {errors.userRole?.message}
              </div>
            )}
          </div>
        </FormSection>

        <div className={"flex justify-start space-x-4 pb-4"}>
          <BrandButton
            colorType={"primary"}
            label={"Add user"}
            buttonType={"submit"}
            SvgIconLeft={AddStackSvg}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
          />
        </div>
      </form>
    </>
  );
};
