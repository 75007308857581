import * as Xstate from "@xstate/react";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import PlusSvg from "../../../components/svg/PlusSvg";

interface IAddNoteProps {
  getWorkNotes: () => void;
  setIsAddNote: (isAddNote: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
}
const AddNote: React.FC<IAddNoteProps> = ({
  getWorkNotes,
  setIsAddNote,
  setIsLoading,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { jobId } = useParams();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      createdByUserRelayId: userInfoByEmail?.id,
      workId: jobId,
      title: "",
      note: "",
    },
  });

  const addNote = async (data: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/work-notes/add-note",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      getWorkNotes();
      setIsAddNote(false);
    } else {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    addNote(data).then();
  };

  return (
    <>
      <div
        className={
          "fixed bottom-1/4 left-0 z-[1] h-screen w-screen bg-transparent"
        }>
        <div className={"wrapper flex h-full items-end justify-center"}>
          <div className={"holder max-w-572 w-100 w-full"}>
            <div
              className={
                "text-18 mt-6 rounded-[15px] border-[1px] border-solid border-[#E8E9EA] bg-white p-10 shadow-[0px_4px_30px_rgba(34,34,34,0.15)]"
              }
              onClick={(event) => event.stopPropagation()}>
              <form
                className={"popup-form c-form "}
                onSubmit={handleSubmit(onSubmit)}>
                <div className={"mb-8 flex items-center justify-between"}>
                  <p className={"mb-0"}>Add note</p>
                  <div
                    className={"close-popup"}
                    onClick={() => setIsAddNote(false)}>
                    <img src={"/img/closse.png"} alt={""} />
                  </div>
                </div>
                <div className={"mb-4"}>
                  <input
                    type={"text"}
                    placeholder={"Title"}
                    className={"input"}
                    {...register("title")}
                  />
                </div>
                <div className={"mb-4"}>
                  <textarea
                    placeholder={"Note"}
                    className={"input"}
                    {...register("note")}></textarea>
                </div>
                <div className={"mb-8 flex items-center justify-end gap-2"}>
                  <OutlineButton
                    buttonType={"button"}
                    label={"Cancel"}
                    onClick={() => setIsAddNote(false)}
                    colorType={"neutral"}
                    extraClasses={"normal-case"}
                  />
                  <Button
                    buttonType={"submit"}
                    label={"Save"}
                    extraClasses={"normal-case"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNote;
