import React, { useEffect, useRef, useState } from "react";

import { showStatus } from "../constants/constants";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { IWork } from "../types/types";

const DropStatus: React.FC<{ job: IWork }> = ({ children, job }) => {
  const statusRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState<{
    top: number;
    left: number;
    bottom?: number;
  }>({
    top: 0,
    left: 0,
  });
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(statusRef, () => setIsOpen(false));
  const handleScroll = () => {
    const rect = statusRef?.current?.getBoundingClientRect();
    if (rect) {
      const bottom = window.innerHeight - rect.bottom;
      const fitsBelow = rect.bottom + 300 <= window.innerHeight; // 300 - высота меню
      setPosition({
        top: Math.ceil(rect.top),
        left: Math.ceil(rect.left),
        bottom: fitsBelow ? undefined : Math.ceil(bottom),
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return (
    <div ref={statusRef} className={"relative"}>
      <div className={"relative"}>
        <div className={"flex items-center"}>
          <div
            className={"flex h-[10px] w-[10px] rounded-full"}
            style={{ backgroundColor: job?.jobStatus?.color }}
          />
          <div
            onClick={() => {
              handleScroll();
              setIsOpen(true);
            }}
            className={
              "badge badge-outline ml-2 cursor-pointer whitespace-nowrap  border-gray-200 bg-white text-[14px]"
            }>
            {showStatus(job)}
          </div>
        </div>
        {isOpen && job.jobStatus && (
          <div
            className={
              "pointer-events-none fixed top-0 left-0 z-[1] min-h-[100vh] min-w-[100vw] bg-transparent"
            }>
            <div
              style={{
                top: position.bottom
                  ? "auto"
                  : position.top
                  ? `${position.top}px`
                  : 0,
                left: position.left ? `${position.left}px` : 0,
                bottom: position.bottom ? `${position.bottom}px` : "auto",
              }}
              className={
                "pointer-events-auto absolute z-[1] mt-[24px] max-h-[300px] w-fit overflow-y-auto rounded-[8px] border-[1px] border-black border-opacity-[0.2] bg-white"
              }>
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropStatus;
