import React from "react";
import { match } from "ts-pattern";

type Props = {
  name: string;
  description?: string;
  showHeading?: boolean;
  RightSideComponent?: JSX.Element;
  viewFormat?: "compact" | "normal" | "custom";
  cardBodyExtraCx?: string;
  extraCx?: string;
  customCx?: string;
  customTitle?: string;
  weightTitle?: "bold" | "normal" | "thin" | "custom";
  flexForRightComponent?: string;
  classForTitle?: string;
  classRightContainer?: string;
  height?: number;
};

export const FormSection: React.FC<Props> = (props) => {
  const {
    name,
    description,
    showHeading = true,
    RightSideComponent,
    viewFormat = "normal",
    cardBodyExtraCx,
    children,
    extraCx = "",
    customCx = "",
    weightTitle = "bold",
    customTitle = "",
    flexForRightComponent = "flex flex-row justify-between",
    classForTitle = "",
    classRightContainer = "",
    height,
  } = props;

  const cardCx = match(viewFormat)
    .with("compact", () => "my-0 py-1")
    .with("normal", () => "my-6 py-6")
    .with("custom", () => customCx)
    .exhaustive();

  const titleCx = match(weightTitle)
    .with("bold", () => "font-bold")
    .with("normal", () => "font-normal")
    .with("thin", () => "font-thin")
    .with("custom", () => customTitle)
    .exhaustive();

  return (
    <div
      style={{ height: `${height}px` }}
      className={`card card-body card-bordered border-base-300 bg-base-100 ${cardCx} ${extraCx} rounded-[10px]`}>
      {showHeading && (
        <div className={`${flexForRightComponent}`}>
          {name && (
            <div className={"flex-col space-y-2 pb-6"}>
              <div
                className={` ${titleCx} text-lg ${classForTitle} whitespace-nowrap`}>
                {name}
              </div>
              {description && (
                <div className={"text-sm opacity-90"}>{description}</div>
              )}
            </div>
          )}
          <div className={`${classRightContainer}`}>
            {RightSideComponent && RightSideComponent}
          </div>
        </div>
      )}

      <div className={`space-y-4 ${cardBodyExtraCx}`}>{children}</div>
    </div>
  );
};
