import { useQuery } from "@apollo/client";
import * as Xstate from "@xstate/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../../app/constants/TextConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  GetOrgById,
  GetOrgByIdVariables,
} from "../../../generated/operation-result-types";
import { GET_ORG_BY_ID_GQL } from "../../../queries/OrgQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";

export const OrgDetailsView: React.FC<unknown> = () => {
  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const {
    loading: loadingQ,
    error: errorQ,
    data,
    refetch,
  } = useQuery<GetOrgById, GetOrgByIdVariables>(GET_ORG_BY_ID_GQL, {
    variables: {
      orgId: userInfoByEmail?.org?.id ?? LABELS.errors.noOrgId,
    },
  });

  /*const getFirm = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/org/${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data
    }
  };*/

  useEffect(() => {
    refetch();
    //getFirm();
  }, []);

  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    console.error("orgDetailsView | orgDetailsView", { error: errorQ });
    throw new Error("Error getting OrgDetailsView");
  }

  const orgResponse = data?.orgById;
  if (!orgResponse) {
    return <p>Missing org edges</p>;
  }
  const editOrgInfo = (
    <Link
      to={"./edit"}
      className={
        "bg-purple flex items-center justify-center rounded-[7px] px-[20px] py-[8px] text-[14px] text-white"
      }>
      <div>Edit</div>
    </Link>
  );

  return (
    <>
      <FormSection
        name={"Firm details"}
        RightSideComponent={editOrgInfo}
        weightTitle={"bold"}
        cardBodyExtraCx={"space-y-8"}>
        <div>
          <div className={"text-[14px] font-bold"}>Company Name</div>
          <div className={"mt-0 text-[14px]"}> {orgResponse.legalName}</div>
        </div>
        <div>
          <div className={"text-[14px] font-bold"}>Address</div>
          <div>
            <div className={"mt-0 text-[14px]"}>
              {orgResponse.locations.edges[0].node.locationAddress.line1}
            </div>
            <div className={"mt-0 text-[14px]"}>
              {orgResponse.locations.edges[0].node.locationAddress.line2}
            </div>
          </div>
        </div>
        <div className={"flex justify-between"}>
          <div className={"w-1/2"}>
            <div className={"text-[14px] font-bold"}>City</div>
            <div className={"mt-0 text-[14px]"}>
              {orgResponse.locations.edges[0].node.locationAddress.city}
            </div>
          </div>
          <div className={"w-1/2"}>
            <div className={"text-[14px] font-bold"}>Province/State</div>
            <div className={"mt-0 text-[14px]"}>
              {orgResponse.locations.edges[0].node.locationAddress.region}
            </div>
          </div>
        </div>
        <div className={"flex justify-between"}>
          <div className={"w-1/2"}>
            <div className={"text-[14px] font-bold"}>Postal Code/Zip</div>
            <div className={"mt-0 text-[14px]"}>
              {orgResponse.locations.edges[0].node.locationAddress.postalCode}
            </div>
          </div>
          <div className={"w-1/2"}>
            <div className={"text-[14px] font-bold"}>Country</div>
            <div className={"mt-0 text-[14px]"}>
              {orgResponse.locations.edges[0].node.locationAddress.country}
            </div>
          </div>
        </div>
        {/*<div className={""}>
          <div className={"text-[14px] font-bold"}>Phone number</div>
          <div className={"mt-0 text-[14px]"}>-</div>
        </div>
        <div className={""}>
          <div className={"text-[14px] font-bold"}>Email</div>
          <div className={"mt-0 text-[14px]"}>-</div>
        </div>*/}
      </FormSection>
    </>
  );
};
