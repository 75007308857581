import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect } from "react";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";

import { INTERNAL, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { HelpQuestionSvg } from "../../components/svg/HelpQuestionSvg";
import { sideNavRouteMap } from "../../pages/main/SidenavRouteMaps";
import { LocationSelector } from "./LocationSelector";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { JobsSvg } from "../../components/svg/JobsSvg";
import { CustomersSvg } from "../../components/svg/CustomersSvg";
import { TimeSvg } from "../../components/svg/TimeSvg";
import { AddJobGlobalView } from "../Works/AddWork/AddJobGlobalView";
import AddContact from "../../components/addContact";
import StartTimer from "../time/StartTimer";
import PlusSvg from "../../components/svg/PlusSvg";
import AddPeopleSvg from "../../components/svg/AddPeopleSvg";
import AddWorkSvg from "../../components/svg/AddWorkSvg";
import AddTimeSvg from "../../components/svg/AddTimeSvg";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";

export const MainSidebarNav: React.FC<unknown> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [isCollapsible, setIsCollapsible] = React.useState(false);
  const [isOpenAdd, setIsOpenAdd] = React.useState(false);
  const [isAddContact, setIsAddContact] = React.useState(false);
  const [isAddWork, setIsAddWork] = React.useState(false);
  const [isAddTime, setIsAddTime] = React.useState(false);
  const [user, setUser] = React.useState<{
    userRoleEnum: { userRole: string };
  }>();
  const openButtonRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(openButtonRef, () => setIsOpenAdd(false));
  // hide sidebar on bank doc details view
  const docPathMatch = useMatch({
    path: `${ROUTE_NAMES.documents.bankDoc.index}/:docId/*`,
  });
  const isDocPath = !!docPathMatch;

  React.useEffect(() => {
    if (isDocPath) {
      setIsCollapsed(true);
    }
  }, [setIsCollapsed, isDocPath]);
  React.useEffect(() => {
    setIsCollapsed(true);
  }, [document.URL]);
  // focus active menu
  // const router = useRouter();
  //
  // const activeMenu = useMemo(
  //   () => menuItems.find((menu) => menu.link === router.pathname),
  //   [router.pathname],
  // );
  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/users/${userInfoByEmail?.id}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setUser(data);
    }
  };
  const getNavItemClasses = () => {
    return classNames(
      // "flex items-center cursor-pointer hover:bg-light-lighter rounded w-full overflow-hidden whitespace-nowrap",
      " w-full block flex items-center bg-transparent text-gray-600 ",
      "focus:bg-gray-200 focus:font-semibold focus:text-primary focus:text-gray-900 focus:outline-none focus:shadow-outline",
      "hover:bg-gray-200 hover:text-gray-900 hover:border-l-[3px] border-[#7C66F0]",
    );
  };

  const onMouseOver = () => {
    setIsCollapsible(!isCollapsible);
  };
  const userRoleNavIcons = (name: string) => {
    if (`${user?.userRoleEnum?.userRole}` === "Staff") {
      location.pathname.includes("settings") && navigate("/home");
      return name !== "settings";
    }
    return true;
  };

  const handleSidebarToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  const getLocation = () => {
    if (location) {
      return location.pathname ? location.pathname.split("/")[1] : "";
    } else return "";
  };
  const sideNavRouteLinks = sideNavRouteMap.map((routeElement) => {
    const { name, icon } = routeElement;
    return (
      <>
        {userRoleNavIcons(name) && (
          <div
            className={`${getNavItemClasses()} ${
              getLocation().includes(name) &&
              "border-l-[3px] border-[#7C66F0]  bg-gray-200 text-gray-900"
            }`}
            key={name}>
            <Link to={`/${name}`} className={"w-full py-4"}>
              <div>
                <div className={"mb-1.5 flex items-center justify-center"}>
                  <img src={icon} alt="name" />
                </div>
                <div
                  className={classNames("text-center text-[12px] capitalize")}>
                  {name}
                </div>
              </div>
            </Link>
          </div>
        )}
      </>
    );
  });

  return (
    <>
      <div
        className={
          "z-nav fixed left-0 top-0 mt-[64px] flex min-h-[96vh] w-24 flex-col justify-between bg-white  pt-4 pb-8"
        }
        // style={{ transition: "width 300ms cubic-bezier(0.2, 0, 0, 1) 0s" }}
        /*onMouseEnter={onMouseOver}
                    onMouseLeave={onMouseOver}*/
      >
        <div className={"flex flex-col"}>
          <div className={"absolute flex items-center justify-between"}>
            <div className={"flex items-center pl-1"}>
              <span>
                <LocationSelector isHidden={isCollapsed} />
              </span>
            </div>

            {/*  <IconButton*/}
            {/*    colorType={"secondary"}*/}
            {/*    size={"small"}*/}
            {/*    IconSvg={ChevronDoubleLeftSvg}*/}
            {/*    extraCx={classNames("rounded absolute right-0", {*/}
            {/*      "rotate-180": isCollapsed,*/}
            {/*    })}*/}
            {/*    onClick={handleSidebarToggle}*/}
            {/*  />*/}
          </div>

          <div className={"flex flex-col justify-center"}>
            <div
              className={"relative mb-[24px] flex justify-center px-4"}
              ref={openButtonRef}>
              <button
                onClick={() => setIsOpenAdd(!isOpenAdd)}
                className={`${
                  isOpenAdd ? "bg-[#1F2937]" : "bg-purple"
                } border-purple flex h-[50px] w-[50px] items-center justify-center rounded-[10px] border-solid text-[20px] text-white duration-300`}>
                <div
                  className={`${
                    isOpenAdd && "rotate-[-135deg]"
                  } fill-white duration-300`}>
                  <PlusSvg />
                </div>
              </button>
              {isOpenAdd && (
                <div
                  className={
                    "absolute left-[calc(100%+17px)] top-[-16px] flex gap-[16px] bg-white p-4 shadow-dropdown"
                  }>
                  <div
                    onClick={() => {
                      setIsOpenAdd(false);
                      setIsAddContact(true);
                    }}
                    className={
                      "flex cursor-pointer flex-col items-center gap-2 rounded-[12px] p-2 hover:bg-gray-200"
                    }>
                    <div
                      className={
                        "flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#7C66F0]"
                      }>
                      <AddPeopleSvg />
                    </div>
                    <div
                      className={
                        "whitespace-nowrap text-[14px] font-[550] text-[#1F2937]"
                      }>
                      New Contact
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setIsOpenAdd(false);
                      setIsAddWork(true);
                    }}
                    className={
                      "flex cursor-pointer flex-col items-center gap-2 rounded-[12px] p-2 hover:bg-gray-200"
                    }>
                    <div
                      className={
                        "flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#7C66F0]"
                      }>
                      <AddWorkSvg />
                    </div>
                    <div
                      className={
                        "whitespace-nowrap text-[14px] font-[550] text-[#1F2937]"
                      }>
                      New Work
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setIsOpenAdd(false);
                      setIsAddTime(true);
                    }}
                    className={
                      "flex cursor-pointer flex-col items-center gap-2 rounded-[12px] p-2 hover:bg-gray-200"
                    }>
                    <div
                      className={
                        "flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#7C66F0]"
                      }>
                      <div className={"relative right-[-2px]"}>
                        <AddTimeSvg />
                      </div>
                    </div>
                    <div
                      className={
                        "whitespace-nowrap text-[14px] font-[550] text-[#1F2937]"
                      }>
                      New Time
                    </div>
                  </div>
                </div>
              )}
            </div>
            {sideNavRouteLinks}
          </div>
        </div>

        <div className={`${getNavItemClasses()}`}>
          <a
            className={"w-full py-4"}
            href={`mailto:${INTERNAL.emails.support}?subject=Support%20request&body=ProCharted%20Support%2C%0A%0A`}>
            <div>
              <div
                className={
                  "mb-1.5 flex items-center justify-center fill-[#1F2937]"
                }>
                <HelpQuestionSvg />
              </div>
              <div className={classNames("text-center text-[12px] capitalize")}>
                Support
              </div>
            </div>
          </a>
        </div>
      </div>
      {isAddWork && <AddJobGlobalView setIsAddWork={setIsAddWork} />}
      <AddContact
        isOpen={isAddContact}
        closeModal={() => setIsAddContact(false)}
        isSearch={false}
      />
      {isAddTime && (
        <StartTimer isOpen={isAddTime} closeModal={() => setIsAddTime(false)} />
      )}
    </>
  );
};
