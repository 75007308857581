import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import * as devConfig from "../../app/configs/devConfig";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import Button from "../../components/button/Button";
import { showStatus } from "../../constants/constants";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { timerSlice } from "../../services/TimerService";
import { IWork } from "../../types/types";
import { WorkModel } from "../work-charts/models/work.model";
interface ITimerAndStatusProps {
  workState: WorkModel | any;
  getWorkApiCall: () => void;
  setIsLoading: (isLoading: boolean) => void;
}
const TimerAndStatus: React.FC<ITimerAndStatusProps> = ({
  workState,
  getWorkApiCall,
  setIsLoading,
}) => {
  const { jobId } = useParams();
  const statusRef = useRef<HTMLDivElement>(null);
  const closeStatusMenu = () => {
    setIsOpenStatus(false);
  };
  useOnClickOutside(statusRef, closeStatusMenu);
  const { timer } = useAppSelector((state) => state.timerReducer);
  const { setStatusTimer } = timerSlice.actions;
  const dispatch = useAppDispatch();
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const updateStatusWork = async (statusId: string, subStatusId: string) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work/update-status?workId=${jobId}&statusId=${statusId}&subStatusId=${subStatusId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({}),
      },
    );
    if (response.ok) {
      setIsOpenStatus(false);
      getWorkApiCall();
    } else {
      setIsLoading(false);
    }
  };

  const statusesName = {
    Ready: "Ready",
    InProgress: "In Progress",
    Waiting: "Waiting",
    Completed: "Completed",
  };

  const sortStatuses = (statuses: any) => {
    return statuses
      .filter((item: any) => item.name !== "trial")
      .sort((a: any, b: any) => {
        if (a.name === statusesName.Ready) {
          return -1;
        }
        if (b.name === statusesName.Ready) {
          return 1;
        }
        if (a.name === statusesName.InProgress) {
          return -2;
        }
        if (b.name === statusesName.InProgress) {
          return 2;
        }
        if (a.name === statusesName.Waiting) {
          return -3;
        }
        if (b.name === statusesName.Waiting) {
          return 3;
        }
        if (a.name === statusesName.Completed) {
          return -4;
        }
        if (b.name === statusesName.Completed) {
          return 4;
        }
      });
  };
  const buttonTimerVariant = (work: IWork) => {
    if (
      timer.workId !== workState.id &&
      (timer.timerStatus === "ended" || timer.timerStatus === null)
    ) {
      return (
        <Button
          buttonType={"button"}
          label={"Start timer"}
          onClick={() =>
            dispatch(
              setStatusTimer({
                workId: workState.id,
                timerStatus: "started",
                work: { name: workState.name },
                contactId: work.customerId ? work.customer.contactId : null,
                businessContactId: work.customerId
                  ? null
                  : work.businessContactId,
              }),
            )
          }
          extraClasses={"normal-case"}
        />
      );
    }
    if (
      timer.workId === workState.id &&
      (timer.timerStatus === "started" || timer.timerStatus === "paused")
    ) {
      return (
        <Button
          buttonType={"button"}
          label={timer.timerStatus === "paused" ? "Continue" : "Pause"}
          onClick={() =>
            dispatch(
              setStatusTimer({
                timerStatus:
                  timer.timerStatus === "started" ? "paused" : "started",
              }),
            )
          }
          extraClasses={"normal-case w-[120px] max-w-[120px]"}
        />
      );
    } else return <></>;
  };
  return (
    <div className={"flex flex-wrap items-center justify-end gap-4"}>
      <div className={""}>{buttonTimerVariant(workState)}</div>
      <div className={"relative"}>
        <div
          className={"relative flex justify-center text-[14px]"}
          ref={statusRef}>
          <div
            className={"select select-bordered flex min-w-[150px] items-center"}
            onClick={() => setIsOpenStatus(!isOpenStatus)}>
            {workState?.jobStatus && (
              <div
                className={" mr-2 h-[10px] w-[10px] rounded-full"}
                style={{
                  background: `${workState?.jobStatus?.color}`,
                }}></div>
            )}
            <div>{showStatus(workState)}</div>
          </div>
          {isOpenStatus && workState?.service && (
            <div
              className={
                "absolute top-[50px] z-[1] max-h-[300px] overflow-y-auto rounded-[8px] border-[1px] border-black border-opacity-[0.2] bg-white"
              }>
              {sortStatuses(workState?.service?.serviceStatuses)?.map(
                (status: any) => (
                  <div key={status.id} className={"overflow-hidden"}>
                    <div
                      onClick={() => {
                        setIsLoading(true);
                        updateStatusWork(status.id, " ");
                      }}
                      className={
                        "mb-2 flex cursor-pointer items-center whitespace-nowrap px-4 py-2 font-[600] last:mr-0 last:mb-0 hover:bg-gray-200"
                      }>
                      <div
                        className={" mr-2 h-[10px] w-[10px] rounded-full"}
                        style={{
                          background: `${status?.color}`,
                        }}></div>
                      <div>{status?.name}</div>
                    </div>
                    {status?.serviceSubStatuses?.map((sub: any) => (
                      <div
                        key={sub.id}
                        onClick={() => {
                          setIsLoading(true);
                          updateStatusWork(status?.id, sub?.id);
                        }}
                        className={
                          "mb-2 flex cursor-pointer items-center whitespace-nowrap px-4 py-2 hover:bg-gray-200"
                        }>
                        <div
                          className={" mr-2 h-[10px] w-[10px] rounded-full"}
                          style={{
                            background: `${status.color}`,
                          }}></div>
                        <div key={sub.id}>{sub?.subStatus}</div>
                      </div>
                    ))}
                  </div>
                ),
              )}
            </div>
          )}
        </div>
        {isOpenStatus && !workState?.service && (
          <div
            className={
              "absolute top-full left-0 pt-2 text-sm font-bold text-error"
            }>
            Choose the work type
          </div>
        )}
      </div>
    </div>
  );
};

export default TimerAndStatus;
