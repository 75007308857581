import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import * as devConfig from "../../../app/configs/devConfig";
import { WORK_TASK_EDIT } from "../../../app/constants/ApiEndpointConstants";
import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { IWorkTask, IWorkTemplateTask } from "../../../types/types";
import { WorkTaskModel } from "../../work-charts/models/work-task.model";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";

interface IEditTaskProps {
  task?: IWorkTask | IWorkTemplateTask;
  setIsUpdateTask: (isUpdateTask: boolean) => void;
  getWorkApiCall: () => void;
  setIsLoading: (isLoading: boolean) => void;
  isTemplate?: boolean;
}

const EditTask: React.FC<IEditTaskProps> = ({
  task,
  setIsUpdateTask,
  getWorkApiCall,
  setIsLoading,
  isTemplate,
}) => {
  const { nameRule } = ValidationConstants.customerRules;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WorkTaskModel>({
    defaultValues: {
      name: task?.name,
      description: task?.description,
    },
  });
  const { jobId } = useParams<{ jobId: string }>();
  const onSubmit: SubmitHandler<WorkTaskModel> = async (data) => {
    setIsLoading(true);
    setIsUpdateTask(false);
    const updateTask = async () => {
      const response = await fetch(
        isTemplate
          ? `${devConfig.getServerBaseUrlForEnv()}/api/template-task/update-template-task`
          : `${devConfig.getServerBaseUrlForEnv()}/${WORK_TASK_EDIT}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: getAuthHeader().Authorization,
          },
          method: "PUT",
          body: JSON.stringify(
            isTemplate
              ? {
                  ...task,
                  name: data.name,
                  description: data.description,
                }
              : {
                  ...task,
                  name: data.name,
                  description: data.description,
                  jobIdBase64: jobId,
                },
          ),
        },
      );
      if (response.ok) {
        getWorkApiCall();
      } else {
        setIsLoading(false);
      }
    };
    await updateTask();
  };
  return (
    <div className={"fixed bottom-1/4 left-0 z-10 w-screen bg-transparent"}>
      <div className={"wrapper flex h-full items-end justify-center"}>
        <div className={"holder max-w-572 w-100 w-full"}>
          <div
            className={
              "text-18 mt-6 rounded-[15px] border-[1px] border-solid border-[#E8E9EA] bg-white p-10 shadow-[0px_4px_30px_rgba(34,34,34,0.15)]"
            }>
            <form
              className={"popup-form c-form "}
              onSubmit={handleSubmit(onSubmit)}>
              <div className={"mb-8 flex items-center justify-between"}>
                <p className={"mb-0"}>Update Subtask</p>
                <div
                  className={"close-popup"}
                  onClick={() => setIsUpdateTask(false)}>
                  <img src={"/img/closse.png"} alt={""} />
                </div>
              </div>
              <div className={"mb-4"}>
                <input
                  type={"text"}
                  placeholder={"Subtask name"}
                  className={"input"}
                  {...register("name", {
                    required: LABELS.required,
                    ...nameRule.valueLength,
                  })}
                />
                {errors?.name?.message && (
                  <span className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.name?.message}
                  </span>
                )}
              </div>
              <div className={"mb-4"}>
                <textarea
                  placeholder={"Description"}
                  className={"input"}
                  {...register("description", {
                    maxLength: {
                      value: 500,
                      message: "Must not be more than 500 characters",
                    },
                  })}></textarea>
                {errors?.description?.message && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.description?.message}
                  </div>
                )}
              </div>
              <div className={"mb-8 flex items-center justify-end gap-4"}>
                <OutlineButton
                  buttonType={"button"}
                  label={"Cancel"}
                  colorType={"neutral"}
                  onClick={() => setIsUpdateTask(false)}
                  extraClasses={"normal-case"}
                />
                <Button
                  buttonType={"submit"}
                  label={"Save"}
                  extraClasses={"normal-case"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTask;
