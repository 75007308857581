import React, { useRef } from "react";

import useOnClickOutside from "../hooks/useOnClickOutside";

const PreviewFile = ({ close, file }: { close: () => void; file: string }) => {
  const previewRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(previewRef, close);
  return (
    <div
      onClick={close}
      className={
        "fixed left-0 top-0 z-[3] flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.3)]"
      }>
      <div ref={previewRef}>
        <iframe src={file} className={"h-[80vh] w-[80vw] min-w-[100px] "} />
      </div>
    </div>
  );
};

export default PreviewFile;
