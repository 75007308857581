import _ from "lodash";
import path from "path-browserify";

export const INTERNAL = {
  emails: {
    support: "support@procharted.com",
  },
};

export const BrandConstants = {
  name: "ProCharted",
  tagLine: "Accounting software for CPA firms and bookkeepers",
  landingUrl: "https://www.procharted.com",
};

const getUrlForPath = (routePath: string) => {
  return _.join([BrandConstants.landingUrl, routePath], "/");
};

export const LinkConstants = {
  policies: {
    terms: getUrlForPath("policy/terms"),
    privacy: getUrlForPath("policy/privacy"),
  },
  bankStatements: {
    pricing: getUrlForPath("pricing/statements"),
    accEnumQueryParam: "accEnum",
  },
  signIn: {
    index: getUrlForPath("sign-in"),
  },
};

export const LABELS = {
  optional: "(Optional)",
  required: "This field is required",
  minLength10: "Must be at least 10 characters",
  deletion: {
    inactive: "inactive",
  },
  sort: {
    name: "Sort",
  },
  filters: {
    name: "Filter",
    apply: "Apply",
    reset: "Reset",
  },
  buttons: {
    add: "add",
    edit: "edit",
    review: "review",
    clickHere: "click here",
  },
  messages: {
    bankDocs: {
      status: {
        pendingProcessing:
          "We are currently processing your statement. We will notify you by email once it's ready.",
        pendingNotUploaded: "This statement has not yet been uploaded.",
        pendingAiLearningTooLong:
          "We are currently processing your statement. Our AI software is learning this new format, so it is taking a longer than expected. We will email you once this is ready!",
        failureExceededSize:
          "This statement exceeded the maximum size limit. Please upload a new statement.",
        failureExceededPageCount:
          "This statement exceeded the maximum number of pages allowed. Please upload a new statement.",
        failureInvalidFormat:
          "The statement format is invalid. Please verify that you upload only one month at a time, and that it is not password protected.",
        failureUnsupportedBank:
          "Oops! This bank is not supported yet, but our AI software is evolving and we add new banks to our database daily. We will email you once this bank is supported.",
        failureVirusInfected:
          "This statement might be infected with a virus. Please make sure your anti-virus is up-to-date and scan your files before uploading.",
        failureDefault:
          "Oops! Something went wrong. We apologize for the inconvenience. Please contact our support if this issues persists.",
      },
    },
    bankDocOutput: {
      status: {
        errorInTxnRow:
          "Error: All transaction row errors must be fixed before saving.",
        noTxnSelected:
          "Error: At least one transaction must be selected before saving.",
        notMatched:
          "Warning: PDF ending balance and calculated ending balance does not match. Please verify before downloading.",
      },
    },
  },
  warnings: {
    customerInactive:
      "This contact has been deactivated. Activate the customer to restore.",
    documentInactive:
      "This contact document has been deactivated. Activate the customer to restore this document.",
    inactiveTooltip: "This contact has been deactivated.",
  },
  errors: {
    default:
      "Oops! There has been an internal server error while processing your request. Please refresh or try again later.",
    noOrgId: "Missing org id",
    errorSavingChanges: "Error saving changes. Please try again shortly.",
  },
  empty: {
    default: "--",
    none: "None",
    cardMask: "••••",
    noData: "There is no data yet!",
  },
  unknownUser: { defaultInitials: "NA", defaultFullName: "Not Available" },
  toast: { saving: "Saving...", saved: "Saved!" },
  features: {
    home: "Home",
    dashboard: "Dashboard",
    signout: "Sign out",
    contact: "Contact",
    contacts: "Contacts",
    customer: "Customer",
    customers: "Customers",
    clients: "Clients",
    client: "Client",
    organization: "Organization",
    activeCustomers: "Active customers",
    activeContacts: "Active",
    deactivatedCustomers: "Deactivated customers",
    deactivatedContacts: "Archived",
    user: "User",
    users: "Users",
    profile: "Profile",
    job: "Job",
    jobs: "Jobs",
    work: "Work",
    Works: "Works",
    service: "Service",
    services: "Services",
    document: "Statement",
    documents: "Statements",
    bankDocs: "Bank Statements",
    bankDoc: "Bank Statement",
    setting: "Setting",
    settings: "Settings",
    billing: "Billing",
    plans: "Plans",
    notes: "Notes",
  },
};

const toRouteName = (label: string) => label.split(" ").join("-").toLowerCase();

export const ROUTE_NAMES = {
  home: LABELS.features.home.toLowerCase(),
  dashboard: LABELS.features.dashboard.toLowerCase(),
  signOut: toRouteName(LABELS.features.signout),
  contact: LABELS.features.contact.toLowerCase(),
  contacts: LABELS.features.contacts.toLowerCase(),
  customer: {
    index: LABELS.features.customer.toLowerCase(),
    profile: "profile",
    edit: "edit",
  },
  customers: {
    index: LABELS.features.customers.toLowerCase(),
    active: {
      index: "active-customers",
      add: "add",
    },
    deactivated: {
      index: "deactivated-customers",
    },
  },
  client: {
    index: LABELS.features.client.toLowerCase(),
    profile: "profile",
    edit: "edit",
    work: "work",
    billing: "billing",
    notes: "notes",
  },
  organization: {
    index: LABELS.features.organization.toLowerCase(),
    profile: "profile",
    edit: "edit",
    work: "work",
    billing: "billing",
    notes: "notes",
  },
  clients: {
    index: LABELS.features.contacts.toLowerCase(),
    active: {
      index: "active-clients",
      add: "add",
    },
    deactivated: {
      index: "deactivated-clients",
    },
  },
  user: {
    index: toRouteName(LABELS.features.user),
    profile: toRouteName(LABELS.features.profile),
  },
  users: LABELS.features.users.toLowerCase(),
  job: LABELS.features.job.toLowerCase(),
  jobs: LABELS.features.jobs.toLowerCase(),
  billing: LABELS.features.billing.toLowerCase(),
  work: LABELS.features.work.toLowerCase(),
  works: LABELS.features.Works.toLowerCase(),
  service: LABELS.features.service.toLowerCase(),
  services: LABELS.features.services.toLowerCase(),
  document: LABELS.features.document.toLowerCase(),
  documents: {
    index: LABELS.features.documents.toLowerCase(),
    bankDocs: {
      index: toRouteName(LABELS.features.bankDocs),
      add: "add",
      details: {
        index: "details",
      },
    },
    bankDoc: {
      index: toRouteName(LABELS.features.bankDoc),
      details: "details",
      review: "review",
      edit: "edit",
      download: "download",
    },
  },
  setting: LABELS.features.setting.toLowerCase(),
  settings: {
    index: toRouteName(LABELS.features.settings),
    billing: { index: toRouteName(LABELS.features.billing) },
  },
};

export const ROUTE_IDS = {
  customerId: "customerId",
  bankDocId: "bankDocId",
};

export const PATHS = {
  home: () => path.join("/", ROUTE_NAMES.home),
  user: () => path.join("/", ROUTE_NAMES.user.index),
  userProfile: () => path.join(PATHS.user(), ROUTE_NAMES.user.profile),
  contacts: () => path.join("/", ROUTE_NAMES.contacts),
  contact: () => path.join("/", ROUTE_NAMES.contact),
  contactById: (contactId: string) => path.join(PATHS.contact(), contactId),
  contactByIdCustomers: (contactId: string) =>
    path.join(PATHS.contacts(), contactId, ROUTE_NAMES.customers.index),
  customers: () => path.join("/", ROUTE_NAMES.customers.index),
  clients: () => path.join("/", ROUTE_NAMES.clients.index),
  customer: () => path.join("/", ROUTE_NAMES.customer.index),
  client: () => path.join("/", ROUTE_NAMES.client.index),
  customerById: (customerId?: string) =>
    path.join(PATHS.customer(), customerId ?? ""),
  clientById: (clientId?: string) => path.join(PATHS.client(), clientId ?? ""),
  customerAdd: () =>
    path.join(
      PATHS.customers(),
      ROUTE_NAMES.customers.active.index,
      ROUTE_NAMES.customers.active.add,
    ),
  clientAdd: () =>
    path.join(
      PATHS.clients(),
      ROUTE_NAMES.clients.active.index,
      ROUTE_NAMES.clients.active.add,
    ),
  jobs: () => path.join("/", ROUTE_NAMES.works),
  job: () => path.join("/", ROUTE_NAMES.job),
  jobById: (jobId: string) => path.join(PATHS.job(), jobId),
  services: () => path.join("/", ROUTE_NAMES.services),
  servicesActiveById: (serviceId: string) =>
    path.join(PATHS.services(), "active", serviceId),
  documents: () => path.join("/", ROUTE_NAMES.documents.index),
  bankDocs: () =>
    path.join(
      "/",
      ROUTE_NAMES.documents.index,
      ROUTE_NAMES.documents.bankDocs.index,
    ),
  bankDoc: () => path.join("/", ROUTE_NAMES.documents.bankDoc.index),
  bankDocById: (bankDocId: string) => path.join(PATHS.bankDoc(), bankDocId),
  bankDocByIdDetails: (bankDocId: string) =>
    path.join(
      PATHS.bankDoc(),
      bankDocId,
      ROUTE_NAMES.documents.bankDoc.details,
    ),
  bankDocAdd: () =>
    path.join(PATHS.bankDocs(), ROUTE_NAMES.documents.bankDocs.add),
  bankDocByIdEdit: (bankDocId: string) =>
    path.join(PATHS.bankDoc(), bankDocId, ROUTE_NAMES.documents.bankDoc.edit),
  bankDocByIdDownload: (bankDocId: string, accEnumName: string) =>
    `${path.join(
      PATHS.bankDoc(),
      bankDocId,
      ROUTE_NAMES.documents.bankDoc.download,
    )}?${LinkConstants.bankStatements.accEnumQueryParam}=${accEnumName}`,
  settings: () => path.join("/", ROUTE_NAMES.settings.index),
  settingsBilling: () =>
    path.join(PATHS.settings(), ROUTE_NAMES.settings.billing.index),
};
