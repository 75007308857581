import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Xstate from "@xstate/react";
import { Pagination } from "baseui/pagination";
import React, { useEffect, useState } from "react";

import { LABELS } from "../../app/constants/TextConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import Button from "../../components/button/Button";
import { OutlineButton } from "../../components/button/OutlineButton";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import WorkTable from "../../components/WorkTable";
import { filterTypes } from "../../constants/constants";
import { useAppSelector } from "../../redux/redux";
import { FormSection } from "../../support/FormSection";
import { IWork } from "../../types/types";
import { AddJobGlobalView } from "./AddWork/AddJobGlobalView";
import FilterWorks from "./FilterWorks";

export const AllWorks: React.FC<unknown> = () => {
  const { user } = useAppSelector((state) => state.appReducer);
  const pageSize = 20;
  const [works, setWorks] = useState<IWork[]>([]);
  const [totalPages, setTotalPage] = useState(1);
  const [isAddWork, setIsAddWork] = useState(false);
  const [isLoadingWorks, setIsLoadingWorks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const [isFilter, setIsFilter] = useState(false);
  const [filterByType, setFilterByType] = useState({ id: "", name: "" });
  const [arrayFilter, setArrayFilter] = useState<
    string[] | { isMain: boolean; id: string }[]
  >([]);
  const [isOpenAddContact, setIsOpenAddContact] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [worksStats, setWorksStats] = useState({
    allWorksCount: 0,
    completedWorkCount: 0,
    openWorkCount: 0,
    overDueWorkCount: 0,
  });

  // state - sorting
  type SortOrderT =
    | { name: "Work Name"; sort: "asc" | "desc" }
    | { name: "Contact"; sort: "asc" | "desc" }
    | { name: "Status"; sort: "asc" | "desc" }
    | { name: "Start Date"; sort: "asc" | "desc" }
    | { name: "Due Date"; sort: "asc" | "desc" }
    | { name: "Progress"; sort: "asc" | "desc" }
    | { name: "Repeats"; sort: "asc" | "desc" }
    | { name: "Work Type"; sort: "asc" | "desc" }
    | { name: "Assignee"; sort: "asc" | "desc" };

  const [selectedSortOrder, setSelectedSortOrder] = React.useState<SortOrderT>({
    name: "Work Name",
    sort: "asc",
  });

  const onClickSort = (item: { id: number; body: SortOrderT }) => {
    setSelectedSortOrder((prev) =>
      prev.name === item.body.name
        ? { ...prev, sort: prev.sort === "asc" ? "desc" : "asc" }
        : item.body,
    );
  };

  const getWorks = async () => {
    const sortName = selectedSortOrder.name.replace(/\s+/g, "");

    const response = await fetch(
      `https://dev.procharted.com/api/work/find-work/${
        userInfoByEmail?.org?.id
      }?sortBy=${sortName === "WorkName" ? "Name" : sortName}&sortOrder=${
        selectedSortOrder.sort
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          pageNumber: currentPage,
          pageSize: pageSize.toString(),
          worktypeIds: filterByType.id === filterTypes[1].id ? arrayFilter : [],
          workStatuses:
            filterByType.id === filterTypes[0].id
              ? arrayFilter.map((item) => typeof item !== "string" && item?.id)
              : [],
          userIds: filterByType.id === filterTypes[2].id ? arrayFilter : [],
        }),
      },
    );

    if (response.ok) {
      const data: IWork[] = await response.json();
      setIsLoadingWorks(false);

      return data;
    } else {
      setIsLoadingWorks(false);
      return [];
    }
  };
  const getWorksStats = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/dashboard/get-all-works/?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      return response.json();
    }
  };

  const getData = async () => {
    getWorks().then((res) => {
      window.scrollTo(0, 0);
      setTotalPage(
        Math.ceil(
          (res && res.length > 0 && res[0]?.totalRecords / pageSize) || 1,
        ),
      );
      setWorks(res);
    });
  };

  useEffect(() => {
    setIsLoadingWorks(true);
    getData().then();
  }, [currentPage, filterByType, arrayFilter, user, selectedSortOrder]);

  useEffect(() => {
    setIsLoading(true);

    getWorksStats().then((res) => {
      setWorksStats(res);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <div>
      <FormSection name={""} extraCx={"m-0 py-0 px-0 gap-0"}>
        <div className={"flex h-full w-full"}>
          <div
            className={
              "w-1/3 border-r-[1px] border-gray-300 px-[20px] py-[10px]"
            }>
            <div className={"text-[16px] text-gray-400"}>All Work</div>
            <div className={"text-[48px] font-bold"}>
              {worksStats.allWorksCount}
            </div>
          </div>
          <div
            className={
              "w-1/3 border-r-[1px] border-gray-300 px-[20px] py-[10px]"
            }>
            <div className={"text-[16px] text-gray-400"}>Open</div>
            <div className={"text-[48px] font-bold"}>
              {worksStats.openWorkCount}
            </div>
          </div>
          <div className={"w-1/3 px-[20px] py-[10px]"}>
            <div className={"text-[16px] text-gray-400"}>Completed</div>
            <div className={"text-[48px] font-bold"}>
              {worksStats.completedWorkCount}
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection
        name={" "}
        flexForRightComponent={"flex w-full"}
        classRightContainer={"flex w-full justify-between h-fit"}
        RightSideComponent={
          <>
            <Button
              buttonType={"button"}
              label={`Add ${LABELS.features.work}`}
              onClick={() => setIsAddWork(true)}
              extraClasses={"normal-case"}
            />
            <OutlineButton
              buttonType={"button"}
              label={""}
              colorType={"neutral"}
              icon={<FontAwesomeIcon icon={faSliders} />}
              onClick={() => setIsFilter(true)}
              extraClasses={"normal-case"}
            />
          </>
        }>
        {filterByType.id && (
          <div
            className={
              "flex w-fit items-center rounded-[7px] border-[1px] border-gray-400 bg-white py-[5px] px-[10px] text-[12px]"
            }>
            <div className={"mr-4 "}>
              Filter by {filterByType.name} (
              {!isLoadingWorks &&
                works &&
                works.length > 0 &&
                works[0]?.totalRecords}
              )
            </div>
            <div
              className={"close-popup cursor-pointer"}
              onClick={() => {
                setFilterByType({ id: "", name: "" });
                setArrayFilter([]);
              }}>
              <img src={"/img/closse.png"} alt={"clear-filter-icon"} />
            </div>
          </div>
        )}
        <div
          className={
            "card card-body  overflow-x-auto border-base-300 bg-base-100 py-0 px-0"
          }>
          {isLoadingWorks ? (
            <LottieLoading />
          ) : (
            <WorkTable
              works={works}
              selectedSortOrder={selectedSortOrder}
              onClickSort={onClickSort}
              closeAddContact={() => setIsOpenAddContact(false)}
              isOpenAddContact={isOpenAddContact}
              getWorks={getData}
              setIsLoading={setIsLoadingWorks}
            />
          )}
        </div>
        <Pagination
          numPages={totalPages}
          currentPage={currentPage}
          onPageChange={({ nextPage }) => {
            if (!isLoading && !isLoadingWorks) {
              setCurrentPage(Math.min(Math.max(nextPage, 1), 20));
            }
          }}
        />
      </FormSection>
      {isAddWork && <AddJobGlobalView setIsAddWork={setIsAddWork} />}
      {isFilter && (
        <FilterWorks
          setIsFilter={setIsFilter}
          setArrayFilter={setArrayFilter}
          setCurrentPage={setCurrentPage}
          setFilterByType={setFilterByType}
          filterOptions={["status", "assignee", "work type"]}
        />
      )}
    </div>
  );
};
