import { useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import * as Xstate from "@xstate/react";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import {
  GetUserInfoByEmail,
  GetUserInfoByEmailVariables,
} from "../../generated/operation-result-types";
import { GET_USER_INFO_BY_EMAIL_GQL } from "../../queries/UserQueries.gql";
import { FormSection } from "../../support/FormSection";
import { GetPageTitle } from "../../support/ScrollToTop";
import EditUserProfile from "./EditUserProfile";

export const UserProfileView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("User profile");
  });

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [isEdit, setIsEdit] = useState(false);
  const closeEdit = () => {
    setIsEdit(false);
  };
  const openEdit = () => {
    setIsEdit(true);
  };
  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
    refetch,
  } = useQuery<GetUserInfoByEmail, GetUserInfoByEmailVariables>(
    GET_USER_INFO_BY_EMAIL_GQL,
    {
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        email: userInfoByEmail!.userProfile!.email,
      },
    },
  );

  if (loadingQ) {
    return <LottieLoading />;
  }
  if (errorQ) {
    console.error("UserProfileView", { errorQ });
    throw new Error("Error getting UserProfileView");
  }

  const userResponse = _.first(dataQ?.users?.edges)?.node;
  if (!userResponse) {
    throw new Error("Missing user in UserProfileView");
  }
  console.log(dataQ);
  const editUserProfile = (
    <>
      <button
        onClick={openEdit}
        className={
          " hover:bg-purple hover:border-purple h-[40px] rounded-[7px] border-[1px] border-black px-[20px] text-[14px] uppercase hover:text-white"
        }>
        Edit
      </button>
      <Transition appear show={isEdit} as={Fragment}>
        <Dialog as={"div"} className={"relative z-10"} onClose={closeEdit}>
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}>
            <div className={"fixed inset-0 bg-black bg-opacity-25"} />
          </Transition.Child>

          <div className={"fixed inset-0 overflow-y-auto"}>
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0 scale-95"}
                enterTo={"opacity-100 scale-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100 scale-100"}
                leaveTo={"opacity-0 scale-95"}>
                <Dialog.Panel
                  className={
                    "z-index-100 w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                  }>
                  <div className={"py-8"}>
                    <EditUserProfile
                      closeModal={closeEdit}
                      id={userResponse.userProfile?.id}
                      refetch={refetch}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
  return (
    <>
      <FormSection
        name={LABELS.features.profile}
        RightSideComponent={editUserProfile}>
        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>Name</div>
          <div className={"text-md font-semibold opacity-80"}>
            {userResponse.userProfile?.fullName}
          </div>
        </div>
        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>Email</div>
          <div className={"text-md font-semibold opacity-80"}>
            {userResponse.userProfile?.email}
          </div>
        </div>
      </FormSection>
      <FormSection name={"Change password"}>
        <div className={"text-base-content opacity-80"}>
          Follow these steps to change your password:
        </div>
        <div className={"text-md opacity-80"}>
          <li>Sign-out of ProCharted</li>
          {/* eslint-disable-next-line */}
          <li>{'Use "forgot my password" link to reset'}</li>
        </div>
      </FormSection>
    </>
  );
};
