import React from "react";
import { match } from "ts-pattern";

type Props = {
  size: "extra-small" | "small" | "medium" | "large" | "super-small";
  label: string;
  type?: "Client" | "Organization";
};

export const AvatarPlaceholder: React.FC<Props> = ({
  type = "Client",
  size,
  label,
}) => {
  const { avatarSizeCx, textSizeCx } = match(size)
    .with("large", () => {
      return { avatarSizeCx: "w-32 h-32", textSizeCx: "text-3xl" };
    })
    .with("medium", () => {
      return { avatarSizeCx: "w-24 h-24", textSizeCx: "text-xl" };
    })
    .with("small", () => {
      return { avatarSizeCx: "w-16 h-16", textSizeCx: "" };
    })
    .with("extra-small", () => {
      return { avatarSizeCx: "w-10 h-10", textSizeCx: "text-[14px]" };
    })
    .with("super-small", () => {
      return { avatarSizeCx: "w-8 h-8", textSizeCx: "text-[12px]" };
    })
    .exhaustive();
  const avatarType = match(type)
    .with("Client", () => {
      return "bg-neutral-focus";
    })
    .with("Organization", () => {
      return "bg-purple-900";
    })
    .exhaustive();

  return (
    <>
      <div className={"placeholder avatar static"}>
        <div
          className={`rounded-full ${avatarType} text-neutral-content ${avatarSizeCx}`}>
          <span className={`uppercase ${textSizeCx}`}>{label}</span>
        </div>
      </div>
    </>
  );
};
