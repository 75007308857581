import { ServiceFeeModel } from "../features/work-charts/models/service-fee.model";
import { LocationModel } from "@syncfusion/ej2-react-charts";

export const repeats = [
  { id: "EA4BC051-000C-4990-9688-DCE840BE1DAA", name: "Never" },
  { id: "51E84C88-3FBC-4B30-812B-115C0EEEFC26", name: "Yearly" },
  { id: "85A935E1-B716-4363-AAF1-582D1FE3BF8A", name: "Monthly" },
  { id: "A4DD8752-A9C3-4EC7-AE39-76933294328E", name: "Daily" },
  { id: "2C8923EC-2278-4ECF-AD0D-9220B70AAFBF", name: "Weekly" },
  { id: "9674B983-3E5E-451C-B049-C77AD662AEED", name: "Quarterly" },
];
export const repeatNever = {
  id: "EA4BC051-000C-4990-9688-DCE840BE1DAA",
  name: "Never",
};

export interface ITime {
  duration: number;
  notes: string;
  id: string;
  assignedUser: IUser;
  assignedUserId: string;
  work: IWork;
  workId: string;
  date: Date;
  contact: IContact | null;
  businessContact: IOrganizationContact | null;
  contactId: string | null;
  businessContactId: string | null;
  assignedUserRelayId: string | null;
}
export interface ISubStatusCount {
  name: string;
  subs: { name: string; count: number }[];
}
export interface ITimer {
  isRunning: boolean;
  isPause: boolean;
}
export interface IDashboard {
  completedWorkCount: number;
  openWorkCount: number;
  overDueWorkCount: number;
  assignedUserOpenWorkCount: { name: string; count: number }[];
  assignedUserOverDueCount: { name: string; count: number }[];
  workTypeOpenWorkCount: { name: string; count: number }[];
  workTypeOverDueCount: { name: string; count: number }[];
  statusCount: {
    Ready: number;
    "In Progress": number;
    Waiting: number;
    Completed: number;
  };
  subStatusCount: ISubStatusCount[];
}
export interface addIndividualState {
  customerType: {
    id: string;
  };
}
export interface IPhoneType {
  id: string;
  phoneNumberType: string;
}
export interface IUserInvitation {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}
export interface IOrganizationContactNote {
  businessContactId: string;
  createdAt: Date;
  id: string;
  note: string;
  title: string;
  isPinned: boolean;
  createdByUser: { userProfile: { firstName: string; lastName: string } };
}
export interface IWorkTask {
  createdAt: string;
  description: string;
  id: string;
  isDone: boolean;
  jobId: string;
  jobIdBase64: string;
  name: string;
  order: number;
}
export interface IWorkTemplateTask {
  name: string;
  description: string;
  linkName: null;
  linkUrl: null;
  order: number;
  isDone: boolean;
  workTemplateId: string;
  id: string;
  relayId: null;
  createdAt: Date;
}
export interface ITemplate {
  templateName: string;
  description: string;
  orgId: string;
  workTemplateTasks: IWorkTemplateTask[];
  id: string;
  relayId: null;
  createdAt: Date;
}
/*serviceStatuses:*/
export interface IServiceStatusesWork {
  name: string;
  description: string;
  color: string;
  order: number;
  serviceId: string;
  workTypeStatusId: string;
  workTypeStatus: {
    name: string;
    description: string;
    color: string;
    order: number;
    workTypeSubStatuses: any[];
    id: string;
    relayId: null;
    createdAt: string;
  };
  serviceSubStatuses: [];
  countWork: number;
  id: string;
  relayId: null;
  createdAt: string;
}

export interface IService {
  name: string;
  description: string;

  serviceFee: null;
  id: string;
  relayId: null;
  createdAt: string;
  serviceStatuses: IServiceStatusesWork[];
}
export interface IWork {
  customerId: string;
  totalRecords: number;
  createdAt: string;
  id: string;
  name: string;
  assignedUser: {
    id: string;
    userProfile: { firstName: string; middleName: string; lastName: string };
  };
  jobStatusId: string;
  jobSubStatusId: string;
  customer: { name: string; contactId: string };
  jobStatus: {
    name: string;
    color: string;
    id: string;
    workTypeStatusId: string;
  };
  jobSubStatus: {
    subStatus: string;
    color: string;
    serviceStatusId: string;
    worktypeSubStatusId: string;
  };
  repeatTypeEnum: { id: string; repeatType: string };
  repeatTypeRecurring: number;
  service: IService;
  startDate: string;
  dueDate: string;
  template: ITemplate;
  templateId: string;
  workTasks: IWorkTask[];
  businessContact: any;
  businessContactId: string;
}

export interface IContactProfile {
  address: { line1: string; city: string; postalCode: string; region: string };
  contactId: string;
  customerRelayId: string;
  contactStatusTypeEnumId: string;
  contactType: string;
  contactTypeEnumId: string;
  customerId: string;
  customerType: string;
  customerTypeEnumId: string;
  email: string;
  firstName: string;
  lastName: string;
  locationId: string;
  middleName: string;
  orgId: string;
  phoneNumber: string;
  phoneNumberTypeEnumId: string;
  socialIdentificationNumber: string;
  additionalInfo: string;
}

export interface IRepeatTypeEnum {
  createdAt: string;
  id: string;
  relayId: string;
  repeatType: string;
}

export interface ITaskTemplate {
  createdAt: string;
  id: string;
  org: string;
  orgId: string;
  orgRelayId: string;
  templateName: string;
  workTemplateTasks: IWorkTemplateTasks[];
  description: string;
}
export interface IWorkTemplateTasks {
  createdAt: string;
  description: string;
  id: string;
  isDone: boolean;
  linkName: string;
  linkUrl: string;
  name: string;
  order: number;
  relayId: string;
  workTemplateId: string;
}

export interface IAddWork {
  workName: string;
  workTypeId: string;
  taskTemplateId: string;
  assignedToUserId: string;
  startDate: Date;
  dueDate: Date;
  repeats: string;
  repeatsEveryMonths: number;
  orgId: string;
}

export interface ISubStatus {
  id: string;
  subStatus: string;
  workTypeStatusId: string;
  check: boolean;
}

export interface IWorkflow {
  color: string;
  createdAt: string;
  description: string;
  id: string;
  name: string;
  order: number;
  relayId: string;
  serviceId: string;
  workTypeSubStatuses: ISubStatus[];
}

export interface IAddSubStatus {
  id: string;
  relayId: string;
  name: string;
}

export interface IEditWorkType {
  id: string;
  statuses: IStatusesWorkType[];
}

export interface IStatusesWorkType {
  statusId: string;
  subStatuses: string[];
}

export interface IWorkType {
  id: string;
  name: string;
  serviceStatuses: IServiceStatuses[];
}

export interface IServiceStatuses {
  id: string;
  name: string;
  workTypeStatusId: string;
  serviceSubStatuses: IServiceSubStatuses[];
}

export interface IServiceSubStatuses {
  id: string;
  serviceStatusId: string;
  subStatus: string;
  worktypeSubStatusId: string;
}

export interface IContact {
  contactStatusEnumId: string;
  contactTypeEnumId: string;
  createdAt: string;
  firstName: string;
  middleName: string;
  lastName: string;
  contactType: IContactType;
  customers: ICustomers[];
  email: IEmail;
  phoneNumbers: IPhoneNumber[];
  id: string;
  socialIdentificationNumber: string;
  address: string;
  customerType: string;
  phoneNumber: string;
  statusType: IStatusTypeContact;
  openWorkCount: number;
}

export interface IStatusTypeContact {
  createdAt: string;
  id: string;
  relayId: string;
  statusType: string;
}

export interface ICustomers {
  contactId: string;
  createdAt: string;
  customerTypeEnumId: string;
  id: string;
  name: string;
  relayId: string;
}

export interface ILinkedProfile {
  id: string;
  contactId: string;
  linkedContactId: string;
  relation: string;
  linkedProfile: ILinkedProfileContact;
  contact: IContact;
}
export interface ILinkedProfileOrgContact {
  id: string;
  contactId: string;
  linkedContactId: string;
  relation: string;
  linkedContact: ILinkedProfileContact;
  contact: IContact;
}

export interface ILinkedProfileContact {
  contactStatusEnumId: string;
  contactType: string;
  firstName: string;
  id: string;
  middleName: string;
  lastName: string;
  customers: { id: string }[];
}

export interface IContactType {
  contactType: string;
  contactTypeEnumId: string;
  createdAt: string;
  id: string;
}
export interface IOrganizationType {
  createdAt: string;
  id: string;
  organizationType: string;
}

export interface IEmail {
  emailAddress: string;
  contactId: string;
  createdAt: string;
  id: string;
}

export interface IPhoneNumber {
  contactId: string;
  createdAt: string;
  id: string;
  number: string;
  phoneNumberTypeEnumId: string;
}

export interface IUser {
  azureB2CUserId: string;
  createdAt: string;
  id: string;
  invitationCount: number;
  invitedAt: string;
  orgId: string;
  userRoleEnumId: string;
  org: IOrg;
  userAgreement: IUserAgreement;
  userFeatureFlag: IUserFeatureFlag;
  userProfile: IUserProfile;
  userRoleEnum: IUserRoleEnum;
}

export interface IOrg {
  id: string;
  legalName: string;
}

export interface IUserAgreement {
  acceptedPrivacyAt: string;
  acceptedTermsAt: string;
  userId: string;
}

export interface IUserFeatureFlag {
  id: string;
  quickStartV1Completed: string;
  userId: string;
}

export interface IUserProfile {
  createdAt: string;
  email: string;
  firstName: string;
  if: string;
  lastName: string;
  middleName: string;
  userId: string;
}

export interface IUserRoleEnum {
  createdAt: string;
  id: string;
  userRole: string;
}

export interface IWorkNote {
  createdAt: Date;
  createdByUser: { userProfile: { firstName: string; lastName: string } };
  createdByUserId: string;
  id: string;
  isPinned: boolean;
  modifiedByUser: string | null;
  note: string;
  relayId: string;
  title: string;
  updatedAt: string;
  workId: string;
}
export interface IOrganizationContact {
  businessCustomers: { id: string }[];
  incorporationDate: Date;
  yearEnd: string;
  organizationType: IOrganizationType;
  businessNumber: string;
  companyName: string;
  contact: IContact;
  contactId: string;
  contactStatusEnumId: string;
  contactType: IContactType;
  contactTypeEnumId: string;
  createdAt: string;
  id: string;
  locationId: string;
  relayId: string;
  role: string;
  socialIdentificationNumber: string;
  email: string;
  address: { line1: string; city: string; region: string; postalCode: string };
  statusType: IStatusTypeContact;
  openWorkCount: number;
  additionalInfo: string;
  organizationTypeId: string;
}
