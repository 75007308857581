import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import * as Xstate from "@xstate/react";
import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Outlet, useNavigate } from "react-router-dom";

import { ROUTE_NAMES } from "../../app/constants/TextConstants";
import { reactPlugin } from "../../app/insights/AppInsightsPlugin";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { ErrorFallback } from "../../components/errors/ErrorFallback";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { LottieSessionExpired } from "../../components/graphics/LottieSessionExpired";
import { MainSidebarNav } from "./MainSidebarNav";
import { MainTopNav } from "./MainTopNav";
function clearAllCookies() {
  const Cookies = document.cookie.split(";");
  // set past expiry to all cookies
  for (let i = 0; i < Cookies.length; i++) {
    document.cookie = Cookies[i] + "=; expires=" + new Date(0).toUTCString();
  }
}
export const MainView: React.FC<unknown> = () => {
  // xstate
  const navigate = useNavigate();
  const { userAuthService, userInfoService } = React.useContext(GlobalContext);
  const [userAuthState] = Xstate.useActor(userAuthService);
  const { userAuthInfo } = userAuthState.context;
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation } = userInfoState.context;

  const userEmail = userAuthInfo?.email;
  const locationId = currentLocation?.id;

  const [isSessionExpired, setIsSessionExpired] = React.useState(false);
  useIdleTimer({
    crossTab: false,
    timeout: 1000 * 60 * 30,
    debounce: 1000,
    stopOnIdle: true,
    onIdle: () => {
      sessionStorage.clear();
      clearAllCookies();
      navigate(ROUTE_NAMES.signOut);
      // setIsSessionExpired(true);
    },
  });

  if (isSessionExpired) {
    return (
      <div>
        <LottieSessionExpired />
      </div>
    );
  }

  if (!userEmail) {
    return <LottieLoading />;
  }

  return (
    <div className={"flex flex-col justify-start"}>
      <MainTopNav />
      <div
        className={
          "flex flex h-full min-h-[95vh] flex-grow justify-start justify-center bg-gray-100"
        }>
        {/* side-nav sets the location */}
        <MainSidebarNav />

        {/* render content only if location id is not empty */}
        {!!locationId && (
          <div
            className={"h-full w-full min-w-[75vw] pl-32 pr-8"}
            id={"proc-main-view"}>
            <div className={"w-full py-4"}>
              <AppInsightsErrorBoundary
                onError={ErrorFallback}
                appInsights={reactPlugin}>
                {/* render main route */}
                <Outlet />
              </AppInsightsErrorBoundary>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
