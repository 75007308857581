import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getIndividualContacts, getOrganizationContacts } from "../../api/api";
import { LABELS } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { getInitialsFromContactName } from "../../constants/constants";
import { FormSection } from "../../support/FormSection";
import { IconButton } from "../../components/button/IconButton";
import { WarningExclaimSvg } from "../../components/svg/WarningExclaimSvg";
import TooltipDeactivatedMessage from "./TooltipDeactivatedMessage";

interface IContactItem {
  name: string;
  id: string;
  email: string | null;
  phoneNumber: string | null;
  contactType: string;
  openWork: number | null;
  isBusinessContact: boolean;
}

export const DeactivatedCustomersListView: React.FC<unknown> = () => {
  const navigate = useNavigate();

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const [contactList, setContactList] = useState<IContactItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    const contacts = await getIndividualContacts(
      false,
      `${userInfoByEmail?.org?.id}`,
    );
    const businessContact = await getOrganizationContacts(
      false,
      `${userInfoByEmail?.org?.id}`,
    );
    setContactList([
      ...contacts.map((item) => ({
        name: `${item.firstName} ${item.lastName}`,
        id: item.customers[0].id,
        isBusinessContact: false,
        email: item.email?.emailAddress,
        contactType: item.contactType?.contactType,
        phoneNumber: item.phoneNumbers[0]?.number,
        openWork: item.openWorkCount,
      })),
      ...businessContact.map((item) => ({
        name: item.companyName,
        id: item.id,
        isBusinessContact: true,
        email: item.email,
        contactType: item.contactType?.contactType,
        phoneNumber: item.businessNumber,
        openWork: item.openWorkCount,
      })),
    ]);
    setIsLoading(false);
  };
  useEffectOnce(() => {
    setIsLoading(true);
    fetchData();
  });

  if (isLoading) {
    return <LottieLoading />;
  }

  const contactRows = contactList
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((contact, index) => {
      const contactNameColumn = () => {
        if (!contact) return null;

        return (
          <div className={"flex items-center space-x-3 text-[14px]"}>
            <AvatarPlaceholder
              type={contact.isBusinessContact ? "Organization" : "Client"}
              size={"extra-small"}
              label={
                contact?.name
                  ? getInitialsFromContactName(contact?.name)
                  : "N/A"
              }
            />
            <div
              onClick={() =>
                navigate(
                  contact.isBusinessContact
                    ? `/organization/${contact.id}`
                    : `/client/${contact.id}`,
                )
              }
              className={
                "link-primary  cursor-pointer text-[14px] font-[500] hover:underline"
              }>
              {contact.name}
            </div>
            <TooltipDeactivatedMessage />
          </div>
        );
      };

      const contactPhoneNumberColumn = () => {
        if (!contact) return null;
        return (
          contact.phoneNumber && (
            <div className={"text-[14px]"}>{contact.phoneNumber}</div>
          )
        );
      };

      const contactEmailColumn = () => {
        if (!contact) return null;

        return (
          contact.email && (
            <a
              href={`mailto:${contact.email}`}
              className={"link-primary text-[14px] hover:underline"}>
              {contact.email}
            </a>
          )
        );
      };
      const contactTypeColumn = () => {
        if (!contact) return null;

        return (
          contact.contactType && (
            <div className={"text-[14px]"}>{contact.contactType}</div>
          )
        );
      };
      const openWorksColumn = () => {
        if (!contact) return null;

        return (
          contact.openWork !== null && (
            <div className={"text-[14px]"}>{contact.openWork}</div>
          )
        );
      };

      return (
        <tr key={contact.id} className={"hover:bg-gray-100"}>
          <td
            className={
              "border-b-[1px] border-gray-300 bg-transparent px-[16px] py-[8px]"
            }>
            {contactNameColumn()}
          </td>
          <td
            className={
              "border-b-[1px] border-gray-300 bg-transparent px-[16px] py-[8px]"
            }>
            {contactPhoneNumberColumn()}
          </td>
          <td
            className={
              "border-b-[1px] border-gray-300 bg-transparent px-[16px] py-[8px]"
            }>
            {contactEmailColumn()}
          </td>
          <td
            className={
              "border-b-[1px] border-gray-300 bg-transparent px-[16px] py-[8px]"
            }>
            {contactTypeColumn()}
          </td>
          <td
            className={
              "border-b-[1px] border-gray-300 bg-transparent px-[16px] py-[8px]"
            }>
            {openWorksColumn()}
          </td>
        </tr>
      );
    });

  const customersTableView = (
    <table className={"w-full"}>
      <thead className={"border-b-[1px] border-gray-300"}>
        <tr>
          <th
            className={
              "py-[16px] pl-[16px] pr-[32px] text-start text-[14px] normal-case"
            }>
            {LABELS.features.contact}
          </th>
          <th
            className={
              "py-[16px] pl-[16px] pr-[32px] text-start text-[14px] normal-case"
            }>
            Phone Number
          </th>
          <th
            className={
              "py-[16px] pl-[16px] pr-[32px] text-start text-[14px] normal-case"
            }>
            Email Address
          </th>
          <th
            className={
              "py-[16px] pl-[16px] pr-[32px] text-start text-[14px] normal-case"
            }>
            Contact type
          </th>
          <th
            className={
              "px-[16px] py-[16px] text-start text-[14px] normal-case"
            }>
            Open work
          </th>
        </tr>
      </thead>
      <tbody>{contactRows}</tbody>
    </table>
  );
  return (
    <FormSection
      name={`${LABELS.features.deactivatedContacts} ( ${contactList.length} )`}
      extraCx={""}
      showHeading={false}>
      <div
        className={
          "text-lg font-bold"
        }>{`${LABELS.features.deactivatedContacts} ( ${contactList.length} )`}</div>

      <div
        className={
          "padding card card-body overflow-x-auto border-base-300 bg-base-100 px-0 py-0"
        }>
        {customersTableView}
      </div>
    </FormSection>
  );
};
