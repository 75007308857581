import * as Xstate from "@xstate/react";
import { DatePicker } from "baseui/datepicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  getIndividualContacts,
  getOrganizationContacts,
  getUsers,
  getWorkTypes,
} from "../../../api/api";
import { DATE_FORMATS, DATES } from "../../../app/commonOps/CommonDateOps";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import SearchContact from "../../../components/searchContact";
import {
  IAddWork,
  ITaskTemplate,
  IUser,
  IWorkType,
  repeatNever,
} from "../../../types/types";

type AddWorkProps = {
  setIsAddWork: (isAddWork: boolean) => void;
  defaultContact?: {
    id: string;
    name: string;
    isBusinessContact: boolean;
    customerId?: string;
  };
  errorMessage?: string;
};

const AddWork: React.FC<AddWorkProps> = ({
  setIsAddWork,
  defaultContact,
  errorMessage,
}) => {
  const navigate = useNavigate();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [taskTemplates, setTaskTemplates] = useState<ITaskTemplate[]>([]);
  const [workTypes, setWorkTypes] = useState<IWorkType[]>([]);
  const [contactsForSort, setContactsForSort] = useState<
    {
      id: string;
      name: string;
      isBusinessContact: boolean;
      customerId?: string;
    }[]
  >([]);

  const [users, setUsers] = useState<IUser[]>([]);

  const [currentContact, setCurrentContact] = useState(
    defaultContact
      ? defaultContact
      : { id: "", name: "", isBusinessContact: false, customerId: "" },
  );

  const [error, setError] = useState("");
  const [errorUser, setErrorUser] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<IAddWork>({
    defaultValues: {
      orgId: userInfoByEmail?.org?.id,
      dueDate: DATES.tomorrow,
      startDate: DATES.today(),
      taskTemplateId: "",
      assignedToUserId: "",
      workName: "",
      workTypeId: "",
      repeats: repeatNever.id,
      repeatsEveryMonths: 0,
    },
  });

  const getTaskTemplate = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/work-template/get-all?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTaskTemplates(data);
    }
  };
  const getCurrentUserByRelayId = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/users/${userInfoByEmail?.id}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setValue("assignedToUserId", data.id);
      setCurrentUserId(data.id);
    }
  };
  const fetchData = async () => {
    const contacts = await getIndividualContacts(
      true,
      `${userInfoByEmail?.org?.id}`,
    );
    const businessContact = await getOrganizationContacts(
      true,
      `${userInfoByEmail?.org?.id}`,
    );
    await getCurrentUserByRelayId();
    setContactsForSort([
      ...contacts.map((item) => ({
        name: `${item?.firstName} ${item?.lastName}`,
        id: item.customers[0].id,
        isBusinessContact: false,
      })),
      ...businessContact.map((item) => ({
        name: item.companyName,
        id: item.id,
        isBusinessContact: true,
        customerId: item.businessCustomers[0].id,
      })),
    ]);
  };
  useEffect(() => {
    getTaskTemplate().then();
    getWorkTypes(`${userInfoByEmail?.org?.id}`).then((res) =>
      setWorkTypes(res),
    );
    getUsers(`${userInfoByEmail?.org?.id}`).then((res) => setUsers(res));
    fetchData().then();
  }, []);

  const addWork = async (data: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/work/add-work",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      const work = await response.json();
      setIsAddWork(false);

      return work;
    } else {
      setErrorUser("Something went wrong");
    }
  };
  const onSubmit: SubmitHandler<IAddWork> = async (data) => {
    if (!currentContact.id) {
      setError("Select Contact");
    }
    if (errorMessage) {
      setErrorUser(errorMessage);
    } else {
      const work = await addWork({
        name: data.workName,
        orgId: null,
        orgRelayId: `${userInfoByEmail?.org?.id}`,
        serviceId: data.workTypeId,
        templateId: data.taskTemplateId ? data.taskTemplateId : null,
        repeatTypeEnumId: repeatNever.id,
        repeatTypeRecurring: 0,
        dueDate: moment(data.dueDate).utc(),
        startDate: moment(data.startDate).utc(),
        assignedUserId: data.assignedToUserId,
        customerId: currentContact.isBusinessContact
          ? currentContact.customerId
          : currentContact?.id,
        businessContactId: currentContact.isBusinessContact
          ? currentContact.id
          : null,
        workTasks: [],
      });
      navigate(`/job/${work.id}`);
    }
  };

  return (
    <>
      <div
        onClick={() => setIsAddWork(false)}
        id={"my-popup"}
        className={
          "fixed top-0 left-0 z-[3] flex h-screen w-screen items-center justify-center bg-gray-500/20"
        }>
        <div
          className={
            "max-h-[750px] w-[480px] overflow-hidden rounded-[10px] bg-white "
          }
          onClick={(event) => event.stopPropagation()}>
          <div className={"max-h-[750px] overflow-auto"}>
            <div
              className={
                "flex border-b-[1px] border-gray-300 px-[20px] py-[15px]"
              }>
              <div className={"w-full text-center font-bold"}>New work</div>
              <div
                className={"close-popup text-end"}
                onClick={() => setIsAddWork(false)}>
                <img src={"/img/closse.png"} alt={""} />
              </div>
            </div>
            <div className={"mt-[30px] mb-[10px] px-[20px]"}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"mb-10"}>
                  <div className={"mb-4"}>
                    <div className={"label-text mb-2"}>Work Name*</div>
                    <div>
                      <input
                        type={"text"}
                        className={"input input-bordered w-full "}
                        {...register("workName", {
                          validate: (value) => {
                            // Trim the input value to remove leading and trailing spaces
                            const trimmedValue = value.trim();
                            // Check if the trimmed value is empty or consists only of spaces
                            if (trimmedValue === "") {
                              return "This field is required";
                            }
                            return true;
                          },
                        })}
                      />
                    </div>
                    {errors?.workName?.message && (
                      <span className={"pt-2 text-sm font-bold text-error"}>
                        {errors?.workName?.message}
                      </span>
                    )}
                  </div>
                  <div className={"mb-4"}>
                    <div className={"label-text mb-2"}>Work type*</div>
                    <div className={"w-full"}>
                      <select
                        {...register("workTypeId", {
                          required: "This field is required",
                        })}
                        className={"select select-bordered w-full "}>
                        <option value={""} disabled selected hidden>
                          Choose a work type
                        </option>
                        {workTypes.map((workType) => (
                          <option
                            key={workType.id}
                            value={workType.id}
                            className={"text-black"}>
                            {workType.name}
                          </option>
                        ))}
                      </select>
                      {errors?.workTypeId?.message && (
                        <span className={"pt-2 text-sm font-bold text-error"}>
                          {errors?.workTypeId?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={"mb-4"}>
                    <div className={"label-text mb-2"}>Subtask Template</div>
                    <div className={"w-full"}>
                      <select
                        {...register("taskTemplateId")}
                        className={"select select-bordered w-full"}>
                        <option value={""}>None</option>
                        {taskTemplates.map((taskTemplate) => (
                          <option key={taskTemplate.id} value={taskTemplate.id}>
                            {taskTemplate.templateName}
                          </option>
                        ))}
                      </select>
                      {errors?.taskTemplateId?.message && (
                        <span className={"pt-2 text-sm font-bold text-error"}>
                          {errors?.taskTemplateId?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={"mb-4"}>
                    <div className={"label-text mb-2"}>Contact*</div>
                    <SearchContact
                      contacts={contactsForSort}
                      saveContact={(contact) => {
                        setContactsForSort((prev) => [...prev, contact]);
                        setCurrentContact(contact);
                        setError("");
                      }}
                      defaultContact={defaultContact}
                    />
                    {error && (
                      <span className={"pt-2 text-sm font-bold text-error"}>
                        {error}
                      </span>
                    )}
                  </div>
                  <div className={"mb-4"}>
                    <div className={"label-text mb-2"}>Assigned to*</div>
                    <div className={"w-full"}>
                      <select
                        {...register("assignedToUserId")}
                        className={"select select-bordered w-full"}>
                        {users.map((user) => (
                          <option
                            key={user.id}
                            value={user.id}
                            selected={user.id === currentUserId}>
                            {user.userProfile &&
                              `${user.userProfile.firstName} ${user.userProfile.middleName} ${user.userProfile.lastName}`}
                          </option>
                        ))}
                      </select>
                      {errors?.assignedToUserId?.message && (
                        <span className={"pt-2 text-sm font-bold text-error"}>
                          {errors?.assignedToUserId?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={"mb-4 flex space-x-[10px]"}>
                    <div className={"w-full"}>
                      <div className={"label-text mb-2"}>Start date</div>
                      <div>
                        <Controller
                          control={control}
                          name={"startDate"}
                          rules={{
                            required: "Start date is required",
                            validate: (value) => {
                              const dueDate = getValues("dueDate");
                              return (
                                (dueDate && value && value <= dueDate) ||
                                "Start date must be earlier than or equal to due date"
                              );
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              overrides={{
                                MonthYearSelectPopover: {
                                  props: {
                                    overrides: {
                                      Body: {
                                        style: { zIndex: 10000 },
                                      },
                                    },
                                  },
                                },
                                Input: {
                                  props: {
                                    mountNode:
                                      document.getElementById("my-popup"),
                                    overrides: {
                                      Input: {
                                        style: () => ({
                                          backgroundColor: "#FFFFFF",
                                        }),
                                      },
                                      Root: {
                                        style: () => ({
                                          borderTopWidth: "1px",
                                          borderRightWidth: "1px",
                                          borderBottomWidth: "1px",
                                          borderLeftWidth: "1px",
                                          borderTopColor: "#D4D6D9",
                                          borderRightColor: "#D4D6D9",
                                          borderBottomColor: "#D4D6D9",
                                          borderLeftColor: "#D4D6D9",
                                        }),
                                      },
                                    },
                                  },
                                },
                                Popover: {
                                  props: {
                                    overrides: {
                                      Body: {
                                        style: {
                                          zIndex: 9999,
                                        },
                                      },
                                    },
                                  },
                                },
                              }}
                              value={value}
                              formatString={DATE_FORMATS.dateOnly}
                              onChange={({ date }) => onChange(date)}
                            />
                          )}
                        />
                        {errors?.startDate?.message && (
                          <span className={"pt-2 text-sm font-bold text-error"}>
                            {errors?.startDate?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={"w-full"}>
                      <div className={"label-text mb-2"}>Due date</div>
                      <div>
                        <Controller
                          control={control}
                          name={"dueDate"}
                          rules={{
                            required: "Due date is required",
                            validate: (value) => {
                              const startDate = getValues("startDate");
                              return (
                                (startDate && value && value >= startDate) ||
                                "Due date must be later than or equal to start date"
                              );
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              overrides={{
                                Input: {
                                  props: {
                                    mountNode:
                                      document.getElementById("my-popup"),
                                    overrides: {
                                      Input: {
                                        style: () => ({
                                          backgroundColor: "#FFFFFF",
                                        }),
                                      },
                                      Root: {
                                        style: () => ({
                                          borderTopWidth: "1px",
                                          borderRightWidth: "1px",
                                          borderBottomWidth: "1px",
                                          borderLeftWidth: "1px",
                                          borderTopColor: "#D4D6D9",
                                          borderRightColor: "#D4D6D9",
                                          borderBottomColor: "#D4D6D9",
                                          borderLeftColor: "#D4D6D9",
                                        }),
                                      },
                                    },
                                  },
                                },
                                Popover: {
                                  props: {
                                    overrides: {
                                      Body: {
                                        style: {
                                          zIndex: 9999,
                                        },
                                      },
                                    },
                                  },
                                },
                              }}
                              value={value}
                              formatString={DATE_FORMATS.dateOnly}
                              onChange={({ date }) => onChange(date)}
                            />
                          )}
                        />

                        {errors?.dueDate?.message && (
                          <span className={"pt-2 text-sm font-bold text-error"}>
                            {errors?.dueDate?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"mb-8 mt-4 flex items-center justify-end gap-4"}>
                  <OutlineButton
                    colorType={"neutral"}
                    label={"Cancel"}
                    onClick={() => setIsAddWork(false)}
                    buttonType={"button"}
                    extraClasses={"normal-case"}
                  />
                  <Button
                    buttonType={"submit"}
                    label={"Save"}
                    onClick={() => {
                      if (!currentContact.id) {
                        setError("Select Contact");
                      }
                    }}
                    extraClasses={"normal-case"}
                  />
                  {errorUser && (
                    <div className={"pt-2 text-sm font-bold text-error"}>
                      {errorUser}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWork;
