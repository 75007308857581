import React, { useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import OrganizationDetails from "./OrganizationDetails";
import OrganizationNotes from "../OrganizationNotes";
import OrganizationWorks from "../OrganizationWorks";
import { useEffectOnce } from "usehooks-ts";
import { GetPageTitle } from "../../../../support/ScrollToTop";
import { CustomerProfileView } from "../../contact/contactProfile/CustomerProfileView";
import CustomerJobsListView from "../../contact/CustomerJobsListView";
import ContactNote from "../../contact/ContactNote";

const OrganizationView = () => {
  const [activePage, setActivePage] = useState<"profile" | "notes" | "work">(
    "profile",
  );

  useEffectOnce(() => {
    document.title = GetPageTitle("Organization");
  });

  switch (activePage) {
    case "profile":
      return (
        <OrganizationDetails
          setActivePage={setActivePage}
          activePage={activePage}
        />
      );
    case "work":
      return (
        <OrganizationWorks
          setActivePage={setActivePage}
          activePage={activePage}
        />
      );
    case "notes":
      return (
        <OrganizationNotes
          setActivePage={setActivePage}
          activePage={activePage}
        />
      );
    default:
      return <>Not found</>;
  }
};

export default OrganizationView;
