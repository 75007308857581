import { combineReducers, configureStore } from "@reduxjs/toolkit";

import appReducer from "../services/AppService";
import { contactsAPI } from "../services/ContactService";
import timerReducer from "../services/TimerService";
import { userAPI } from "../services/UserService";
import worksReducer from "../services/WorkReducer";

const rootReducer = combineReducers({
  [contactsAPI.reducerPath]: contactsAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  timerReducer,
  worksReducer,
  appReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(contactsAPI.middleware, userAPI.middleware),
  });
};
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
