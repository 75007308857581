import React from "react";

import Button from "./button/Button";
import { OutlineButton } from "./button/OutlineButton";

interface IConfirmPopupProps {
  close: () => void;
  confirm: () => void;
  title?: string;
  message?: string | JSX.Element;
}
const ConfirmPopup: React.FC<IConfirmPopupProps> = ({
  close,
  confirm,
  title,
  message,
}) => {
  return (
    <div
      onClick={close}
      className={
        "fixed top-0 left-0 z-[3] flex h-screen w-screen items-center justify-center bg-gray-500/20"
      }>
      <div
        className={"max-h-[750px] w-[480px] rounded-[10px] bg-white p-4"}
        onClick={(event) => event.stopPropagation()}>
        <div className={"mb-4 text-[16px] font-medium text-black"}>{title}</div>
        <div className={"mb-4 text-[14px] text-gray-500"}>{message}</div>
        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <Button
            buttonType={"button"}
            onClick={confirm}
            label={"Continue"}
            extraClasses={"normal-case"}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={close}
            extraClasses={"normal-case"}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
