import { Dialog, Transition } from "@headlessui/react";
import * as Xstate from "@xstate/react";
import { DatePicker } from "baseui/datepicker";
import moment from "moment";
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";

import { getIndividualContacts, getOrganizationContacts } from "../../api/api";
import { DATE_FORMATS } from "../../app/commonOps/CommonDateOps";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import {
  getInitialsFromContactName,
  isValidDateFormat,
  timeFormatter,
  timeReformat,
} from "../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {
  deleteTime,
  getAllTimes,
  getAllUsers,
  updateTime,
} from "../../services/AppService";
import {
  getWorkByContact,
  getWorkByOrganizationContact,
} from "../../services/WorkReducer";
import { ITime, IWork } from "../../types/types";

interface IEditTimeProps {
  closeModal: () => void;
  time: ITime;
  isUpdate: number;
}

const EditTime: React.FC<IEditTimeProps> = ({ closeModal, time, isUpdate }) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const dispatch = useAppDispatch();
  const { users, isLoading } = useAppSelector((state) => state.appReducer);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    assignedUserId: string;
    date: Date;
  }>({
    defaultValues: {
      assignedUserId: time.assignedUserId,
      date: new Date(),
    },
  });

  const contactRef = useRef<HTMLDivElement>(null);
  const workRef = useRef<HTMLDivElement>(null);

  const [notes, setNotes] = useState(time?.notes);
  const [duration, setDuration] = useState(`${timeFormatter(time.duration)}`);
  const [error, setError] = useState("");
  const [errorWork, setErrorWork] = useState("");
  const [contactsForSort, setContactsForSort] = useState<
    { id: string; name: string; isBusinessContact: boolean }[]
  >([]);
  const [contactsFiltered, setContactsFiltered] = useState<
    { id: string; name: string; isBusinessContact: boolean }[]
  >([]);
  const [worksFiltered, setWorksFiltered] = useState<IWork[]>([]);
  const [letter, setLetter] = useState(
    time.contactId
      ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
      : `${time.businessContact?.companyName}`,
  );
  const [letterWork, setLetterWork] = useState(`${time.work?.name}`);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isOpenWork, setIsOpenWork] = useState(false);
  const [work, setWork] = useState<IWork>(time.work);

  const closeStatusMenu = () => {
    setIsOpenContact(false);
  };
  useOnClickOutside(contactRef, closeStatusMenu);
  useOnClickOutside(workRef, () => setIsOpenWork(false));
  const [contactId, setContactId] = useState({
    id: time.contactId
      ? time.contact?.customers[0]?.id
      : time.businessContactId,
    isBusinessContact: Boolean(time.businessContactId),
  });
  const { works } = useAppSelector((state) => state.worksReducer);
  const sortContacts = (e: ChangeEvent<HTMLInputElement>) => {
    const sortLetter = e.target.value.toLowerCase();
    setLetter(e.target.value);
    const filtered = contactsForSort.filter((item) =>
      item?.name.toLowerCase().includes(sortLetter.toLowerCase()),
    );
    setContactsFiltered(filtered);
  };
  const sortWorks = (e: ChangeEvent<HTMLInputElement>) => {
    const sortLetter = e.target.value.toLowerCase();
    setLetterWork(e.target.value);
    const filtered = works.filter((item) =>
      item?.name.toLowerCase().includes(sortLetter.toLowerCase()),
    );
    setWorksFiltered(filtered || []);
  };
  const fetchData = async () => {
    const contacts = await getIndividualContacts(
      true,
      userInfoByEmail!.org!.id,
    );
    const businessContact = await getOrganizationContacts(
      true,
      userInfoByEmail!.org!.id,
    );
    setContactsForSort([
      ...contacts.map((item) => ({
        name: `${item?.firstName} ${item?.lastName}`,
        id: item.customers[0].id,
        isBusinessContact: false,
      })),
      ...businessContact.map((item) => ({
        name: item.companyName,
        id: item.id,
        isBusinessContact: true,
      })),
    ]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (contactId.isBusinessContact) {
      if (contactId.id) {
        dispatch(
          getWorkByOrganizationContact({
            id: contactId.id,
            orgId: userInfoByEmail!.org!.id,
          }),
        );
      }
    } else {
      if (contactId.id) {
        dispatch(
          getWorkByContact({
            contactId: contactId.id,
            orgId: userInfoByEmail!.org!.id,
          }),
        );
      }
    }
  }, [contactId]);

  const currentContacts = () => {
    return letter ? contactsFiltered : contactsForSort;
  };
  const currentWorks = () => {
    return letterWork ? worksFiltered : works;
  };

  useEffect(() => {
    dispatch(getAllUsers(userInfoByEmail!.org!.id));
  }, []);

  useEffect(() => {
    setDuration(`${timeFormatter(time.duration)}`);
    setLetterWork(`${time.work?.name}`);
    setLetter(
      time.contactId
        ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
        : `${time.businessContact?.companyName}`,
    );
    setNotes(time?.notes);
    setWork(time.work);
    setValue("date", new Date(time.date) || new Date());
  }, [time, isUpdate]);
  const onChangeDuration = (value: string) => {
    if (value) {
      const hours = value.match(/\d+(?=h)/)?.[0] || "";
      const minutes = value.match(/\d+(?=m)/)?.[0] || "";
      setDuration(`${hours}h ${minutes}m`);
    }
  };
  const onBlurDuration = (event: ChangeEvent<HTMLInputElement>) => {
    const minutes = event.target.value.match(/\d+(?=m)/)?.[0];
    const hours = event.target.value.match(/\d+(?=h)/)?.[0];
    if (minutes) {
      Number(minutes) > 59
        ? setError("Minutes cannot be greater than or equal to 60")
        : setError("");
    }
    if (!minutes || !hours) {
      setError("Wrong format");
    }
  };
  const onSubmit = async (data: any) => {
    if (!letterWork || !work.id) {
      setErrorWork("This field is required");
    }
    if (!error && !errorWork && work.id && letterWork) {
      await dispatch(
        updateTime({
          id: time.id,
          workId: work.id,
          assignedUserId: data.assignedUserId,
          duration: timeReformat(duration),
          contactId: work?.businessContact ? null : work?.customer.contactId,
          businessContactId: work?.businessContact
            ? work?.businessContactId
            : null,
          notes: notes,
          date: moment(data.date).utc(),
        }),
      );
      await setTimeout(
        () => dispatch(getAllTimes(`${userInfoByEmail?.org?.id}`)),
        100,
      );
      closeModal();
    }
  };
  return (
    <div
      onClick={closeModal}
      id={"edit-time"}
      className={
        "fixed top-0 left-0 z-[3] flex h-screen w-screen items-center justify-center bg-gray-500/20"
      }>
      <div
        onClick={(event) => event.stopPropagation()}
        className={
          "max-h-[750px] w-[480px] overflow-hidden rounded-[10px] bg-white "
        }>
        <div
          className={"max-h-[750px] w-full overflow-auto px-[20px] py-[15px]"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div id={"edit-time"}>
              <div className={"w-full text-center"}>Edit Time</div>
              <div className={"mb-4"}>
                <div className={"label-text mb-2"}>Contact</div>
                <div className={"relative w-full"} ref={contactRef}>
                  <div className={""}>
                    <input
                      value={letter}
                      type={"text"}
                      onChange={sortContacts}
                      onFocus={() => setIsOpenContact(true)}
                      placeholder={"Search"}
                      className={
                        "select select-bordered flex w-full items-center placeholder:font-normal"
                      }
                    />
                  </div>
                  {isOpenContact && (
                    <div
                      className={
                        "absolute top-[47px] left-0 z-[1] flex max-h-[200px] w-full flex-col space-y-[2px] overflow-hidden rounded-[10px] border-[1px] border-gray-300 bg-white text-[13px]"
                      }>
                      <div className={"overflow-auto"}>
                        {currentContacts().map((contact) => (
                          <div
                            onClick={() => {
                              setContactId({
                                id: contact.id,
                                isBusinessContact: contact.isBusinessContact,
                              });
                              setLetter(contact.name);
                              setIsOpenContact(false);
                              setLetterWork("");
                              setWork({} as IWork);
                            }}
                            className={
                              "mt-2 flex cursor-default items-center space-x-[4px] px-[16px] hover:bg-blue-400 hover:text-white"
                            }
                            key={contact?.id}>
                            <AvatarPlaceholder
                              type={
                                contact.isBusinessContact
                                  ? "Organization"
                                  : "Client"
                              }
                              size={"super-small"}
                              label={
                                contact?.name
                                  ? getInitialsFromContactName(contact?.name)
                                  : "N/A"
                              }
                            />
                            <div>{`${contact?.name}`}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={"mb-4"}>
                <div className={"label-text mb-2"}>Work</div>
                <div className={"relative"} ref={workRef}>
                  <div>
                    <input
                      value={letterWork}
                      onChange={sortWorks}
                      className={
                        "select select-bordered w-full disabled:border-[#D4D6D9] disabled:bg-transparent"
                      }
                      onFocus={() => setIsOpenWork(true)}
                      disabled={!Boolean(contactId.id)}
                    />
                  </div>
                  {isOpenWork && (
                    <div
                      className={
                        "absolute top-[47px] left-0 flex max-h-[200px] w-full flex-col space-y-[2px] overflow-hidden rounded-[10px] border-[1px] border-gray-300 bg-white pb-2 text-[13px]"
                      }>
                      {isLoading ? (
                        <div>
                          <LottieLoading />
                        </div>
                      ) : (
                        <div className={"overflow-auto"}>
                          {currentWorks()?.map((work) => (
                            <div
                              onClick={() => {
                                setWork(work);
                                setLetterWork(work?.name);
                                setErrorWork("");
                                setIsOpenWork(false);
                              }}
                              className={
                                "mt-2 cursor-default px-[16px] hover:bg-blue-400 hover:text-white"
                              }
                              key={work?.id}>
                              <div>{`${work?.name}`}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {errorWork && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errorWork}
                  </div>
                )}
              </div>
              <div className={"mb-4"}>
                <div className={"label-text mb-2"}>Assignee</div>
                <div>
                  <select
                    {...register("assignedUserId")}
                    className={"select select-bordered w-full"}>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.userProfile &&
                          `${user.userProfile.firstName} ${user.userProfile.middleName} ${user.userProfile.lastName}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={"mb-4 flex justify-between space-x-[20px]"}>
                <div>
                  <div className={"label-text mb-2"}>Date</div>
                  <div>
                    <Controller
                      control={control}
                      name={"date"}
                      rules={{
                        validate: (value) =>
                          Boolean(value) || "Invalid date format",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          overrides={{
                            MonthYearSelectPopover: {
                              props: {
                                overrides: {
                                  Body: {
                                    style: { zIndex: 10000 },
                                  },
                                },
                              },
                            },
                            Input: {
                              props: {
                                mountNode: document.getElementById("edit-time"),
                                overrides: {
                                  Input: {
                                    style: () => ({
                                      backgroundColor: "#FFFFFF",
                                    }),
                                  },
                                  Root: {
                                    style: () => ({
                                      borderTopWidth: "1px",
                                      borderRightWidth: "1px",
                                      borderBottomWidth: "1px",
                                      borderLeftWidth: "1px",
                                      borderTopColor: "#D4D6D9",
                                      borderRightColor: "#D4D6D9",
                                      borderBottomColor: "#D4D6D9",
                                      borderLeftColor: "#D4D6D9",
                                    }),
                                  },
                                },
                              },
                            },
                            Popover: {
                              props: {
                                overrides: {
                                  Body: {
                                    style: {
                                      zIndex: 9999,
                                    },
                                  },
                                },
                              },
                            },
                          }}
                          value={value}
                          formatString={DATE_FORMATS.dateOnly}
                          onChange={({ date }) => {
                            onChange(date);
                          }}
                        />
                      )}
                    />
                  </div>
                  {errors?.date?.message && (
                    <div className={"pt-2 text-sm font-bold text-error"}>
                      {errors?.date?.message}
                    </div>
                  )}
                </div>
                <div>
                  <div className={"label-text mb-2"}>Duration</div>
                  <div>
                    <input
                      onChange={(event) => onChangeDuration(event.target.value)}
                      onBlur={onBlurDuration}
                      value={duration}
                      type={"text"}
                      className={"input input-bordered w-full"}
                    />
                  </div>
                  {error && (
                    <div className={"pt-2 text-sm font-bold text-error"}>
                      {error}
                    </div>
                  )}
                </div>
              </div>
              <div className={"mb-4"}>
                <div className={"label-text mb-2"}>Notes</div>
                <div>
                  <textarea
                    value={notes}
                    className={
                      "input input-bordered min-h-[100px] w-full py-[8px]"
                    }
                    onChange={(event) => setNotes(event.target.value)}
                  />
                </div>
              </div>
              <div className={"flex justify-between"}>
                <div>
                  <button
                    onClick={() => {
                      dispatch(deleteTime(time));
                      setTimeout(
                        () =>
                          dispatch(getAllTimes(`${userInfoByEmail?.org?.id}`)),
                        100,
                      );
                      closeModal();
                    }}
                    className={
                      "inline-block h-[40px] rounded-[7px] border-solid border-[#F15252]  bg-[#F15252] px-[20px] text-[14px] uppercase leading-[40px] text-white"
                    }>
                    Delete
                  </button>
                </div>
                <div className={"flex space-x-[10px]"}>
                  <div>
                    <button
                      type={"button"}
                      onClick={() => closeModal()}
                      className={
                        "bg-purple border-purple inline-block h-[40px] rounded-[7px]  border-solid px-[20px] text-[14px] uppercase leading-[40px] text-white"
                      }>
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button
                      type={"submit"}
                      className={
                        "bg-purple border-purple inline-block h-[40px] rounded-[7px]  border-solid px-[20px] text-[14px] uppercase leading-[40px] text-white"
                      }>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTime;
