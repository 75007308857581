import * as Xstate from "@xstate/react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import {
  getIndividualContacts,
  getOrganizationContacts,
  getUsers,
  getWorkTypes,
} from "../../api/api";
import * as devConfig from "../../app/configs/devConfig";
import { WORK_UPDATE_DATE } from "../../app/constants/ApiEndpointConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import Button from "../../components/button/Button";
import { OutlineButton } from "../../components/button/OutlineButton";
import ConfirmPopup from "../../components/ConfirmPopup";
import { getInitialsFromContactName } from "../../constants/constants";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { FormSection } from "../../support/FormSection";
import { ITaskTemplate, IUser, IWorkType, repeats } from "../../types/types";
import { WorkModel } from "../work-charts/models/work.model";

interface IWorkDetailsProps {
  model: WorkModel;
  getWorkApiCall: () => void;
  setIsLoading: (isLoading: boolean) => void;
}
interface IDetailForm {
  id: string | null;
  relayId: string | null;
  createdAt: string | null;
  workName: string | null;
  userId: string | null;
  templateName: string | null;
  workTypeId: string | null;
  serviceId: string | null;
  taskTemplateId: string | null;
  recurring: string | null;
  assignedUserId: string | null;
  contactId: string | null;
  templateId: string | null;
  startDate: Date;
  endDate: Date;
}
const WorkDetails: React.FC<IWorkDetailsProps> = ({
  model,
  getWorkApiCall,
  setIsLoading,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const assigneeUser = `${model?.assignedUser?.userProfile?.firstName} ${model?.assignedUser?.userProfile?.middleName} ${model?.assignedUser?.userProfile?.lastName}`;
  const [workTypes, setWorkTypes] = useState<IWorkType[]>([]);
  const [taskTemplates, setTaskTemplates] = useState<ITaskTemplate[]>([]);
  const [isOpenConfirmTemplate, setIsOpenConfirmTemplate] = useState(false);

  const [users, setUsers] = useState<IUser[]>([]);
  const [contactsForSort, setContactsForSort] = useState<
    {
      id: string;
      name: string;
      isBusinessContact: boolean;
      customerId?: string;
    }[]
  >([]);
  const [contactsFiltered, setContactsFiltered] = useState<
    {
      id: string;
      name: string;
      isBusinessContact: boolean;
      customerId?: string;
    }[]
  >([]);
  const [error, setError] = useState("");
  const contactRef = useRef<HTMLDivElement>(null);

  const [isOpenContact, setIsOpenContact] = useState(false);
  const closeStatusMenu = () => {
    setIsOpenContact(false);
  };
  useOnClickOutside(contactRef, closeStatusMenu);
  const [contactId, setContactId] = useState({
    id: Boolean(model.businessContactId)
      ? model.businessContactId
      : model.customerId,
    isBusinessContact: Boolean(model.businessContactId),
    customerId: model.customerId,
  });
  const [letter, setLetter] = useState(
    model?.customer?.name || model?.businessContact?.companyName,
  );
  const getTaskTemplates = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/work-template/get-all?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTaskTemplates(data);
    }
  };
  const fetchContacts = async () => {
    const contacts = await getIndividualContacts(
      true,
      userInfoByEmail!.org!.id,
    );
    const businessContact = await getOrganizationContacts(
      true,
      userInfoByEmail!.org!.id,
    );
    setContactsForSort([
      ...contacts.map((item) => ({
        name: `${item?.firstName} ${item?.lastName}`,
        id: item.customers[0].id,
        isBusinessContact: false,
      })),
      ...businessContact.map((item) => ({
        name: item.companyName,
        id: item.id,
        customerId: item.businessCustomers[0].id,
        isBusinessContact: true,
      })),
    ]);
  };
  useEffectOnce(() => {
    getWorkTypes(userInfoByEmail!.org!.id).then((res) => setWorkTypes(res));
    getUsers(userInfoByEmail!.org!.id).then((res) => setUsers(res));
    fetchContacts();
    getTaskTemplates();
  });
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IDetailForm>({
    defaultValues: {
      workName: model?.name,
      templateId: model?.templateId,
      recurring: model?.repeatTypeEnumId,
      assignedUserId: model?.assignedUserId,
      workTypeId: model?.service?.id || "",
      endDate: new Date(model.dueDate),
      startDate: new Date(model.startDate),
    },
  });
  useEffect(() => {
    setValue("workName", model?.name);
    setValue("templateId", model?.templateId);
    setValue("recurring", model?.repeatTypeEnumId);
    setValue("assignedUserId", model?.assignedUserId);
    setValue("workTypeId", model?.service?.id || "");
    setValue("endDate", new Date(model.dueDate));
    setValue("startDate", new Date(model.startDate));
  }, [model]);
  const update = async (data: any) => {
    const responseDate = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/${WORK_UPDATE_DATE}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({
          workId: model?.id,
          startDate: data.startDate,
          endDate: data.endDate,
          createdAt: model?.createdAt,
          relayId: model?.relayId,
        }),
      },
    );
    const responseDetails = await fetch(
      "https://dev.procharted.com/api/work/update-details",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({
          workId: model.id,
          workName: data?.workName,
          serviceId: data.workTypeId,
          taskTemplateId: data.templateId ? data.templateId : null,
          userId: data.assignedUserId,
          customerId: contactId.isBusinessContact
            ? contactId.customerId
            : contactId.id,
          businessContactId: contactId.isBusinessContact ? contactId.id : null,
          repeatTypeEnumId: data.recurring,
        }),
      },
    );
    if (responseDate.ok && responseDetails.ok) {
      getWorkApiCall();
    } else {
      setIsLoading(false);
    }
  };

  const onSubmit = (data: any) => {
    if (!contactId.id) {
      setError("Select a contact or organization contact");
    } else {
      setIsLoading(true);
      update(data).then();
    }
  };
  const sortContacts = (e: ChangeEvent<HTMLInputElement>) => {
    const sortLetter = e.target.value.toLowerCase();
    setLetter(e.target.value);
    const filtered = contactsForSort.filter((item) =>
      item?.name.toLowerCase().includes(sortLetter.toLowerCase()),
    );
    setContactsFiltered(filtered);
  };
  const currentContacts = () => {
    return letter ? contactsFiltered : contactsForSort;
  };

  return (
    <>
      <FormSection
        name={""}
        extraCx={"h-fit px-0 w-2/3 max-w-2/3 overflow-visible"}
        showHeading={false}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={
              "mb-4 flex items-center justify-between border-b-[1px] border-gray-300 px-6 pb-4"
            }>
            <div className={"text-lg font-bold"}>Details</div>
            <div>
              {isEdit ? (
                <div className={"flex gap-4"}>
                  <OutlineButton
                    colorType={"neutral"}
                    label={"Cancel"}
                    onClick={() => setIsEdit(false)}
                    extraClasses={"normal-case"}
                  />
                  <Button
                    buttonType={"button"}
                    label={"Save"}
                    onClick={() => {
                      getValues()?.templateId === model?.templateId ||
                      !model?.templateId
                        ? handleSubmit(onSubmit)()
                        : setIsOpenConfirmTemplate(true);
                    }}
                    extraClasses={"normal-case"}
                  />
                </div>
              ) : (
                <Button
                  buttonType={"button"}
                  label={"Edit"}
                  onClick={() => setIsEdit(true)}
                  extraClasses={"normal-case"}
                />
              )}
            </div>
          </div>
          <div className={"px-6"}>
            {!isEdit ? (
              <div className={"mb-[11px] text-[14px]"}>
                <div className={"mb-12 flex justify-between gap-4"}>
                  <div className={"w-1/3"}>
                    <div className={"mb-2 w-[100px] text-[16px] text-gray-400"}>
                      WORK NAME
                    </div>
                    <div className={"font-[500]"}>{model?.name}</div>
                  </div>
                  <div className={"w-1/3"}>
                    <div className={"mb-2 w-[100px] text-[16px] text-gray-400"}>
                      TEMPLATE
                    </div>
                    <div className={"font-[500]"}>
                      {model?.template?.templateName || "None"}
                    </div>
                  </div>
                  <div className={"w-1/3"}>
                    <div className={"mb-2 w-[100px] text-[16px] text-gray-400"}>
                      WORK TYPE
                    </div>
                    <div className={"font-[500]"}>
                      {model?.service?.name}{" "}
                      {model?.service?.description &&
                        `: ${model?.service?.description}`}
                    </div>
                  </div>
                </div>
                <div className={"flex justify-between gap-4"}>
                  <div className={"w-1/3"}>
                    <div className={"mb-2 w-[100px] text-[16px] text-gray-400"}>
                      CONTACT
                    </div>
                    <div className={"flex items-center"}>
                      {!model.businessContactId ? (
                        <div className={"flex items-center text-[14px]"}>
                          <AvatarPlaceholder
                            size={"super-small"}
                            label={getInitialsFromContactName(
                              model?.customer?.name,
                            )}
                          />
                          <div className={"pl-2 text-[14px] normal-case"}>
                            <Link
                              to={`/client/${model?.customerId}`}
                              className={
                                "link-primary text-sm font-[500] hover:underline"
                              }>
                              {model?.customer?.name}
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className={"flex items-center text-[14px]"}>
                          <AvatarPlaceholder
                            type={"Organization"}
                            size={"super-small"}
                            label={getInitialsFromContactName(
                              model?.businessContact?.companyName,
                            )}
                          />
                          <div className={"pl-2 text-[14px] normal-case"}>
                            <Link
                              to={`/organization/${model?.businessContactId}`}
                              className={
                                "link-primary text-sm font-[500] hover:underline"
                              }>
                              {model?.businessContact?.companyName}
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"w-1/3"}>
                    <div className={"mb-2 w-[100px] text-[16px] text-gray-400"}>
                      ASSIGNEE
                    </div>
                    <div className={"flex items-center text-[14px]"}>
                      <AvatarPlaceholder
                        size={"super-small"}
                        label={
                          model?.assignedUser?.userProfile?.firstName &&
                          model?.assignedUser?.userProfile?.lastName
                            ? `${model?.assignedUser?.userProfile?.firstName.substring(
                                0,
                                1,
                              )}${model?.assignedUser?.userProfile?.lastName.substring(
                                0,
                                1,
                              )}`
                            : "User"
                        }
                      />
                      <div
                        className={
                          "pl-2 text-[14px] text-sm font-[500] normal-case"
                        }>
                        {assigneeUser}
                      </div>
                    </div>
                  </div>
                  <div className={"w-1/3"}>
                    <div className={"mb-2 w-[100px] text-[16px] text-gray-400"}>
                      RECURRING
                    </div>
                    <div className={"font-[500]"}>
                      {model?.repeatTypeEnum?.repeatType}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={"text-[14px]"}>
                <div className={"mb-4 flex justify-between gap-4"}>
                  <div className={"w-1/3"}>
                    <div
                      className={
                        "mb-2 w-[100px] whitespace-nowrap text-[16px] text-gray-400"
                      }>
                      WORK NAME
                    </div>
                    <div className={"w-full min-w-[100px]"}>
                      <input
                        type={"text"}
                        className={"input input-bordered w-full"}
                        {...register("workName", {
                          validate: (value) => {
                            // Trim the input value to remove leading and trailing spaces
                            const trimmedValue = value?.trim();
                            // Check if the trimmed value is empty or consists only of spaces
                            if (trimmedValue === "") {
                              return "This field is required";
                            }
                            return true;
                          },
                        })}
                      />
                      {errors?.workName?.message && (
                        <div className={"pt-2 text-sm font-bold text-error"}>
                          {errors?.workName?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"w-1/3"}>
                    <div
                      className={
                        "mb-2 min-w-[100px] text-[16px] text-gray-400"
                      }>
                      TEMPLATE
                    </div>
                    <select
                      defaultValue={model?.templateId ? model?.templateId : ""}
                      {...register("templateId")}
                      className={"select select-bordered w-full"}>
                      <option value={""}>None</option>
                      {taskTemplates.map((taskTemplate) => (
                        <option
                          key={taskTemplate.id}
                          value={taskTemplate.id}
                          selected={taskTemplate?.id === model?.templateId}>
                          {taskTemplate?.templateName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={"w-1/3"}>
                    <div
                      className={
                        "mb-2 w-[100px] whitespace-nowrap text-[16px] text-gray-400"
                      }>
                      WORK TYPE
                    </div>
                    <div className={"w-full min-w-[100px]"}>
                      <select
                        {...register("workTypeId", { required: true })}
                        className={"select select-bordered w-full"}>
                        {workTypes.map((workType) => (
                          <option
                            key={workType.id}
                            value={workType.id}
                            selected={model?.service?.id === workType.id}>
                            {workType?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className={"flex justify-between gap-4"}>
                  <div className={"w-1/3"}>
                    <div
                      className={
                        "mb-2 min-w-[100px] text-[16px] text-gray-400"
                      }>
                      CONTACT
                    </div>
                    <div className={"flex items-center"}>
                      {!model.businessContactId ? (
                        <div className={"flex items-center text-[14px]"}>
                          <AvatarPlaceholder
                            size={"super-small"}
                            label={getInitialsFromContactName(
                              model?.customer?.name,
                            )}
                          />
                          <div className={"pl-2 text-[14px] normal-case"}>
                            <Link
                              to={`/client/${model.customerId}`}
                              className={
                                "link-primary text-sm font-[500] hover:underline"
                              }>
                              {model.customer?.name}
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className={"flex items-center text-[14px]"}>
                          <AvatarPlaceholder
                            type={"Organization"}
                            size={"super-small"}
                            label={getInitialsFromContactName(
                              model?.businessContact?.companyName,
                            )}
                          />
                          <div className={"pl-2 text-[14px] normal-case"}>
                            <Link
                              to={`/organization/${model?.businessContactId}`}
                              className={
                                "link-primary text-sm font-[500] hover:underline"
                              }>
                              {model?.businessContact?.companyName}
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"w-1/3"}>
                    <div className={"mb-2 w-[100px] text-[16px] text-gray-400"}>
                      ASSIGNEE
                    </div>
                    <div className={"w-full min-w-[100px]"}>
                      <select
                        {...register("assignedUserId", {
                          required: true,
                        })}
                        className={"select select-bordered w-full"}>
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user?.userProfile &&
                              `${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className={"w-1/3"}>
                    <div
                      className={
                        "mb-2 min-w-[100px] text-[16px] text-gray-400"
                      }>
                      RECURRING
                    </div>
                    <div className={"w-full min-w-[100px]"}>
                      <select
                        defaultValue={model?.repeatTypeEnumId}
                        {...register("recurring", { required: true })}
                        className={"select select-bordered w-full"}>
                        {repeats.map((r) => (
                          <option value={r.id.toLowerCase()} key={r.id}>
                            {r?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </FormSection>
      {Boolean(
        isOpenConfirmTemplate &&
          getValues()?.templateId !== model?.templateId &&
          model?.templateId,
      ) && (
        <ConfirmPopup
          confirm={() => handleSubmit(onSubmit)()}
          close={() => setIsOpenConfirmTemplate(false)}
          title={"Change subtask template?"}
          message={
            "You are about to change the subtask template. All current subtasks will be deleted and replaced with the new one. Do you wish to proceed?"
          }
        />
      )}
    </>
  );
};

export default WorkDetails;
