import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { CustomLink } from "../../support/CustomLink";
import { GetPageTitle } from "../../support/ScrollToTop";
import HomeFirmWork from "./HomeFirmWork";
import HomeMyWork from "./HomeMyWork";
import HomeTeamWork from "./HomeTeamWork";
import HomeWorksByUser from "./HomeWorksByUser";

export const HomeView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Home");
  });

  return (
    <div>
      <div className={"mb-6 flex"}>
        <CustomLink to={"./my-work"}>My work</CustomLink>
        <CustomLink to={"./firm-work"}>{"Firm's work"}</CustomLink>
        <CustomLink to={"./team-work"}>{"Team's work"}</CustomLink>
      </div>
      <Routes>
        <Route path={""} element={<Outlet />}>
          <Route
            index
            element={<Navigate to={"/home/my-work"} replace={true} />}
          />
          <Route path={"/my-work"} element={<HomeMyWork />} />
          <Route path={"/firm-work"} element={<HomeFirmWork />} />
          <Route path={"/team-work"} element={<HomeTeamWork />} />
          <Route path={"/team-work/:userId"} element={<HomeWorksByUser />} />
        </Route>
      </Routes>
    </div>
  );
};
