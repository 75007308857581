import React, { useState } from "react";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { BrandButton } from "../../../components/button/BrandButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { AddStackSvg } from "../../../components/svg/AddStackSvg";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";

interface IAddWorkTypeProps {
  setActive: (active: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  getWorkTypesFetch: () => void;
}

const AddWorkType: React.FC<IAddWorkTypeProps> = ({
  setActive,
  setIsLoading,
  getWorkTypesFetch,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail, currentLocation } = userInfoState.context;
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const addWorkType = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/services/add-service",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          name: name,
          orgId: userInfoByEmail?.org?.id,
          locationId: null,
          locationRelayId: currentLocation?.id,
        }),
      },
    );
    if (response.ok) {
      setActive(false);
      getWorkTypesFetch();
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={
        "fixed top-0 left-0 flex h-screen w-screen items-center justify-center bg-black/20"
      }
      onClick={() => setActive(false)}>
      <div
        className={"w-[400px] rounded-[16px] bg-white p-8"}
        onClick={(event) => event.stopPropagation()}>
        <div className={"mb-8 text-center text-[18px] font-bold"}>
          Add Work Type
        </div>
        <div>
          <div className={"mb-2"}>Name</div>
          <div>
            <input
              type={"text"}
              className={"input input-bordered w-full"}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            {error && (
              <div className={"pt-2 text-sm font-bold text-error"}>{error}</div>
            )}
          </div>
        </div>
        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <BrandButton
            buttonType={"button"}
            colorType={"primary"}
            label={"Add"}
            SvgIconLeft={AddStackSvg}
            onClick={() => {
              if (Boolean(name)) {
                setIsLoading(true);
                addWorkType();
              } else {
                setError("This field cannot be empty");
              }
            }}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => setActive(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default AddWorkType;
