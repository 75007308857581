import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";

import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import {
  GetCustomersByLocationForAdd,
  GetCustomersByLocationForAddVariables,
} from "../../../generated/operation-result-types";
import { GET_CUSTOMERS_BY_LOCATION_FOR_ADD_GQL } from "../../../queries/CustomerQueries.gql";
import AddWork from "./AddWork";

interface IAddJobGlobalViewProps {
  setIsAddWork: (isAddWork: boolean) => void;
  defaultContact?: {
    id: string;
    name: string;
    isBusinessContact: boolean;
    customerId?: string;
  };
  error?: string;
}
export const AddJobGlobalView: React.FC<IAddJobGlobalViewProps> = ({
  setIsAddWork,
  defaultContact,
  error,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation } = userInfoState.context;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<
    GetCustomersByLocationForAdd,
    GetCustomersByLocationForAddVariables
  >(GET_CUSTOMERS_BY_LOCATION_FOR_ADD_GQL, {
    fetchPolicy: "network-only",
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { locationId: currentLocation!.id },
  });

  if (errorQ) {
    console.error("AddJobGlobalView | AddJobGlobalView", { errorQ });
    throw new Error("Error getting AddJobGlobalView");
  }

  return (
    <>
      <AddWork
        setIsAddWork={setIsAddWork}
        defaultContact={defaultContact}
        errorMessage={error}
      />
    </>
  );
};
