import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import { getIndividualContacts } from "../../../../api/api";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import { BrandButton } from "../../../../components/button/BrandButton";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import { AddStackSvg } from "../../../../components/svg/AddStackSvg";
import { GetPageTitle } from "../../../../support/ScrollToTop";
import {
  IContact,
  IContactType,
  ICustomers,
  IEmail,
  IPhoneNumber,
  IStatusTypeContact,
} from "../../../../types/types";
import Button from "../../../../components/button/Button";
import SearchContact from "../../../../components/searchContact";
import { DatePicker } from "baseui/datepicker";
import { DATE_FORMATS } from "../../../../app/commonOps/CommonDateOps";
interface IEditLinkedProfileProps {
  closeModal: () => void;
  linkedProfile: any;
  getOrganizationContact: () => void;
}
const EditLinkedProfile: React.FC<IEditLinkedProfileProps> = ({
  closeModal,
  linkedProfile,
  getOrganizationContact,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [contacts, setContacts] = useState<IContact[]>([]);
  const getContacts = async () => {
    const data = await getIndividualContacts(true, userInfoByEmail!.org!.id);
    setContacts(data);
  };
  const editLinkedProfile = async (data: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/business-contacts/linked-profiles/update",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      closeModal();
      getOrganizationContact();
    } else {
    }
  };
  useEffectOnce(() => {
    document.title = GetPageTitle("Add Linked Profiles");
    getContacts();
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      id: linkedProfile?.id,
      linkedContactId: linkedProfile?.linkedContactId,
      relation: linkedProfile?.relation,
    },
  });
  // local effects
  React.useEffect(() => {
    setFocus("relation");
  }, []);
  const onSubmit = async (data: any) => {
    await editLinkedProfile({
      ...data,
      linkedContactId: data.linkedContactId,
    });
  };
  const spouseRow = (
    <div className={"form-control lg:col-span-5"}>
      <label className={"label"}>
        <span className={"label-text"}>Name</span>
      </label>
      <Controller
        control={control}
        {...register("linkedContactId", {
          required: "This field can not be empty",
        })}
        render={({ field: { onChange } }) => (
          <SearchContact
            isIdLikeContactId={true}
            isLinkedProfile={true}
            saveContact={(contact) => {
              onChange(contact.id);
              setContacts((prev) => [
                ...prev,
                {
                  id: contact.id,
                  firstName: contact.name.split(" ")[0],
                  lastName:
                    contact.name.split(" ")[contact.name.split(" ").length - 1],
                  contactType: {} as IContactType,
                  address: "",
                  contactStatusEnumId: "",
                  contactTypeEnumId: "",
                  createdAt: "",
                  customers: [] as ICustomers[],
                  email: {} as IEmail,
                  customerType: "",
                  middleName: "",
                  openWorkCount: 0,
                  phoneNumber: "",
                  phoneNumbers: [] as IPhoneNumber[],
                  socialIdentificationNumber: "",
                  statusType: {} as IStatusTypeContact,
                },
              ]);
            }}
            contacts={contacts.map((item) => ({
              id: item.id,
              name: `${item.firstName} ${item.lastName}`,
              isBusinessContact: false,
            }))}
            defaultContact={{
              isBusinessContact: false,
              name: `${linkedProfile?.linkedContact.firstName} ${linkedProfile?.linkedContact.lastName}`,
              id: linkedProfile?.linkedContactId,
            }}
          />
        )}
      />

      {errors?.linkedContactId?.message && (
        <span className={"pt-2 text-sm font-bold text-error"}>
          {errors?.linkedContactId?.message}
        </span>
      )}
    </div>
  );
  const relationShipRow = (
    <div className={"form-control lg:col-span-5"}>
      <label className={"label"}>
        <span className={"label-text"}>Relationship</span>
      </label>
      <input
        type={"text"}
        {...register("relation", {
          required: "This field can not be empty",
        })}
        className={"input input-bordered"}
        placeholder={"Relationship"}
      />
      {errors?.relation?.message && (
        <span className={"pt-2 text-sm font-bold text-error"}>
          {errors?.relation?.message}
        </span>
      )}
    </div>
  );
  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Edit Linked Profiles</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {spouseRow}
        {relationShipRow}
        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <Button buttonType={"submit"} label={"Update"} />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={closeModal}
          />
        </div>
      </form>
    </>
  );
};

export default EditLinkedProfile;
