export function formatTime(
  seconds: number,
  variant: { isHours: boolean; isMinutes: boolean; isSeconds: boolean },
): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(secs).padStart(2, "0");

  return `${
    variant.isHours
      ? formattedHours + `${variant.isSeconds || variant.isMinutes ? ":" : ""}`
      : ""
  }${
    variant.isMinutes
      ? formattedMinutes + `${variant.isSeconds ? ":" : ""}`
      : ""
  }${variant.isSeconds ? formattedSeconds : ""}`;
}

export const showStatus = (work: any) => {
  if (!work?.jobStatus) {
    return "No status";
  }
  return work?.jobStatus?.id === work?.jobSubStatus?.serviceStatusId
    ? work.jobSubStatus.subStatus
    : work.jobStatus.name;
};
export const getStatus = (work: any) => {
  if (!work?.jobStatus) {
    return "No status";
  }
  return work?.jobStatus?.id === work?.jobSubStatus?.serviceStatusId
    ? work.jobSubStatus
    : work.jobStatus;
};
export const getInitialsFromContactName = (name: string) => {
  if (!name) {
    return "";
  }
  return name
    .split(" ")
    .map((i) => i.substring(0, 1))
    .filter((item) => item !== " " && item !== "")
    .filter((item, index) => index < 2)
    .join("");
};
export const isValidDateFormat = (dateString: string) => {
  const pattern = /^\d{2}\/\d{2}\/\d{4}$/;
  return pattern.test(dateString);
};
export const filterTypes = [
  { id: "0", name: "Status" },
  { id: "1", name: "Work type" },
  { id: "2", name: "Assignee" },
];
export const inactiveStatusId = "9075cde5-08cc-4c90-a7a7-c97c411af4aa";

export const getDaysBetweenDates = (date1: string, date2: string): number => {
  const oneDay = 86400000;
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  const diffInMs = Math.abs(secondDate.getTime() - firstDate.getTime());
  const diffInDays = Math.floor(diffInMs / oneDay);

  return diffInDays;
};
export const timeFormatter = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  return `${formattedHours}h ${formattedMinutes}m`;
};
export const timeReformat = (time: string): number => {
  const hours = time.match(/\d+(?=h)/)?.[0] || 0;
  const minutes = time.match(/\d+(?=m)/)?.[0] || 0;
  return Number(hours) * 3600 + Number(minutes) * 60;
};

export function validatePhoneFormat(phoneNumber: string) {
  if (!phoneNumber) {
    return true;
  }
  // Regular expression for a basic phone number format (e.g., +1 123-456-7890)
  const phoneRegex = /^[+]?\d{0,3}[-\s.]?\d{3}[-\s.]?\d{3}[-\s.]?\d{4}$/;

  return phoneRegex.test(phoneNumber);
}
