import * as Xstate from "@xstate/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DATES } from "../../app/commonOps/CommonDateOps";
import { LABELS, PATHS, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { BrandButton } from "../../components/button/BrandButton";
import { LogoLink } from "../../components/logos/LogoLink";
import SearchContact from "../../components/searchContact";
import { SignOutSvg } from "../../components/svg/SignOutSvg";
import StopWatchSvg from "../../components/svg/stopwatch-icon.svg";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import {
  useGetAllContactsQuery,
  useGetAllOrgContactsQuery,
} from "../../services/ContactService";
import { getTimer, updateTimer } from "../../services/TimerService";
import StartTimer from "../time/StartTimer";
import Timer from "./Timer";
import { getUser } from "../../services/AppService";
function clearAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
}

export const MainTopNav: React.FC<unknown> = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { timer } = useAppSelector((state) => state.timerReducer);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [isAddTime, setIsAddTime] = useState(false);
  const uploadDurationRef = useRef(timer);
  const { data: contacts } = useGetAllContactsQuery(userInfoByEmail!.org!.id);
  const { data: businessContacts } = useGetAllOrgContactsQuery(
    userInfoByEmail!.org!.id,
  );

  const [contactsForSort, setContactsForSort] = useState<
    { id: string; name: string; isBusinessContact: boolean }[]
  >([]);

  const fetchData = async () => {
    const orgContactsRtk = businessContacts
      ? businessContacts.map((item) => ({
          name: item.companyName,
          id: item.id,
          isBusinessContact: true,
        }))
      : [];
    const contactsRtk = contacts
      ? contacts.map((item) => ({
          name: `${item?.firstName} ${item?.lastName}`,
          id: item.customers[0].id,
          isBusinessContact: false,
        }))
      : [];
    setContactsForSort([...contactsRtk, ...orgContactsRtk]);
  };
  useEffect(() => {
    fetchData().then();
  }, [businessContacts, contacts]);

  useEffect(() => {
    dispatch(
      getTimer({
        userId: `${userInfoByEmail?.id}`,
        orgId: `${userInfoByEmail?.org?.id}`,
      }),
    );
    dispatch(
      getUser({ userId: userInfoByEmail!.id, orgId: userInfoByEmail!.org!.id }),
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer.timerStatus === "started") {
        dispatch(
          updateTimer({
            duration: uploadDurationRef.current.duration + 1,
            workId: uploadDurationRef.current.workId,
            notes: uploadDurationRef.current.notes,
            timerStatus: uploadDurationRef.current.timerStatus,
            assignedUserRelayId: userInfoByEmail!.id,
            contactId: uploadDurationRef.current.contactId,
            businessContactId: uploadDurationRef.current.businessContactId,
            date: DATES.today(),
          }),
        );
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [timer.timerStatus]);
  useEffect(() => {
    uploadDurationRef.current = timer;
  }, [timer.duration]);

  return (
    <>
      <div className={"z-header bg-purple navbar sticky top-0 h-[5vh]"}>
        <div
          className={"inset-x-0 z-50 block flex-1 border-base-200 bg-base-100"}>
          <div className={"bg-purple mx-auto max-w-none space-x-1"}>
            <div className={"flex flex-none text-[21px] font-bold text-white"}>
              <LogoLink />
            </div>
          </div>
        </div>
        <div className={"flex"}>
          {timer.timerStatus === "started" || timer.timerStatus === "paused" ? (
            <Timer />
          ) : (
            <div
              onClick={() => setIsAddTime(true)}
              className={
                "mr-2 flex w-[43px] cursor-pointer items-center justify-center rounded-full p-2 hover:bg-purple-700"
              }>
              <img
                src={StopWatchSvg}
                alt={"Time"}
                className={"h-[27px] w-[27px]"}
              />
            </div>
          )}
          <div className={"mr-4"}>
            <SearchContact
              contacts={contactsForSort}
              saveContact={() => null}
              inputVariant={"header"}
            />
          </div>
          <div className={"dropdown-end dropdown"}>
            <label tabIndex={0} className={"avatar btn btn-ghost btn-circle"}>
              <div className={"w-10 rounded-full"}>
                <AvatarPlaceholder
                  size={"extra-small"}
                  label={
                    userInfoByEmail?.userProfile?.avatarInitials ??
                    LABELS.unknownUser.defaultInitials
                  }
                />
              </div>
            </label>
            <ul
              tabIndex={0}
              className={
                "dropdown-content menu rounded-box menu-compact mt-3 w-64 bg-base-100 p-2 shadow"
              }>
              <li></li>
              <li>
                <a href={PATHS.user()}>
                  <div className={"flex flex-col"}>
                    <div>Profile</div>
                  </div>
                </a>
              </li>
              <li>
                <BrandButton
                  colorType={"ghost"}
                  label={LABELS.features.signout}
                  SvgIconLeft={SignOutSvg}
                  onClick={() => {
                    sessionStorage.clear();
                    clearAllCookies();
                    navigate(ROUTE_NAMES.signOut);
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      {isAddTime && (
        <StartTimer isOpen={isAddTime} closeModal={() => setIsAddTime(false)} />
      )}
    </>
  );
};
