import React from "react";
import { useForm } from "react-hook-form";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";

interface IAddNoteProps {
  getContact: () => void;
  setIsEditNote: (isAddNote: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  note: any;
}

const ContactEditNote: React.FC<IAddNoteProps> = ({
  getContact,
  setIsEditNote,
  note,
  setIsLoading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: note?.title,
      note: note?.note,
    },
  });

  const editNote = async (data: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/contact-notes/update-contact-note",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({
          ...note,
          title: data.title,
          note: data.note,
        }),
      },
    );
    if (response.ok) {
      setIsEditNote(false);
      getContact();
    } else {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    editNote(data);
  };

  return (
    <div className={"fixed bottom-1/4 left-0 w-screen bg-transparent"}>
      <div className={"wrapper flex h-full items-end justify-center"}>
        <div className={"holder max-w-572 w-100 w-full"}>
          <div
            className={
              "text-18 mt-6 rounded-[15px] border-[1px] border-solid border-[#E8E9EA] bg-white p-10 shadow-[0px_4px_30px_rgba(34,34,34,0.15)]"
            }>
            <form
              className={"popup-form c-form "}
              onSubmit={handleSubmit(onSubmit)}>
              <div className={"mb-8 flex items-center justify-between"}>
                <p className={"mb-0"}>Edit note</p>
                <div
                  className={"close-popup"}
                  onClick={() => setIsEditNote(false)}>
                  <img src={"/img/closse.png"} alt={""} />
                </div>
              </div>
              <div className={"mb-4"}>
                <input
                  type={"text"}
                  placeholder={"Title"}
                  className={"input"}
                  {...register("title")}
                />
              </div>
              <div className={"mb-4"}>
                <textarea
                  placeholder={"Note"}
                  className={"input"}
                  {...register("note")}></textarea>
              </div>
              <div className={"mb-8 flex items-center justify-end"}>
                <div>
                  <button
                    onClick={() => setIsEditNote(false)}
                    type={"button"}
                    className={
                      "cancel-btn hover:bg-purple hover:border-purple close-popup mr-2 inline-block h-[40px] rounded-[7px] border-0  border-solid border-[#1F2937] px-[20px] text-[14px] font-bold uppercase leading-[40px] hover:text-white"
                    }>
                    cancel
                  </button>
                  <button
                    type={"submit"}
                    className={
                      "bg-purple border-purple inline-block h-[40px] rounded-[7px] border-solid  px-[20px] text-[14px] font-bold uppercase leading-[40px] text-white"
                    }>
                    save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactEditNote;
