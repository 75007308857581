import moment from "moment/moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import Button from "../../components/button/Button";
import EditDots from "../../components/EditDots/EditDots";
import NoItems from "../../components/NoItems";
import ContactNoteSvg from "../../components/svg/ContactNoteSvg";
import PlusSvg from "../../components/svg/PlusSvg";
import { FormSection } from "../../support/FormSection";
import { IWorkNote } from "../../types/types";
import AddNote from "./Notes/AddNote";
import EditNote from "./Notes/EditNote";

const WorkNotes = () => {
  const { jobId } = useParams();
  const [workNotes, setWorkNotes] = useState<IWorkNote[]>();
  const [isAddNote, setIsAddNote] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState<IWorkNote>();
  const [collapsedNotes, setCollapsedNotes] = useState<string[]>([]);
  const getWorkNotes = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/work-notes/${jobId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IWorkNote[] = await response.json();
      const sortedData = data.sort(
        (a, b) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
      );

      const pinnedNotes = sortedData.filter((note) => note.isPinned);
      const unpinnedNotes = sortedData.filter((note) => !note.isPinned);

      setWorkNotes([...pinnedNotes, ...unpinnedNotes]);
    }
    setIsLoading(false);
  };
  const deleteNote = async (data: IWorkNote) => {
    const response = await fetch("https://dev.procharted.com/api/work-notes", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "DELETE",
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const data = await response.json();
      getWorkNotes();
    } else {
      setIsLoading(false);
    }
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getWorkNotes();
  });

  return (
    <>
      <FormSection
        name={""}
        showHeading={false}
        extraCx={
          "min-h-[306px] max-h-[307px] overflow-y-auto overflow-x-visible z-0 h-full w-1/3"
        }>
        <div className={"flex items-center justify-between pb-3"}>
          <div className={"text-lg font-bold"}>Notes</div>
          <div>
            <Button
              buttonType={"button"}
              label={""}
              icon={<PlusSvg />}
              onClick={() => setIsAddNote(true)}
              extraClasses={"normal-case"}
            />
          </div>
        </div>
        <div style={{ marginTop: "0" }} className={""}>
          {workNotes?.length === 0 ? (
            <NoItems
              height={"200px"}
              icon={<ContactNoteSvg />}
              message={"There are no notes"}
              addButtonMessage={"Add Note"}
              openAdd={() => setIsAddNote(true)}
            />
          ) : (
            workNotes?.map((note, index) => (
              <div key={note.id}>
                {collapsedNotes.includes(note.id) ? (
                  <div
                    className={
                      "mb-2 rounded-[12px] border-[1px] bg-gray-100 p-2"
                    }>
                    <div
                      className={
                        "flex  justify-end text-[12px] text-gray-400 "
                      }>
                      {moment(note.createdAt).format("MMM DD, YYYY")}
                    </div>
                    <div className={"mb-2 text-[14px] font-[500]"}>
                      {note.title}
                    </div>
                    <div className={"mb-2 whitespace-pre-line text-[12px]"}>
                      {note.note}
                    </div>
                    <div className={"flex items-center justify-between"}>
                      <div className={"flex items-center gap-2"}>
                        <AvatarPlaceholder
                          size={"super-small"}
                          label={
                            note.createdByUser?.userProfile?.firstName &&
                            note.createdByUser?.userProfile?.lastName
                              ? `${note.createdByUser?.userProfile?.firstName.substring(
                                  0,
                                  1,
                                )}${note.createdByUser?.userProfile?.lastName.substring(
                                  0,
                                  1,
                                )}`
                              : "N/A"
                          }
                        />
                        <div className={"text-[12px] text-gray-400"}>
                          {note.createdByUser
                            ? `${note.createdByUser?.userProfile?.firstName} ${note.createdByUser?.userProfile?.lastName}`
                            : "N/A"}
                        </div>
                      </div>
                      <Button
                        buttonType={"button"}
                        label={"OK"}
                        onClick={() =>
                          setCollapsedNotes((prev) =>
                            prev.filter((item) => item !== note.id),
                          )
                        }
                        extraClasses={"normal-case"}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      setCollapsedNotes((prev) => [...prev, note.id])
                    }
                    className={`flex cursor-pointer items-center justify-between border-b-[1px]  border-[#E8E9EA]  py-4 hover:bg-gray-100 ${
                      index === 0 && "border-t-[1px]"
                    }`}>
                    <div className={"flex items-center space-x-3 text-[14px]"}>
                      <AvatarPlaceholder
                        size={"super-small"}
                        label={
                          note.createdByUser?.userProfile?.firstName &&
                          note.createdByUser?.userProfile?.lastName
                            ? `${note.createdByUser?.userProfile?.firstName.substring(
                                0,
                                1,
                              )}${note.createdByUser?.userProfile?.lastName.substring(
                                0,
                                1,
                              )}`
                            : "N/A"
                        }
                      />
                      <div>
                        <div
                          className={
                            "... mb:max-w-[270px] overflow-hidden  text-ellipsis  whitespace-nowrap text-[14px] font-[500] sm:max-w-[270px] xl:max-w-[270px] 2xl:max-w-[360px]"
                          }>
                          {note.title || "No title"}
                        </div>
                        <div
                          className={
                            "... overflow-hidden text-ellipsis whitespace-nowrap text-[12px]  text-gray-400  xl:max-w-[210px] 3xl:max-w-[270px] 4xl:max-w-[340px] "
                          }>
                          {note.note}
                        </div>
                      </div>
                    </div>
                    <div>
                      <EditDots>
                        <div className={""}>
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                              setNote(note);
                              setIsEditNote(true);
                              setIsLoading(true);
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Edit
                          </div>
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                              deleteNote(note);
                              setIsLoading(true);
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Delete
                          </div>
                        </div>
                      </EditDots>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </FormSection>
      {isAddNote && (
        <AddNote
          setIsAddNote={setIsAddNote}
          getWorkNotes={getWorkNotes}
          setIsLoading={setIsLoading}
        />
      )}
      {isEditNote && (
        <EditNote
          setIsEditNote={setIsEditNote}
          getWorkNotes={getWorkNotes}
          note={note}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default WorkNotes;
