import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";

import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { PageLayout } from "../../components/layouts/PageLayout";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { timerSlice, updateTimer } from "../../services/TimerService";
import { CustomLink } from "../../support/CustomLink";
import StartTimer from "./StartTimer";
import { TimesListView } from "./TimesListView";
import { useEffectOnce } from "usehooks-ts";
import { GetPageTitle } from "../../support/ScrollToTop";

export const TimeView: React.FC<unknown> = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { timer } = useAppSelector((state) => state.timerReducer);
  const [isOpenStart, setIsOpenStart] = useState(false);

  const { setStatusTimer } = timerSlice.actions;
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    document.title = GetPageTitle("Time");
  });

  const buttonTimerVariant = () => {
    if (timer.timerStatus === "ended" || timer.timerStatus === null) {
      return (
        <button
          onClick={() => setIsOpenStart(true)}
          className={
            "bg-purple whitespace-nowrap rounded-[7px] px-[20px] py-[14px] text-[14px] uppercase text-white"
          }>
          Start timer
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            timer.timerStatus === "started" &&
              dispatch(
                updateTimer({
                  ...timer,
                  assignedUserId: null,
                  timerStatus: "paused",
                  assignedUserRelayId: userInfoByEmail!.id,
                }),
              );
            dispatch(
              setStatusTimer({
                timerStatus:
                  timer.timerStatus === "started" ? "paused" : "started",
              }),
            );
          }}
          className={
            "bg-purple flex h-[51px] w-[120px] items-center justify-center whitespace-nowrap rounded-[7px] text-[14px] uppercase text-white"
          }>
          {timer.timerStatus === "paused" ? "Continue" : "Pause"}
        </button>
      );
    }
  };
  const params = useParams();
  return (
    <>
      <PageLayout title={"Time"}>
        <div className={"mt-4 flex items-center justify-between"}>
          <div className={"tabs"}>
            <CustomLink to={"all"}>All</CustomLink>
            <CustomLink to={"completed"}>Completed</CustomLink>
          </div>
          {params["*"] === "all" && <div>{buttonTimerVariant()}</div>}
        </div>

        <Routes>
          <Route index element={<Navigate to={"./all"} replace={true} />} />
          <Route path={"all"} element={<TimesListView />} />
          <Route path={"completed"} element={<>Todo completed times</>} />
          <Route path={"*"} element={<>Not found</>} />
        </Routes>

        <div>
          <Outlet />
        </div>
      </PageLayout>
      {isOpenStart && (
        <StartTimer
          isOpen={isOpenStart}
          closeModal={() => setIsOpenStart(false)}
        />
      )}
    </>
  );
};
