import * as Xstate from "@xstate/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getWorkFlow, getWorkType } from "../../../api/api";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { FormSection } from "../../../support/FormSection";
import { ISubStatus, IWorkflow, IWorkType } from "../../../types/types";

const EditWorkType = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const navigate = useNavigate();
  const { id } = useParams();
  const [workflow, setWorkflow] = useState<IWorkflow[]>([]);
  const [workType, setWorkType] = useState<IWorkType | null>(null);
  const [error, setError] = useState("");

  const getAllData = async () => {
    const flow: IWorkflow[] = await getWorkFlow(userInfoByEmail!.org!.id);
    const type: IWorkType = await getWorkType(id);
    const data = flow.map((item) => ({
      ...item,
      workTypeSubStatuses: item.workTypeSubStatuses.map((sub) => ({
        ...sub,
        check: Boolean(
          type.serviceStatuses
            .find((el) => el.workTypeStatusId === item.id)
            ?.serviceSubStatuses?.some(
              (el) => sub.id === el.worktypeSubStatusId,
            ),
        ),
      })),
    }));
    setWorkType(type);
    setWorkflow(data);
  };
  useEffect(() => {
    getAllData();
  }, []);
  const onCheck = (check: boolean, subStatus: ISubStatus) => {
    const data = workflow.map((item) =>
      item.id === subStatus.workTypeStatusId
        ? {
            ...item,
            workTypeSubStatuses: item.workTypeSubStatuses.map((sub) =>
              sub.id === subStatus.id
                ? {
                    ...sub,
                    check: check,
                  }
                : sub,
            ),
          }
        : item,
    );
    setWorkflow(data);
  };

  const update = async () => {
    const data = {
      workTypeId: workType?.id,
      statuses: workflow.map((item) => ({
        workTypeStatusId: item.id,
        workTypeSubStatusIds: item.workTypeSubStatuses
          .map((sub) => sub.check && sub.id)
          .filter((sub) => sub),
      })),
    };
    const response = await fetch(
      "https://dev.procharted.com/api/services/add-service-statuses",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      navigate("../");
    } else {
      setError("The request failed");
    }
  };
  if (workflow.length === 0 || !workType) {
    return <LottieLoading />;
  }

  return (
    <>
      <FormSection name={`${workType.name} | Edit Work Type`}>
        <div className={"text-[14px]"}>
          {workflow.map((item) => (
            <div key={item.id} className={"mb-8"}>
              <div
                className={
                  "mb-4 flex w-full items-center border-b-[1px] border-blue-700 pb-2"
                }>
                <div
                  style={{ background: item.color }}
                  className={"mr-4 h-[15px] w-[15px]  rounded-full"}></div>
                <div className={"text-[14px] font-bold"}>{item.name}</div>
              </div>
              <div>
                {item.workTypeSubStatuses.map((subStatus) => (
                  <div
                    key={subStatus.id}
                    className={
                      "mb-4 flex border-b-[1px] border-gray-300  pb-2"
                    }>
                    <div>
                      <input
                        type={"checkbox"}
                        className={"mr-4"}
                        checked={subStatus.check}
                        onChange={(event) =>
                          onCheck(event.target.checked, subStatus)
                        }
                      />
                    </div>
                    <div className={"text-[14px]"}>{subStatus.subStatus}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className={"flex"}>
          <button
            onClick={() => update()}
            className={
              "bg-purple  mr-8 flex items-center justify-center rounded-[7px] px-[20px] py-[8px] text-[14px] text-white"
            }>
            <div>UPDATE</div>
          </button>
          <OutlineButton
            colorType={"secondary"}
            label={"Cancel"}
            onClick={() => navigate("../")}
          />
        </div>
        {error && (
          <div className={"mt-4 text-[14px] text-red-600"}>{error}</div>
        )}
      </FormSection>
    </>
  );
};

export default EditWorkType;
