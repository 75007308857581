import * as Xstate from "@xstate/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../app/constants/TextConstants";
import { ValidationConstants } from "../../app/constants/ValidationConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { BrandButton } from "../../components/button/BrandButton";
import Button from "../../components/button/Button";
import { OutlineButton } from "../../components/button/OutlineButton";
import { useAddContactMutation } from "../../services/ContactService";
import { GetPageTitle } from "../../support/ScrollToTop";
import {
  addIndividualState,
  IContactType,
  IPhoneType,
} from "../../types/types";
import { validatePhoneFormat } from "../../constants/constants";

interface IAddIndividualProps {
  setIsLoading?: (isLoading: boolean) => void;
  closeModal: () => void;
  closeAddCustomerPopupModal: () => void;
  setContactsForSort?: (newContact: {
    id: string;
    name: string;
    isBusinessContact: boolean;
  }) => void;
  isSearch?: boolean;
  isIdLikeContactId?: boolean;
}

export const AddIndividualView: React.FC<IAddIndividualProps> = ({
  setIsLoading,
  closeAddCustomerPopupModal,
  isSearch,
  setContactsForSort,
  isIdLikeContactId,
  closeModal,
}) => {
  const [contactType, setContactType] = useState("Client");
  const [contactTypes, setContactTypes] = useState<IContactType[]>([]);
  const [phoneTypes, setPhoneTypes] = useState<IPhoneType[]>([]);
  const [addIndividualState, setAddIndividualState] =
    useState<addIndividualState>();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail, currentLocation } = userInfoState.context;
  const [addContact, { isSuccess, data: newContact }] = useAddContactMutation();

  useEffectOnce(() => {
    document.title = GetPageTitle("Add Customer");
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      phoneNumberTypeEnumId: "",
    },
  });
  const updateContactTypeClient = () => {
    setContactType("Client");
  };
  const updateContactTypeProspect = () => {
    setContactType("Prospect");
  };
  const updateContactTypeOther = () => {
    setContactType("Other");
  };
  const getPhoneTypes = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/phone-num-types/all",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      const sortData = data.sort((a: any, b: any) => {
        if (a?.phoneNumberType === "Mobile") {
          return -1;
        } else if (b?.phoneNumberType === "Mobile") {
          return 1;
        } else {
          return 0;
        }
      });
      setValue("phoneNumberTypeEnumId", sortData[0].id);
      setPhoneTypes(sortData);
    }
  };
  const getContactTypes = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/contact-types/all",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setContactTypes(data);
    }
  };
  const getDataForAddContact = async () => {
    await getPhoneTypes();
    await getContactTypes();
  };
  useEffectOnce(() => {
    getDataForAddContact().then();
    getAddIndividualApiCall();
  });

  const navigate = useNavigate();
  const getAddIndividualApiCall = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/contacts/add-individual",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const addedResponse = await response.json();
      setAddIndividualState(addedResponse);
    }
  };
  const onSubmit = async (data: any) => {
    if (setIsLoading) {
      setIsLoading(true);
    }
    await addContact({
      customerTypeEnumId: addIndividualState?.customerType?.id,
      orgId: userInfoByEmail?.org?.id,
      locationId: currentLocation?.id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      phoneNumberTypeEnumId: data.phoneNumberTypeEnumId,
      contactTypeEnumId: contactTypes.find((i) => i.contactType === contactType)
        ?.id,
      phoneNumber: data.phone,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (isSearch) {
        isIdLikeContactId && closeModal();
        closeAddCustomerPopupModal();
        setContactsForSort &&
          setContactsForSort({
            name: `${newContact?.firstName} ${newContact?.lastName}`,
            id: isIdLikeContactId
              ? `${newContact.id}`
              : `${newContact?.customers[0].id}`,
            isBusinessContact: false,
          });
      } else {
        navigate(`/client/${newContact?.customers[0].id}`);
        closeModal();
        closeAddCustomerPopupModal();
      }
    }
  }, [isSuccess]);
  const { nameRule, profileRules } = ValidationConstants.customerRules;

  const emailRows = (
    <div className={"form-control mt-5"}>
      <label className={"label"}>
        <span className={"label-text"}>Email address</span>
      </label>
      <input
        type={"text"}
        {...register("email", {
          ...profileRules.emailLength,
          pattern: {
            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            message: "Invalid email address format",
          },
        })}
        className={"input input-bordered"}
        placeholder={"Email address"}
      />
      {errors?.email && (
        <span className={"pt-2 text-sm font-bold text-error"}>
          {errors?.email?.message}
        </span>
      )}
    </div>
  );
  const phoneRows = (
    <div className={"mt-5 grid grid-cols-1 lg:grid-cols-5"}>
      <div className={"form-control lg:col-span-3"}>
        <label className={"label"}>
          <span className={"label-text"}>Phone number</span>
        </label>
        <input
          type={"text"}
          {...register("phone", {
            ...profileRules.emailLength,
            validate: {
              phoneFormat: (value) =>
                value
                  ? validatePhoneFormat(value) || "Invalid phone number format"
                  : true,
            },
          })}
          className={"input input-bordered"}
          placeholder={"Phone number"}
        />
        {errors.phone && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors.phone.message}
          </span>
        )}
      </div>
      <div className={"form-control ml-5 lg:col-span-2"}>
        <label className={"label"}>
          <span className={"label-text"}>&nbsp;</span>
        </label>
        <select
          {...register("phoneNumberTypeEnumId", {
            ...profileRules.emailLength,
          })}
          className={"input input-bordered"}>
          {phoneTypes?.map((p) => {
            return (
              <option key={`${p.id}`} value={`${p.id}`}>
                {p.phoneNumberType}
              </option>
            );
          })}
        </select>
        {errors?.phoneNumberTypeEnumId?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.phoneNumberTypeEnumId?.message}
          </span>
        )}
      </div>
    </div>
  );
  const contactTypeRows = (
    <div className={"mt-5 flex gap-4"}>
      <div className={"form-control w-fit "}>
        <label className={"label"}>
          <span className={"label-text"}>Contact type</span>
        </label>
        <Button
          buttonType={"button"}
          colorType={contactType === "Client" ? "secondary" : "ghost"}
          label={"Client"}
          onClick={updateContactTypeClient}
        />
      </div>
      <div className={"form-control w-fit "}>
        <label className={"label"}>
          <span className={"label-text"}>&nbsp;</span>
        </label>
        <Button
          buttonType={"button"}
          colorType={contactType === "Prospect" ? "secondary" : "ghost"}
          label={"Prospect"}
          onClick={updateContactTypeProspect}
        />
      </div>
      <div className={"form-control w-fit "}>
        <label className={"label"}>
          <span className={"label-text"}>&nbsp;</span>
        </label>
        <Button
          buttonType={"button"}
          colorType={contactType === "Other" ? "secondary" : "ghost"}
          label={"Other"}
          onClick={updateContactTypeOther}
        />
      </div>
    </div>
  );

  const customerInfoRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>First Name *</span>
        </label>
        <input
          type={"text"}
          {...register("firstName", {
            required: LABELS.required,
            ...nameRule.valueLength,
          })}
          className={"input input-bordered"}
          placeholder={"First name"}
        />
        {errors?.firstName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.firstName?.message}
          </span>
        )}
      </div>
      <div className={"form-control mt-5 lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Middle Name</span>
        </label>
        <input
          type={"text"}
          {...register("middleName", {
            ...nameRule.valueLength,
            required: false,
          })}
          className={"input input-bordered"}
          placeholder={"Middle name"}
        />
        {errors?.middleName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.middleName?.message}
          </span>
        )}
      </div>
      <div className={"form-control mt-5 lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Last Name *</span>
        </label>
        <input
          type={"text"}
          {...register("lastName", {
            ...nameRule.valueLength,
            required: LABELS.required,
          })}
          className={"input input-bordered"}
          placeholder={"Last name"}
        />
        {errors?.lastName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.lastName?.message}
          </span>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Add New Individual</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {customerInfoRow}
        {emailRows}
        {phoneRows}
        {contactTypeRows}

        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <Button onClick={handleSubmit(onSubmit)} label={"Create"} />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={closeAddCustomerPopupModal}
          />
        </div>
      </form>
    </>
  );
};
