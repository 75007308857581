import * as Xstate from "@xstate/react";
import { Pagination } from "baseui/pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../app/constants/TextConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import Button from "../../components/button/Button";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import WorkTable from "../../components/WorkTable";
import { showStatus } from "../../constants/constants";
import { FormSection } from "../../support/FormSection";
import { IWork } from "../../types/types";
import { AddJobGlobalView } from "../Works/AddWork/AddJobGlobalView";

type SortOrderT =
  | { name: "Work Name"; sort: "asc" | "desc" }
  | { name: "Contact"; sort: "asc" | "desc" }
  | { name: "Status"; sort: "asc" | "desc" }
  | { name: "Start Date"; sort: "asc" | "desc" }
  | { name: "Due Date"; sort: "asc" | "desc" }
  | { name: "Progress"; sort: "asc" | "desc" }
  | { name: "Repeats"; sort: "asc" | "desc" }
  | { name: "Work Type"; sort: "asc" | "desc" }
  | { name: "Assignee"; sort: "asc" | "desc" };
interface IDates {
  name: "This week" | "Next week" | "Later" | "All open" | "Completed";
  workCountName:
    | "firmsWorkCompletedCount"
    | "firmsWorkDueLaterCount"
    | "firmsWorkNextWeekCount"
    | "firmAllOpenWorksCount"
    | "firmsWorkWeekCount";
  date: { start: number | string; end: number | string };
}
const dates: IDates[] = [
  {
    name: "This week",
    workCountName: "firmsWorkWeekCount",
    date: {
      start: moment().startOf("week").unix(),
      end: moment().startOf("week").add(1, "weeks").unix(),
    },
  },
  {
    name: "Next week",
    workCountName: "firmsWorkNextWeekCount",
    date: {
      start: moment().startOf("week").add(1, "weeks").unix(),
      end: moment().startOf("week").add(2, "weeks").unix(),
    },
  },
  {
    name: "Later",
    workCountName: "firmsWorkDueLaterCount",
    date: {
      start: moment().startOf("week").add(2, "weeks").unix(),
      end: "",
    },
  },

  {
    name: "All open",
    workCountName: "firmAllOpenWorksCount",
    date: { start: moment().unix(), end: "" },
  },
  {
    name: "Completed",
    workCountName: "firmsWorkCompletedCount",
    date: { start: "", end: "" },
  },
];

const statuses = [
  { status: "Ready" },
  { status: "In Progress" },
  { status: "Waiting" },
  { status: "Overdue" },
];

const pageSize = 20;
const HomeFirmWork = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [isAddWork, setIsAddWork] = useState(false);

  const [works, setWorks] = useState<IWork[]>([]);
  const [overdueWorks, setOverdueWorks] = useState<IWork[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWorks, setIsLoadingWorks] = useState(false);
  const [isOpenAddContact, setIsOpenAddContact] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);

  const [firmWorksStats, setFirmWorksStats] = useState<{
    firmsWorkCompletedCount: number;
    firmsWorkDueLaterCount: number;
    firmsWorkNextWeekCount: number;
    firmsWorkOverDueCount: number;
    firmAllOpenWorksCount: number;
    firmsWorkWeekCount: number;
    thisWeekReadyCount: number;
    thisWeekProgressCount: number;
    thisWeekWaitingCount: number;
    nextWeekReadyCount: number;
    nextWeekProgressCount: number;
    nextWeekWaitingCount: number;
    dueLaterReadyCount: number;
    dueLaterProgressCount: number;
    dueLaterWaitingCount: number;
    allOpenReadyCount: number;
    allOpenProgressCount: number;
    allOpenWaitingCount: number;
    overDueReadyCount: number;
    overDueProgressCount: number;
    overDueWaitingCount: number;
  }>({
    firmsWorkCompletedCount: 0,
    firmsWorkDueLaterCount: 0,
    firmsWorkNextWeekCount: 0,
    firmsWorkOverDueCount: 0,
    firmAllOpenWorksCount: 0,
    firmsWorkWeekCount: 0,
    thisWeekReadyCount: 0,
    thisWeekProgressCount: 0,
    thisWeekWaitingCount: 0,
    nextWeekReadyCount: 0,
    nextWeekProgressCount: 0,
    nextWeekWaitingCount: 0,
    dueLaterReadyCount: 0,
    dueLaterProgressCount: 0,
    dueLaterWaitingCount: 0,
    allOpenReadyCount: 0,
    allOpenProgressCount: 0,
    allOpenWaitingCount: 0,
    overDueReadyCount: 0,
    overDueProgressCount: 0,
    overDueWaitingCount: 0,
  });
  const [currentPeriod, setCurrentPeriod] = useState(dates[0]);
  const [selectedSortOrder, setSelectedSortOrder] = React.useState<SortOrderT>({
    name: "Work Name",
    sort: "asc",
  });
  const [currentStatus, setCurrentStatus] = useState("");
  const [isOverdueWorks, setIsOverdueWorks] = useState(false);

  const periodClasses = (period: string) => {
    if (currentPeriod.name === period) {
      switch (period) {
        case dates[4].name:
          return "bg-[#47DE96] text-white";
        default:
          return "bg-purple text-white";
      }
    } else {
      switch (period) {
        case dates[4].name:
          return "border-[#47DE96]";
        default:
          return "border-purple";
      }
    }
  };
  const statusClasses = (status: string) => {
    if (currentStatus === status || status === "true") {
      switch (status) {
        case "true":
          return "bg-[#F15252] text-white border-[#F15252] mr-4 cursor-pointer px-[25px] text-[16px] last:mr-0  h-fit w-fit whitespace-nowrap rounded-[15px] border-[1px]";
        default:
          return "bg-purple text-white border-purple mr-4 cursor-pointer px-[25px] text-[16px] last:mr-0  h-fit w-fit whitespace-nowrap rounded-[15px] border-[1px]";
      }
    } else {
      switch (status) {
        case "false":
          return "border-[#F15252] mr-4 cursor-pointer px-[25px] text-[16px] last:mr-0  h-fit w-fit whitespace-nowrap rounded-[15px] border-[1px]";
        default:
          return "border-purple mr-4 cursor-pointer px-[25px] text-[16px] last:mr-0  h-fit w-fit whitespace-nowrap rounded-[15px] border-[1px]";
      }
    }
  };
  const progress = (workTasks: any) => {
    const done = workTasks.filter((task: any) => task.isDone && task);
    const progress = (done.length / workTasks.length) * 100;
    return Math.ceil(progress || 0);
  };

  const Sort = (name: string, sort: string, works: IWork[]) => {
    const sortWork = works.sort(function (a, b): number {
      if (name === "Contact") {
        const nameA = a.customer?.name.toLowerCase() || "";
        const nameB = b.customer?.name.toLowerCase() || "";
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Status") {
        const nameA = showStatus(a).toLowerCase() || "";
        const nameB = showStatus(b).toLowerCase() || "";
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Start Date") {
        const nameA = a.startDate.toLowerCase();
        const nameB = b.startDate.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Due Date") {
        const nameA = a.dueDate.toLowerCase();
        const nameB = b.dueDate.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Progress") {
        const nameA = progress(a.workTasks);
        const nameB = progress(a.workTasks);
        if (nameA - nameB) {
          return 1;
        }
        if (nameA - nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Repeats") {
        const nameA = a.repeatTypeEnum?.repeatType.toLowerCase() || "";
        const nameB = b.repeatTypeEnum?.repeatType.toLowerCase() || "";
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Work Type") {
        const nameA = a.service?.name.toLowerCase();
        const nameB = b.service?.name.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Assignee") {
        const nameA = a.assignedUser?.userProfile?.firstName.toLowerCase();
        const nameB = b.assignedUser?.userProfile?.firstName.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      } else {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
    });
    return sort === "asc" ? sortWork : sortWork.reverse();
  };
  const onClickSort = (item: { id: number; body: SortOrderT }) => {
    setSelectedSortOrder((prev) =>
      prev.name === item.body.name
        ? { ...prev, sort: prev.sort === "asc" ? "desc" : "asc" }
        : item.body,
    );
  };

  const getFirmWorksStats = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/dashboard/get-firms-work?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      return response.json();
    } else {
      return [];
    }
  };
  const getThisWeekWorks = async () => {
    const thisWeek: IWork[] = isOverdueWorks ? [] : await getThisWeek();
    const overdue = await getOverdueWorks();
    const totalCountsThisWeek =
      thisWeek.length > 0
        ? Math.ceil(thisWeek[0]?.totalRecords / pageSize || 1)
        : 0;
    const totalCountsOverdue =
      overdue.length > 0
        ? Math.ceil(overdue[0]?.totalRecords / pageSize || 1)
        : 0;

    setTotalPage(
      totalCountsThisWeek > totalCountsOverdue
        ? totalCountsThisWeek
        : totalCountsOverdue,
    );
    return Sort(selectedSortOrder.name, selectedSortOrder.sort, [
      ...thisWeek,
      ...overdue,
    ]);
  };

  const getThisWeek = async () => {
    const sortName = selectedSortOrder.name.replace(/\s+/g, "");
    const response = await fetch(
      `https://dev.procharted.com/api/firms-work/this-week?orgId=${
        userInfoByEmail?.org?.id
      }&sortBy=${sortName === "WorkName" ? "Name" : sortName}&sortOrder=${
        selectedSortOrder.sort
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          pageNumber: currentPage,
          pageSize: pageSize.toString(),
          worktypeIds: [],
          workStatuses: currentStatus ? [`${currentStatus}`] : [],
          userIds: [],
        }),
      },
    );
    if (response.ok) {
      return response.json();
    } else {
      return [];
    }
  };
  const getNextWeekWorks = async () => {
    const sortName = selectedSortOrder.name.replace(/\s+/g, "");
    const response = await fetch(
      `https://dev.procharted.com/api/firms-work/next-week?orgId=${
        userInfoByEmail?.org?.id
      }&sortBy=${sortName === "WorkName" ? "Name" : sortName}&sortOrder=${
        selectedSortOrder.sort
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          pageNumber: currentPage,
          pageSize: pageSize.toString(),
          worktypeIds: [],
          workStatuses: currentStatus ? [`${currentStatus}`] : [],
          userIds: [],
        }),
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTotalPage(
        Math.ceil(
          (data && data.length > 0 && data[0]?.totalRecords / pageSize) || 1,
        ),
      );
      return data;
    } else {
      return [];
    }
  };
  const getLaterWorks = async () => {
    const sortName = selectedSortOrder.name.replace(/\s+/g, "");
    const response = await fetch(
      `https://dev.procharted.com/api/firms-work/due-later?orgId=${
        userInfoByEmail?.org?.id
      }&sortBy=${sortName === "WorkName" ? "Name" : sortName}&sortOrder=${
        selectedSortOrder.sort
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          pageNumber: currentPage,
          pageSize: pageSize.toString(),
          worktypeIds: [],
          workStatuses: currentStatus ? [`${currentStatus}`] : [],
          userIds: [],
        }),
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTotalPage(
        Math.ceil(
          (data && data.length > 0 && data[0]?.totalRecords / pageSize) || 1,
        ),
      );
      return data;
    } else {
      return [];
    }
  };
  const getAllOpenWorks = async () => {
    const sortName = selectedSortOrder.name.replace(/\s+/g, "");
    const response = await fetch(
      `https://dev.procharted.com/api/firms-work/All-open-work?orgId=${
        userInfoByEmail?.org?.id
      }&sortBy=${sortName === "WorkName" ? "Name" : sortName}&sortOrder=${
        selectedSortOrder.sort
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          pageNumber: currentPage,
          pageSize: pageSize.toString(),
          worktypeIds: [],
          workStatuses: currentStatus ? [`${currentStatus}`] : [],
          userIds: [],
        }),
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTotalPage(
        Math.ceil(
          (data && data.length > 0 && data[0]?.totalRecords / pageSize) || 1,
        ),
      );
      return data;
    } else {
      return [];
    }
  };
  const getCompletedWorks = async () => {
    const sortName = selectedSortOrder.name.replace(/\s+/g, "");
    const response = await fetch(
      `https://dev.procharted.com/api/firms-work/completed-work?orgId=${
        userInfoByEmail?.org?.id
      }&sortBy=${sortName === "WorkName" ? "Name" : sortName}&sortOrder=${
        selectedSortOrder.sort
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          pageNumber: currentPage,
          pageSize: pageSize.toString(),
          worktypeIds: [],
          workStatuses: [],
          userIds: [],
        }),
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTotalPage(
        Math.ceil(
          (data && data.length > 0 && data[0]?.totalRecords / pageSize) || 1,
        ),
      );
      return data;
    } else {
      return [];
    }
  };
  const getOverdueWorks = async () => {
    const sortName = selectedSortOrder.name.replace(/\s+/g, "");
    const response = await fetch(
      `https://dev.procharted.com/api/firms-work/overdue-work?orgId=${
        userInfoByEmail?.org?.id
      }&sortBy=${sortName === "WorkName" ? "Name" : sortName}&sortOrder=${
        selectedSortOrder.sort
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          pageNumber: currentPage,
          pageSize: pageSize.toString(),
          worktypeIds: [],
          workStatuses: currentStatus ? [`${currentStatus}`] : [],
          userIds: [],
        }),
      },
    );
    if (response.ok) {
      return response.json();
    } else {
      return [];
    }
  };

  const getWorkByTab = async () => {
    switch (currentPeriod.name) {
      case "This week": {
        return getThisWeekWorks();
      }
      case "Next week": {
        return getNextWeekWorks();
      }
      case "Later": {
        return getLaterWorks();
      }
      case "All open": {
        return getAllOpenWorks();
      }
      case "Completed": {
        return getCompletedWorks();
      }
      default:
        return [];
    }
  };

  useEffect(() => {
    setIsLoadingWorks(true);
    getWorkByTab()
      .then((res) => {
        setWorks(res);
        setIsLoadingWorks(false);
      })
      .catch(() => setIsLoadingWorks(false));
  }, [
    currentPeriod,
    currentPage,
    selectedSortOrder,
    currentStatus,
    isOverdueWorks,
  ]);

  useEffectOnce(() => {
    setIsLoading(true);
    getFirmWorksStats().then((res) => setFirmWorksStats(res));
    getThisWeekWorks()
      .then((res) => {
        setWorks(res);
        setTotalPage(
          Math.ceil(
            (res && res.length > 0 && res[0]?.totalRecords / pageSize) || 1,
          ),
        );
        setWorks(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  });

  const currentWorksByStatus = (status: string) => {
    if (status === statuses[3].status) {
      return { works: overdueWorks, set: setOverdueWorks };
    }
    return { works: works, set: setWorks };
  };
  const countWorksByStatus = (status: string) => {
    if (currentPeriod === dates[0]) {
      if (status === statuses[0].status) {
        return (
          firmWorksStats?.thisWeekReadyCount + firmWorksStats?.overDueReadyCount
        );
      }
      if (status === statuses[1].status) {
        return (
          firmWorksStats?.thisWeekProgressCount +
          firmWorksStats?.overDueProgressCount
        );
      }
      if (status === statuses[2].status) {
        return (
          firmWorksStats?.thisWeekWaitingCount +
          firmWorksStats?.overDueWaitingCount
        );
      }
    }
    if (currentPeriod === dates[1]) {
      if (status === statuses[0].status) {
        return firmWorksStats?.nextWeekReadyCount;
      }
      if (status === statuses[1].status) {
        return firmWorksStats?.nextWeekProgressCount;
      }
      if (status === statuses[2].status) {
        return firmWorksStats?.nextWeekWaitingCount;
      }
    }
    if (currentPeriod === dates[2]) {
      if (status === statuses[0].status) {
        return firmWorksStats?.dueLaterReadyCount;
      }
      if (status === statuses[1].status) {
        return firmWorksStats?.dueLaterProgressCount;
      }
      if (status === statuses[2].status) {
        return firmWorksStats?.dueLaterWaitingCount;
      }
    }
    if (currentPeriod === dates[3]) {
      if (status === statuses[0].status) {
        return firmWorksStats?.allOpenReadyCount;
      }
      if (status === statuses[1].status) {
        return firmWorksStats?.allOpenProgressCount;
      }
      if (status === statuses[2].status) {
        return firmWorksStats?.allOpenWaitingCount;
      }
    }

    if (status === statuses[3].status) {
      return { works: overdueWorks, set: setOverdueWorks };
    }
  };
  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <>
      <div className={"flex w-full min-w-[1100px]"}>
        {dates.map((date) => (
          <div
            key={date.name}
            className={` mr-6 w-1/4 cursor-pointer rounded-[10px] border-[1px] py-[34px] text-center 
            ${periodClasses(date.name)}`}
            onClick={() => {
              setCurrentPeriod(date);
              setCurrentPage(1);
              setSelectedSortOrder({ name: "Work Name", sort: "asc" });
              setCurrentStatus("");
            }}>
            <div className={"mb-2 text-[48px] font-bold"}>
              {date.name === dates[0].name
                ? firmWorksStats[date.workCountName] +
                  firmWorksStats.firmsWorkOverDueCount
                : firmWorksStats[date.workCountName]}
            </div>
            <div className={"text-[18px] font-[550]"}>{date.name}</div>
          </div>
        ))}
      </div>
      <FormSection name={""} extraCx={"overflow-x-auto py-6 gap-0"}>
        {isLoadingWorks ? (
          <LottieLoading />
        ) : (
          <div>
            <div className={"mb-6 flex justify-between"}>
              <div>
                {currentPeriod.name !== dates[4].name && (
                  <div className={"flex items-center"}>
                    {statuses.map((status) => (
                      <div
                        key={status.status}
                        onClick={() => {
                          setCurrentStatus(
                            currentStatus === status.status
                              ? ""
                              : status.status,
                          );
                        }}
                        className={`${
                          status.status === statuses[3].status && "hidden"
                        } ${statusClasses(status.status)}`}>
                        {`${status.status} ${
                          countWorksByStatus(status.status) || "0"
                        }`}
                      </div>
                    ))}
                    <div
                      onClick={() => setIsOverdueWorks(!isOverdueWorks)}
                      className={`${
                        currentPeriod.name !== dates[0].name && "hidden"
                      } ${statusClasses(`${isOverdueWorks}`)}`}>
                      Overdue{" "}
                      {currentWorksByStatus("Overdue").works[0]?.totalRecords ||
                        "0"}
                    </div>
                  </div>
                )}
              </div>
              <div className={""}>
                <Button
                  buttonType={"button"}
                  label={`Add ${LABELS.features.work}`}
                  onClick={() => setIsAddWork(true)}
                  extraClasses={"normal-case"}
                />
              </div>
            </div>
            {isLoading ? (
              <LottieLoading />
            ) : (
              <WorkTable
                works={currentWorksByStatus(currentStatus).works}
                selectedSortOrder={selectedSortOrder}
                onClickSort={onClickSort}
                closeAddContact={() => setIsOpenAddContact(false)}
                isOpenAddContact={isOpenAddContact}
                getWorks={() =>
                  getWorkByTab()
                    .then((res) => {
                      getFirmWorksStats().then((stats) => {
                        setFirmWorksStats(stats);
                        setWorks(res);
                        setIsLoadingWorks(false);
                      });
                    })
                    .catch(() => setIsLoadingWorks(false))
                }
                setIsLoading={setIsLoadingWorks}
              />
            )}
          </div>
        )}
        <Pagination
          numPages={totalPages}
          currentPage={currentPage}
          onPageChange={({ nextPage }) => {
            if (!isLoading && !isLoadingWorks) {
              setCurrentPage(Math.min(Math.max(nextPage, 1), 20));
            }
          }}
        />
      </FormSection>
      {isAddWork && <AddJobGlobalView setIsAddWork={setIsAddWork} />}
    </>
  );
};

export default HomeFirmWork;
