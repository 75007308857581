import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../../app/constants/TextConstants";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { CustomPage } from "../../../support/CustomLink";
import { FormSection } from "../../../support/FormSection";
import { IOrganizationContactNote } from "../../../types/types";
import OrganizationAddNote from "./OrganizationAddNote";
import OrganizationEditNote from "./OrganizationEditNote";
interface IOrganizationNotesProps {
  setActivePage: (activePage: "profile" | "work" | "notes") => void;
  activePage: "profile" | "work" | "notes";
}
const OrganizationNotes: React.FC<IOrganizationNotesProps> = ({
  setActivePage,
  activePage,
}) => {
  const { organizationId } = useParams();
  const [contactNotes, setContactNotes] = useState<IOrganizationContactNote[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isAddNote, setIsAddNote] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [currentNote, setCurrentNote] = useState<IOrganizationContactNote>();
  const getOrganizationContactNotes = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/business-contact-notes/get-by-business-contact-id/${organizationId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IOrganizationContactNote[] = await response.json();
      const sortedData = data.sort(
        (a, b) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
      );

      const pinnedNotes = sortedData.filter((note) => note.isPinned);
      const unpinnedNotes = sortedData.filter((note) => !note.isPinned);
      setContactNotes([...pinnedNotes, ...unpinnedNotes]);
    }
    setIsLoading(false);
  };
  const pinNote = async (data: IOrganizationContactNote) => {
    const response = await fetch(
      `https://dev.procharted.com/api/business-contact-notes/mark-pinned?businessContactNoteId=${
        data.id
      }&isPinned=${!data.isPinned}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({}),
      },
    );
    if (response.ok) {
      getOrganizationContactNotes();
    } else {
      setIsLoading(false);
    }
  };
  const deleteNote = async (noteDelete: IOrganizationContactNote) => {
    const response = await fetch(
      "https://dev.procharted.com/api/business-contact-notes",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(noteDelete),
      },
    );
    if (response.ok) {
      getOrganizationContactNotes();
    } else {
      setIsLoading(false);
    }
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getOrganizationContactNotes();
  });
  if (isLoading) {
    return <LottieLoading />;
  }
  return (
    <>
      <div className={"tabs my-8"}>
        <CustomPage
          onClick={() => setActivePage("profile")}
          isActive={activePage === "profile"}>
          {LABELS.features.profile}
        </CustomPage>
        <CustomPage
          onClick={() => setActivePage("work")}
          isActive={activePage === "work"}>
          {LABELS.features.work}
        </CustomPage>
        <CustomPage
          onClick={() => setActivePage("notes")}
          isActive={activePage === "notes"}>
          {LABELS.features.notes}
        </CustomPage>
      </div>
      <FormSection name={"Notes"}>
        <button
          className={
            "open-popup add-task border-purple text-purple mt-8 block w-full rounded-[7px] border-[1px] border-solid px-[20px] py-[15px] text-[14px]"
          }
          onClick={() => setIsAddNote(true)}>
          <img src={"/img/plus.png"} alt={""} className={"mr-[17px]"} /> Add
          Note
        </button>
        {contactNotes?.map((note) => (
          <div
            key={note.id}
            className={
              "rounded-[7px]  border-[1px] border-[#AFAFAF] bg-[#F9FAFB] px-[20px] py-[15px]"
            }>
            <div className={"flex justify-between"}>
              <div className={"text-14 font-bold "}>{note.title}</div>
              <div className={"text-[14px] font-bold"}>
                {note.isPinned && "Pinned"}
              </div>
            </div>
            <div className={"mb-4 text-[14px] text-[#6A7584]"}>{note.note}</div>
            <div className={"flex justify-between"}>
              <div className={"flex items-center space-x-3 text-[14px]"}>
                <AvatarPlaceholder
                  size={"extra-small"}
                  label={
                    note.createdByUser?.userProfile?.firstName &&
                    note.createdByUser?.userProfile?.lastName
                      ? `${note.createdByUser?.userProfile?.firstName.substring(
                          0,
                          1,
                        )}${note.createdByUser?.userProfile?.lastName.substring(
                          0,
                          1,
                        )}`
                      : "User"
                  }
                />
                <div className={"text-[12px] text-gray-400"}>
                  {`${note.createdByUser?.userProfile?.firstName} ${note.createdByUser?.userProfile?.lastName}`}
                </div>
              </div>
              <div className={"flex"}>
                <div
                  className={"mr-4 flex items-end text-[12px] text-gray-400"}>
                  {moment(note.createdAt).format("MMM DD, YYYY")}
                </div>
                <Button
                  buttonType={"button"}
                  label={"Edit"}
                  onClick={() => {
                    setCurrentNote(note);
                    setIsEditNote(true);
                  }}
                  extraClasses={"normal-case mr-4"}
                />
                <div
                  className={
                    "mr-4 cursor-pointer rounded-[5px] p-[5px] hover:bg-gray-200"
                  }
                  onClick={() => {
                    setIsLoading(true);
                    pinNote(note);
                  }}>
                  <img
                    src={"/img/pin.png"}
                    alt={"pin"}
                    className={"w-[20px] "}
                  />
                </div>
                <div
                  onClick={() => {
                    setIsLoading(true);
                    deleteNote(note);
                  }}
                  className={
                    " cursor-pointer rounded-[5px] p-[5px] hover:bg-gray-200"
                  }>
                  <img
                    src={"/img/trash.png"}
                    alt={"delete"}
                    className={"w-[20px] "}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </FormSection>
      {isAddNote && (
        <OrganizationAddNote
          setIsAddNote={setIsAddNote}
          id={`${organizationId}`}
          setIsLoading={setIsLoading}
          getOrganization={getOrganizationContactNotes}
        />
      )}
      {isEditNote && (
        <OrganizationEditNote
          setIsEditNote={setIsEditNote}
          note={currentNote}
          setIsLoading={setIsLoading}
          getOrganization={getOrganizationContactNotes}
        />
      )}
    </>
  );
};

export default OrganizationNotes;
