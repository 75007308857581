import "../work-charts/work-chart.css";
import "jquery";
import "jquery-ui";
import "jquery-circle-progress";

import { DatePicker } from "baseui/datepicker";
import {
  ArcElement,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  Tooltip,
} from "chart.js";
import moment from "moment";
import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";

import { DATE_FORMATS } from "../../app/commonOps/CommonDateOps";
import * as devConfig from "../../app/configs/devConfig";
import { WORK_UPDATE_DATE } from "../../app/constants/ApiEndpointConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { formatTime, getDaysBetweenDates } from "../../constants/constants";
import { FormSection } from "../../support/FormSection";
import { IWorkTask } from "../../types/types";
import { WorkModel } from "../work-charts/models/work.model";
import WorkTasks from "./Tasks/WorkTasks";
import TimerAndStatus from "./TimerAndStatus";
import WorkDetails from "./WorkDetails";
import WorkFiles from "./WorkFiles";
import WorkNotes from "./WorkNotes";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IWorkProps {
  workState: WorkModel | any;
  workTasks: IWorkTask[];
  completedTasks: number;
  totalTasks: number;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  getWorkApiCall: () => void;
  getWorkTasks: () => void;
  getData: () => void;
}
export const Work: React.FC<IWorkProps> = ({
  workState,
  workTasks,
  completedTasks,
  totalTasks,
  isLoading,
  setIsLoading,
  getData,
  getWorkApiCall,
  getWorkTasks,
}) => {
  const [errorDate, setErrorDate] = useState(false);
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const dateString = `${year}-${month}-${day}`;
  const progressDays =
    getDaysBetweenDates(workState?.startDate, dateString) + 1;
  const totalDays =
    getDaysBetweenDates(workState?.startDate, workState?.dueDate) + 1;
  const dataDue: ChartData<"doughnut"> = {
    datasets: [
      {
        data: [
          progressDays,
          totalDays - progressDays < 0 ? 0 : totalDays - progressDays,
          progressDays === 0 && totalDays - progressDays === 0 ? 1 : 0,
        ],
        backgroundColor: ["#47DE96", "#e8e9ea", "#e8e9ea"],
        borderWidth: 0,
        borderRadius: 5,
      },
    ],
  };
  const dataTasks: ChartData<"doughnut"> = {
    datasets: [
      {
        data: [
          completedTasks,
          totalTasks - completedTasks,
          totalTasks - completedTasks === 0 && !completedTasks ? 1 : 0,
        ],
        backgroundColor: ["#FFD80B", "#e8e9ea", "#e8e9ea"],
        borderWidth: 0,
        borderRadius: 5,
      },
    ],
  };
  const options: ChartOptions<"doughnut"> = {
    cutout: "83.3%",
    rotation: 180,
  };

  const updateDate = async (data: any) => {
    const responseDate = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/${WORK_UPDATE_DATE}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({
          workId: workState?.id,
          startDate: data?.startDate,
          endDate: data?.endDate,
          createdAt: workState?.createdAt,
          relayId: workState?.relayId,
        }),
      },
    );
    if (responseDate.ok) {
      await getWorkApiCall();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  if (!workState) {
    return <></>;
  }

  if (isLoading) {
    return <LottieLoading />;
  }
  const RenderDashBoard = () => {
    return (
      <>
        <FormSection name={""} extraCx={"overflow-visible"}>
          <div className={"max-xl:flex-wrap flex justify-between gap-8"}>
            <div className={"flex w-full gap-6"}>
              <div className={"flex gap-6"}>
                <div className={""}>
                  <div
                    className={
                      "flex flex-col items-center justify-center text-center"
                    }>
                    <div className={"flex items-end"}>
                      <div className={"w-[100px]"}>
                        <div
                          className={
                            "w-[90px] text-center text-[14px] text-gray-400"
                          }>
                          SUBTASKS
                        </div>
                        <div className={"relative h-[100px] w-[90px]"}>
                          <div
                            className={
                              "absolute flex h-full w-full items-center justify-center text-[12px] font-bold text-[#1f2937]"
                            }>
                            {completedTasks} / {totalTasks}
                          </div>
                          <Doughnut data={dataTasks} options={options} />
                        </div>
                      </div>
                      <div
                        className={
                          "flex h-[100px] items-center whitespace-nowrap text-[14px] font-[500]"
                        }>
                        {completedTasks} of {totalTasks} completed
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"w-[100px]"}>
                  <div
                    className={
                      " flex flex-col items-center justify-center text-center"
                    }>
                    <p className={"text-center text-[14px] text-gray-400"}>
                      DUE IN
                    </p>
                    <div className={"relative h-[100px] w-[90px]"}>
                      <div
                        className={
                          "absolute flex h-full w-full items-center justify-center text-[12px] font-bold text-[#1f2937]"
                        }>
                        {totalDays - progressDays > 0
                          ? totalDays - progressDays
                          : 0}{" "}
                        days
                      </div>
                      <Doughnut data={dataDue} options={options} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"flex items-center gap-[24px]"}>
                <div>
                  <div className={"mb-2 text-[16px] text-gray-400"}>
                    START DATE
                  </div>
                  <div className={"w-[100px] text-[14px] "}>
                    <DatePicker
                      overrides={{
                        MonthYearSelectPopover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 10000 },
                              },
                            },
                          },
                        },
                        Input: {
                          props: {
                            overrides: {
                              Input: {
                                style: () => ({
                                  backgroundColor: "#FFFFFF",
                                  paddingBottom: "0",
                                  paddingTop: "0",
                                  paddingRight: "0",
                                  paddingLeft: "0",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  width: "100px",
                                  cursor: "pointer",
                                }),
                              },
                              Root: {
                                style: () => ({
                                  borderBottomColor: "transparent",
                                  borderTopColor: "transparent",
                                  borderRightColor: "transparent",
                                  borderLeftColor: "transparent",
                                  borderBottomWidth: "0px",
                                  borderTopWidth: "0px",
                                  borderRightWidth: "0px",
                                  borderLeftWidth: "0px",
                                }),
                              },
                            },
                          },
                        },
                      }}
                      value={new Date(workState?.startDate)}
                      formatString={DATE_FORMATS.dateOnly}
                      onChange={({ date }) => {
                        if (
                          moment(new Date(workState?.dueDate)).unix() <
                          moment(date?.toString()).unix()
                        ) {
                          setErrorDate(true);
                        } else {
                          updateDate({
                            startDate: date,
                            endDate: workState?.dueDate,
                          }).then();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={"relative"}>
                  <div className={"mb-2 text-[16px] text-gray-400"}>
                    DUE DATE
                  </div>
                  <div className={"w-[100px] text-[14px]"}>
                    <DatePicker
                      overrides={{
                        MonthYearSelectPopover: {
                          props: {
                            overrides: {
                              Body: {
                                style: { zIndex: 10000 },
                              },
                            },
                          },
                        },
                        Input: {
                          props: {
                            overrides: {
                              Input: {
                                style: () => ({
                                  backgroundColor: "#FFFFFF",
                                  paddingBottom: "0",
                                  paddingTop: "0",
                                  paddingRight: "0",
                                  paddingLeft: "0",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  width: "100px",
                                  cursor: "pointer",
                                }),
                              },
                              Root: {
                                style: () => ({
                                  borderBottomColor: "transparent",
                                  borderTopColor: "transparent",
                                  borderRightColor: "transparent",
                                  borderLeftColor: "transparent",
                                  borderBottomWidth: "0px",
                                  borderTopWidth: "0px",
                                  borderRightWidth: "0px",
                                  borderLeftWidth: "0px",
                                }),
                              },
                            },
                          },
                        },
                      }}
                      value={new Date(workState.dueDate)}
                      formatString={DATE_FORMATS.dateOnly}
                      onChange={({ date }) => {
                        if (
                          moment(date?.toString()).unix() <
                          moment(new Date(workState?.startDate)).unix()
                        ) {
                          setErrorDate(true);
                        } else {
                          setErrorDate(false);
                          updateDate({
                            startDate: workState.startDate,
                            endDate: date,
                          }).then();
                        }
                      }}
                    />
                  </div>
                  {errorDate && (
                    <div
                      className={
                        "absolute w-[300px] pt-2 text-sm font-bold text-error"
                      }>
                      Start date must be earlier than or equal to due date
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                "max-xl:justify-start flex w-full items-center xl:justify-end"
              }>
              <div className={"flex items-center gap-6"}>
                <div>
                  <div
                    className={
                      "mb-2 whitespace-nowrap text-[16px] text-gray-400"
                    }>
                    TIME LOGGED
                  </div>
                  <div className={"text-[14px] font-[500]"}>
                    {workState.duration
                      ? formatTime(workState.duration, {
                          isHours: true,
                          isMinutes: true,
                          isSeconds: false,
                        })
                      : "00:00"}
                  </div>
                </div>
                <TimerAndStatus
                  workState={workState}
                  getWorkApiCall={async () => {
                    await getWorkApiCall();
                    setIsLoading(false);
                  }}
                  setIsLoading={setIsLoading}
                />
              </div>
            </div>
          </div>
        </FormSection>
      </>
    );
  };
  return (
    <>
      <div>
        <RenderDashBoard />
        <div className={"flex gap-6"}>
          <WorkDetails
            model={workState}
            getWorkApiCall={getData}
            setIsLoading={setIsLoading}
          />
          <WorkNotes />
        </div>
        <div className={"flex gap-6"}>
          <WorkTasks
            work={workState}
            getWorkApiCall={async () => {
              await getWorkTasks();
              setIsLoading(false);
            }}
            workTasksState={workTasks}
            setIsLoading={setIsLoading}
          />
          <WorkFiles />
        </div>
      </div>
    </>
  );
};
