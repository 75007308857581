import React, { useState } from "react";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { BrandButton } from "../../../components/button/BrandButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { AddStackSvg } from "../../../components/svg/AddStackSvg";
import { ISubStatus } from "../../../types/types";
import Button from "../../../components/button/Button";
import { LABELS } from "../../../app/constants/TextConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";

interface IAddStatus {
  setActive: (active: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  getWorkFlowFetch: () => void;
  item: ISubStatus;
}

const UpdateStatus: React.FC<IAddStatus> = ({
  setActive,
  item,
  getWorkFlowFetch,
  setIsLoading,
}) => {
  const [name, setName] = useState(item.subStatus);
  const [error, setError] = useState("");

  const onClickUpdateStatus = async () => {
    const response = await fetch(
      "https://dev.procharted.com/api/work-type-status/update-work-type-sub-status",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({ ...item, subStatus: name }),
      },
    );
    if (response.ok) {
      setActive(false);
      getWorkFlowFetch();
    } else {
      setIsLoading(false);
    }
  };
  return (
    <div
      className={
        "fixed top-0 left-0 flex h-screen w-screen items-center justify-center bg-black/20"
      }
      onClick={() => setActive(false)}>
      <div
        className={"w-[400px] rounded-[16px] bg-white p-8"}
        onClick={(event) => event.stopPropagation()}>
        <div className={"mb-8 text-center text-[18px] font-bold"}>
          Update sub status
        </div>
        <div>
          <div className={"mb-2"}>Name</div>
          <div>
            <input
              type={"text"}
              className={"input input-bordered w-full"}
              value={name}
              onChange={(event) => {
                if (!event.target.value) {
                  setError("This field cannot be empty");
                } else {
                  setError("");
                }
                setName(event.target.value);
              }}
            />
            {error && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {error}
              </span>
            )}
          </div>
        </div>
        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <BrandButton
            buttonType={"button"}
            colorType={"primary"}
            label={"Update"}
            SvgIconLeft={AddStackSvg}
            onClick={() => {
              if (!error && name) {
                setIsLoading(true);
                onClickUpdateStatus();
              } else {
                setError("This field cannot be empty");
              }
            }}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => setActive(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateStatus;
