import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { IUser } from "../types/types";
import moment from "moment/moment";

export const userAPI = createApi({
  reducerPath: "usersAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://dev.procharted.com",
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUser: build.query<IUser, { userId: string; orgId: string }>({
      query: ({ userId, orgId }) => ({
        url: `api/users/${userId}`,
        params: {
          orgId: orgId,
        },
      }),
    }),
    saveTimer: build.mutation({
      query: (body) => ({
        url: "api/time/upload",
        method: "POST",
        body: { ...body, date: moment().utc() },
      }),
    }),
  }),
});
export const { useSaveTimerMutation } = userAPI;
