import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomLink } from "../../support/CustomLink";
import { GetPageTitle } from "../../support/ScrollToTop";
import { AllWorks } from "./AllWorks";
import { MyWorks } from "./MyWorks";
import { OpenWorks } from "./OpenWorks";

export const WorksView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Works");
  });

  return (
    <PageLayout title={"Work"}>
      <div className={"tabs my-8"}>
        <CustomLink to={"all"}>All Work</CustomLink>
        <CustomLink to={"open"}>Open Work </CustomLink>
        <CustomLink to={"my"}>My Work</CustomLink>
      </div>

      <Routes>
        <Route index element={<Navigate to={"./all"} replace={true} />} />
        <Route path={"all"} element={<Outlet />}>
          <Route index element={<AllWorks />} />
        </Route>
        <Route path={"open"} element={<Outlet />}>
          <Route index element={<OpenWorks />} />
        </Route>
        <Route path={"my"} element={<Outlet />}>
          <Route index element={<MyWorks />} />
        </Route>
        <Route path={"*"} element={<>Not found</>} />
      </Routes>

      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};
