import { useQuery } from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { BrandButton } from "../../../components/button/BrandButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  GetOrgById,
  GetOrgByIdVariables,
  RenameOrgInputForm,
} from "../../../generated/operation-result-types";
import { GET_ORG_BY_ID_GQL } from "../../../queries/OrgQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { OutlineButton } from "../../../components/button/OutlineButton";
import * as devConfig from "../../../app/configs/devConfig";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { ORG_UPDATE } from "../../../app/constants/ApiEndpointConstants";

export const EditOrgDetails: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Edit firm details");
  });

  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail, currentLocation } = userInfoState.context;

  // query
  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = useQuery<GetOrgById, GetOrgByIdVariables>(GET_ORG_BY_ID_GQL, {
    variables: {
      orgId: userInfoByEmail?.org?.id ?? LABELS.errors.noOrgId,
    },
  });

  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RenameOrgInputForm>({
    defaultValues: {
      id: userInfoByEmail?.org?.id,
    },
  });

  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    console.error("EditOrgDetails | EditOrgDetails", { errorQ });
    throw new Error("Error getting EditOrgDetails");
  }

  const getOrgQResp = dataQ?.orgById;
  if (!getOrgQResp) {
    return <p>Missing firm details</p>;
  }

  const onSubmit: SubmitHandler<RenameOrgInputForm> = async (data) => {
    const submitData = {
      relayId: getOrgQResp.id,
      legalName: data.name,
      createdAt: getOrgQResp.createdAt,
      locations: [
        {
          relayId: getOrgQResp.locations.edges[0].node.id,
          orgId: userInfoByEmail?.org?.id,
          locationId: currentLocation?.id,
          createdAt: getOrgQResp.locations.edges[0].node.createdAt,
          locationAddress: {
            createdAt:
              getOrgQResp.locations.edges[0].node.locationAddress.createdAt,
            relayId: getOrgQResp.locations.edges[0].node.locationAddress.id,
            city: data.city,
            region: data.region,
            country: data.country,
            postalCode: data.postalCode,
            line1: data.line1,
            line2: data.line2,
          },
        },
      ],
    };
    const updateOrg = async () => {
      const response = await fetch(
        `${devConfig.getServerBaseUrlForEnv()}/${ORG_UPDATE}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: getAuthHeader().Authorization,
          },
          method: "PUT",
          body: JSON.stringify(submitData),
        },
      );
      if (response.ok) {
        navigate("../");
      }
    };
    updateOrg();
  };

  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Edit Firm Details</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"Firm Info"} weightTitle={"bold"}>
          <div className={"form-control mb-8"}>
            <label className={"label"}>
              <span className={"label-text"}>Name</span>
            </label>
            <input
              type={"text"}
              defaultValue={getOrgQResp.legalName}
              {...register("name", {
                required: LABELS.required,
                ...ValidationConstants.orgRules.nameRule.valueLength,
              })}
              className={"input input-bordered"}
            />
            {errors.name?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors.name?.message}
              </span>
            )}
          </div>
          <div className={"form-control mb-8"}>
            <div className={"mb-4 w-full"}>
              <label className={"label"}>
                <span className={"label-text"}>Address</span>
              </label>
              <input
                type={"text"}
                defaultValue={
                  getOrgQResp.locations.edges[0].node.locationAddress.line1
                }
                {...register("line1", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered  w-full"}
              />
              {errors.line1?.message && (
                <span className={"pt-2 text-sm font-bold text-error"}>
                  {errors.line1?.message}
                </span>
              )}
            </div>
            <div className={"w-full"}>
              <input
                type={"text"}
                defaultValue={
                  getOrgQResp.locations.edges[0].node.locationAddress.line2
                }
                {...register("line2", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered w-full"}
              />
              {errors.line2?.message && (
                <span className={"pt-2 text-sm font-bold text-error"}>
                  {errors.line2?.message}
                </span>
              )}
            </div>
          </div>

          <div className={"form-control mb-8 flex-row"}>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>City</span>
              </label>
              <input
                type={"text"}
                defaultValue={
                  getOrgQResp.locations.edges[0].node.locationAddress.city
                }
                {...register("city", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.city?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.city?.message}
                </div>
              )}
            </div>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>Province/State</span>
              </label>
              <input
                type={"text"}
                defaultValue={
                  getOrgQResp.locations.edges[0].node.locationAddress.region
                }
                {...register("region", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.region?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.region?.message}
                </div>
              )}
            </div>
          </div>
          <div className={"form-control flex-row"}>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>Postal code/Zip</span>
              </label>
              <input
                type={"text"}
                defaultValue={
                  getOrgQResp.locations.edges[0].node.locationAddress.postalCode
                }
                {...register("postalCode", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.postalCode?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.postalCode?.message}
                </div>
              )}
            </div>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>Country</span>
              </label>
              <input
                type={"text"}
                defaultValue={
                  getOrgQResp.locations.edges[0].node.locationAddress.country
                }
                {...register("country", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.country?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.country?.message}
                </div>
              )}
            </div>
          </div>
        </FormSection>
        <div className={"flex justify-start space-x-4 pb-4"}>
          <BrandButton
            colorType={"primary"}
            label={"Update"}
            buttonType={"submit"}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
          />
        </div>
      </form>
    </>
  );
};
