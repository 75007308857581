import * as Xstate from "@xstate/react";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend as Legend2,
  LinearScale,
  Title,
  Tooltip as Tooltip2,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useEffectOnce } from "usehooks-ts";

import { getDashboard, getWorkTypes } from "../../api/api";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { getInitialsFromContactName } from "../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { getAllUsers } from "../../services/AppService";
import { FormSection } from "../../support/FormSection";
import { IDashboard, IWorkType } from "../../types/types";
ChartJS.register(
  ArcElement,
  Tooltip2,
  Legend2,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
);
interface IWorkTypeStatuses {
  statusName: string;
  statusCount: number;
  subStatuses: { subStatusName: string; subStatusCount: number }[];
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<any>;
  label?: string;
}
const desiredOrder = ["Ready", "In Progress", "Waiting", "Completed"];
const statuses = [
  {
    color: "#93AAFD",
    name: "Ready",
  },
  {
    color: "#FFD80B",
    name: "In Progress",
  },
  {
    color: "#F15252",
    name: "Waiting",
  },
  {
    color: "#47DE96",
    name: "Completed",
  },
];

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data: { name: string; overDue: number; open: number } =
      payload[0].payload;
    return (
      <div className={"rounded-[8px] border-[1px] bg-white p-4"}>
        <p className={"text-[] text-[18px] "}>{`${data.name}`}</p>
        <p className={"text-[18px] text-[#166AD9] "}>{`Open: ${data.open}`}</p>
        <p
          className={
            "text-[18px] text-[#FFD80B] "
          }>{`Overdue: ${data.overDue}`}</p>
      </div>
    );
  }

  return null;
};

export const Dashboard = () => {
  const navigate = useNavigate();
  const [workTypes, setWorkTypes] = useState<IWorkType[]>([]);
  const [workTypeId, setWorkTypeId] = useState("all");
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { users } = useAppSelector((state) => state.appReducer);
  const dispatch = useAppDispatch();

  const [workTypeSize, setWorkTypeSize] = useState(0);
  const [chartDataTeam, setChartDataTeam] = useState<
    { id: string; name: string; open: number; overDue: number }[]
  >([]);
  const [workTypeStatuses, setWorkTypeStatuses] = useState<IWorkTypeStatuses[]>(
    [],
  );
  const [workTypeStatusesAll, setWorkTypeStatusesAll] = useState<
    IWorkTypeStatuses[]
  >([]);
  const [dataMainStatusesChart, setDataMainStatusesChart] = useState<{
    ready: number;
    inProgress: number;
    waiting: number;
    completed: number;
  }>({ ready: 0, inProgress: 0, waiting: 0, completed: 0 });
  const [dashboard, setDashboard] = useState<IDashboard>({
    completedWorkCount: 0,
    openWorkCount: 0,
    overDueWorkCount: 0,
    assignedUserOpenWorkCount: [],
    assignedUserOverDueCount: [],
    workTypeOpenWorkCount: [],
    workTypeOverDueCount: [],
    statusCount: {
      Ready: 0,
      "In Progress": 0,
      Waiting: 0,
      Completed: 0,
    },
    subStatusCount: [],
  });

  useEffectOnce(() => {});
  useEffect(() => {
    if (chartDataWorkType.length !== 0) {
      setWorkTypeSize(
        chartDataWorkType.reduce((prev, current) => {
          return prev.name.length > current.name.length ? prev : current;
        }).name.length * 10,
      );
    }
  }, [dashboard]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(getAllUsers(`${userInfoByEmail?.org?.id}`));
    }
    if (users.length > 0) {
      getDashboard(`${userInfoByEmail?.org?.id}`).then((res) => {
        setDashboard({
          completedWorkCount: res.completedWorkCount || 0,
          openWorkCount: res.openWorkCount || 0,
          overDueWorkCount: res.overDueWorkCount || 0,
          assignedUserOpenWorkCount: Object.entries(
            res.assignedUserOpenWorkCount,
          ).map(([key, value]) => ({
            name: key.toString(),
            count: Number(value),
          })),
          assignedUserOverDueCount: Object.entries(
            res.assignedUserOverDueCount,
          ).map(([key, value]) => ({
            name: key.toString(),
            count: Number(value),
          })),
          workTypeOpenWorkCount: Object.entries(res.workTypeOpenWorkCount).map(
            ([key, value]) => ({ name: key.toString(), count: Number(value) }),
          ),
          workTypeOverDueCount: Object.entries(res.workTypeOverDueCount).map(
            ([key, value]) => ({ name: key.toString(), count: Number(value) }),
          ),
          statusCount: {
            Ready: res.statusCount.Ready,
            "In Progress": res.statusCount["In Progress"],
            Waiting: res.statusCount.Waiting,
            Completed: res.statusCount.Completed,
          },
          subStatusCount: Object.entries(res.subStatusCount)
            .filter(([key]) => key !== "trial")
            .map(([key, value]: [string, any]) => ({
              name: key,
              subs: Object.entries(value).map(([keySub, valueSub]) => ({
                name: keySub.toString(),
                count: Number(valueSub),
              })),
            })),
        });
      });
    }
  }, [users]);

  const getStatusesByWorkType = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/dashboard/status-by-workType/${workTypeId}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: any[] = await response.json();
      const statuses = data
        ?.map((item) => ({
          ...item,
          statusCount: item?.statusCount ? item?.statusCount : 0,
          subStatuses: Object.entries(item?.subStatuses)
            .map(([key, value]) => ({
              subStatusName: key,
              subStatusCount: value,
            }))
            .filter((item) => item.subStatusName !== "NA"),
        }))
        .sort((a, b) => {
          return (
            desiredOrder.indexOf(a.statusName) -
            desiredOrder.indexOf(b.statusName)
          );
        });
      setWorkTypeStatuses(statuses);
    }
  };
  const getAllStatusesByWorkType = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/dashboard/get-all-statuses?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: any[] = await response.json();
      const statuses = data
        ?.map((item) => ({
          ...item,
          statusCount: item?.statusCount ? item?.statusCount : 0,
          subStatuses: Object.entries(item?.subStatuses)
            .map(([key, value]) => ({
              subStatusName: key,
              subStatusCount: value,
            }))
            .filter((item) => item.subStatusName !== "NA"),
        }))
        .sort((a, b) => {
          return (
            desiredOrder.indexOf(a.statusName) -
            desiredOrder.indexOf(b.statusName)
          );
        });

      setWorkTypeStatuses(statuses);
      setWorkTypeStatusesAll(statuses);
    }
  };

  const getWorkTypesFetch = () => {
    getWorkTypes(userInfoByEmail!.org!.id).then((res) => {
      setWorkTypes(res);
    });
  };
  useEffect(() => {
    getWorkTypesFetch();
  }, []);
  useEffect(() => {
    if (workTypeId !== "all") {
      getStatusesByWorkType();
    } else {
      getAllStatusesByWorkType();
    }
  }, [workTypeId]);

  const dataCompleted: ChartData<"doughnut"> = {
    datasets: [
      {
        data: [
          (dashboard.completedWorkCount /
            (dashboard.openWorkCount + dashboard.overDueWorkCount)) *
            100 || 0,
          100 -
            (dashboard.completedWorkCount /
              (dashboard.openWorkCount + dashboard.overDueWorkCount)) *
              100,
        ],
        backgroundColor: ["#47DE96", "#F9FAFB"],
        borderWidth: 0,
        borderRadius: 4,
      },
    ],
  };
  const optionsCompleted: ChartOptions<"doughnut"> = {
    cutout: "83.3%",
    rotation: -90,
    circumference: 180,
  };
  const dataWorks: ChartData<"doughnut"> = {
    datasets: [
      {
        data: [
          dataMainStatusesChart.ready,
          dataMainStatusesChart.waiting,
          dataMainStatusesChart.inProgress,
          dataMainStatusesChart.completed,
        ],
        backgroundColor: ["#93AAFD", "#F15252", "#FFD80B", "#47DE96"],
        borderWidth: 0,
      },
    ],
  };
  const optionsWorks: ChartOptions<"doughnut"> = {
    cutout: "83.3%",
    rotation: -135,
  };
  const currentUser = (currentUserName: string) => {
    return users.find((user) => {
      const fullName =
        user.userProfile.firstName + " " + " " + user.userProfile.lastName;
      return fullName === currentUserName;
    });
  };
  useEffect(() => {
    setChartDataTeam(
      dashboard.assignedUserOpenWorkCount.map((item, index) => {
        const id = `${currentUser(item.name)?.id}`;
        const name = item.name;
        const open = item.count;
        const overDue = dashboard.assignedUserOverDueCount[index].count;

        return { id, name, open, overDue };
      }),
    );
  }, [dashboard]);

  const chartDataWorkType = dashboard.workTypeOpenWorkCount.map(
    (item, index) => {
      const name = item.name;
      const open = item.count;
      const overDue = dashboard.workTypeOverDueCount[index].count;

      return { name, open, overDue };
    },
  );
  const calculateAllStatus = () => {
    let ready = Number(
      workTypeStatusesAll.find((item) => item.statusName === "Ready")
        ?.statusCount,
    )
      ? Number(
          workTypeStatusesAll.find((item) => item.statusName === "Ready")
            ?.statusCount,
        )
      : 0;
    let inProgress = Number(
      workTypeStatusesAll.find((item) => item.statusName === "In Progress")
        ?.statusCount,
    )
      ? Number(
          workTypeStatusesAll.find((item) => item.statusName === "In Progress")
            ?.statusCount,
        )
      : 0;
    let waiting = Number(
      workTypeStatusesAll.find((item) => item.statusName === "Waiting")
        ?.statusCount,
    )
      ? Number(
          workTypeStatusesAll.find((item) => item.statusName === "Waiting")
            ?.statusCount,
        )
      : 0;
    let completed = Number(
      workTypeStatusesAll.find((item) => item.statusName === "Completed")
        ?.statusCount,
    )
      ? Number(
          workTypeStatusesAll.find((item) => item.statusName === "Completed")
            ?.statusCount,
        )
      : 0;
    workTypeStatusesAll.forEach((item) => {
      item.subStatuses.forEach((sub) => {
        if (item.statusName === "Ready") {
          ready += sub.subStatusCount;
        }
        if (item.statusName === "In Progress") {
          inProgress += sub.subStatusCount;
        }
        if (item.statusName === "Waiting") {
          waiting += sub.subStatusCount;
        }
        if (item.statusName === "Completed") {
          completed += sub.subStatusCount;
        }
      });
    });
    setDataMainStatusesChart({ ready, inProgress, waiting, completed });
  };
  useEffect(() => {
    if (workTypeStatusesAll.length > 0) {
      calculateAllStatus();
    }
  }, [workTypeStatusesAll]);

  return (
    <div>
      <FormSection name={""} extraCx={"my-0 py-0 gap-0 px-0"}>
        <div className={"flex h-[200px]"}>
          <div className={"w-1/3  flex-col py-[50px]"}>
            <div
              className={
                "flex flex-col items-center justify-center border-r-[1px] border-[#D2D4D7]"
              }>
              <div className={"text-[18px]"}>Open Work</div>
              <div className={"text-[48px] font-bold"}>
                {dashboard?.openWorkCount}
              </div>
            </div>
          </div>
          <div className={"w-1/3  py-[50px] "}>
            <div
              className={
                "flex flex-col items-center justify-center border-r-[1px] border-[#D2D4D7]"
              }>
              <div className={"text-[18px]"}>Work Overdue</div>
              <div className={"text-[48px] font-bold"}>
                {dashboard?.overDueWorkCount}
              </div>
            </div>
          </div>
          <div className={"w-1/3  py-[50px] "}>
            <div className={"flex flex-col items-center justify-center"}>
              <div className={"text-[18px]"}>Work Completed</div>
              <div className={"text-[48px] font-bold"}>
                {" "}
                {dashboard?.completedWorkCount}
              </div>
            </div>
          </div>
        </div>
      </FormSection>
      <div className={"flex gap-6"}>
        <FormSection
          name={""}
          extraCx={"w-[40%] h-fit px-0 mt-6"}
          showHeading={false}>
          <div className={"mb-6 flex items-start justify-between px-8"}>
            <div className={"text-lg font-bold"}>Work type</div>
            <div className={"w-[300px]"}>
              <select
                className={"select select-bordered w-full"}
                value={workTypeId}
                onChange={(event) => setWorkTypeId(event.target.value)}>
                <option value={"all"} selected>
                  All
                </option>
                {workTypes?.map((item) => (
                  <option value={item?.id} key={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {workTypeStatuses.length !== 0 && (
            <div className={"px-2"}>
              {workTypeStatuses.map((item, index) => (
                <div
                  key={index}
                  className={
                    "mt-[20px] border-b-[1px] border-[#D2D4D7] first:mt-0"
                  }>
                  <div
                    onClick={() =>
                      navigate(
                        `/dashboard/works/${item?.statusName}/${workTypeId}`,
                      )
                    }
                    className={
                      "hover:bg-purple flex cursor-pointer items-center justify-between rounded-[7px] py-[14px] px-6 text-[14px] hover:text-white"
                    }>
                    <div className={"flex items-center gap-2"}>
                      <div
                        className={"h-3 w-3 rounded-full"}
                        style={{
                          background:
                            statuses.find(
                              (status) => status.name === item.statusName,
                            )?.color || "black",
                        }}
                      />
                      <div className={"font-bold"}>{item.statusName}</div>
                    </div>
                    <div>{item.statusCount}</div>
                  </div>
                  <div className={"mb-[15px]"}>
                    {item?.subStatuses.map((sub, index) => (
                      <div
                        onClick={() =>
                          navigate(
                            `/dashboard/works/${sub?.subStatusName}/${workTypeId}`,
                          )
                        }
                        className={
                          "hover:bg-purple flex cursor-pointer items-center justify-between rounded-[7px] py-[14px] px-6 text-[14px] hover:text-white"
                        }
                        key={index}>
                        <div className={"flex items-center gap-2"}>
                          <div
                            className={"h-3 w-3 rounded-full"}
                            style={{
                              background:
                                statuses.find(
                                  (status) => status.name === item.statusName,
                                )?.color || "black",
                            }}
                          />
                          <div>{sub.subStatusName}</div>
                        </div>
                        <div>{sub.subStatusCount}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </FormSection>
        <div className={"w-[60%]"}>
          <div className={"mt-[24px] flex space-x-6"}>
            <FormSection
              name={""}
              extraCx={"my-0 py-0 gap-0 px-[0px] pr-[70px] w-[60%]"}>
              <div className={"flex items-center justify-around text-[14px]"}>
                <div className={"my-[40px] mx-[40px] h-[200px] w-[200px]"}>
                  <Doughnut data={dataWorks} options={optionsWorks} />
                </div>
                <div>
                  <div
                    className={"mb-[15px] flex items-center justify-between"}>
                    <div className={"flex items-center"}>
                      <div
                        className={
                          "mr-[6px] h-[12px] w-[12px] rounded-full bg-[#93AAFD] "
                        }></div>
                      <div>Ready</div>
                    </div>
                    <div>{dataMainStatusesChart.ready}</div>
                  </div>
                  <div
                    className={"mb-[15px] flex items-center justify-between"}>
                    <div className={"flex items-center"}>
                      <div
                        className={
                          "mr-[6px] h-[12px] w-[12px] rounded-full bg-[#FFD80B] "
                        }></div>
                      <div>In progress</div>
                    </div>
                    <div>{dataMainStatusesChart.inProgress}</div>
                  </div>
                  <div
                    className={"mb-[15px] flex items-center justify-between"}>
                    <div className={"flex items-center"}>
                      <div
                        className={
                          "mr-[6px] h-[12px] w-[12px] rounded-full bg-[#F15252] "
                        }></div>
                      <div>Waiting</div>
                    </div>
                    <div>{dataMainStatusesChart.waiting}</div>
                  </div>
                  <div className={" flex items-center justify-between"}>
                    <div className={"mr-10 flex items-center"}>
                      <div
                        className={
                          "mr-[6px] h-[12px] w-[12px] rounded-full bg-[#47DE96] "
                        }></div>
                      <div>Completed</div>
                    </div>
                    <div>{dataMainStatusesChart.completed}</div>
                  </div>
                </div>
              </div>
            </FormSection>
            <FormSection
              name={""}
              extraCx={
                "w-[40%] flex justify-center items-center py-0 px-0 my-0 relative"
              }>
              <div className={"mt-[-50px] mb-[-50px] h-[200px] w-[200px]"}>
                <Doughnut data={dataCompleted} options={optionsCompleted} />
                <div
                  className={
                    "absolute top-0 left-0 flex h-full w-full items-center justify-center text-[30px] font-[700]"
                  }>
                  {Math.round(
                    (dashboard.completedWorkCount /
                      (dashboard.openWorkCount +
                        dashboard.overDueWorkCount +
                        dashboard.completedWorkCount)) *
                      100,
                  ) || "0"}
                  %
                </div>
              </div>
              <div
                className={
                  "w-[200px] text-center text-[16px] font-bold text-[#1F2937]"
                }>
                % Work Completed
              </div>
            </FormSection>
          </div>
          <FormSection name={""} extraCx={"py-0"}>
            {chartDataTeam?.length > 0 && (
              <div className={"h-fit border-b-[1px] border-[#D2D4D7] py-10"}>
                <div className={"flex items-center justify-between"}>
                  <div className={"text-[18px] font-bold"}>Team</div>
                  <div className={"flex space-x-[24px]"}>
                    <div className={"flex items-center space-x-[6px]"}>
                      <div
                        className={
                          "h-[12px] w-[12px] rounded-full bg-[#166AD9]"
                        }></div>
                      <div className={"text-[14px]"}>Open</div>
                    </div>
                    <div className={"flex items-center space-x-[6px]"}>
                      <div
                        className={
                          "h-[12px] w-[12px] rounded-full bg-[#FFD80B]"
                        }
                      />
                      <div className={"text-[14px]"}>Overdue</div>
                    </div>
                  </div>
                </div>
                <div className={""}>
                  <ResponsiveContainer
                    width={"100%"}
                    height={chartDataTeam.length * 70}>
                    <BarChart
                      style={{ width: "100%", cursor: "pointer" }}
                      data={chartDataTeam}
                      onClick={(data) => {
                        if (data.activePayload) {
                          navigate(
                            `/home/team-work/${data?.activePayload[0]?.payload?.id}`,
                          );
                        }
                      }}
                      layout={"vertical"}>
                      <XAxis
                        type={"number"}
                        stroke={"#c2c2c2"}
                        tick={({ x, y, payload }) => (
                          <g transform={`translate(${x},${y + 5})`}>
                            <text
                              x={0}
                              y={0}
                              dy={4}
                              fontSize={12}
                              textAnchor={"middle"}
                              fill={"black"}>
                              {payload.value}
                            </text>
                          </g>
                        )}
                      />
                      <CartesianGrid
                        stroke={"#c2c2c2"}
                        strokeWidth={0.5}
                        horizontal={false}
                      />
                      <YAxis
                        tickLine={false}
                        type={"category"}
                        stroke={"#c2c2c2"}
                        dataKey={"name"}
                        tick={({ x, y, payload }) => (
                          <g transform={`translate(${x - 20},${y})`}>
                            <circle r={20} fill={"#002466"} />
                            <text
                              x={0}
                              y={0}
                              dy={4}
                              fontSize={14}
                              textAnchor={"middle"}
                              fill={"white"}>
                              {getInitialsFromContactName(payload.value)}
                            </text>
                          </g>
                        )}
                      />
                      <CartesianGrid strokeDasharray={"0 3"} />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar
                        stackId={"a"}
                        dataKey={"open"}
                        fill={"#166AD9"}
                        barSize={10}
                        radius={3}>
                        <LabelList
                          dataKey={"name"}
                          position={"insideTopLeft"}
                          fontSize={16}
                          dy={-24}
                          textAnchor={"start"}
                          width={0}
                        />
                      </Bar>
                      <Bar
                        stackId={"a"}
                        dataKey={"overDue"}
                        fill={"#FFD80B"}
                        radius={3}
                        barSize={10}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            )}
            {workTypeSize !== 0 && chartDataWorkType?.length > 0 && (
              <div className={"h-fit py-10"}>
                <div className={"flex items-center justify-between"}>
                  <div className={"text-[18px] font-bold"}>Work Type</div>
                  <div className={"flex space-x-[24px]"}>
                    <div className={"flex items-center space-x-[6px]"}>
                      <div
                        className={
                          "h-[12px] w-[12px] rounded-full bg-[#166AD9]"
                        }></div>
                      <div className={"text-[14px]"}>Open</div>
                    </div>
                    <div className={"flex items-center space-x-[6px]"}>
                      <div
                        className={
                          "h-[12px] w-[12px] rounded-full bg-[#FFD80B]"
                        }
                      />
                      <div className={"text-[14px]"}>Overdue</div>
                    </div>
                  </div>
                </div>
                <div className={""}>
                  <ResponsiveContainer
                    width={"100%"}
                    height={chartDataWorkType.length * 80}>
                    <BarChart
                      style={{ width: "100%" }}
                      data={chartDataWorkType}
                      layout={"vertical"}>
                      <XAxis
                        type={"number"}
                        stroke={"#c2c2c2"}
                        tick={({ x, y, payload }) => (
                          <g transform={`translate(${x},${y + 5})`}>
                            <text
                              x={0}
                              y={0}
                              dy={4}
                              fontSize={12}
                              textAnchor={"middle"}
                              fill={"black"}>
                              {payload.value}
                            </text>
                          </g>
                        )}
                      />
                      <CartesianGrid
                        stroke={"#c2c2c2"}
                        strokeWidth={0.5}
                        horizontal={false}
                      />
                      <YAxis
                        tickLine={false}
                        type={"category"}
                        stroke={"#5a5a5a"}
                        dataKey={"name"}
                        width={workTypeSize}
                      />
                      <CartesianGrid strokeDasharray={"0 3"} />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar
                        stackId={"a"}
                        dataKey={"open"}
                        fill={"#166AD9"}
                        barSize={30}
                        radius={4}></Bar>
                      <Bar
                        stackId={"a"}
                        dataKey={"overDue"}
                        fill={"#FFD80B"}
                        radius={4}
                        barSize={30}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            )}
          </FormSection>
        </div>
      </div>
    </div>
  );
};
