import * as Xstate from "@xstate/react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { getIndividualContacts, getOrganizationContacts } from "../../api/api";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import Button from "../../components/button/Button";
import { OutlineButton } from "../../components/button/OutlineButton";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import SearchContact from "../../components/searchContact";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { timerSlice } from "../../services/TimerService";
import {
  getWorkByContact,
  getWorkByOrganizationContact,
} from "../../services/WorkReducer";
import { IWork } from "../../types/types";

const StartTimer = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { setStatusTimer } = timerSlice.actions;
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const contactRef = useRef<HTMLDivElement>(null);
  const workRef = useRef<HTMLDivElement>(null);

  const [contactsForSort, setContactsForSort] = useState<
    { id: string; name: string; isBusinessContact: boolean }[]
  >([]);
  const [worksFiltered, setWorksFiltered] = useState<IWork[]>([]);
  const [letterWork, setLetterWork] = useState("");
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isOpenContactFirst, setIsOpenContactFirst] = useState(false);
  const [isOpenWork, setIsOpenWork] = useState(false);
  const [isOpenWorkFirst, setIsOpenWorkFirst] = useState(false);
  const [work, setWork] = useState<IWork>();
  const [isErrorContact, setIsErrorContact] = useState(true);
  const [isErrorWork, setIsErrorWork] = useState(true);

  const closeStatusMenu = () => {
    setIsOpenContact(false);
  };
  useOnClickOutside(contactRef, closeStatusMenu);

  useOnClickOutside(workRef, () => setIsOpenWork(false));
  const [contactId, setContactId] = useState({
    id: "",
    name: "",
    isBusinessContact: false,
  });
  const { works, isLoading } = useAppSelector((state) => state.worksReducer);

  const sortWorks = (e: ChangeEvent<HTMLInputElement>) => {
    const sortLetter = e.target.value.toLowerCase();
    setLetterWork(e.target.value);
    const filtered = works.filter((item) =>
      item.name.toLowerCase().includes(sortLetter.toLowerCase()),
    );
    setWorksFiltered(filtered || []);
    setIsOpenWorkFirst(true);
  };
  const fetchData = async () => {
    const contacts = await getIndividualContacts(
      true,
      userInfoByEmail!.org!.id,
    );
    const businessContact = await getOrganizationContacts(
      true,
      userInfoByEmail!.org!.id,
    );
    setContactsForSort([
      ...contacts.map((item) => ({
        name: `${item?.firstName} ${item?.lastName}`,
        id: item.customers[0].id,
        isBusinessContact: false,
      })),
      ...businessContact.map((item) => ({
        name: item.companyName,
        id: item.id,
        isBusinessContact: true,
      })),
    ]);
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);
  useEffect(() => {
    if (contactRef) {
      contactRef?.current?.click();
    }
  }, [contactRef]);

  useEffect(() => {
    setIsOpenContactFirst(false);
    setIsErrorWork(false);
    setIsErrorContact(false);
  }, [isOpen]);

  useEffect(() => {
    if (contactId.isBusinessContact) {
      if (contactId.id) {
        dispatch(
          getWorkByOrganizationContact({
            id: contactId.id,
            orgId: userInfoByEmail!.org!.id,
          }),
        );
      }
    } else {
      if (contactId.id) {
        dispatch(
          getWorkByContact({
            contactId: contactId.id,
            orgId: userInfoByEmail!.org!.id,
          }),
        );
      }
    }
  }, [contactId]);

  const currentWorks = () => {
    return letterWork ? worksFiltered : works;
  };
  const onSubmit = async () => {
    if (!work) {
      setIsErrorWork(true);
    }
    if (!contactId.id) {
      setIsErrorContact(true);
    }
    if (work && contactId.id) {
      dispatch(
        setStatusTimer({
          timerStatus: "started",
          workId: work?.id,
          contactId: work?.businessContactId ? null : work?.customer.contactId,
          businessContactId: work?.businessContactId
            ? work?.businessContactId
            : null,
          work: { name: work!.name },
        }),
      );
      closeModal();
    }
  };

  return (
    <div
      onClick={closeModal}
      id={"my-popup"}
      className={
        "fixed top-0 left-0 z-[3] flex h-screen w-screen items-center justify-center bg-gray-500/20"
      }>
      <div
        className={
          "max-h-[750px] w-[480px] overflow-hidden rounded-2xl bg-white p-6 "
        }
        onClick={(event) => event.stopPropagation()}>
        <div>
          <div className={""}>
            <div className={"mb-10 w-full text-center text-[18px] font-bold"}>
              Start Timer
            </div>
            <div className={"flex h-[350px] flex-col justify-between "}>
              <div>
                <div className={"relative z-[1] mb-4"} ref={contactRef}>
                  <div className={"label-text mb-2"}>Contact</div>
                  <SearchContact
                    contacts={contactsForSort}
                    saveContact={(contact) => {
                      setContactsForSort((prev) => [...prev, contact]);
                      setContactId(contact);
                      setIsErrorContact(false);
                    }}
                  />
                  {isErrorContact && (
                    <div className={"pt-2 text-sm font-bold text-error"}>
                      This field can not be empty
                    </div>
                  )}
                </div>
                <div className={"mb-4"}>
                  <div className={"label-text mb-2"}>Work</div>
                  <div className={"relative"} ref={workRef}>
                    <div>
                      <input
                        value={letterWork}
                        onChange={sortWorks}
                        placeholder={
                          !Boolean(contactId.id)
                            ? "Select contact"
                            : "Search work"
                        }
                        className={
                          "select select-bordered w-full disabled:border-[#D4D6D9] disabled:bg-transparent"
                        }
                        onFocus={() => setIsOpenWork(true)}
                        disabled={!Boolean(contactId.id)}
                      />
                    </div>
                    {isOpenWork && (
                      <div
                        className={
                          "absolute top-[47px] left-0 flex max-h-[200px] w-full flex-col space-y-[2px] overflow-hidden rounded-[10px] border-[1px] border-gray-300 bg-white pb-2 text-[13px]"
                        }>
                        {isLoading ? (
                          <div>
                            <LottieLoading />
                          </div>
                        ) : (
                          <div className={"overflow-auto"}>
                            {currentWorks()?.map((work) => (
                              <div
                                onClick={() => {
                                  setWork(work);
                                  setLetterWork(work.name);
                                  setIsOpenWork(false);
                                  setIsErrorWork(false);
                                }}
                                className={
                                  "mt-2 cursor-default px-[16px] hover:bg-blue-400 hover:text-white"
                                }
                                key={work?.id}>
                                <div>{`${work?.name}`}</div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {isErrorWork && (
                    <div className={"pt-2 text-sm font-bold text-error"}>
                      This field can not be empty
                    </div>
                  )}
                </div>
              </div>
              <div className={"flex justify-end"}>
                <div className={"flex space-x-[10px]"}>
                  <Button
                    buttonType={"button"}
                    label={"Start"}
                    onClick={onSubmit}
                    extraClasses={"normal-case"}
                  />
                  <OutlineButton
                    buttonType={"button"}
                    label={"Cancel"}
                    colorType={"neutral"}
                    onClick={closeModal}
                    extraClasses={"normal-case"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartTimer;
