import { gql } from "@apollo/client";

export const GET_CUSTOMERS_BY_LOCATION_GQL = gql`
  query GetCustomersByLocation(
    $where: CustomerFilterInput
    $first: Int
    $after: String
    $order: [CustomerSortInput!]
  ) {
    customers(where: $where, first: $first, after: $after, order: $order) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          deletedAt

          contact {
            id
            fullName
            avatarInitials

            contactTypeEnumId

            email {
              id
              emailAddress
            }

            phoneNumbers {
              id
              number
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_BY_ID_GQL = gql`
  query GetCustomerById($customerId: ID!) {
    customerById(id: $customerId) {
      id
      name
      deletedAt

      customerTypeEnum {
        id
        customerType
      }

      contact {
        id
        fullName
        avatarInitials
        firstName
        middleName
        lastName

        email {
          id
          emailAddress
        }

        phoneNumbers {
          id
          phoneNumberTypeEnum {
            id
            phoneNumberType
          }
          number
        }
      }

      addresses {
        id
        line1
        line2
        city
        region
        country
        postalCode
        addressTypeEnum {
          id
          addressType
        }
      }

      customerTaxIds {
        id
        taxIdType
        taxIdValue
      }
    }
  }
`;

export const GET_CUSTOMERS_BY_LOCATION_FOR_ADD_GQL = gql`
  query GetCustomersByLocationForAdd($locationId: ID!) {
    customers(where: { location: { id: { eq: $locationId } } }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const EDIT_CUSTOMER_GQL = gql`
  mutation EditCustomer($editCustomerInput: EditCustomerInput!) {
    editCustomer(input: $editCustomerInput) {
      customer {
        id
      }
    }
  }
`;

export const ADD_CUSTOMER_GQL = gql`
  mutation AddCustomer($addCustomerInput: AddCustomerInput!) {
    addCustomer(input: $addCustomerInput) {
      customer {
        id
      }
    }
  }
`;

export const UPDATE_CUSTOMER_STATE_GQL = gql`
  mutation UpdateCustomerState(
    $updateCustomerStateInput: UpdateCustomerStateInput!
  ) {
    updateCustomerState(input: $updateCustomerStateInput) {
      customer {
        id
      }
    }
  }
`;
