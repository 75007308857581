import * as Xstate from "@xstate/react";
import React, { useEffect, useRef, useState } from "react";

import { getUsers, getWorkFlow, getWorkTypes } from "../../api/api";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { filterTypes } from "../../constants/constants";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { IUser, IWorkflow, IWorkType } from "../../types/types";
type FilterOption = "assignee" | "status" | "work type";
interface IFilterWorksProps {
  setIsFilter: (isFilter: boolean) => void;
  setFilterByType: (filterType: { id: string; name: string }) => void;
  setArrayFilter: (array: string[] | { isMain: boolean; id: string }[]) => void;
  setCurrentPage: (currentPage: number) => void;
  filterOptions: FilterOption[];
}

const FilterWorks: React.FC<IFilterWorksProps> = ({
  setIsFilter,
  setFilterByType,
  setArrayFilter,
  setCurrentPage,
  filterOptions,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [activeFilterType, setActiveFilterType] = useState({
    id: "",
    name: "",
  });
  const [isSelectFilterType, setIsSelectFilterType] = useState(false);
  const selectFilterTypeRef = useRef<HTMLDivElement>(null);
  const clickOutsideSelect = () => {
    setIsSelectFilterType(false);
  };
  useOnClickOutside(selectFilterTypeRef, clickOutsideSelect);
  const [statuses, setStatuses] = useState<IWorkflow[]>([]);
  const [workTypes, setWorkTypes] = useState<IWorkType[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  useEffect(() => {
    getWorkFlow(userInfoByEmail!.org!.id).then((res) =>
      setStatuses(res.filter((item: IWorkflow) => item.name !== "trial")),
    );
    getWorkTypes(userInfoByEmail!.org!.id).then((res) => setWorkTypes(res));
    getUsers(userInfoByEmail!.org!.id).then((res) => setUsers(res));
  }, []);
  const [filterByStatus, setFilterByStatus] = useState<
    { isMain: boolean; id: string }[]
  >([]);
  const [filterByWorkType, setFilterByWorkType] = useState<string[]>([]);
  const [filterByAssignee, setFilterByAssignee] = useState<string[]>([]);
  const onChangeStatus = (id: string, checked: boolean, isMain: boolean) => {
    checked
      ? setFilterByStatus((prev) => [...prev, { isMain, id }])
      : setFilterByStatus((prev) => prev.filter((item) => item.id !== id));
  };
  const onChangeWorkType = (id: string, checked: boolean) => {
    checked
      ? setFilterByWorkType((prev) => [...prev, id])
      : setFilterByWorkType((prev) => prev.filter((item) => item !== id));
  };
  const onChangeUser = (id: string, checked: boolean) => {
    checked
      ? setFilterByAssignee((prev) => [...prev, id])
      : setFilterByAssignee((prev) => prev.filter((item) => item !== id));
  };
  const onClickSave = () => {
    setCurrentPage(1);
    setFilterByType(activeFilterType);
    if (activeFilterType.id === filterTypes[0].id) {
      setArrayFilter(filterByStatus);
    }
    if (activeFilterType.id === filterTypes[1].id) {
      setArrayFilter(filterByWorkType);
    }
    if (activeFilterType.id === filterTypes[2].id) {
      setArrayFilter(filterByAssignee);
    }
    setIsFilter(false);
  };
  const filterBy = () => {
    if (activeFilterType.id === filterTypes[0].id) {
      return (
        <div>
          {statuses.map((status) => (
            <div key={status.id}>
              <div className={"mb-4 flex"}>
                <div className={"mr-4"}>
                  <input
                    type={"checkbox"}
                    checked={filterByStatus.some(
                      (item) => item.id === status.name,
                    )}
                    onChange={(event) =>
                      onChangeStatus(status.name, event.target.checked, true)
                    }
                  />
                </div>
                <div className={"flex items-center"}>
                  <div
                    style={{ background: `${status.color}` }}
                    className={"mr-4 h-[10px] w-[10px] rounded-full"}></div>
                  <div className={"text-[14px]"}>{status.name}</div>
                </div>
              </div>
              {status.workTypeSubStatuses.map((sub) => (
                <div key={sub.id} className={"mb-4 flex"}>
                  <div className={"mr-4"}>
                    <input
                      className={"cursor-pointer"}
                      type={"checkbox"}
                      checked={filterByStatus.some(
                        (item) => item.id === sub.subStatus,
                      )}
                      onChange={(event) =>
                        onChangeStatus(
                          sub.subStatus,
                          event.target.checked,
                          false,
                        )
                      }
                    />
                  </div>
                  <div className={"flex items-center"}>
                    <div
                      style={{ background: `${status.color}` }}
                      className={"mr-4 h-[10px] w-[10px] rounded-full"}></div>
                    <div className={"text-[14px]"}>{sub.subStatus}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
    if (activeFilterType.id === filterTypes[1].id) {
      return (
        <div>
          {workTypes.map((workType) => (
            <div key={workType.id}>
              <div className={"mb-4 flex last:mb-0"}>
                <div className={"mr-4"}>
                  <input
                    className={"cursor-pointer"}
                    type={"checkbox"}
                    checked={Boolean(filterByWorkType.includes(workType.id))}
                    onChange={(event) =>
                      onChangeWorkType(workType.id, event.target.checked)
                    }
                  />
                </div>
                <div className={"text-[14px]"}>{workType.name}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    if (activeFilterType.id === filterTypes[2].id) {
      return (
        <div>
          {users.map((user) => (
            <div key={user.id}>
              <div className={"mb-4 flex last:mb-0"}>
                <div className={"mr-4"}>
                  <input
                    className={"cursor-pointer"}
                    type={"checkbox"}
                    checked={filterByAssignee.includes(user.id)}
                    onChange={(event) =>
                      onChangeUser(user.id, event.target.checked)
                    }
                  />
                </div>
                <div
                  className={
                    "text-[14px]"
                  }>{`${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}</div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return <></>;
    }
  };
  return (
    <div
      onClick={() => setIsFilter(false)}
      className={
        "fixed top-0 left-0 flex h-screen w-screen items-center justify-center bg-gray-500/20"
      }>
      <div
        className={
          "max-h-[500px] min-h-[500px] min-w-[300px] overflow-hidden rounded-[10px] bg-white "
        }
        onClick={(event) => event.stopPropagation()}>
        <div
          className={
            "max-h-[500px] min-h-[500px] overflow-y-auto px-[20px] py-[10px]"
          }>
          <div
            className={"close-popup text-end"}
            onClick={() => setIsFilter(false)}>
            <img src={"/img/closse.png"} alt={""} />
          </div>
          <div className={"mb-4 text-center"}>
            Filter {activeFilterType.name && `by ${activeFilterType.name}`}
          </div>
          <div className={"relative mb-10"} ref={selectFilterTypeRef}>
            <div
              className={
                "cursor-pointer rounded-[5px] border-[1px] border-gray-200 py-[10px] px-[20px] text-[14px] hover:bg-gray-200"
              }
              onClick={() => setIsSelectFilterType(!isSelectFilterType)}>
              {activeFilterType.name || "Choose a field to filter"}
            </div>
            {isSelectFilterType && (
              <div
                className={
                  "absolute w-full rounded-[5px] border-[1px] border-gray-200 bg-white"
                }>
                {filterTypes.map(
                  (type) =>
                    filterOptions.includes(
                      type.name.toLowerCase() as
                        | "assignee"
                        | "status"
                        | "work type",
                    ) && (
                      <div
                        key={type.id}
                        className={
                          "cursor-pointer py-[10px] px-[20px] text-[14px] hover:bg-gray-200"
                        }
                        onClick={() => {
                          setActiveFilterType(type);
                          setIsSelectFilterType(false);
                        }}>
                        {type.name}
                      </div>
                    ),
                )}
              </div>
            )}
          </div>
          <div>{filterBy()}</div>
          <div className={"mb-8 flex items-center justify-end"}>
            <div>
              <button
                type={"button"}
                onClick={() => setIsFilter(false)}
                className={
                  "cancel-btn hover:bg-purple hover:border-purple close-popup mr-2 inline-block h-[40px] rounded-[7px] border-0  border-solid border-[#1F2937] px-[20px] text-[14px] font-bold uppercase leading-[40px] hover:text-white"
                }>
                cancel
              </button>
              <button
                disabled={!Boolean(activeFilterType.id)}
                onClick={onClickSave}
                type={"button"}
                className={
                  "bg-purple border-purple inline-block h-[40px] rounded-[7px] border-solid  px-[20px] text-[14px] font-bold uppercase leading-[40px] text-white"
                }>
                save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterWorks;
