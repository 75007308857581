import { match } from "ts-pattern";

type Environment = "localWebToLhrApiService" | "azProcDev";
const PROC_ENV = "azProcDev" as Environment;

const localConstants = {
  apiService: {
    baseUrl: "https://dev.procharted.com",
  },
  msalReactSpaLocal01: {
    clientId: "c0541af4-8a00-4549-bc91-a4a211b29896",
  },
};

const procDevConstants = {
  b2cTenant: {
    domainName: "489EADC726434C2EA449.onmicrosoft.com",
    authorityDomain: "489EADC726434C2EA449.b2clogin.com",
  },
  dotnetAuthApi01: {
    clientId: "e5d8569e-c0f3-4943-8b5d-7fd0564331f4",
    defaultScopeName: "demo.read",
  },
  msalReactSpa01: {
    clientId: "545ce3ed-f6ab-4a8a-89f5-6a6b108ef935",
  },
  userFlowSignIn: {
    name: "B2C_1_signin1",
  },
  apiService: {
    baseUrl:
      "https://dev-capp-public-api-01.delightfulocean-178a1dd5.canadacentral.azurecontainerapps.io",
  },
};

const azureConnectionStringsForEnv = {
  appInsights: {
    localWebToLhrApiService:
      "InstrumentationKey=8c1ec4c5-d8d4-4176-9f28-e60ac5903570;IngestionEndpoint=https://canadacentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/",
    azProcDev:
      "InstrumentationKey=b6adef35-501a-4894-9335-aab754511ebf;IngestionEndpoint=https://canadacentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/",
  },
};

const reactSpaClientIdsForEnv = {
  localWebToLhrApiService: localConstants.msalReactSpaLocal01.clientId,
  azProcDev: procDevConstants.msalReactSpa01.clientId,
};

const serverBaseUrlForEnv = {
  localWebToLhrApiService: localConstants.apiService.baseUrl,
  azProcDev: procDevConstants.apiService.baseUrl,
};

const getSigninFlowUrl = (): string => {
  // Example: "https://XYZ123.b2clogin.com/XYZ123.onmicrosoft.com/B2C_1_signin1"
  return `https://${procDevConstants.b2cTenant.authorityDomain}/${procDevConstants.b2cTenant.domainName}/${procDevConstants.userFlowSignIn.name}`;
};

export const getScopes = (): [string] => {
  // Example: `https://XYZ123.onmicrosoft.com/abcd-uuid-1234/demo.read`
  return [
    `https://${procDevConstants.b2cTenant.domainName}/${procDevConstants.dotnetAuthApi01.clientId}/${procDevConstants.dotnetAuthApi01.defaultScopeName}`,
  ];
};

export const getClientIdForEnv = (
  currentEnv: Environment = PROC_ENV,
): string => {
  return match(currentEnv)
    .with(
      "localWebToLhrApiService",
      () => reactSpaClientIdsForEnv.localWebToLhrApiService,
    )
    .with("azProcDev", () => reactSpaClientIdsForEnv.azProcDev)
    .exhaustive();
};

export const getAzAiCsForEnv = (): string => {
  return match(PROC_ENV)
    .with(
      "localWebToLhrApiService",
      () => azureConnectionStringsForEnv.appInsights.localWebToLhrApiService,
    )
    .with("azProcDev", () => azureConnectionStringsForEnv.appInsights.azProcDev)
    .exhaustive();
};

export const getServerBaseUrlForEnv = (): string => {
  return match(PROC_ENV)
    .with(
      "localWebToLhrApiService",
      () => serverBaseUrlForEnv.localWebToLhrApiService,
    )
    .with("azProcDev", () => serverBaseUrlForEnv.azProcDev)
    .exhaustive();
};

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: procDevConstants.userFlowSignIn.name,
  },
  authorities: {
    signUpSignIn: {
      authority: getSigninFlowUrl(),
    },
  },
  authorityDomain: procDevConstants.b2cTenant.authorityDomain,
};
