import * as Xstate from "@xstate/react";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import { getIndividualContacts } from "../../../../api/api";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import Button from "../../../../components/button/Button";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import SearchContact from "../../../../components/searchContact";
import { GetPageTitle } from "../../../../support/ScrollToTop";
import {
  IContact,
  IContactType,
  ICustomers,
  IEmail,
  IPhoneNumber,
  IStatusTypeContact,
} from "../../../../types/types";

interface IAddLinkedProfileProps {
  closeModal: () => void;
  setIsLoading: (isLoading: boolean) => void;
  id: string;
  getOrganizationContact: () => void;
}

const AddLinkedProfile: React.FC<IAddLinkedProfileProps> = ({
  closeModal,
  id,
  getOrganizationContact,
  setIsLoading,
}) => {
  const { userInfoService } = useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [contacts, setContacts] = useState<IContact[]>([]);
  const getContacts = async () => {
    const data = await getIndividualContacts(true, userInfoByEmail!.org!.id);
    setContacts(data);
  };
  useEffectOnce(() => {
    document.title = GetPageTitle("Add Linked Profiles");
    getContacts();
  });
  const addLinkedProfile = async (data: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/business-contacts/linked-profiles/add",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      closeModal();
      getOrganizationContact();
    } else {
      setIsLoading(false);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      businessContactId: id,
      contactId: "",
      relation: "",
    },
  });
  // local effects
  React.useEffect(() => {
    setFocus("relation");
  }, []);
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    await addLinkedProfile({ ...data, linkedContactId: data.contactId });
  };
  const spouseRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Name</span>
        </label>
        <Controller
          control={control}
          {...register("contactId", {
            required: "This field can not be empty",
          })}
          render={({ field: { onChange } }) => (
            <SearchContact
              isIdLikeContactId={true}
              isLinkedProfile={true}
              saveContact={(contact) => {
                onChange(contact.id);
                setContacts((prev) => [
                  ...prev,
                  {
                    id: contact.id,
                    firstName: contact.name.split(" ")[0],
                    lastName:
                      contact.name.split(" ")[
                        contact.name.split(" ").length - 1
                      ],
                    contactType: {} as IContactType,
                    address: "",
                    contactStatusEnumId: "",
                    contactTypeEnumId: "",
                    createdAt: "",
                    customers: [] as ICustomers[],
                    email: {} as IEmail,
                    customerType: "",
                    middleName: "",
                    openWorkCount: 0,
                    phoneNumber: "",
                    phoneNumbers: [] as IPhoneNumber[],
                    socialIdentificationNumber: "",
                    statusType: {} as IStatusTypeContact,
                  },
                ]);
              }}
              contacts={contacts.map((item) => ({
                id: item.id,
                name: `${item.firstName} ${item.lastName}`,
                isBusinessContact: false,
              }))}
            />
          )}
        />

        {errors?.contactId?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.contactId?.message}
          </span>
        )}
      </div>
    </>
  );
  const relationShipRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Relationship</span>
        </label>
        <input
          type={"text"}
          {...register("relation", {
            required: "This field can not be empty",
          })}
          className={"input input-bordered"}
          placeholder={"Relationship"}
        />
        {errors?.relation?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.relation?.message}
          </span>
        )}
      </div>
    </>
  );
  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Add Linked Profiles</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {spouseRow}
        {relationShipRow}
        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <Button buttonType={"submit"} label={"Add"} />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={closeModal}
          />
        </div>
      </form>
    </>
  );
};

export default AddLinkedProfile;
