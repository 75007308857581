import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { useEffectOnce } from "usehooks-ts";

import { getWorkFlow } from "../../../api/api";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { FormSection } from "../../../support/FormSection";
import { ISubStatus, IWorkflow } from "../../../types/types";
import AddStatus from "./Add Status";
import UpdateStatus from "./Update Status";

const Workflow = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [workflow, setWorkflow] = useState<IWorkflow[]>([]);
  const [isAddStatus, setIsAddStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateStatus, setIsUpdateStatus] = useState(false);
  const [statusIndex, setStatusIndex] = useState<number>(-1);
  const [subStatus, setSubStatus] = useState<ISubStatus>({
    id: "",
    subStatus: "",
    workTypeStatusId: "",
    check: false,
  });
  const getWorkFlowFetch = () => {
    getWorkFlow(userInfoByEmail!.org!.id).then((res) => {
      setWorkflow(res);
      setIsLoading(false);
    });
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getWorkFlowFetch();
  });

  const deleteSubStatus = async (data: ISubStatus) => {
    const response = await fetch(
      "https://dev.procharted.com/api/work-type-status/delete-work-type-sub-status",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      getWorkFlowFetch();
    } else {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <LottieLoading />;
  }
  return (
    <>
      <FormSection
        name={"Statuses"}
        weightTitle={"bold"}
        extraCx={"overflow-visible"}>
        <div className={"text-[14px]"}>
          {workflow?.map((item, index) => (
            <div key={item.id} className={"mb-8"}>
              <div
                className={
                  "mb-4 flex w-full items-center border-b-[1px] border-blue-700 pb-2"
                }>
                <div
                  style={{
                    background: `${item.color}`,
                  }}
                  className={"mr-4 h-[15px] w-[15px]  rounded-full"}></div>
                <div className={"text-[14px] font-bold"}>{item.name}</div>
              </div>
              <div>
                {item.workTypeSubStatuses?.map((sub) => (
                  <div
                    key={sub.id}
                    className={
                      "mb-4 flex justify-between border-b-[1px] border-gray-300 pl-8 pb-2"
                    }>
                    <div className={"text-[14px]"}>{sub.subStatus}</div>
                    <EditDots variant={"props"}>
                      {({ setIsOpen }) => (
                        <div className={""}>
                          <div
                            onClick={() => {
                              setSubStatus(sub);
                              setIsUpdateStatus(true);
                              setIsOpen(false);
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Edit
                          </div>
                          <div
                            onClick={() => {
                              setIsLoading(true);
                              deleteSubStatus(sub);
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Delete
                          </div>
                        </div>
                      )}
                    </EditDots>
                  </div>
                ))}
              </div>
              <div
                className={
                  "link-primary cursor-pointer border-b-[1px] border-gray-300 pl-8 pb-2 text-sm font-[500] hover:underline"
                }
                onClick={() => {
                  setIsAddStatus(true);
                  setStatusIndex(index);
                }}>
                Add status
              </div>
            </div>
          ))}
        </div>
      </FormSection>
      {isAddStatus && (
        <AddStatus
          setIsLoading={setIsLoading}
          setActive={setIsAddStatus}
          getWorkFlowFetch={getWorkFlowFetch}
          item={{ id: workflow[statusIndex].id }}
        />
      )}
      {isUpdateStatus && (
        <UpdateStatus
          setIsLoading={setIsLoading}
          getWorkFlowFetch={getWorkFlowFetch}
          setActive={setIsUpdateStatus}
          item={subStatus}
        />
      )}
    </>
  );
};

export default Workflow;
