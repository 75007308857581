import moment from "moment";
import React, { useState } from "react";

import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../components/button/Button";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import EditDots from "../../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import NoItems from "../../../../components/NoItems";
import ContactNoteSvg from "../../../../components/svg/ContactNoteSvg";
import PlusSvg from "../../../../components/svg/PlusSvg";
import { FormSection } from "../../../../support/FormSection";
import { IWorkNote } from "../../../../types/types";
import ContactAddNote from "../ContactAddNote";
import ContactEditNote from "../ContactEditNote";

interface IContactNoteBlockProps {
  contactNotes: any;
  getContact: () => void;
  setIsLoading: (isLoading: boolean) => void;
  contactId?: string;
  isLoading?: boolean;
}
const ContactNoteBlock: React.FC<IContactNoteBlockProps> = ({
  contactNotes,
  getContact,
  setIsLoading,
  contactId,
  isLoading,
}) => {
  const [collapsedNotes, setCollapsedNotes] = useState<string[]>([]);
  const [currentNote, setCurrentNote] = useState();
  const [isEditNote, setIsEditNote] = useState(false);
  const [isAddNote, setIsAddNote] = useState(false);

  const deleteNote = async (data: IWorkNote) => {
    const response = await fetch(
      "https://dev.procharted.com/api/contact-notes",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      getContact();
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormSection
        customCx={"my-0 p-0"}
        extraCx={"space-y-0 h-fit static"}
        viewFormat={"custom"}
        name={""}
        showHeading={false}>
        <div className={"flex items-center justify-between p-6"}>
          <div className={"text-lg font-bold"}>Notes</div>
          <div>
            <OutlineButton
              buttonType={"button"}
              label={""}
              colorType={"neutral"}
              icon={<PlusSvg />}
              onClick={() => setIsAddNote(true)}
            />
          </div>
        </div>
        <div style={{ marginTop: 0 }}>
          {isLoading ? (
            <LottieLoading />
          ) : contactNotes.length === 0 ? (
            <div className={"p-6 pt-0"}>
              <NoItems
                icon={<ContactNoteSvg />}
                message={"There are no notes"}
                addButtonMessage={"Add Note"}
                openAdd={() => setIsAddNote(true)}
              />
            </div>
          ) : (
            contactNotes?.map((note: any, index: number) => (
              <div key={note.id}>
                {collapsedNotes.includes(note.id) ? (
                  <div
                    className={
                      "mx-6 mb-2 rounded-[12px] border-[1px] bg-gray-100 p-2"
                    }>
                    <div
                      className={
                        "flex  justify-end text-[12px] text-gray-400 "
                      }>
                      {moment(note.createdAt).format("MMM DD, YYYY")}
                    </div>
                    <div
                      className={
                        "mb-2 whitespace-pre-wrap break-all text-[14px] font-[500]"
                      }>
                      {note.title}
                    </div>
                    <div className={"mb-2 whitespace-pre-line text-[12px]"}>
                      {note.note}
                    </div>
                    <div className={"flex items-center justify-between"}>
                      <div className={"flex items-center gap-2"}>
                        <AvatarPlaceholder
                          size={"super-small"}
                          label={
                            note.createdByUser?.userProfile?.firstName &&
                            note.createdByUser?.userProfile?.lastName
                              ? `${note.createdByUser?.userProfile?.firstName.substring(
                                  0,
                                  1,
                                )}${note.createdByUser?.userProfile?.lastName.substring(
                                  0,
                                  1,
                                )}`
                              : "N/A"
                          }
                        />
                        <div className={"text-[12px] text-gray-400"}>
                          {note.createdByUser
                            ? `${note.createdByUser?.userProfile?.firstName} ${note.createdByUser?.userProfile?.lastName}`
                            : "N/A"}
                        </div>
                      </div>
                      <Button
                        buttonType={"button"}
                        label={"OK"}
                        onClick={() =>
                          setCollapsedNotes((prev) =>
                            prev.filter((item) => item !== note.id),
                          )
                        }
                        extraClasses={"normal-case"}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={"px-6 hover:bg-gray-100"}>
                    <div
                      onClick={() =>
                        setCollapsedNotes((prev) => [...prev, note.id])
                      }
                      className={
                        "flex cursor-pointer items-center justify-between py-4"
                      }>
                      <div
                        className={"flex items-center space-x-3 text-[14px]"}>
                        <AvatarPlaceholder
                          size={"super-small"}
                          label={
                            note.createdByUser?.userProfile?.firstName &&
                            note.createdByUser?.userProfile?.lastName
                              ? `${note.createdByUser?.userProfile?.firstName.substring(
                                  0,
                                  1,
                                )}${note.createdByUser?.userProfile?.lastName.substring(
                                  0,
                                  1,
                                )}`
                              : "N/A"
                          }
                        />
                        <div>
                          <div
                            className={
                              "... mb:max-w-[100px] overflow-hidden  text-ellipsis  whitespace-nowrap text-[14px] font-[500] sm:max-w-[150px] xl:max-w-[200px] 2xl:max-w-[330px]"
                            }>
                            {note.title || "No title"}
                          </div>
                          <div
                            className={
                              "... mb:max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap  text-[12px] text-gray-400 sm:max-w-[150px] xl:max-w-[200px] 2xl:max-w-[330px]"
                            }>
                            {note.note}
                          </div>
                        </div>
                      </div>
                      <div>
                        <EditDots>
                          <div className={""}>
                            <div
                              onClick={() => {
                                setCurrentNote(note);
                                setIsEditNote(true);
                              }}
                              className={
                                "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                              }>
                              Edit
                            </div>
                            <div
                              onClick={() => {
                                setIsLoading(true);
                                deleteNote(note);
                              }}
                              className={
                                "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                              }>
                              Delete
                            </div>
                          </div>
                        </EditDots>
                      </div>
                    </div>
                    {Boolean(
                      !collapsedNotes.includes(note.id) &&
                        !collapsedNotes.includes(contactNotes[index + 1]?.id) &&
                        index !== contactNotes.length - 1,
                    ) && <div className={"h-[1px] bg-[#E8E9EA]"}></div>}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </FormSection>
      {isAddNote && (
        <ContactAddNote
          setIsLoading={setIsLoading}
          setIsAddNote={setIsAddNote}
          getContact={getContact}
          id={`${contactId}`}
        />
      )}
      {isEditNote && (
        <ContactEditNote
          setIsLoading={setIsLoading}
          setIsEditNote={setIsEditNote}
          getContact={getContact}
          note={currentNote}
        />
      )}
    </>
  );
};

export default ContactNoteBlock;
