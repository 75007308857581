import * as Xstate from "@xstate/react";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../../app/constants/TextConstants";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { CustomLink, CustomPage } from "../../../support/CustomLink";
import { FormSection } from "../../../support/FormSection";
import { IWorkNote } from "../../../types/types";
import ContactAddNote from "./ContactAddNote";
import ContactEditNote from "./ContactEditNote";
interface ICustomerNotesViewProps {
  setActivePage: (activePage: "profile" | "notes" | "work") => void;
  activePage: "profile" | "notes" | "work";
}
const ContactNote: React.FC<ICustomerNotesViewProps> = ({
  setActivePage,
  activePage,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { customerId } = useParams();
  const [contactNotes, setContactNotes] = useState<any[]>([]);
  const [isAddNote, setIsAddNote] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [note, setNote] = useState<IWorkNote>();
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getContact = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/contacts/get/${customerId}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setId(data.contactId);
      await getContactNotes(data.contactId);
    } else {
      setIsLoading(false);
    }
  };
  const getContactNotes = async (id: string) => {
    const response = await fetch(
      `https://dev.procharted.com/api/contact-notes/${id}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: any[] = await response.json();
      const sortedData = data.sort(
        (a, b) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
      );

      const pinnedNotes = sortedData.filter((note) => note.isPinned);
      const unpinnedNotes = sortedData.filter((note) => !note.isPinned);

      setContactNotes([...pinnedNotes, ...unpinnedNotes]);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getContact();
  });

  const deleteNote = async (data: IWorkNote) => {
    const response = await fetch(
      "https://dev.procharted.com/api/contact-notes",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      getContact();
    } else {
      setIsLoading(false);
    }
  };

  const pinNote = async (data: IWorkNote) => {
    const response = await fetch(
      `https://dev.procharted.com/api/contact-notes/mark-pinned?contactNoteId=${
        data.id
      }&isPinned=${!data.isPinned}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({}),
      },
    );
    if (response.ok) {
      getContact().then();
    } else {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <LottieLoading />;
  }
  return (
    <>
      <div className={"tabs my-8"}>
        <CustomPage
          onClick={() => setActivePage("profile")}
          isActive={activePage === "profile"}>
          {LABELS.features.profile}
        </CustomPage>
        <CustomPage
          onClick={() => setActivePage("work")}
          isActive={activePage === "work"}>
          {LABELS.features.work}
        </CustomPage>
        <CustomPage
          onClick={() => setActivePage("notes")}
          isActive={activePage === "notes"}>
          {LABELS.features.notes}
        </CustomPage>
      </div>
      <FormSection name={"Notes"}>
        <button
          className={
            "open-popup add-task border-purple text-purple mt-8 block w-full rounded-[7px] border-[1px] border-solid px-[20px] py-[15px] text-[14px]"
          }
          onClick={() => setIsAddNote(true)}>
          <img src={"/img/plus.png"} alt={""} className={"mr-[17px]"} /> Add
          Note
        </button>
        {contactNotes?.map((note: any) => (
          <div
            key={note.id}
            className={
              "rounded-[7px]  border-[1px] border-[#AFAFAF] bg-[#F9FAFB] px-[20px] py-[15px]"
            }>
            <div className={"flex justify-between"}>
              <div className={"text-14 font-bold "}>{note.title}</div>
              <div className={"text-[14px] font-bold"}>
                {note.isPinned && "Pinned"}
              </div>
            </div>
            <div className={"mb-4 text-[14px] text-[#6A7584]"}>{note.note}</div>
            <div className={"flex justify-between"}>
              <div className={"flex items-center space-x-3 text-[14px]"}>
                <AvatarPlaceholder
                  size={"extra-small"}
                  label={
                    note.createdByUser?.userProfile?.firstName &&
                    note.createdByUser?.userProfile?.lastName
                      ? `${note.createdByUser?.userProfile?.firstName.substring(
                          0,
                          1,
                        )}${note.createdByUser?.userProfile?.lastName.substring(
                          0,
                          1,
                        )}`
                      : "User"
                  }
                />
                <div className={"text-[12px] text-gray-400"}>
                  {`${note.createdByUser?.userProfile?.firstName} ${note.createdByUser?.userProfile?.lastName}`}
                </div>
              </div>
              <div className={"flex"}>
                <div
                  className={"mr-4 flex items-end text-[12px] text-gray-400"}>
                  {moment(note.createdAt).format("MMM DD, YYYY")}
                </div>
                <button
                  onClick={() => {
                    setNote(note);
                    setIsEditNote(true);
                  }}
                  className={
                    "bg-purple border-purple mr-4 inline-block h-[40px] rounded-[7px] border-solid px-[20px]  text-[14px] font-bold uppercase leading-[40px] text-white hover:bg-purple-800"
                  }>
                  Edit
                </button>
                <div
                  className={
                    "mr-4 cursor-pointer rounded-[5px] p-[5px] hover:bg-gray-200"
                  }
                  onClick={() => {
                    setIsLoading(true);
                    pinNote(note);
                  }}>
                  <img
                    src={"/img/pin.png"}
                    alt={"pin"}
                    className={"w-[20px] "}
                  />
                </div>
                <div
                  onClick={() => {
                    setIsLoading(true);
                    deleteNote(note);
                  }}
                  className={
                    " cursor-pointer rounded-[5px] p-[5px] hover:bg-gray-200"
                  }>
                  <img
                    src={"/img/trash.png"}
                    alt={"delete"}
                    className={"w-[20px] "}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </FormSection>
      {isAddNote && (
        <ContactAddNote
          setIsLoading={setIsLoading}
          setIsAddNote={setIsAddNote}
          getContact={getContact}
          id={id}
        />
      )}
      {isEditNote && (
        <ContactEditNote
          setIsLoading={setIsLoading}
          setIsEditNote={setIsEditNote}
          getContact={getContact}
          note={note}
        />
      )}
    </>
  );
};

export default ContactNote;
