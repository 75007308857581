import React, { useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { GetPageTitle } from "../../../../support/ScrollToTop";
import ContactNote from "../ContactNote";
import CustomerJobsListView from "../CustomerJobsListView";
import { CustomerProfileView } from "./CustomerProfileView";

export const CustomerView: React.FC<unknown> = () => {
  const [activePage, setActivePage] = useState<"profile" | "notes" | "work">(
    "profile",
  );

  useEffectOnce(() => {
    document.title = GetPageTitle("Contact");
  });

  switch (activePage) {
    case "profile":
      return (
        <CustomerProfileView
          setActivePage={setActivePage}
          activePage={activePage}
        />
      );
    case "work":
      return (
        <CustomerJobsListView
          setActivePage={setActivePage}
          activePage={activePage}
        />
      );
    case "notes":
      return (
        <ContactNote setActivePage={setActivePage} activePage={activePage} />
      );
    default:
      return <>Not found</>;
  }
};
