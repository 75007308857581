import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as devConfig from "../../app/configs/devConfig";

interface IUserInvite {
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  invitedAccepted: boolean;
  lastName: string;
  orgId: string;
  password: string;
  relayId: string;
  userRoleEnumId: string;
}

interface IMessage {
  type: "simplePassword" | "success" | "differentPassword" | "empty" | "";
  message: string;
}

const UserInvite = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userInvite, setUserInvite] = useState<IUserInvite>();
  const [message, setMessage] = useState<IMessage>({ type: "", message: "" });
  const [isSignIn, setIsSignIn] = useState(false);
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialCharCheck: false,
  });
  const getUserInvite = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-invitations/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
      },
    );
    if (response.ok) {
      return response.json();
    }
  };
  useEffect(() => {
    getUserInvite().then((res) => {
      res.inviteAccepted ? navigate("/home") : setUserInvite(res);
    });
  }, []);
  const updateInvite = async (password: string) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-invitations/mark-invite-accepted/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          orgId: userInvite?.orgId,
          firstName: userInvite?.firstName,
          lastName: userInvite?.lastName,
          password: password,
        }),
      },
    );
    if (response.ok) {
      const data = response.json();
      setIsSignIn(true);
      setMessage({
        type: "success",
        message: "Try to sign in with this email and the password you entered.",
      });
    } else {
    }
  };
  const [password, setPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const onClickCreate = () => {
    if (password || secondPassword) {
      if (password !== secondPassword) {
        setMessage({
          type: "differentPassword",
          message: "Passwords do not match",
        });
      } else {
        if (
          checks.numberCheck &&
          checks.capsLetterCheck &&
          checks.specialCharCheck &&
          checks.pwdLengthCheck
        ) {
          updateInvite(password);
        } else {
          setMessage({
            type: "simplePassword",
            message:
              "The password is too simple. Password must contain capital letters, numbers, special characters and more than 8 letters",
          });
        }
      }
    } else {
      setMessage({ type: "empty", message: "Enter password" });
    }
  };
  const handleOnKeyUp = (e: any) => {
    const { value } = e.target;
    const capsLetterCheck = /[A-Z]/.test(value);
    const numberCheck = /[0-9]/.test(value);
    const pwdLengthCheck = value.length >= 8;
    const specialCharCheck = /[!@#$%^&*]/.test(value);
    setChecks({
      capsLetterCheck,
      numberCheck,
      pwdLengthCheck,
      specialCharCheck,
    });
  };
  return (
    <div
      className={
        "flex min-h-screen items-center justify-center bg-gradient-to-r from-blue-400 to-blue-600"
      }>
      <div className={"w-[420px] rounded-[4px] bg-white py-[30px] px-[40px]"}>
        <div
          className={
            "py-[30px] text-center text-[28px] font-bold font-bold text-blue-500"
          }>
          ProCharted
        </div>
        <div className={"mb-[20px] text-center text-[30px] font-bold"}>
          Create a password
        </div>
        <div className={"font-bold"}>
          Hello {userInvite?.firstName} {userInvite?.lastName}
        </div>
        <div className={"mb-4 font-bold"}>Your email {userInvite?.email}</div>

        <div className={"mb-8"}>
          <div className={"mb-4"}>
            <div className={"mb-1 text-[12px] font-bold"}>Enter password</div>
            <input
              disabled={isSignIn}
              className={"input input-bordered w-full"}
              type={"password"}
              onKeyUp={handleOnKeyUp}
              value={password}
              placeholder={"Password"}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div>
            <div className={"mb-1 text-[12px] font-bold"}>
              Confirm the password
            </div>
            <input
              disabled={isSignIn}
              className={"input input-bordered w-full"}
              type={"password"}
              value={secondPassword}
              placeholder={"Confirm"}
              onChange={(event) => setSecondPassword(event.target.value)}
            />
          </div>
          {message.type && (
            <div
              className={`mt-4 text-[12px] font-bold ${
                message.type === "success" ? "text-green-400" : "text-red-500"
              }`}>
              {message.message}
            </div>
          )}
        </div>

        {isSignIn ? (
          <div>
            <button
              onClick={() => navigate("/home")}
              className={
                "bg-purple rounded-[7px] px-[20px] py-[10px] text-[14px] text-white hover:bg-purple-800"
              }>
              Sign in
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={onClickCreate}
              className={
                "bg-purple rounded-[7px] px-[20px] py-[10px] text-[14px] text-white hover:bg-purple-800"
              }>
              Create
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserInvite;
