import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { CustomLink } from "../../../support/CustomLink";
import { FormSection } from "../../../support/FormSection";
import { ITaskTemplate } from "../../../types/types";
import EditTaskTemplate from "./EditTaskTemplate";
import HideTemplatePopup from "./HideTemplatePopup";

export const InactiveTemplate: React.FC<unknown> = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const [isUpdateTaskTemplate, setIsUpdateTaskTemplate] = useState(false);
  const [isHideTemplate, setIsHideTemplate] = useState(false);
  const [taskTemplate, setTaskTemplate] = useState({});
  const [taskTemplates, setTaskTemplates] = useState<ITaskTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getTaskTemplates = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/work-template/hidden-templates?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTaskTemplates(data);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getTaskTemplates().then();
  });

  const deleteTaskTemplate = async (task: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/work-template",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(task),
      },
    );
    if (response.ok) {
      getTaskTemplates().then();
    } else {
      setIsLoading(false);
      setTaskTemplate(task);

      setIsHideTemplate(true);
    }
  };
  const unhideTaskTemplate = async (task: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/work-template/unhide-template",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(task),
      },
    );
    if (response.ok) {
      getTaskTemplates().then();
    } else {
      setIsLoading(false);
      setIsHideTemplate(true);
    }
  };

  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <>
      <FormSection name={""} extraCx={"overflow-visible"} showHeading={false}>
        <div className={"mb-4 "}>
          <div className={"tabs mb-4"}>
            <CustomLink to={"../active"}>Active</CustomLink>
            <CustomLink to={"./"}>Inactive</CustomLink>
          </div>
          <div className={"text-lg font-bold"}>Details</div>
        </div>
        <div className={"text-[14px]"}>
          {taskTemplates.map((task) => (
            <div
              className={
                "mb-4 flex w-full  justify-between border-b-[1px] border-gray-300 pb-2 text-[14px]"
              }
              key={task.id}>
              <div>
                <Link
                  to={`../${task.id}`}
                  className={"link-primary text-sm font-[500] hover:underline"}>
                  {task.templateName}
                </Link>
              </div>
              <EditDots>
                <div className={""}>
                  <div
                    onClick={() => {
                      setTaskTemplate(task);
                      setIsUpdateTaskTemplate(true);
                    }}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Edit
                  </div>
                  <div
                    onClick={() => {
                      setIsLoading(true);
                      unhideTaskTemplate(task);
                    }}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Activate
                  </div>
                  <div
                    onClick={() => {
                      setIsLoading(true);
                      deleteTaskTemplate(task);
                    }}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Delete
                  </div>
                </div>
              </EditDots>
            </div>
          ))}
        </div>
      </FormSection>
      {isUpdateTaskTemplate && (
        <EditTaskTemplate
          setIsLoading={setIsLoading}
          setActive={setIsUpdateTaskTemplate}
          taskTemplate={taskTemplate}
          getTaskTemplates={getTaskTemplates}
        />
      )}
      {isHideTemplate && (
        <HideTemplatePopup
          isOpen={isHideTemplate}
          closePopup={() => setIsHideTemplate(false)}
          confirmHideTemplate={() => {
            setIsHideTemplate(false);
          }}
        />
      )}
    </>
  );
};
