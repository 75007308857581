import * as Xstate from "@xstate/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import NoItems from "../../../../components/NoItems";
import ContactWorkSvg from "../../../../components/svg/ContactWorkSvg";
import PlusSvg from "../../../../components/svg/PlusSvg";
import { showStatus } from "../../../../constants/constants";
import { FormSection } from "../../../../support/FormSection";
import { IContactProfile, IWork } from "../../../../types/types";
import { AddJobGlobalView } from "../../../Works/AddWork/AddJobGlobalView";
import ErrorPopup from "../../ErrorPopup";
import { OutlineButton } from "../../../../components/button/OutlineButton";

interface IContactWorksBlockProps {
  contact: IContactProfile;
  isActive: boolean;
}

const ContactWorksBlock: React.FC<IContactWorksBlockProps> = ({
  contact,
  isActive,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const navigate = useNavigate();
  const { customerId } = useParams<{ customerId: string }>();

  const [contactWorks, setContactWorks] = useState<IWork[]>([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const getContactWorks = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/work/get-by-customer-id/${customerId}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IWork[] = await response.json();
      const filterWorks = data.filter((work) => work.customer);
      setContactWorks(filterWorks);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getContactWorks().then();
  }, []);

  return (
    <>
      <FormSection
        customCx={"my-0 p-0"}
        extraCx={"space-y-0 h-fit text-[16px]"}
        viewFormat={"custom"}
        name={""}
        showHeading={false}>
        <div className={"flex items-center justify-between p-6"}>
          <div className={"text-lg font-bold"}>Work</div>
          <div>
            <OutlineButton
              buttonType={"button"}
              label={""}
              colorType={"neutral"}
              icon={<PlusSvg />}
              onClick={() => (!isActive ? setIsError(true) : setIsAdd(true))}
            />
          </div>
        </div>
        <div
          style={{ marginTop: 0 }}
          className={"max-h-[400px] overflow-y-auto"}>
          {isLoading ? (
            <LottieLoading />
          ) : contactWorks.length === 0 ? (
            <div className={"p-6 pt-0"}>
              <NoItems
                icon={<ContactWorkSvg />}
                message={"There are no work items"}
                addButtonMessage={"Add Work"}
                openAdd={() => (!isActive ? setIsError(true) : setIsAdd(true))}
              />
            </div>
          ) : (
            <table className={"w-full"}>
              <tbody>
                {contactWorks?.map((work) => (
                  <tr
                    key={work.id}
                    className={
                      "border-b-[1px] border-[#E8E9EA] last-of-type:border-0 hover:bg-gray-100"
                    }>
                    <td
                      className={
                        "w-1/2 cursor-pointer py-4 pl-6 text-[14px] font-[500] text-[#7D66F2]"
                      }
                      onClick={() => navigate(`/job/${work?.id}`)}>
                      {work?.name}
                    </td>
                    <td className={"flex items-center py-4"}>
                      <div
                        className={"flex h-[10px] w-[10px] rounded-full"}
                        style={{
                          backgroundColor: work?.jobStatus?.color,
                        }}
                      />
                      <div
                        className={
                          "badge badge-outline ml-2 whitespace-nowrap border-gray-200 text-[14px]"
                        }>
                        {showStatus(work)}
                      </div>
                    </td>
                    <td className={"pr-6 text-[14px]"}>
                      {moment(work?.dueDate).format("MM/DD/YYYY")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </FormSection>
      {isAdd && (
        <AddJobGlobalView
          setIsAddWork={setIsAdd}
          defaultContact={{
            id: contact.customerId,
            name: `${contact.firstName} ${contact.lastName}`,
            isBusinessContact: false,
          }}
        />
      )}
      <ErrorPopup closePopup={() => setIsError(false)} isOpen={isError} />
    </>
  );
};

export default ContactWorksBlock;
