import React from "react";
import { useForm } from "react-hook-form";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";

interface EditTaskTemplateProps {
  setActive: (active: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  taskTemplate: any;
  getTaskTemplates: () => void;
}
const { nameRule } = ValidationConstants.customerRules;

const EditTaskTemplate: React.FC<EditTaskTemplateProps> = ({
  setActive,
  taskTemplate,
  getTaskTemplates,
  setIsLoading,
}) => {
  const editTaskTemplate = async (data: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/work-template/update-template",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      getTaskTemplates();
    } else {
      setIsLoading(false);
    }
    setActive(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      templateName: taskTemplate.templateName,
    },
  });
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    await editTaskTemplate({
      ...taskTemplate,
      templateName: data.templateName,
    });
  };

  return (
    <div className={"fixed bottom-1/4 left-0 w-screen bg-transparent"}>
      <div className={"wrapper flex h-full items-end justify-center"}>
        <div className={"holder max-w-572 w-100 w-full"}>
          <div
            className={
              "text-18 mt-6 rounded-[15px] border-[1px] border-solid border-[#E8E9EA] bg-white p-10 shadow-[0px_4px_30px_rgba(34,34,34,0.15)]"
            }>
            <form
              className={"popup-form c-form "}
              onSubmit={handleSubmit(onSubmit)}>
              <div className={"mb-8 flex items-center justify-between"}>
                <p className={"mb-0"}>Edit Subtask Template</p>
                <div
                  className={"close-popup cursor-pointer"}
                  onClick={() => setActive(false)}>
                  <img src={"/img/closse.png"} alt={""} />
                </div>
              </div>
              <div className={"mb-4"}>
                <input
                  type={"text"}
                  placeholder={"Subtask name"}
                  className={"input"}
                  {...register("templateName", {
                    required: LABELS.required,
                    ...nameRule.valueLength,
                  })}
                />
                {errors?.templateName?.message && (
                  <span className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.templateName?.message}
                  </span>
                )}
              </div>
              <div className={"mb-8 flex items-center justify-end"}>
                <div>
                  <button
                    onClick={() => setActive(false)}
                    type={"button"}
                    className={
                      "cancel-btn hover:bg-purple hover:border-purple close-popup mr-2 inline-block h-[40px] rounded-[7px] border-0  border-solid border-[#1F2937] px-[20px] text-[14px] font-bold uppercase leading-[40px] hover:text-white"
                    }>
                    cancel
                  </button>
                  <button
                    type={"submit"}
                    className={
                      "bg-purple border-purple inline-block h-[40px] rounded-[7px] border-solid  px-[20px] text-[14px] font-bold uppercase leading-[40px] text-white"
                    }>
                    save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTaskTemplate;
