import React, { useEffect, useRef, useState } from "react";

import { LABELS } from "../../app/constants/TextConstants";
import { IconButton } from "../../components/button/IconButton";
import { WarningExclaimSvg } from "../../components/svg/WarningExclaimSvg";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const TooltipDeactivatedMessage: React.FC<unknown> = () => {
  const messageRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState<{
    top: number;
    left: number;
    bottom?: number;
  }>({
    top: 0,
    left: 0,
  });
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(messageRef, () => setIsOpen(false));
  const handleScroll = () => {
    const rect = messageRef?.current?.getBoundingClientRect();
    if (rect) {
      const bottom = window.innerHeight - rect.bottom;
      const fitsBelow = rect.bottom + 70 <= window.innerHeight; // 300 - высота меню
      setPosition({
        top: Math.ceil(rect.top),
        left: Math.ceil(rect.left),
        bottom: fitsBelow ? undefined : Math.ceil(bottom),
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return (
    <div ref={messageRef} className={"relative"}>
      <IconButton
        size={"extra-small"}
        colorType={"secondary"}
        border={false}
        extraCx={"ml-1 mt-1 rounded-full"}
        onMouseEnter={() => {
          handleScroll();
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
        IconSvg={WarningExclaimSvg}
      />
      {isOpen && (
        <div
          className={
            "pointer-events-none fixed top-0 left-0 z-[1] min-h-[100vh] min-w-[100vw] bg-transparent"
          }>
          <div
            style={{
              position: "absolute",
              top: `calc(${
                position.bottom ? "auto" : position.top ? position.top : 0
              }px - ${position.bottom ? "0" : "32px"})`,
              left: position.left ? `calc(${position.left}px + 16px)` : 0,
              bottom: position.bottom
                ? `calc(${position.bottom}px + 32px)`
                : "auto",
              pointerEvents: "none",
              transform: "translateX(-50%)",
              maxWidth: "20rem",
              borderRadius: "0.25rem",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              paddingTop: "0.25rem",
              paddingBottom: "0.25rem",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              background: "#fbbe24",
              color: "#382800",
              width: "max-content",
            }}>
            {LABELS.warnings.inactiveTooltip}
          </div>
        </div>
      )}
    </div>
  );
};

export default TooltipDeactivatedMessage;
