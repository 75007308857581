import { ChevronDown, ChevronUp } from "baseui/icon";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { getInitialsFromContactName } from "../constants/constants";
import { IWork, IWorkTask } from "../types/types";
import { AvatarPlaceholder } from "./avatar/AvatarPlaceholder";
import DropStatus from "./DropStatus";

interface IWorkTable {
  works: IWork[];
  onClickSort: (item: { id: number; body: SortOrderT }) => void;
  selectedSortOrder: SortOrderT;
  isOpenAddContact: boolean;
  closeAddContact: () => void;
  getWorks: () => void;
  setIsLoading: (isLoading: boolean) => void;
}
type SortOrderT =
  | { name: "Work Name"; sort: "asc" | "desc" }
  | { name: "Contact"; sort: "asc" | "desc" }
  | { name: "Status"; sort: "asc" | "desc" }
  | { name: "Start Date"; sort: "asc" | "desc" }
  | { name: "Due Date"; sort: "asc" | "desc" }
  | { name: "Progress"; sort: "asc" | "desc" }
  | { name: "Repeats"; sort: "asc" | "desc" }
  | { name: "Work Type"; sort: "asc" | "desc" }
  | { name: "Assignee"; sort: "asc" | "desc" };
export const tableHead: { id: number; body: SortOrderT }[] = [
  {
    id: 0,
    body: { name: "Work Name", sort: "asc" },
  },
  {
    id: 1,
    body: { name: "Contact", sort: "asc" },
  },
  {
    id: 2,
    body: { name: "Status", sort: "asc" },
  },
  {
    id: 3,
    body: { name: "Start Date", sort: "asc" },
  },
  {
    id: 4,
    body: {
      name: "Due Date",
      sort: "asc",
    },
  },
  {
    id: 5,
    body: { name: "Progress", sort: "asc" },
  },
  {
    id: 6,
    body: { name: "Repeats", sort: "asc" },
  },
  {
    id: 7,
    body: { name: "Work Type", sort: "asc" },
  },
  {
    id: 8,
    body: { name: "Assignee", sort: "asc" },
  },
];
const WorkTable: React.FC<IWorkTable> = ({
  works,
  onClickSort,
  selectedSortOrder,
  getWorks,
  setIsLoading,
}) => {
  const progress = (workTasks: IWorkTask[]) => {
    const done = workTasks.filter((task) => task.isDone && task);
    const progressCount = (done.length / workTasks.length) * 100;
    return Math.ceil(progressCount || 0);
  };
  const statusesName = {
    Ready: "Ready",
    InProgress: "In Progress",
    Waiting: "Waiting",
    Completed: "Completed",
  };

  const sortStatuses = (statuses: any) => {
    return statuses
      ?.filter((item: any) => item.name !== "trial")
      .sort((a: any, b: any) => {
        if (a.name === statusesName.Ready) {
          return -1;
        }
        if (b.name === statusesName.Ready) {
          return 1;
        }
        if (a.name === statusesName.InProgress) {
          return -2;
        }
        if (b.name === statusesName.InProgress) {
          return 2;
        }
        if (a.name === statusesName.Waiting) {
          return -3;
        }
        if (b.name === statusesName.Waiting) {
          return 3;
        }
        if (a.name === statusesName.Completed) {
          return -4;
        }
        if (b.name === statusesName.Completed) {
          return 4;
        }
      });
  };
  const updateStatusWork = async (
    statusId: string,
    subStatusId: string,
    jobId: string,
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work/update-status?workId=${jobId}&statusId=${statusId}&subStatusId=${subStatusId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({}),
      },
    );
    if (response.ok) {
      getWorks();
    } else {
      setIsLoading(false);
    }
  };

  const worksRows = works.map((job, index) => {
    const { dueDate, isOverdue } = {
      dueDate: new Date(job.dueDate).toLocaleDateString(),
      isOverdue:
        moment().startOf("day").unix() > moment(new Date(job.dueDate)).unix(),
    };
    const localeStartDate = new Date(job.startDate).toLocaleDateString();
    return (
      <tr
        key={job.id}
        className={"border-b-[1px] border-gray-300 hover:bg-gray-100"}>
        <td className={"bg-transparent px-[16px] py-[8px]"}>
          <div className={"flex items-center"}>
            <div className={"max-w-[300px] whitespace-normal"}>
              <Link
                to={`/job/${job.id}`}
                className={"link-primary text-sm font-[500] hover:underline"}>
                {job?.name}
              </Link>
            </div>
          </div>
        </td>
        {!job.businessContactId ? (
          <td className={"bg-transparent px-[16px] py-[8px]"}>
            <div className={"flex items-center"}>
              <div className={"flex items-center text-[14px]"}>
                <AvatarPlaceholder
                  size={"extra-small"}
                  label={
                    job?.customer && job?.customer?.name
                      ? getInitialsFromContactName(job?.customer?.name)
                      : "N/A"
                  }
                />
                <div className={"pl-2 text-[14px] normal-case"}>
                  <Link
                    to={`/client/${job?.customerId}`}
                    className={
                      "link-primary text-sm  font-[500] hover:underline"
                    }>
                    {job?.customer?.name}
                  </Link>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td className={"bg-transparent px-[16px] py-[8px]"}>
            <div className={"flex items-center"}>
              <div className={"flex items-center text-[14px]"}>
                <AvatarPlaceholder
                  type={"Organization"}
                  size={"extra-small"}
                  label={
                    job?.businessContact
                      ? getInitialsFromContactName(
                          job?.businessContact?.companyName,
                        )
                      : "N/A"
                  }
                />
                <div className={"pl-2 text-[14px] normal-case"}>
                  <Link
                    to={`/organization/${job?.businessContactId}`}
                    className={
                      "link-primary whitespace-nowrap text-sm font-[500] hover:underline"
                    }>
                    {job?.businessContact?.companyName}
                  </Link>
                </div>
              </div>
            </div>
          </td>
        )}
        <td className={"bg-transparent px-[16px] py-[8px]"}>
          {job.jobStatus && (
            <DropStatus job={job}>
              {sortStatuses(job?.service?.serviceStatuses)?.map(
                (status: any) => (
                  <div key={status.id} className={"overflow-hidden"}>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsLoading(true);
                        updateStatusWork(status.id, " ", job?.id);
                      }}
                      className={
                        "mb-2 flex cursor-pointer items-center whitespace-nowrap px-4 py-2 font-[600] last:mr-0 last:mb-0 hover:bg-gray-200"
                      }>
                      <div
                        className={" mr-2 h-[10px] w-[10px] rounded-full"}
                        style={{
                          background: `${status?.color}`,
                        }}></div>
                      <div>{status?.name}</div>
                    </div>
                    {status?.serviceSubStatuses?.map((sub: any) => (
                      <div
                        key={sub.id}
                        onClick={(event) => {
                          event.stopPropagation();
                          setIsLoading(true);
                          updateStatusWork(status?.id, sub?.id, job?.id);
                        }}
                        className={
                          "mb-2 flex cursor-pointer items-center whitespace-nowrap px-4 py-2 hover:bg-gray-200"
                        }>
                        <div
                          className={" mr-2 h-[10px] w-[10px] rounded-full"}
                          style={{
                            background: `${status.color}`,
                          }}></div>
                        <div key={sub.id}>{sub?.subStatus}</div>
                      </div>
                    ))}
                  </div>
                ),
              )}
            </DropStatus>
          )}
        </td>
        <td className={" bg-transparent px-[16px] py-[8px] text-[14px]"}>
          {localeStartDate}
        </td>
        <td
          className={`${
            isOverdue ? "text-red-500" : "text-black"
          } bg-transparent px-[16px] py-[8px] text-[14px]`}>
          {dueDate}
        </td>
        <td
          className={
            "w-[100px] bg-transparent px-[16px] py-[8px] text-center text-[14px]"
          }>
          <div>{progress(job?.workTasks) || "0"}%</div>
          <div className={"h-[5px] w-[100px] rounded-[10px] bg-gray-400"}>
            <div
              style={{ width: `${progress(job.workTasks)}%` }}
              className={"bg-green h-[5px] rounded-[10px]"}></div>
          </div>
        </td>
        <td className={" py-[8px]text-[14px] bg-transparent px-[16px]"}>
          {job.repeatTypeEnum?.repeatType}
          {job.repeatTypeRecurring !== null &&
            job.repeatTypeRecurring !== undefined &&
            `: ${job.repeatTypeRecurring}`}
        </td>
        <td className={"bg-transparent px-[16px] py-[8px] text-[14px]"}>
          {job.service?.name}
        </td>
        <td className={"bg-transparent px-[16px] py-[8px]"}>
          <div className={"flex items-center text-[14px]"}>
            <AvatarPlaceholder
              size={"extra-small"}
              label={
                job.assignedUser?.userProfile?.firstName &&
                job.assignedUser?.userProfile?.lastName
                  ? `${job.assignedUser?.userProfile?.firstName.substring(
                      0,
                      1,
                    )}${job.assignedUser?.userProfile?.lastName.substring(
                      0,
                      1,
                    )}`
                  : "N/A"
              }
            />
            <div className={"whitespace-nowrap pl-2 text-[14px]  normal-case "}>
              {job.assignedUser &&
                `${job.assignedUser?.userProfile?.firstName}
              ${job.assignedUser?.userProfile?.middleName} 
              ${job.assignedUser?.userProfile?.lastName}`}
            </div>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <table className={"w-full"}>
        <thead className={"border-b-[1px] border-gray-300"}>
          <tr>
            {tableHead.map((item) => (
              <th
                key={item.id}
                className={"py-[16px] pr-[32px] pl-[16px] last:pr-[16px]"}>
                <div
                  className={"flex cursor-pointer"}
                  onClick={() => onClickSort(item)}>
                  <div className={"whitespace-nowrap text-[14px] normal-case"}>
                    {item?.body?.name}
                  </div>
                  {selectedSortOrder.name === item.body.name && (
                    <div className={"relative top-[-4px] left-0"}>
                      {selectedSortOrder.sort === "asc" ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={"text-[14px]"}>{worksRows}</tbody>
      </table>
    </>
  );
};

export default WorkTable;
