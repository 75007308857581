import { useMutation } from "@apollo/client";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import { LABELS, LinkConstants } from "../../app/constants/TextConstants";
import { ValidationConstants } from "../../app/constants/ValidationConstants";
import { Alert } from "../../components/alerts/alert";
import { BrandButton } from "../../components/button/BrandButton";
import { AddStackSvg } from "../../components/svg/AddStackSvg";
import {
  OnboardOrg,
  OnboardOrgInput,
  OnboardOrgVariables,
} from "../../generated/operation-result-types";
import { ONBOARD_ORG_GQL } from "../../queries/OnboardingQueries.gql";
import { FormSection } from "../../support/FormSection";
import { GetPageTitle } from "../../support/ScrollToTop";
import { OnboardingLayout } from "./OnboardingLayout";

type Props = {
  orgId: string;
  userId: string;
  onDone: () => void;
};

const provinces = [
  { value: "BC", label: "British Columbia", country: "CA" },
  { value: "NL", label: "Newfoundland", country: "CA" },
  { value: "PE", label: "Prince Edward Island", country: "CA" },
  { value: "NS", label: "Nova Scotia", country: "CA" },
  { value: "NB", label: "New Brunswick", country: "CA" },
  { value: "QC", label: "Quebec", country: "CA" },
  { value: "ON", label: "Ontario", country: "CA" },
  { value: "MB", label: "Manitoba", country: "CA" },
  { value: "SK", label: "Saskatchewan", country: "CA" },
  { value: "AB", label: "Alberta", country: "CA" },
  { value: "YT", label: "Yukon", country: "CA" },
  { value: "NT", label: "Northwest Territories", country: "CA" },
  { value: "NU", label: "Nunavut", country: "CA" },
  { value: "AL", label: "Alabama", country: "US" },
  { value: "AK", label: "Alaska", country: "US" },
  { value: "AS", label: "American Samoa", country: "US" },
  { value: "AZ", label: "Arizona", country: "US" },
  { value: "AR", label: "Arkansas", country: "US" },
  { value: "CA", label: "California", country: "US" },
  { value: "CO", label: "Colorado", country: "US" },
  { value: "CT", label: "Connecticut", country: "US" },
  { value: "DE", label: "Delaware", country: "US" },
  { value: "DC", label: "District Of Columbia", country: "US" },
  { value: "FL", label: "Florida", country: "US" },
  { value: "GA", label: "Georgia", country: "US" },
  { value: "GU", label: "Guam", country: "US" },
  { value: "HI", label: "Hawaii", country: "US" },
  { value: "ID", label: "Idaho", country: "US" },
  { value: "IL", label: "Illinois", country: "US" },
  { value: "IN", label: "Indiana", country: "US" },
  { value: "IA", label: "Iowa", country: "US" },
  { value: "KS", label: "Kansas", country: "US" },
  { value: "KY", label: "Kentucky", country: "US" },
  { value: "LA", label: "Louisiana", country: "US" },
  { value: "ME", label: "Maine", country: "US" },
  { value: "MD", label: "Maryland", country: "US" },
  { value: "MA", label: "Massachusetts", country: "US" },
  { value: "MI", label: "Michigan", country: "US" },
  { value: "MN", label: "Minnesota", country: "US" },
  { value: "MS", label: "Mississippi", country: "US" },
  { value: "MO", label: "Missouri", country: "US" },
  { value: "MT", label: "Montana", country: "US" },
  { value: "NE", label: "Nebraska", country: "US" },
  { value: "NV", label: "Nevada", country: "US" },
  { value: "NH", label: "New Hampshire", country: "US" },
  { value: "NJ", label: "New Jersey", country: "US" },
  { value: "NM", label: "New Mexico", country: "US" },
  { value: "NY", label: "New York", country: "US" },
  { value: "NC", label: "North Carolina", country: "US" },
  { value: "ND", label: "North Dakota", country: "US" },
  { value: "MP", label: "Northern Mariana IS", country: "US" },
  { value: "OH", label: "Ohio", country: "US" },
  { value: "OK", label: "Oklahoma", country: "US" },
  { value: "OR", label: "Oregon", country: "US" },
  { value: "PA", label: "Pennsylvania", country: "US" },
  { value: "PR", label: "Puerto Rico", country: "US" },
  { value: "RI", label: "Rhode Island", country: "US" },
  { value: "SC", label: "South Carolina", country: "US" },
  { value: "SD", label: "South Dakota", country: "US" },
  { value: "TN", label: "Tennessee", country: "US" },
  { value: "TX", label: "Texas", country: "US" },
  { value: "UT", label: "Utah", country: "US" },
  { value: "VT", label: "Vermont", country: "US" },
  { value: "VI", label: "Virgin Islands", country: "US" },
  { value: "VA", label: "Virginia", country: "US" },
  { value: "WA", label: "Washington", country: "US" },
  { value: "WV", label: "West Virginia", country: "US" },
  { value: "WI", label: "Wisconsin", country: "US" },
  { value: "WY", label: "Wyoming", country: "US" },
];

export const OrgOnboardingView: React.FC<Props> = (props) => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Onboarding org");
  });

  const { orgId, userId, onDone } = props;

  // RHF
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<OnboardOrgInput>({
    defaultValues: {
      orgId: orgId,
      userId: userId,
      locationAddressInput: {
        country: "CA",
      },
    },
  });

  const [onboardUser, { loading: loadingM, error: errorM, reset: resetM }] =
    useMutation<OnboardOrg, OnboardOrgVariables>(ONBOARD_ORG_GQL, {});

  const onSubmit: SubmitHandler<OnboardOrgInput> = async (data) => {
    resetM();
    await onboardUser({
      variables: {
        onboardOrgInput: data,
      },
    });
    onDone();
  };

  const { customerRules, orgRules } = ValidationConstants;
  const orgInfoView = (
    <>
      <div
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Business Name</span>
          </label>
          <input
            type={"text"}
            placeholder={"Your accounting firm name ..."}
            {...register("name", {
              required: LABELS.required,
              ...orgRules.nameRule.valueLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.name?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.name?.message}
            </span>
          )}
        </div>
      </div>
      <div
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Line 1</span>
          </label>
          <input
            type={"text"}
            autoComplete={"address-line1"}
            {...register("locationAddressInput.line1", {
              required: LABELS.required,
              ...customerRules.addressRule.line1Length,
            })}
            className={"input input-bordered"}
          />
          {errors?.locationAddressInput?.line1?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.line1?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Line 2</span>
          </label>
          <input
            type={"text"}
            autoComplete={"address-line2"}
            {...register("locationAddressInput.line2", {
              required: false,
              ...customerRules.addressRule.line2Length,
            })}
            className={"input input-bordered"}
          />
          {errors?.locationAddressInput?.line2?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.line2?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>City</span>
          </label>
          <input
            type={"text"}
            autoComplete={"address-level2"}
            {...register("locationAddressInput.city", {
              required: LABELS.required,
              ...customerRules.addressRule.cityLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.locationAddressInput?.city?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.city?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Province / State</span>
          </label>
          <select
            autoComplete={"address-level1"}
            className={"select select-bordered w-full max-w-xs"}
            {...register("locationAddressInput.region", {
              required: LABELS.required,
              ...customerRules.addressRule.regionLength,
            })}>
            {provinces.map((item, index) => (
              <option
                value={item.value}
                key={index}
                onClick={() =>
                  setValue("locationAddressInput.country", item.country)
                }>
                {item.label}
              </option>
            ))}
          </select>
          {errors?.locationAddressInput?.region?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.region?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Country</span>
          </label>
          <select
            autoComplete={"country"}
            className={"select select-bordered w-full max-w-xs"}
            {...register("locationAddressInput.country", {
              required: LABELS.required,
              ...customerRules.addressRule.countryLength,
            })}>
            <option value={"CA"}>Canada</option>
            <option value={"US"}>US</option>
          </select>
          {errors?.locationAddressInput?.country?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.country?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Postal / Zip Code</span>
          </label>
          <input
            type={"text"}
            autoComplete={"postal-code"}
            {...register("locationAddressInput.postalCode", {
              required: LABELS.required,
            })}
            className={"input input-bordered"}
          />
          {errors?.locationAddressInput?.postalCode?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.postalCode?.message}
            </span>
          )}
        </div>
      </div>
    </>
  );

  const freePlanView = (
    <div
      className={
        "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6"
      }>
      <div className={"form-control space-y-4"}>
        <div className={"flex"}>
          <div className={"flex h-5 items-center"}>
            <input
              type={"checkbox"}
              className={"checkbox checkbox-primary"}
              disabled={loadingM}
              {...register("subscribeFreePlan", {
                required: LABELS.required,
              })}
            />
          </div>
          <div className={"ml-3 flex flex-col text-sm"}>
            <label className={"font-medium text-gray-700"}>
              Free trial plan{" "}
              <a
                href={LinkConstants.bankStatements.pricing}
                className={"link link-primary"}
                target={"_blank"}
                rel={"noreferrer"}>
                View plan details
              </a>
            </label>
            {errors?.subscribeFreePlan?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors?.subscribeFreePlan?.message}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <OnboardingLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"My Firm's Information"}>{orgInfoView}</FormSection>
        <FormSection name={"Subscription Plan"}>{freePlanView}</FormSection>

        {errorM && <Alert type={"error"} label={LABELS.errors.default} />}
        <BrandButton
          buttonType={"submit"}
          colorType={"primary"}
          label={"Continue"}
          SvgIconLeft={AddStackSvg}
          disabled={loadingM}
        />
      </form>
    </OnboardingLayout>
  );
};
