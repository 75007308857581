import React, { ChangeEvent, RefObject } from "react";

import Button from "./button/Button";
import PlusSvg from "./svg/PlusSvg";

interface InputFileProps {
  setFile: (file: File) => void;
  setError: (error: string) => void;
  inputRef: RefObject<HTMLInputElement>;
}

const maxFileSize = 30 * 1000 * 1000;

const InputFile: React.FC<InputFileProps> = ({
  setFile,
  setError,
  inputRef,
}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      if (selectedFile.size > maxFileSize) {
        setError("The file size exceeds the limit of 30mb");
        event.target.value = "";
      } else {
        setFile(event.target.files[0]);
        setError("");
      }
    }
  };

  return (
    <div>
      <input
        ref={inputRef}
        type={"file"}
        accept={
          ".pdf,.xls,.xlsx,.doc,.docx,image/*,.ppt,.pptx,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint.presentation"
        }
        className={"absolute left-[-9999px] top-[-9999px]"}
        onChange={handleFileChange}
      />
      <Button
        buttonType={"button"}
        label={""}
        icon={<PlusSvg />}
        onClick={() => inputRef?.current?.click()}
        extraClasses={"normal-case"}
      />
    </div>
  );
};

export default InputFile;
