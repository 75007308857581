import * as Xstate from "@xstate/react";
import { Pagination } from "baseui/pagination";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import WorkTable from "../../components/WorkTable";
import { showStatus } from "../../constants/constants";
import { useAppSelector } from "../../redux/redux";
import { FormSection } from "../../support/FormSection";
import { IWork } from "../../types/types";

export const WorksByStatus: React.FC<unknown> = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { status, workTypeId } = useParams();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.appReducer);

  const pageSize = 20;

  const [works, setWorks] = useState<IWork[]>([]);
  const [totalPages, setTotalPage] = useState(1);
  const [isLoadingWorks, setIsLoadingWorks] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const progress = (workTasks: any) => {
    const done = workTasks.filter((task: any) => task.isDone && task);
    const progressCount = (done.length / workTasks.length) * 100;
    return Math.ceil(progressCount || 0);
  };

  // state - sorting
  type SortOrderT =
    | { name: "Work Name"; sort: "asc" | "desc" }
    | { name: "Contact"; sort: "asc" | "desc" }
    | { name: "Status"; sort: "asc" | "desc" }
    | { name: "Start Date"; sort: "asc" | "desc" }
    | { name: "Due Date"; sort: "asc" | "desc" }
    | { name: "Progress"; sort: "asc" | "desc" }
    | { name: "Repeats"; sort: "asc" | "desc" }
    | { name: "Work Type"; sort: "asc" | "desc" }
    | { name: "Assignee"; sort: "asc" | "desc" };

  const [selectedSortOrder, setSelectedSortOrder] = React.useState<SortOrderT>({
    name: "Work Name",
    sort: "asc",
  });

  const onClickSort = (item: { id: number; body: SortOrderT }) => {
    setSelectedSortOrder((prev) =>
      prev.name === item.body.name
        ? { ...prev, sort: prev.sort === "asc" ? "desc" : "asc" }
        : item.body,
    );
  };

  const getWorks = async () => {
    const sortName = selectedSortOrder.name.replace(/\s+/g, "");
    if (
      status === "Ready" ||
      status === "In Progress" ||
      status === "Waiting" ||
      status === "Completed"
    ) {
      const response = await fetch(
        `https://dev.procharted.com/api/work/find-work-by-status-name/${
          userInfoByEmail?.org?.id
        }?sortBy=${sortName === "WorkName" ? "Name" : sortName}&sortOrder=${
          selectedSortOrder.sort
        }`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: getAuthHeader().Authorization,
          },
          method: "POST",
          body: JSON.stringify({
            pageNumber: currentPage,
            pageSize: pageSize.toString(),
            worktypeIds: workTypeId === "all" ? [] : [`${workTypeId}`],
            workStatuses: [`${status}`],
            userIds: [],
          }),
        },
      );

      if (response.ok) {
        const data: IWork[] = await response.json();
        return data.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      } else {
        return [];
      }
    } else {
      const response = await fetch(
        `https://dev.procharted.com/api/work/find-work/${
          userInfoByEmail?.org?.id
        }?sortBy=${sortName === "WorkName" ? "Name" : sortName}&sortOrder=${
          selectedSortOrder.sort
        }`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: getAuthHeader().Authorization,
          },
          method: "POST",
          body: JSON.stringify({
            pageNumber: currentPage,
            pageSize: pageSize.toString(),
            worktypeIds: workTypeId === "all" ? [] : [`${workTypeId}`],
            workStatuses: [`${status}`],
            userIds: [],
          }),
        },
      );

      if (response.ok) {
        const data: IWork[] = await response.json();
        return data.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      } else {
        return [];
      }
    }
  };
  const getData = () => {
    getWorks().then((res) => {
      setTotalPage(
        Math.ceil(
          (res && res.length > 0 && res[0]?.totalRecords / pageSize) || 1,
        ),
      );
      setWorks(res);
      setIsLoadingWorks(false);
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    });
  };
  useEffect(() => {
    setIsLoadingWorks(true);
    getData();
  }, [currentPage, user, selectedSortOrder]);

  return (
    <FormSection
      name={`All Work ( ${
        works[0]?.totalRecords ? works[0]?.totalRecords : 0
      } )`}>
      <div
        className={
          "flex w-fit items-center rounded-[7px] border-[1px] border-gray-400 bg-white py-[5px] px-[10px] text-[12px]"
        }>
        <div className={"mr-4 "}>Filter by {status}</div>
        <div
          className={"close-popup cursor-pointer"}
          onClick={() => navigate("/dashboard")}>
          <img src={"/img/closse.png"} alt={"clear-filter-icon"} />
        </div>
      </div>
      <div
        className={
          "card card-body  overflow-x-auto border-base-300 bg-base-100 py-0 px-0"
        }>
        {isLoadingWorks ? (
          <LottieLoading />
        ) : (
          <WorkTable
            works={works}
            selectedSortOrder={selectedSortOrder}
            onClickSort={onClickSort}
            closeAddContact={() => null}
            isOpenAddContact={false}
            getWorks={getData}
            setIsLoading={setIsLoadingWorks}
          />
        )}
      </div>
      <Pagination
        numPages={totalPages}
        currentPage={currentPage}
        onPageChange={({ nextPage }) => {
          setCurrentPage(Math.min(Math.max(nextPage, 1), 20));
        }}
      />
    </FormSection>
  );
};
