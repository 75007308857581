import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import * as devConfig from "../../../app/configs/devConfig";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { IUser } from "../../../types/types";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
interface IUserRoleEnum {
  createdAt: string;
  id: string;
  relayId: string;
  userRole: string;
}
interface IEditUserViewProps {
  isEdit: boolean;
  closeEdit: () => void;
  setIsLoading: (isLoading: boolean) => void;
  user: IUser;
  getUsersFetch: () => void;
}
export const EditUserView: React.FC<IEditUserViewProps> = ({
  isEdit,
  closeEdit,
  user,
  setIsLoading,
  getUsersFetch,
}) => {
  const [userRoleEnum, setUserRoleEnum] = useState<IUserRoleEnum[]>([
    {
      id: "",
      createdAt: "",
      relayId: "",
      userRole: "",
    },
  ]);
  const getUserRoleEnum = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-role-enum/get-all`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setUserRoleEnum(data);
    }
  };
  useEffectOnce(() => {
    document.title = GetPageTitle("Edit user");
    getUserRoleEnum();
  });
  const editUser = async (data: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/users/update-user",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      getUsersFetch();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      firstName: user?.userProfile?.firstName,
      lastName: user?.userProfile?.lastName,
      email: user?.userProfile?.email,
      role: user?.userRoleEnum?.userRole,
    },
  });
  // local effects
  React.useEffect(() => {
    setValue("role", user?.userRoleEnumId);
  }, [user]);
  const onSubmit = async (data: any) => {
    closeEdit();
    setIsLoading(true);
    await editUser({
      ...user,
      userRoleEnumId: data.role,
    });
  };

  return (
    <Transition appear show={isEdit} as={Fragment}>
      <Dialog as={"div"} className={"relative z-10"} onClose={closeEdit}>
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}>
          <div className={"fixed inset-0 bg-black bg-opacity-25"} />
        </Transition.Child>

        <div className={"fixed inset-0 overflow-y-auto"}>
          <div
            className={
              "flex min-h-full items-center justify-center p-4 text-center"
            }>
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 scale-95"}
              enterTo={"opacity-100 scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 scale-100"}
              leaveTo={"opacity-0 scale-95"}>
              <Dialog.Panel
                className={
                  "z-index-100 w-[500px] max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                }>
                <div className={""}>
                  <div className={"mb-6 text-xl font-semibold"}>
                    Edit user role
                  </div>
                  <div className={"flex items-center"}>
                    <AvatarPlaceholder
                      size={"extra-small"}
                      label={
                        user?.userProfile?.firstName &&
                        user?.userProfile?.lastName
                          ? `${user?.userProfile?.firstName.substring(
                              0,
                              1,
                            )}${user?.userProfile?.lastName.substring(0, 1)}`
                          : "User"
                      }
                    />
                    <div className={"flex flex-col pl-2"}>
                      <div className={"text-[14px] capitalize"}>
                        {`${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={"form-control mb-8 w-full"}>
                      <label className={"label"}>
                        <span className={"label-text"}>Role</span>
                      </label>
                      <select
                        {...register("role")}
                        className={"select select-bordered w-full"}>
                        {userRoleEnum?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.userRole}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={"flex justify-start space-x-4 pb-4"}>
                      <Button buttonType={"submit"} label={"Update"} />
                      <OutlineButton
                        colorType={"neutral"}
                        label={"Cancel"}
                        onClick={closeEdit}
                      />
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
