import * as Xstate from "@xstate/react";
import React from "react";
import { useForm } from "react-hook-form";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";

interface IAddNoteProps {
  getContact: () => void;
  setIsAddNote: (isAddNote: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  id: string;
}

const ContactAddNote: React.FC<IAddNoteProps> = ({
  getContact,
  setIsAddNote,
  id,
  setIsLoading,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      createdByUserRelayId: userInfoByEmail?.id,
      contactId: id,
      title: "",
      note: "",
    },
  });

  const addNote = async (data: any) => {
    const response = await fetch(
      "https://dev.procharted.com/api/contact-notes/add-contact-note",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      const data = await response.json();
      getContact();
      setIsAddNote(false);
    } else {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    addNote(data);
  };

  return (
    <>
      <div className={"fixed bottom-1/4 left-0 w-screen bg-transparent"}>
        <div className={"wrapper flex h-full items-end justify-center"}>
          <div className={"holder max-w-572 w-100 w-full"}>
            <div
              className={
                "text-18 mt-6 rounded-[15px] border-[1px] border-solid border-[#E8E9EA] bg-white p-10 shadow-[0px_4px_30px_rgba(34,34,34,0.15)]"
              }>
              <form
                className={"popup-form c-form "}
                onSubmit={handleSubmit(onSubmit)}>
                <div className={"mb-8 flex items-center justify-between"}>
                  <p className={"mb-0"}>Add note</p>
                  <div
                    className={"close-popup"}
                    onClick={() => setIsAddNote(false)}>
                    <img src={"/img/closse.png"} alt={""} />
                  </div>
                </div>
                <div className={"mb-4"}>
                  <input
                    type={"text"}
                    placeholder={"Title"}
                    className={"input"}
                    {...register("title")}
                  />
                </div>
                <div className={"mb-4"}>
                  <textarea
                    placeholder={"Note"}
                    className={"input"}
                    {...register("note")}></textarea>
                </div>
                <div className={"mb-8 flex items-center justify-end"}>
                  <div>
                    <button
                      onClick={() => setIsAddNote(false)}
                      type={"button"}
                      className={
                        "cancel-btn hover:bg-purple hover:border-purple close-popup mr-2 inline-block h-[40px] rounded-[7px] border-0  border-solid border-[#1F2937] px-[20px] text-[14px] font-bold uppercase leading-[40px] hover:text-white"
                      }>
                      cancel
                    </button>
                    <button
                      type={"submit"}
                      className={
                        "bg-purple border-purple inline-block h-[40px] rounded-[7px] border-solid  px-[20px] text-[14px] font-bold uppercase leading-[40px] text-white"
                      }>
                      save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactAddNote;
