import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../../app/constants/TextConstants";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { Alert } from "../../../components/alerts/alert";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import WorkTable from "../../../components/WorkTable";
import { inactiveStatusId, showStatus } from "../../../constants/constants";
import { CustomPage } from "../../../support/CustomLink";
import { FormSection } from "../../../support/FormSection";
import { IContactProfile, IWork } from "../../../types/types";
import { AddJobGlobalView } from "../../Works/AddWork/AddJobGlobalView";
import ErrorPopup from "../ErrorPopup";
interface ICustomerWorkViewProps {
  setActivePage: (activePage: "profile" | "notes" | "work") => void;
  activePage: "profile" | "notes" | "work";
}
const CustomerJobsListView: React.FC<ICustomerWorkViewProps> = ({
  setActivePage,
  activePage,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { customerId } = useParams();

  const [works, setWorks] = useState<IWork[]>([]);
  const [isAddWork, setIsAddWork] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const [contact, setContact] = useState<IContactProfile>(
    {} as IContactProfile,
  );

  const getContact = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/contacts/get/${customerId}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IContactProfile = await response.json();
      setContact(data);
      setIsActive(
        Boolean(
          data?.contactStatusTypeEnumId &&
            data?.contactStatusTypeEnumId !== inactiveStatusId,
        ),
      );
    } else {
    }
  };
  const getWorks = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/work/get-by-customer-id/${customerId}?orgId=${userInfoByEmail?.org?.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IWork[] = await response.json();
      const filterWorks = data.filter((work) => work.customer);
      setWorks(filterWorks);
    }
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getContact().then();
    getWorks().then(() => setIsLoading(false));
  });
  type SortOrderT =
    | { name: "Work Name"; sort: "asc" | "desc" }
    | { name: "Contact"; sort: "asc" | "desc" }
    | { name: "Status"; sort: "asc" | "desc" }
    | { name: "Start Date"; sort: "asc" | "desc" }
    | { name: "Due Date"; sort: "asc" | "desc" }
    | { name: "Progress"; sort: "asc" | "desc" }
    | { name: "Repeats"; sort: "asc" | "desc" }
    | { name: "Work Type"; sort: "asc" | "desc" }
    | { name: "Assignee"; sort: "asc" | "desc" };

  const [selectedSortOrder, setSelectedSortOrder] = React.useState<SortOrderT>({
    name: "Work Name",
    sort: "asc",
  });

  const onClickSort = (item: { id: number; body: SortOrderT }) => {
    Sort(
      item.body.name,
      selectedSortOrder.name === item.body.name
        ? item.body.sort === selectedSortOrder.sort
          ? "desc"
          : "asc"
        : "asc",
    );
    setSelectedSortOrder((prev) =>
      prev.name === item.body.name
        ? { ...prev, sort: prev.sort === "asc" ? "desc" : "asc" }
        : item.body,
    );
  };

  const Sort = (name: string, sort: string) => {
    const sortWork = works.sort(function (a, b): number {
      if (name === "Contact") {
        const nameA = a.customer?.name.toLowerCase() || "";
        const nameB = b.customer?.name.toLowerCase() || "";
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Status") {
        const nameA = showStatus(a).toLowerCase() || "";
        const nameB = showStatus(b).toLowerCase() || "";
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Start Date") {
        const nameA = a.startDate.toLowerCase();
        const nameB = b.startDate.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Due Date") {
        const nameA = a.dueDate.toLowerCase();
        const nameB = b.dueDate.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Progress") {
        const nameA = progress(a.workTasks);
        const nameB = progress(a.workTasks);
        if (nameA - nameB) {
          return 1;
        }
        if (nameA - nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Repeats") {
        const nameA = a.repeatTypeEnum?.repeatType.toLowerCase() || "";
        const nameB = b.repeatTypeEnum?.repeatType.toLowerCase() || "";
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Work Type") {
        const nameA = a.service?.name.toLowerCase();
        const nameB = b.service?.name.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
      if (name === "Assignee") {
        const nameA = a.assignedUser?.userProfile?.firstName.toLowerCase();
        const nameB = b.assignedUser?.userProfile?.firstName.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      } else {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      }
    });
    setWorks(sort === "asc" ? sortWork : sortWork.reverse());
  };
  const progress = (workTasks: any) => {
    const done = workTasks.filter((task: any) => task.isDone && task);
    const progress = (done.length / workTasks.length) * 100;
    return Math.ceil(progress);
  };

  const jobs = works;
  if (!jobs) {
    return <Alert type={"info"} label={LABELS.empty.none} />;
  }
  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <>
      <div className={"tabs my-8"}>
        <CustomPage
          onClick={() => setActivePage("profile")}
          isActive={activePage === "profile"}>
          {LABELS.features.profile}
        </CustomPage>
        <CustomPage
          onClick={() => setActivePage("work")}
          isActive={activePage === "work"}>
          {LABELS.features.work}
        </CustomPage>
        <CustomPage
          onClick={() => setActivePage("notes")}
          isActive={activePage === "notes"}>
          {LABELS.features.notes}
        </CustomPage>
      </div>
      <FormSection
        name={`All Work ( ${works.length} )`}
        flexForRightComponent={"flex"}
        RightSideComponent={
          <div className={"ml-10"}>
            <button
              className={
                "bg-purple flex items-center justify-center rounded-[7px] px-[20px] py-[10px] text-[14px] text-white hover:bg-purple-800"
              }
              onClick={() =>
                !isActive ? setIsError(true) : setIsAddWork(true)
              }>
              <div>Add {LABELS.features.work}</div>
            </button>
          </div>
        }>
        <div
          className={
            "card card-body overflow-x-auto border-base-300 bg-base-100 px-0 py-0"
          }>
          <WorkTable
            works={works}
            selectedSortOrder={selectedSortOrder}
            onClickSort={onClickSort}
            closeAddContact={() => null}
            isOpenAddContact={false}
            getWorks={getWorks}
            setIsLoading={setIsLoading}
          />

          {/*{paginationView}*/}
        </div>
      </FormSection>
      {isAddWork && (
        <AddJobGlobalView
          setIsAddWork={setIsAddWork}
          defaultContact={{
            id: contact?.customerId,
            name: `${contact?.firstName} ${contact?.lastName}`,
            isBusinessContact: false,
          }}
        />
      )}
      <ErrorPopup closePopup={() => setIsError(false)} isOpen={isError} />
    </>
  );
};
export default CustomerJobsListView;
