import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import {
  getInitialsFromContactName,
  timeFormatter,
} from "../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { getTimesByWork } from "../../services/AppService";
import { FormSection } from "../../support/FormSection";
import EditTime from "./EditTime";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";
import { ITime } from "../../types/types";

const WorkTime = () => {
  const { jobId } = useParams();
  const dispatch = useAppDispatch();

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { timesByWork, isLoading } = useAppSelector(
    (state) => state.appReducer,
  );
  const [isEditTime, setIsEditTime] = useState(false);
  const [currentTime, setCurrentTime] = useState<ITime>({} as ITime);
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);

  useEffect(() => {
    dispatch(
      getTimesByWork({
        jobId: `${jobId}`,
        orgId: `${userInfoByEmail?.org?.id}`,
      }),
    );
  }, []);

  if (isLoading) {
    return <LottieLoading />;
  }
  if (!timesByWork || timesByWork.length === 0) {
    return <></>;
  }

  return (
    <>
      <FormSection name={""}>
        <div>
          <table className={"w-full"}>
            <thead className={"border-b-[1px] border-gray-300"}>
              <tr className={"py-[16px] pr-[32px] pl-[16px] last:pr-[16px]"}>
                <th
                  className={
                    "py-[16px] pr-[32px] pl-[16px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Work
                </th>
                <th
                  className={
                    "py-[16px] pr-[32px] pl-[16px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Contact
                </th>
                <th
                  className={
                    "py-[16px] pr-[32px] pl-[16px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Work Type
                </th>
                <th
                  className={
                    "py-[16px] pr-[32px] pl-[16px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Total Time
                </th>
                <th
                  className={
                    "py-[16px] pr-[32px] pl-[16px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Date
                </th>
                <th
                  className={
                    "py-[16px] pr-[32px] pl-[16px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Assignee
                </th>
                <th
                  className={
                    "py-[16px] pr-[32px] pl-[16px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Notes
                </th>
              </tr>
            </thead>
            <tbody>
              {timesByWork.map((time) => (
                <tr
                  key={time?.id}
                  className={
                    "border-b-[1px] border-gray-300 hover:bg-gray-100"
                  }>
                  <td className={"px-[16px] py-[8px] align-top"}>
                    <div className={"flex h-[40px] items-center"}>
                      <div className={"max-w-[300px] whitespace-normal"}>
                        <Link
                          to={`/job/${time?.workId}`}
                          className={
                            "link-primary text-sm font-[500] hover:underline"
                          }>
                          {time?.work?.name}
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td className={"px-[16px] py-[8px] align-top"}>
                    <div className={"flex items-center"}>
                      {time.contactId ? (
                        <div className={"flex items-center text-[14px]"}>
                          <AvatarPlaceholder
                            size={"extra-small"}
                            label={
                              time?.contact
                                ? `${time?.contact?.firstName[0]}${time?.contact?.lastName[0]}`
                                : ""
                            }
                          />
                          <div className={"pl-2 text-[14px] normal-case"}>
                            <Link
                              to={
                                time?.contact
                                  ? `/client/${time?.contact.id}`
                                  : ""
                              }
                              className={
                                "link-primary text-sm font-[500] hover:underline"
                              }>
                              {time?.contact
                                ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
                                : "Contact name"}
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className={"flex items-center text-[14px]"}>
                          <AvatarPlaceholder
                            size={"extra-small"}
                            type={"Organization"}
                            label={getInitialsFromContactName(
                              time?.businessContact?.companyName || "N/A",
                            )}
                          />
                          <div className={"pl-2 text-[14px] normal-case"}>
                            <Link
                              to={
                                time?.contact
                                  ? `/organization/${time?.businessContactId}`
                                  : ""
                              }
                              className={
                                "link-primary text-sm font-[500] hover:underline"
                              }>
                              {time?.businessContact
                                ? time?.businessContact.companyName
                                : "Contact name"}
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                    <div className={"flex h-[40px] items-center"}>
                      {time?.work?.service?.name}
                    </div>
                  </td>
                  <td
                    onClick={() => {
                      setCurrentTime(time);
                      setIsEditTime(true);
                    }}
                    className={"px-[16px] py-[8px] align-top "}>
                    <div
                      className={
                        "link-primary flex h-[40px] cursor-pointer items-center text-sm font-[500] hover:underline"
                      }>
                      {timeFormatter(time?.duration)}
                    </div>
                  </td>
                  <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                    <div className={"flex h-[40px] items-center"}>
                      {moment(time?.date).format("YYYY-MM-DD")}
                    </div>
                  </td>
                  <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                    <div className={"flex items-center"}>
                      <AvatarPlaceholder
                        size={"extra-small"}
                        label={
                          time?.assignedUser?.userProfile
                            ? `${time?.assignedUser?.userProfile.firstName[0]}${time.assignedUser.userProfile.lastName[0]}`
                            : "N/A"
                        }
                      />
                      <div className={"pl-2 text-[14px] normal-case"}>
                        {time?.assignedUser?.userProfile
                          ? `${time?.assignedUser?.userProfile?.firstName} ${time.assignedUser.userProfile.lastName}`
                          : "User name"}
                      </div>
                    </div>
                  </td>
                  <td
                    className={"px-[16px] py-[8px] align-top text-[14px]"}
                    onClick={() => {
                      setCollapsedItems((prev) =>
                        collapsedItems.includes(time?.id)
                          ? prev.filter((item) => item !== time?.id)
                          : [...prev, time?.id],
                      );
                    }}>
                    <div
                      className={`${
                        !collapsedItems.includes(time?.id)
                          ? "...  max-w-[370px] overflow-hidden text-ellipsis whitespace-nowrap"
                          : " max-w-[370px] whitespace-pre-line"
                      }`}>
                      {time?.notes}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </FormSection>
      {isEditTime && (
        <EditTime
          isOpen={isEditTime}
          closeModal={() => {
            setIsEditTime(false);
            setCurrentTime({} as ITime);
          }}
          openModal={() => setIsEditTime(true)}
          time={currentTime}
        />
      )}
    </>
  );
};

export default WorkTime;
