import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import { getIndividualContacts } from "../../../../api/api";
import { LABELS } from "../../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../../app/constants/ValidationConstants";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import Button from "../../../../components/button/Button";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import SearchContact from "../../../../components/searchContact";
import { GetPageTitle } from "../../../../support/ScrollToTop";
import {
  IContact,
  IContactType,
  ICustomers,
  IEmail,
  ILinkedProfile,
  IPhoneNumber,
  IStatusTypeContact,
} from "../../../../types/types";

type Props = {
  linkedProfile?: ILinkedProfile;
  closeModalPopup: () => void;
  getContact: () => void;
};

export const UpdateLinkedProfile: React.FC<Props> = ({
  linkedProfile,
  closeModalPopup,
  getContact,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [contacts, setContacts] = useState<IContact[]>([]);
  const getContacts = async () => {
    const data = await getIndividualContacts(true, userInfoByEmail!.org!.id);
    setContacts(data);
  };
  useEffectOnce(() => {
    document.title = GetPageTitle("Update Linked Profiles");
    getContacts();
  });
  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    setValue,
  } = useForm({
    defaultValues: {
      contactId: linkedProfile?.linkedContactId,
      relation: linkedProfile?.relation,
    },
  });
  // local effects
  React.useEffect(() => {
    setFocus("relation");
  }, []);

  // RHF submit handler
  const onSubmit = async (data: any) => {
    const updateLinkedProfiles = async () => {
      const response = await fetch(
        "https://dev.procharted.com/api/contacts/linked-profiles/update",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: getAuthHeader().Authorization,
          },
          method: "PUT",
          body: JSON.stringify({
            contactId: linkedProfile?.contactId,
            id: linkedProfile?.id,
            relation: data.relation,
            linkedContactId: data.contactId,
          }),
        },
      );
      if (response.ok) {
        closeModalPopup();
        getContact();
      }
    };
    updateLinkedProfiles();
  };

  const { spouseRule } = ValidationConstants.customerRules;

  const spouseRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Name</span>
        </label>
        <SearchContact
          isLinkedProfile={true}
          isIdLikeContactId={true}
          saveContact={(contact) => {
            setValue("contactId", contact.id);
            setContacts((prev) => [
              ...prev,
              {
                id: contact.id,
                firstName: contact.name.split(" ")[0],
                lastName:
                  contact.name.split(" ")[contact.name.split(" ").length - 1],
                contactType: {} as IContactType,
                address: "",
                contactStatusEnumId: "",
                contactTypeEnumId: "",
                createdAt: "",
                customers: [] as ICustomers[],
                email: {} as IEmail,
                customerType: "",
                middleName: "",
                openWorkCount: 0,
                phoneNumber: "",
                phoneNumbers: [] as IPhoneNumber[],
                socialIdentificationNumber: "",
                statusType: {} as IStatusTypeContact,
              },
            ]);
          }}
          contacts={contacts.map((item) => ({
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            isBusinessContact: false,
          }))}
          defaultContact={{
            isBusinessContact: false,
            name: `${linkedProfile?.linkedProfile.firstName} ${linkedProfile?.linkedProfile.lastName}`,
            id: `${linkedProfile?.linkedContactId}`,
          }}
        />
        {errors?.contactId?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.contactId?.message}
          </span>
        )}
      </div>
    </>
  );
  const relationShipRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Relationship</span>
        </label>
        <input
          type={"text"}
          {...register("relation", {
            required: LABELS.required,
            ...spouseRule.valueLength,
          })}
          className={"input input-bordered"}
          placeholder={"Relationship"}
        />
        {errors?.relation?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.relation?.message}
          </span>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Update Linked Profiles</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {spouseRow}
        {relationShipRow}
        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <Button buttonType={"submit"} label={"Update"} />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => closeModalPopup()}
          />
        </div>
      </form>
    </>
  );
};
