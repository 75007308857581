import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { FormSection } from "../../../support/FormSection";
import { IWorkNote } from "../../../types/types";
import AddNote from "./AddNote";
import EditNote from "./EditNote";

const WorkNotes = () => {
  const { jobId } = useParams();
  const [workNotes, setWorkNotes] = useState<IWorkNote[]>();
  const [isAddNote, setIsAddNote] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState<IWorkNote>();
  const getWorkNotes = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/work-notes/${jobId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IWorkNote[] = await response.json();
      const sortedData = data.sort(
        (a, b) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
      );

      const pinnedNotes = sortedData.filter((note) => note.isPinned);
      const unpinnedNotes = sortedData.filter((note) => !note.isPinned);

      setWorkNotes([...pinnedNotes, ...unpinnedNotes]);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getWorkNotes();
  });

  const deleteNote = async (data: IWorkNote) => {
    const response = await fetch("https://dev.procharted.com/api/work-notes", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "DELETE",
      body: JSON.stringify(data),
    });
    if (response.ok) {
      await response.json();
      getWorkNotes();
    } else {
      setIsLoading(false);
    }
  };
  const pinNote = async (data: IWorkNote) => {
    const response = await fetch(
      `https://dev.procharted.com/api/work-notes/mark-pinned?workNoteId=${
        data.id
      }&isPinned=${!data.isPinned}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({}),
      },
    );
    if (response.ok) {
      await response.json();
      getWorkNotes();
    } else {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <LottieLoading />;
  }
  return (
    <>
      <div>
        <FormSection name={"Notes"}>
          <button
            className={
              "open-popup add-task border-purple text-purple mt-8 block w-full rounded-[7px] border-[1px] border-solid px-[20px] py-[15px] text-[14px]"
            }
            onClick={() => setIsAddNote(true)}>
            <img src={"/img/plus.png"} alt={""} className={"mr-[17px]"} /> Add
            Note
          </button>
          {workNotes?.map((note) => (
            <div
              key={note.id}
              className={
                "rounded-[7px]  border-[1px] border-[#AFAFAF] bg-[#F9FAFB] px-[20px] py-[15px]"
              }>
              <div className={"flex justify-between"}>
                <div className={"text-14 font-bold "}>{note.title}</div>
                <div className={"text-[14px] font-bold"}>
                  {note.isPinned && "Pinned"}
                </div>
              </div>
              <div className={"mb-4 text-[14px] text-[#6A7584]"}>
                {note.note}
              </div>
              <div className={"flex justify-between"}>
                <div className={"flex items-center space-x-3 text-[14px]"}>
                  <AvatarPlaceholder
                    size={"extra-small"}
                    label={
                      note.createdByUser?.userProfile?.firstName &&
                      note.createdByUser?.userProfile?.lastName
                        ? `${note.createdByUser?.userProfile?.firstName.substring(
                            0,
                            1,
                          )}${note.createdByUser?.userProfile?.lastName.substring(
                            0,
                            1,
                          )}`
                        : "User"
                    }
                  />
                  <div className={"text-[12px] text-gray-400"}>
                    {`${note.createdByUser?.userProfile?.firstName} ${note.createdByUser?.userProfile?.lastName}`}
                  </div>
                </div>
                <div className={"flex"}>
                  <div
                    className={"mr-4 flex items-end text-[12px] text-gray-400"}>
                    {moment(note.createdAt).format("MMM DD, YYYY")}
                  </div>
                  <Button
                    buttonType={"button"}
                    label={"Edit"}
                    onClick={() => {
                      setNote(note);
                      setIsEditNote(true);
                    }}
                    extraClasses={"normal-case mr-4"}
                  />
                  <div
                    className={
                      "mr-4 cursor-pointer rounded-[5px] p-[5px] hover:bg-gray-200"
                    }
                    onClick={() => {
                      setIsLoading(true);
                      pinNote(note);
                    }}>
                    <img
                      src={"/img/pin.png"}
                      alt={"pin"}
                      className={"w-[20px] "}
                    />
                  </div>
                  <div
                    onClick={() => {
                      setIsLoading(true);
                      deleteNote(note);
                    }}
                    className={
                      "cursor-pointer rounded-[5px] p-[5px] hover:bg-gray-200"
                    }>
                    <img
                      src={"/img/trash.png"}
                      alt={"delete"}
                      className={"w-[20px] "}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </FormSection>
      </div>
      {isAddNote && (
        <AddNote
          setIsAddNote={setIsAddNote}
          getWorkNotes={getWorkNotes}
          setIsLoading={setIsLoading}
        />
      )}
      {isEditNote && (
        <EditNote
          setIsEditNote={setIsEditNote}
          getWorkNotes={getWorkNotes}
          note={note}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default WorkNotes;
