import React from "react";
import {
  Link,
  LinkProps,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

export const CustomLink: React.FC<LinkProps> = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to);
  const location = useLocation();
  const linkPath = resolved.pathname.split("/").filter((item) => item !== "");
  const locationPath = location.pathname
    .split("/")
    .filter((item) => item !== "");

  const match = linkPath.every((element) => locationPath.includes(element));

  const tabBaseClass = "tab tab-bordered text-[16px]";
  const currentTabClass = match ? "tab-active text-[16px]" : "";

  return (
    <div>
      <Link className={`${tabBaseClass} ${currentTabClass}`} to={to} {...props}>
        {children}
      </Link>
    </div>
  );
};

interface ICustomPageProps {
  onClick: () => void;
  extraClasses?: string;
  isActive: boolean;
}

export const CustomPage: React.FC<ICustomPageProps> = ({
  onClick,
  isActive,
  extraClasses,
  children,
  ...props
}) => {
  const tabBaseClass = "tab tab-bordered text-[16px]";
  const currentTabClass = isActive ? "tab-active text-[16px]" : "";

  return (
    <div
      onClick={onClick}
      className={`${tabBaseClass} ${currentTabClass} ${extraClasses}`}
      {...props}>
      {children}
    </div>
  );
};
