import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import EditDots from "../../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import NoItems from "../../../../components/NoItems";
import ContactNoteSvg from "../../../../components/svg/ContactNoteSvg";
import PlusSvg from "../../../../components/svg/PlusSvg";
import { FormSection } from "../../../../support/FormSection";
import { IOrganizationContactNote, IWorkNote } from "../../../../types/types";
import OrganizationAddNote from "../OrganizationAddNote";
import OrganizationEditNote from "../OrganizationEditNote";

interface IOrganizationNotesBlockProps {
  contactId?: string;
}
const OrganizationNotesBlock: React.FC<IOrganizationNotesBlockProps> = ({}) => {
  const { organizationId } = useParams();
  const [contactNotes, setContactNotes] = useState<IOrganizationContactNote[]>(
    [],
  );
  const [collapsedNotes, setCollapsedNotes] = useState<string[]>([]);
  const [currentNote, setCurrentNote] = useState();
  const [isEditNote, setIsEditNote] = useState(false);
  const [isAddNote, setIsAddNote] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getOrganizationContactNotes = async () => {
    const response = await fetch(
      `https://dev.procharted.com/api/business-contact-notes/get-by-business-contact-id/${organizationId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IOrganizationContactNote[] = await response.json();
      const sortedData = data.sort(
        (a, b) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
      );

      const pinnedNotes = sortedData.filter((note) => note.isPinned);
      const unpinnedNotes = sortedData.filter((note) => !note.isPinned);
      setContactNotes([...pinnedNotes, ...unpinnedNotes]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const deleteNote = async (data: IWorkNote) => {
    const response = await fetch(
      "https://dev.procharted.com/api/business-contact-notes",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      getOrganizationContactNotes().then();
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getOrganizationContactNotes().then();
  }, [organizationId]);

  return (
    <>
      <FormSection
        customCx={"my-0 p-0"}
        extraCx={"space-y-0 h-fit static"}
        viewFormat={"custom"}
        name={""}
        showHeading={false}>
        <div className={"flex justify-between p-6"}>
          <div className={"text-lg font-bold"}>Notes</div>
          <div>
            <OutlineButton
              buttonType={"button"}
              label={""}
              colorType={"neutral"}
              icon={<PlusSvg />}
              onClick={() => setIsAddNote(true)}
            />
          </div>
        </div>
        <div style={{ marginTop: 0 }}>
          {isLoading ? (
            <LottieLoading />
          ) : contactNotes.length === 0 ? (
            <div className={"p-6 pt-0"}>
              <NoItems
                icon={<ContactNoteSvg />}
                message={"There are no notes"}
                addButtonMessage={"Add Note"}
                openAdd={() => setIsAddNote(true)}
              />
            </div>
          ) : (
            contactNotes?.map((note: any, index: number) => (
              <div key={note.id}>
                {collapsedNotes.includes(note.id) ? (
                  <div
                    className={
                      "mx-6 mb-2 rounded-[12px] border-[1px] bg-gray-100 p-2"
                    }>
                    <div
                      className={
                        "flex  justify-end text-[12px] text-gray-400 "
                      }>
                      {moment(note.createdAt).format("MMM DD, YYYY")}
                    </div>
                    <div
                      className={
                        "mb-2 whitespace-pre-wrap break-all text-[14px] font-[500]"
                      }>
                      {note.title}
                    </div>
                    <div className={"mb-2 whitespace-pre-line text-[12px]"}>
                      {note.note}
                    </div>
                    <div className={"flex items-center justify-between"}>
                      <div className={"flex items-center gap-2"}>
                        <AvatarPlaceholder
                          size={"super-small"}
                          label={
                            note.createdByUser?.userProfile?.firstName &&
                            note.createdByUser?.userProfile?.lastName
                              ? `${note.createdByUser?.userProfile?.firstName.substring(
                                  0,
                                  1,
                                )}${note.createdByUser?.userProfile?.lastName.substring(
                                  0,
                                  1,
                                )}`
                              : "N/A"
                          }
                        />
                        <div className={"text-[12px] text-gray-400"}>
                          {note.createdByUser
                            ? `${note.createdByUser?.userProfile?.firstName} ${note.createdByUser?.userProfile?.lastName}`
                            : "N/A"}
                        </div>
                      </div>
                      <button
                        onClick={() =>
                          setCollapsedNotes((prev) =>
                            prev.filter((item) => item !== note.id),
                          )
                        }
                        className={
                          "bg-purple whitespace-nowrap rounded-[7px] px-4 py-2 text-[12px] uppercase text-white"
                        }>
                        OK
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className={"px-6 hover:bg-gray-100"}>
                    <div
                      onClick={() =>
                        setCollapsedNotes((prev) => [...prev, note.id])
                      }
                      className={
                        "flex cursor-pointer items-center justify-between py-4"
                      }>
                      <div
                        className={"flex items-center space-x-3 text-[14px]"}>
                        <AvatarPlaceholder
                          size={"super-small"}
                          label={
                            note.createdByUser?.userProfile?.firstName &&
                            note.createdByUser?.userProfile?.lastName
                              ? `${note.createdByUser?.userProfile?.firstName.substring(
                                  0,
                                  1,
                                )}${note.createdByUser?.userProfile?.lastName.substring(
                                  0,
                                  1,
                                )}`
                              : "N/A"
                          }
                        />
                        <div>
                          <div
                            className={
                              "... mb:max-w-[100px] overflow-hidden  text-ellipsis  whitespace-nowrap text-[14px] font-[500] sm:max-w-[150px] xl:max-w-[200px] 2xl:max-w-[330px]"
                            }>
                            {note.title || "No title"}
                          </div>
                          <div
                            className={
                              "... mb:max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap  text-[12px] text-gray-400 sm:max-w-[150px] xl:max-w-[200px] 2xl:max-w-[330px] "
                            }>
                            {note.note}
                          </div>
                        </div>
                      </div>
                      <div>
                        <EditDots>
                          <div className={""}>
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                setCurrentNote(note);
                                setIsEditNote(true);
                              }}
                              className={
                                "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                              }>
                              Edit
                            </div>
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                setIsLoading(true);
                                deleteNote(note);
                              }}
                              className={
                                "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                              }>
                              Delete
                            </div>
                          </div>
                        </EditDots>
                      </div>
                    </div>
                    {Boolean(
                      !collapsedNotes.includes(note.id) &&
                        !collapsedNotes.includes(contactNotes[index + 1]?.id) &&
                        index !== contactNotes.length - 1,
                    ) && <div className={"h-[1px] bg-[#E8E9EA]"}></div>}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </FormSection>
      {isAddNote && (
        <OrganizationAddNote
          setIsAddNote={setIsAddNote}
          id={`${organizationId}`}
          setIsLoading={setIsLoading}
          getOrganization={getOrganizationContactNotes}
        />
      )}
      {isEditNote && (
        <OrganizationEditNote
          setIsEditNote={setIsEditNote}
          note={currentNote}
          setIsLoading={setIsLoading}
          getOrganization={getOrganizationContactNotes}
        />
      )}
    </>
  );
};

export default OrganizationNotesBlock;
