export const PHONE_NUMBER_TYPE_ENUM_GET_ALL = "api/phone-num-types/all";
export const CONTACT_TYPE_ENUM_GET_ALL = "api/contact-types/all";
export const CONTACT_ADD_INDIVIDUAL_GET = "api/contacts/add-individual";
export const CONTACT_ADD_INDIVIDUAL_POST = "api/contacts/add-individual";
export const CONTACT_UPDATE_INDIVIDUAL_POST = "api/contacts/update-individual";
export const CONTACT_INDIVIDUAL_GET_BY_ID = (customerId: string) => {
  return `api/contacts/get/${customerId}`;
};
export const WORK_GET_BY_ID = (workId: any) => {
  return `api/work/get-by-work-id/${workId}`;
};
export const WORK_TASK_CHANGE_STATUS = (
  taskId: string | null,
  isDone: boolean,
  isTemplate?: boolean,
) => {
  return isTemplate
    ? `api/template-task/change-status/${taskId}/${isDone}`
    : `api/work-tasks/${taskId}/${isDone}`;
};
export const WORK_TASK_CHANGE_ORDER = () => {
  return `api/work-tasks/change-order`;
};
export const WORK_TASK_ADD = `api/work-tasks/add-task`;
export const WORK_TASK_DELETE = `api/work-tasks`;
export const WORK_TASK_EDIT = `api/work-tasks/update-task`;
export const WORK_UPDATE_DATE = `api/work/update-due-date`;
export const WORK_MARK_AS_COMPLETED = (workId: string | null) => {
  return `api/work/mark-as-completed/${workId}`;
};

export const ORG_GET_BY_ID = (orgId: any) => {
  return `api/org/${orgId}`;
};
export const ORG_UPDATE = `api/org/update-org`;
