import { ROUTE_NAMES } from "../../app/constants/TextConstants";
import {
  DashboardIcon,
  HomeIcon,
  SettingsIcon,
  TimeIcon,
  UsersIcon,
  WorkIcon,
} from "../../components/svg/navIcons";
import { CustomerView } from "../../features/customers/contact/contactProfile/CustomerView";
import { CustomersView } from "../../features/customers/CustomersView";
import OrganizationView from "../../features/customers/organization/organizationProfile/OrganizationView";
import { DashboardView } from "../../features/dashboard/DashboardView";
import { BankDocView } from "../../features/documents/bankStatements/BankDocView";
import { HomeAuthView } from "../../features/home/HomeAuthView";
import { SignOutView } from "../../features/main/SignOutView";
import { SettingsView } from "../../features/settings/SettingsView";
import { TimeView } from "../../features/time/TimeView";
import { UserView } from "../../features/user/UserView";
import { WorkView } from "../../features/Work/WorkView";
import { WorksView } from "../../features/Works/WorksView";

export const sideNavRouteMap = [
  { name: ROUTE_NAMES.home, component: HomeAuthView, icon: HomeIcon },
  {
    name: ROUTE_NAMES.dashboard,
    component: DashboardView,
    icon: DashboardIcon,
  },

  {
    name: ROUTE_NAMES.clients.index,
    component: CustomersView,
    icon: UsersIcon,
  },

  { name: ROUTE_NAMES.work, component: WorksView, icon: WorkIcon },
  { name: "time", component: TimeView, icon: TimeIcon },
  /*{ name: ROUTE_NAMES.billing, component: BillingView, icon: BillingSvg },*/
  {
    name: ROUTE_NAMES.settings.index,
    component: SettingsView,
    icon: SettingsIcon,
  },
];

export const invisibleRouteMap = [
  // { name: "add-new-firm", component: AddNewFirm },
  { name: "/client/:customerId", component: CustomerView },
  { name: "/organization/:organizationId", component: OrganizationView },
  { name: ROUTE_NAMES.documents.bankDoc.index, component: BankDocView },
  { name: ROUTE_NAMES.signOut, component: SignOutView },
  { name: ROUTE_NAMES.user.index, component: UserView },
  { name: "/job/:jobId", component: WorkView },
];
